import type { UntitledIconData } from '@faceup/icons'

export const ucMessageSettings: UntitledIconData = {
  prefix: 'custom',
  name: 'messageSettings',
  viewBox: '0 0 20 20',
  paths: [
    {
      shape:
        'M5.04102 3.54161L5.54158 3.54102C4.05397 3.54102 4.01849 3.54102 3.4503 3.83052C2.95051 4.08518 2.54416 4.49152 2.28951 4.99132C2 5.5595 2 6.30331 2 7.79091V12.395C2 13.2183 2 13.63 2.09051 13.9678C2.33612 14.8844 3.05208 15.6004 3.96871 15.846C4.30649 15.9365 4.71818 15.9365 5.54158 15.9365V18.0044C5.54158 18.4762 5.54158 18.712 5.63829 18.8332C5.72239 18.9386 5.84993 18.9999 5.98475 18.9997C6.13977 18.9995 6.32397 18.8522 6.69237 18.5575L8.80444 16.8678C9.2359 16.5226 9.45162 16.3501 9.69185 16.2273C9.90497 16.1185 10.1318 16.0389 10.3663 15.9908C10.6305 15.9365 10.9068 15.9365 11.4593 15.9365H12.8018C14.2894 15.9365 15.0332 15.9365 15.6014 15.647C16.1012 15.3924 16.5075 14.986 16.7622 14.4862C17.0517 13.918 17.0517 13.8826 17.0517 12.395L17.0391 13.5123',
      stroke: 'currentColor',
      strokeWidth: '1.77079',
      strokeLinecap: 'round',
      strokeLinejoin: 'round',
    },
    {
      shape:
        'M11.6948 10.6279L12.0154 11.349C12.1107 11.5637 12.2663 11.7461 12.4632 11.8741C12.6601 12.0021 12.89 12.0702 13.1249 12.0702C13.3597 12.0702 13.5896 12.0021 13.7865 11.8741C13.9834 11.7461 14.139 11.5637 14.2343 11.349L14.5549 10.6279C14.6691 10.372 14.8611 10.1587 15.1036 10.0183C15.3476 9.87754 15.6299 9.81758 15.91 9.84701L16.6946 9.93052C16.9281 9.95522 17.1638 9.91164 17.3731 9.80506C17.5823 9.69849 17.7562 9.53349 17.8735 9.33008C17.991 9.12679 18.047 8.89377 18.0347 8.65929C18.0223 8.42481 17.9422 8.19895 17.804 8.00911L17.3395 7.37088C17.1741 7.14193 17.0857 6.86635 17.0871 6.58391C17.0871 6.30223 17.1763 6.02779 17.342 5.79998L17.8065 5.16175C17.9446 4.97191 18.0248 4.74605 18.0371 4.51157C18.0494 4.27709 17.9935 4.04407 17.8759 3.84078C17.7586 3.63737 17.5847 3.47237 17.3755 3.3658C17.1662 3.25922 16.9305 3.21564 16.697 3.24034L15.9125 3.32385C15.6323 3.35328 15.35 3.29332 15.106 3.15256C14.863 3.0114 14.671 2.79694 14.5574 2.53993L14.2343 1.81879C14.139 1.60413 13.9834 1.42174 13.7865 1.29373C13.5896 1.16573 13.3597 1.09762 13.1249 1.09766C12.89 1.09762 12.6601 1.16573 12.4632 1.29373C12.2663 1.42174 12.1107 1.60413 12.0154 1.81879L11.6948 2.53993C11.5812 2.79694 11.3891 3.0114 11.1461 3.15256C10.9021 3.29332 10.6198 3.35328 10.3397 3.32385L9.5527 3.24034C9.31916 3.21564 9.08348 3.25922 8.87422 3.3658C8.66496 3.47237 8.49112 3.63737 8.37376 3.84078C8.25626 4.04407 8.20028 4.27709 8.21261 4.51157C8.22495 4.74605 8.30507 4.97191 8.44325 5.16175L8.90776 5.79998C9.07341 6.02779 9.16261 6.30223 9.16256 6.58391C9.16261 6.86558 9.07341 7.14002 8.90776 7.36783L8.44325 8.00606C8.30507 8.1959 8.22495 8.42176 8.21261 8.65624C8.20028 8.89072 8.25626 9.12374 8.37376 9.32703C8.49123 9.53034 8.6651 9.69524 8.87433 9.80181C9.08356 9.90837 9.31918 9.95202 9.5527 9.92747L10.3372 9.84396C10.6174 9.81453 10.8997 9.87449 11.1437 10.0152C11.3876 10.156 11.5805 10.3705 11.6948 10.6279Z',
      stroke: 'currentColor',
      strokeWidth: '1.5',
      strokeLinecap: 'round',
      strokeLinejoin: 'round',
    },
    {
      shape:
        'M13.1239 8.22978C14.0329 8.22978 14.7697 7.4929 14.7697 6.58391C14.7697 5.67491 14.0329 4.93803 13.1239 4.93803C12.2149 4.93803 11.478 5.67491 11.478 6.58391C11.478 7.4929 12.2149 8.22978 13.1239 8.22978Z',
      stroke: 'currentColor',
      strokeWidth: '1.5',
      strokeLinecap: 'round',
      strokeLinejoin: 'round',
    },
  ],
}
