import styled from '@emotion/styled'
import { UntitledIcon, type UntitledIconData } from '@faceup/icons'
import { Space, Typography } from '@faceup/ui-base'
import { AttachmentRedact } from '../Redaction'

const { Text } = Typography

type Attachment = { id: string; name: string; url: string; mimetype: string }
type AttachmentListProps = {
  icons: {
    attachment: UntitledIconData
  }
  attachments: Attachment[]
  download: (payload: Attachment) => void
  backgroundColor?: string
  color?: string
}

const ChatAttachmentList = (props: AttachmentListProps) => {
  if (!props.attachments.length) {
    return null
  }

  return (
    <AttachmentsWrapper color={props.color} backgroundColor={props.backgroundColor}>
      {props.attachments.map(attachment => (
        <AttachmentWrap key={attachment.url}>
          <AttachmentSpace>
            <UntitledIcon icon={props.icons.attachment} />
            <AttachmentRedact
              type={{
                type: 'attachments',
                id: attachment.id,
              }}
              text={attachment.name}
            >
              <AttachmentName underline onClick={() => props.download(attachment)}>
                {attachment.name}
              </AttachmentName>
            </AttachmentRedact>
          </AttachmentSpace>
        </AttachmentWrap>
      ))}
    </AttachmentsWrapper>
  )
}

const AttachmentsWrapper = styled.div<{ color?: string; backgroundColor?: string }>`
  background-color: ${({ backgroundColor }) => backgroundColor ?? '#c1e5fc'};
  color: ${({ color }) => color ?? '#0e9af7'};
  padding: 5px 16px;
`

const AttachmentSpace = styled(Space)`
  max-width: 100%;

  .ant-space-item {
    max-width: calc(100% - 20px);
  }
`

const AttachmentName = styled(Text)`
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
`

const AttachmentWrap = styled.div`
  padding: 5px 0;
  display: flex;
  align-items: center;
`

export default ChatAttachmentList
