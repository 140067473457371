import {
  FormattedMessage as FMessage,
  type IntlFormatters,
  type IntlShape,
  type MessageDescriptor,
  defineMessages as dMessages,
  useIntl as uIntl,
} from 'react-intl'
import type { BaseTranslations } from './locales'

export type TypedMessageDescriptor = MessageDescriptor & {
  id: keyof BaseTranslations
}

export type DefineMessagesType<KT extends string = string> = Record<KT, TypedMessageDescriptor>

type DefineMessages = <KT extends string = string>(
  msgs: Record<KT, keyof BaseTranslations>
) => Record<KT, TypedMessageDescriptor>
export const defineMessages: DefineMessages = msgs =>
  dMessages(
    Object.fromEntries(
      Object.entries(msgs).map(([key, value]) => [key, { id: value }])
    ) as ReturnType<DefineMessages>
  )

export const FormattedMessage = (
  props: React.ComponentProps<typeof FMessage> & TypedMessageDescriptor
) => <FMessage {...props} />

type Tail<T extends unknown[]> = T extends [unknown, ...infer Rest] ? Rest : T

export type FormatMessageFn =
  | IntlFormatters['formatMessage']
  | IntlFormatters<React.ReactNode>['formatMessage']
type FormatMessage = (
  descriptor: TypedMessageDescriptor,
  ...args: Tail<Parameters<FormatMessageFn>>
) => string

type TypedIntlShape = Omit<IntlShape, 'formatMessage'> & { formatMessage: FormatMessage }

export const useIntl = () => uIntl() as TypedIntlShape
