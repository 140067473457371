import { defineMessages } from './TypedIntl'

export const sharedMessages = defineMessages({
  apiError: 'Shared.global.apiError',
  attachmentsTooManyFiles: 'Shared.attachments.tooManyFiles',
  attachmentsTooLargeFile: 'Shared.attachments.tooLargeFile',
  metadataRemoved: 'Shared.attachments.metadataRemoved',
  metadataUnableToRemove: 'Shared.attachments.metadataUnableToRemove',
  unsupportedMimetype: 'Shared.attachments.unsupportedMimetype',
  submit: 'Shared.action.send',

  fieldRequired: 'Shared.global.invalidInput',
  selectPlaceholder: 'Shared.global.select.placeholder',
  encryptionError: 'Shared.global.encryptionError',

  // action
  send: 'Shared.action.send',
  delete: 'Shared.action.delete',
  download: 'Shared.action.download',
  cancel: 'Shared.cancel',
  ok: 'Shared.ok',

  bio: 'Shared.bio',
  partnerBio: 'Shared.bio.partner',
})
