import { gql, useMutation } from '@apollo/client'
import { UntitledIcon } from '@faceup/icons'
import { ulPlus } from '@faceup/icons/ulPlus'
import { useNavigate } from '@faceup/router'
import { Button, Switch } from '@faceup/ui'
import {
  Form,
  Input,
  MinusCircleOutlined,
  Modal,
  PlusOutlined,
  Select,
  notification,
} from '@faceup/ui-base'
import { convertCountryToCountryName, isEmail } from '@faceup/utils'
import { Group } from '@mantine/core'
import moment from 'moment-timezone'
import { useState } from 'react'
import {
  Country,
  type CreateDemoCompany,
  type CreateDemoCompanyVariables,
  Institution,
  Plan,
} from '../../__generated__/globalTypes'

const { Option } = Select

const mutation = {
  CreateDemoCompany: gql`
    mutation CreateDemoCompany($input: CreateDemoCompanyInput!) {
      createDemoCompany(input: $input) {
        company {
          id
        }
      }
    }
  `,
}

type Props = {
  institutionType: Institution
}

const CreateCompany = ({ institutionType }: Props) => {
  const [visible, setVisible] = useState(false)

  const [name, setName] = useState({ value: '', error: false })
  const [country, setCountry] = useState({ value: '', error: false })
  const [plan, setPlan] = useState({ value: '', error: false })
  const [timezone, setTimezone] = useState({ value: '', error: false })
  const [importMockData, setImportMockData] = useState(false)
  const [timezones, setTimezones] = useState(moment.tz.names())
  const [managers, setManagers] = useState<
    {
      error: boolean
      email: { value: string }
    }[]
  >([])

  const navigate = useNavigate()

  const resetForm = () => {
    setName({ value: '', error: false })
    setCountry({ value: '', error: false })
    setPlan({ value: '', error: false })
    setTimezone({ value: '', error: false })
    setImportMockData(false)
    setTimezones(moment.tz.names())
    setManagers([])
  }

  const [createDemoCompany, { loading }] = useMutation<
    CreateDemoCompany,
    CreateDemoCompanyVariables
  >(mutation.CreateDemoCompany, {
    onError: error => {
      console.error(error)
      notification.error({
        message: 'Demo Company Not Created',
        description: error.message,
      })
    },
    onCompleted: data => {
      setVisible(!visible)
      resetForm()
      navigate(routes => routes.institution({ id: data.createDemoCompany?.company?.id ?? '' }))
    },
  })

  const validateForm = () => {
    let ok = true

    if (!name.value) {
      setName({ ...name, error: true })
      ok = false
    }
    if (!country.value) {
      setCountry({ ...country, error: true })
      ok = false
    }
    if (!plan.value) {
      setPlan({ ...plan, error: true })
      ok = false
    }
    if (!timezone.value) {
      setTimezone({ ...timezone, error: true })
      ok = false
    }

    setManagers(
      managers.map(manager => {
        if (!manager.email.value || !isEmail(manager.email.value.trim())) {
          ok = false
          return { ...manager, error: true }
        }

        return manager
      })
    )

    return ok
  }

  return (
    <>
      <Group noWrap position='right'>
        <Button onClick={() => setVisible(true)} iconBefore={<UntitledIcon icon={ulPlus} />}>
          Register DEMO institution
        </Button>
      </Group>

      <Modal
        title='Register DEMO institution'
        visible={visible}
        onOk={() => {
          if (!validateForm()) {
            return
          }
          createDemoCompany({
            variables: {
              input: {
                institutionType,
                name: name.value,
                country: country.value as Country,
                requestedPlan: plan.value as Plan,
                managersEmails: managers.map(m => m.email.value.trim()),
                timezone: timezone.value,
                importMockData,
              },
            },
          })
        }}
        onCancel={() => setVisible(!visible)}
        okButtonProps={{
          loading,
        }}
      >
        <Form
          layout='vertical'
          initialValues={{
            managers: [''],
          }}
        >
          <Form.Item
            label='Institution name'
            {...(name.error && {
              validateStatus: 'error',
              help: 'Enter institution name',
            })}
          >
            <Input
              placeholder='Apple Inc.'
              value={name.value}
              onChange={({ target: { value } }) => {
                setName({ error: false, value })
              }}
            />
          </Form.Item>
          <Form.Item
            label='Country'
            {...(country.error && {
              validateStatus: 'error',
              help: 'Select country',
            })}
          >
            <Select<string>
              placeholder='Select country'
              onChange={value => {
                setCountry({ error: false, value })
              }}
              value={country.value}
              showSearch
              filterOption={(input, option) =>
                ((option?.children ?? '') as string).toLowerCase()?.includes(input.toLowerCase())
              }
            >
              {Object.values(Country).map(country => (
                <Option key={country} value={country}>
                  {convertCountryToCountryName(country)}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label='Product version'
            {...(plan.error && {
              validateStatus: 'error',
              help: 'Select product version',
            })}
          >
            <Select<string>
              placeholder='Select product version'
              onChange={value => {
                setPlan({ error: false, value })
              }}
              value={plan.value}
            >
              {institutionType === Institution.Company ? (
                <>
                  <Option value={Plan.Basic}>{Plan.Basic}</Option>
                  <Option value={Plan.Standard}>{Plan.Standard}</Option>
                  <Option value={Plan.Premium}>{Plan.Premium}</Option>
                </>
              ) : (
                <Option value={Plan.Standard}>{Plan.Standard}</Option>
              )}
            </Select>
          </Form.Item>
          <Form.Item
            label='Timezone'
            {...(timezone.error && {
              validateStatus: 'error',
              help: 'Select timezone',
            })}
          >
            <Select<string>
              placeholder='Select timezone'
              onChange={value => {
                setTimezone({ error: false, value })
              }}
              value={timezone.value}
              showSearch
              onSearch={v => {
                const t = moment.tz
                  .names()
                  .filter(element => element.toLowerCase().includes(v.toLowerCase()))
                setTimezones(t)
              }}
            >
              {timezones.map(t => (
                <Option key={t} value={t}>
                  {t}
                </Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item label='Import Demo Data'>
            <Switch onChange={() => setImportMockData(!importMockData)} checked={importMockData} />
          </Form.Item>

          <Form.List name='managers'>
            {(fields, { add, remove }) => (
              <div>
                {fields.map((field, index) => {
                  if (!managers[index]) {
                    setManagers([
                      ...managers,
                      {
                        error: false,
                        email: { value: '' },
                      },
                    ])
                  }
                  return (
                    <Form.Item
                      {...field}
                      key={`${field.name}-${index}`}
                      label={
                        <>
                          Administrator details {index}
                          {Boolean(index) && ( // main manager
                            <MinusCircleOutlined
                              style={{ marginLeft: 10 }}
                              onClick={() => {
                                const newManagers = managers.filter((_, i) => i !== index)
                                setManagers(newManagers)
                                remove(field.name)
                              }}
                            />
                          )}
                        </>
                      }
                      {...(managers?.[index]?.error && {
                        validateStatus: 'error',
                        help: 'Enter valid email',
                      })}
                    >
                      <Input
                        style={{ marginTop: 10 }}
                        placeholder='Email'
                        value={managers?.[index]?.email.value}
                        onChange={({ target: { value } }) => {
                          const newManagers = managers.map((manager, i) => {
                            if (i === index) {
                              return {
                                email: {
                                  value,
                                },
                                error: false,
                              }
                            }
                            return manager
                          })
                          setManagers(newManagers)
                        }}
                      />
                    </Form.Item>
                  )
                })}
                <Form.Item>
                  <Button
                    variant='secondary'
                    onClick={() => {
                      add()
                    }}
                  >
                    <PlusOutlined /> Add Administrator
                  </Button>
                </Form.Item>
              </div>
            )}
          </Form.List>
        </Form>
      </Modal>
    </>
  )
}

export default CreateCompany
