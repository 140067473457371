import styled from '@emotion/styled'

const Footer = () => (
  <FooterWrapper>
    <FooterContainer>
      <div>FaceUp Technology s.r.o. &copy; {new Date().getFullYear()}</div>
    </FooterContainer>
  </FooterWrapper>
)

export const FooterWrapper = styled.div`
  display: flex;
  justify-content: center;
  border-top: 1px solid #e6eaec;
  flex-shrink: 0;
  position: relative;
  top: 81px;
  margin-top: -81px;
  background-color: #f6f7f8;
`

const FooterContainer = styled.footer`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 1280px;
  font-weight: 600;
  font-size: 14px;
  color: #688699;
  padding: 32px;
  flex-direction: column-reverse;
`

export default Footer
