import styled from '@emotion/styled'
import { Card, type CardProps, Layout } from '@faceup/ui-base'
import type { ReactNode } from 'react'
import PublicHeader from '../Components/PublicHeader'
import Footer from './Login/Footer'

const { Content: AntContent } = Layout

type PageTemplateUnloggedProps = {
  children?: ReactNode
  contentUnderCard?: ReactNode
  isFooterShown?: boolean
  cardProps?: Omit<CardProps, 'children'>
}

const PageTemplateUnlogged = ({
  children,
  contentUnderCard,
  isFooterShown = false,
  cardProps,
}: PageTemplateUnloggedProps) => (
  <LayoutFullHeight>
    <PublicHeader />
    <Content>
      <ContentCard {...cardProps}>{children}</ContentCard>
      {contentUnderCard}
    </Content>
    {isFooterShown && <Footer />}
  </LayoutFullHeight>
)

const LayoutFullHeight = styled(Layout)`
  min-height: 100%;
`
const Content = styled(AntContent)`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  margin-bottom: 2em;
`
const ContentCard = styled(Card)`
  max-width: 35rem;
  width: 100%;

  .ant-card-body {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    padding: 3.5rem 24px;
  }
`

export default PageTemplateUnlogged
