import { useWindowEvent } from '@mantine/hooks'
import type { RefObject } from 'react'

export const useTabKeyBlocker = (refs: RefObject<HTMLElement>[]) => {
  useWindowEvent('keydown', e => {
    if (e.key === 'Tab') {
      refs.forEach(ref => {
        if (ref.current) {
          // function to add recursively tabindex into all children
          const addTabIndex = (node: HTMLElement) => {
            node.tabIndex = -1
            if (node.children) {
              for (const child of node.children) {
                addTabIndex(child as HTMLElement)
              }
            }
          }
          addTabIndex(ref.current)
        }
      })
    }
  })
}
