import { UntitledIcon } from '@faceup/icons'
import { ulAlertCircle } from '@faceup/icons/ulAlertCircle'
import { ulXClose } from '@faceup/icons/ulXClose'
import { ActionIcon, Group, Modal as MantineModal } from '@mantine/core'
import type { JSX, ReactNode } from 'react'
import { Card } from '../Card'
import { Text } from '../CoreComponents'
import { Segment, type SegmentProps } from '../CoreComponents/Segment'
import { useThemeColors } from '../hooks'

type ModalComponent = (props: ModalProps) => JSX.Element
type ModalSegmentComponent = (props: ModalSegmentProps) => JSX.Element
export type ModalSubcomponents = { Segment: ModalSegmentComponent }
type ComplexComponent = ModalComponent & ModalSubcomponents

type ModalWidth = 520 | 620 | 800 | 950

export type ModalVariants = 'primary' | 'danger'

export type ModalProps = {
  children: ReactNode
  opened: boolean
  onClose?: () => void
  title?: ReactNode
  width?: ModalWidth
  footer?: ReactNode
  variant?: ModalVariants
}

export const Modal: ComplexComponent = (_props: ModalProps) => {
  const { children, opened, onClose, width, title, variant = 'default', footer, ...props } = _props
  const { getColorFromTheme } = useThemeColors()
  return (
    <MantineModal
      opened={opened}
      onClose={onClose ? onClose : () => null}
      withCloseButton={false}
      size={width}
      centered
      styles={{
        body: {
          padding: 0,
        },
        inner: {
          zIndex: 201,
        },
        content: {
          backgroundColor: 'transparent',
          boxShadow: 'none',
        },
        // not specified in the design
        overlay: {
          backgroundColor: '#000000!important',
          opacity: '0.45!important',
          zIndex: 201,
        },
      }}
    >
      <Card
        {...props}
        footer={footer}
        hasNoBorder
        // Modal has different font color. If unified, Text component should be removed from here
        title={
          variant === 'danger' ? (
            <Group align='center'>
              <UntitledIcon icon={ulAlertCircle} color={getColorFromTheme('error.100')} size='24' />
              <Text variant='title' size='medium' color='text'>
                {title}
              </Text>
            </Group>
          ) : (
            <Text variant='title' size='medium' color='text'>
              {title}
            </Text>
          )
        }
        extra={
          onClose && (
            <ActionIcon onClick={onClose}>
              <UntitledIcon icon={ulXClose} size='24px' color={getColorFromTheme('dark.50')} />
            </ActionIcon>
          )
        }
        // value from mantine
        // -2px are the border width
        maxHeight='calc(100dvh - (5dvh * 2) - 2 * 32px - 2px)'
      >
        {children}
      </Card>
    </MantineModal>
  )
}

type ModalSegmentProps = Omit<SegmentProps, 'paddingContent' | 'paddingHeader' | 'marginDivider'>

export const ModalSegment: ModalSegmentComponent = (props: ModalSegmentProps) => (
  <Segment {...props} marginDivider='24px 0' />
)

Modal.Segment = ModalSegment
