import { gql, useMutation, useQuery } from '@apollo/client'
import { Button, Card, ContentLayout } from '@faceup/ui'
import { Popconfirm, Table, notification, useMessage } from '@faceup/ui-base'
import type {
  GlobalTable,
  GlobalTablesQuery,
  TruncateGlobalTableMutation,
  TruncateGlobalTableMutationVariables,
} from '../__generated__/globalTypes'

const query = {
  GlobalTablesQuery: gql`
    query GlobalTablesQuery {
      globalTables
    }
  `,
}

const mutations = {
  truncateGlobalTable: gql`
    mutation TruncateGlobalTableMutation($input: TruncateGlobalTableInput!) {
      truncateGlobalTable(input: $input) {
        success
      }
    }
  `,
}

const columns = [
  {
    title: 'Table name',
    dataIndex: 'table',
    width: '80%',
  },
  {
    title: 'Actions',
    dataIndex: 'actions',
    width: '20%',
    render: (_: string, { truncateTable }: { truncateTable: () => void }) => (
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Popconfirm
          title='Truncating global table will delete all data across all regions. Are you sure?'
          onConfirm={() => truncateTable()}
          placement='topRight'
        >
          <Button variant='secondary' color='error'>
            Truncate table
          </Button>
        </Popconfirm>
      </div>
    ),
  },
]

const Institutions = () => {
  const message = useMessage()
  const { loading, data } = useQuery<GlobalTablesQuery>(query.GlobalTablesQuery, {
    fetchPolicy: 'cache-and-network',
    onError: error => {
      console.error(error)
      notification.error({
        message: 'GQL ERROR',
        description: error.message,
      })
    },
  })

  const [truncateGlobalTable] = useMutation<
    TruncateGlobalTableMutation,
    TruncateGlobalTableMutationVariables
  >(mutations.truncateGlobalTable, {
    onError: error => {
      console.error(error)
      notification.error({
        message: 'GQL ERROR',
        description: error.message,
      })
    },
    onCompleted: data => {
      if (data?.truncateGlobalTable?.success) {
        message.success('Table truncated')
      } else {
        message.error('Error truncating table')
      }
    },
  })

  const globalTables = data?.globalTables?.map((table: GlobalTable) => ({
    table,
    truncateTable: () => truncateGlobalTable({ variables: { input: { table } } }),
  }))

  return (
    <ContentLayout header={<ContentLayout.Header title='Global tables' />}>
      <Card hasNoPadding>
        <Table
          loading={loading}
          columns={columns}
          dataSource={globalTables}
          pagination={false}
          style={{ minHeight: 300 }}
          scroll={{ x: 'max-content' }}
        />
      </Card>
    </ContentLayout>
  )
}

export default Institutions
