import { deepMerge } from '@faceup/utils'
import {
  Button as MantineButton,
  type ButtonProps as MantineButtonProps,
  type MantineSize,
  type Sx,
  createPolymorphicComponent,
} from '@mantine/core'
import { type ReactNode, forwardRef, useCallback, useMemo } from 'react'
import { fontWeightBold } from '../constants'
import { colorsWith6Variants, colorsWith12Variants, useThemeColors } from '../hooks'

export const buttonColors = [...colorsWith6Variants, ...colorsWith12Variants] as const
export type ButtonColor = (typeof buttonColors)[number]
export const buttonVariantDefinition = ['primary', 'secondary', 'tertiary', 'text'] as const
export type ButtonVariant = (typeof buttonVariantDefinition)[number]
export const buttonSizeDefinition = ['small', 'default'] as const
export type ButtonSize = (typeof buttonSizeDefinition)[number]
export const buttonContentAlignDefinition = ['left', 'center'] as const
export type ButtonContentAlign = (typeof buttonContentAlignDefinition)[number]

export type ButtonProps = {
  color?: ButtonColor
  variant?: ButtonVariant
  children?: ReactNode
  size?: ButtonSize
  type?: 'submit' | 'button' | 'reset'
  isCompact?: boolean
  loading?: boolean
  disabled?: boolean
  isFullWidth?: boolean
  iconBefore?: ReactNode
  iconAfter?: ReactNode
  contentAlign?: ButtonContentAlign
  sx?: Sx
}

const sizeMapper: Record<ButtonSize, MantineSize | undefined> = {
  small: 'sm',
  default: undefined,
}

const contentAlignMapper: Record<ButtonContentAlign, 'flex-start' | undefined> = {
  left: 'flex-start',
  center: undefined,
}

const _Button = forwardRef<HTMLButtonElement, ButtonProps>((props, ref) => {
  const {
    variant = 'primary',
    color = 'primary',
    size = 'default',
    isCompact,
    isFullWidth,
    iconBefore,
    iconAfter,
    contentAlign = 'center',
    disabled,
    loading,
    ...passProps
  } = props
  const { getColorFromTheme } = useThemeColors()

  const getButtonData: (variant: ButtonVariant) => MantineButtonProps = useCallback(
    variant => {
      switch (variant) {
        case 'primary':
          return {
            styles: {
              root: {
                backgroundColor: getColorFromTheme(`${color}.100`),
                color: getColorFromTheme('white'),
                '&:hover': {
                  backgroundColor: getColorFromTheme(`${color}.110`),
                },
                '&:active': {
                  backgroundColor: getColorFromTheme(`${color}.100`),
                },
                '&[data-loading], &[disabled]': {
                  backgroundColor: getColorFromTheme(`${color}.20`),
                },
                '&[data-loading]:before': {
                  backgroundColor: 'transparent',
                },
              },
              inner: {
                color: getColorFromTheme('white'),
              },
            },
          }
        case 'secondary':
          return {
            styles: {
              root: {
                backgroundColor: 'transparent',
                borderColor: getColorFromTheme(`${color}.100`),
                color: getColorFromTheme(`${color}.100`),
                '&:hover': {
                  backgroundColor: getColorFromTheme(`${color}.10`),
                },
                '&:active': {
                  backgroundColor: getColorFromTheme(`${color}.10`),
                },
                '&[data-loading], &[disabled]': {
                  backgroundColor: 'transparent',
                  borderColor: getColorFromTheme(`${color}.20`),
                  '& .mantine-Button-label': {
                    color: getColorFromTheme(`${color}.20`),
                  },
                },
                '&[data-loading]:before': {
                  backgroundColor: 'transparent',
                },
              },
              inner: {
                color: getColorFromTheme(`${color}.100`),
              },
            },
            loaderProps: {
              color: getColorFromTheme(`${color}.100`),
            },
          }
        case 'tertiary':
          return {
            styles: {
              root: {
                backgroundColor: getColorFromTheme('white'),
                borderColor: getColorFromTheme('dark.20'),
                color: getColorFromTheme('text'),
                '&:hover': {
                  backgroundColor: getColorFromTheme('dark.4'),
                  borderColor: 'transparent',
                  '& .mantine-Button-label': {
                    color: getColorFromTheme('text'),
                  },
                },
                '&:active': {
                  backgroundColor: getColorFromTheme('dark.10'),
                  borderColor: 'transparent',
                  '& .mantine-Button-label': {
                    color: getColorFromTheme('text'),
                  },
                },
                '&[data-loading], &[disabled]': {
                  backgroundColor: getColorFromTheme('white'),
                  borderColor: getColorFromTheme('dark.10'),
                  '& .mantine-Button-label': {
                    color: getColorFromTheme('dark.20'),
                  },
                },
                '&[data-loading]:before': {
                  backgroundColor: 'transparent',
                },
              },
              inner: {
                color: getColorFromTheme('text'),
              },
            },
            loaderProps: {
              color: getColorFromTheme('text'),
            },
          }
        case 'text':
          return {
            styles: {
              root: {
                borderInline: 0,
                padding: 0,
                backgroundColor: 'transparent',
                color: getColorFromTheme(`${color}.100`),
                '&:hover': {
                  backgroundColor: 'transparent',
                  color: getColorFromTheme(`${color}.110`),
                },
                '&[data-loading], &[disabled]': {
                  backgroundColor: 'transparent',
                  '& .mantine-Button-label': {
                    color: getColorFromTheme(`${color}.40`),
                  },
                },
                '&[data-loading]:before': {
                  backgroundColor: 'transparent',
                },
              },
              inner: {
                margin: 0,
                color: getColorFromTheme(`${color}.100`),
              },
            },
            loaderProps: {
              color: getColorFromTheme(`${color}.100`),
            },
          }
      }
    },
    [getColorFromTheme, color]
  )

  const buttonData = useMemo(() => getButtonData(variant), [getButtonData, variant])

  const styles = useMemo(
    () =>
      deepMerge.all([
        {
          root: {
            borderRadius: '8px',
            border: '1px solid transparent',
            cursor: 'pointer',
            transition: 'all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1)',
            paddingInline: isCompact ? 0 : '12px',
          },
          inner: {
            justifyContent: contentAlignMapper[contentAlign],
            marginLeft: iconBefore ? 0 : '12px',
            marginRight: iconAfter ? 0 : '12px',
          },
          label: {
            textOverflow: 'ellipsis',
            display: 'block',
            height: 'auto',
            overflow: 'inherit',
            fontFamily: 'Inter, sans-serif',
            fontWeight: fontWeightBold,
            fontSize: '0.875rem',
          },
        },
        buttonData.styles ?? {},
      ]),
    [buttonData, contentAlign, iconAfter, iconBefore, isCompact]
  )

  return (
    <MantineButton
      ref={ref}
      disabled={loading || disabled}
      loading={loading}
      {...passProps}
      {...buttonData}
      size={sizeMapper[size]}
      compact={isCompact}
      fullWidth={isFullWidth}
      leftIcon={iconBefore}
      rightIcon={iconAfter}
      styles={styles}
    />
  )
})

_Button.displayName = 'Button'

export const Button = createPolymorphicComponent<'button', ButtonProps, 'button'>(_Button)
