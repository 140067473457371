import type { UntitledIconData } from '../../UntitledIconData'

const ulPlus: UntitledIconData = {
  prefix: 'ul',
  name: 'plus',
  paths: [
    {
      shape: 'M12 5V19M5 12H19',
    },
  ],
}

export default ulPlus
