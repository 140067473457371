import type { UntitledIconData } from '../../UntitledIconData'

const ulBuilding08: UntitledIconData = {
  prefix: 'ul',
  name: 'building-08',
  paths: [
    {
      shape:
        'M3 21H21M6 18V9.99998M10 18V9.99998M14 18V9.99998M18 18V9.99998M20 6.99998L12.424 2.26498C12.2702 2.16884 12.1933 2.12077 12.1108 2.10203C12.0379 2.08546 11.9621 2.08546 11.8892 2.10203C11.8067 2.12077 11.7298 2.16884 11.576 2.26498L4 6.99998H20Z',
    },
  ],
}

export default ulBuilding08
