import { Avatar, type AvatarProps } from '@faceup/ui-base'
import { Box } from '@mantine/core'
import { useThemeColors } from '../hooks'

type AvatarEmptyProps = AvatarProps

const AvatarEmpty = (props: AvatarEmptyProps) => {
  const { getColorFromTheme } = useThemeColors()
  return (
    <Box
      component={Avatar}
      {...props}
      sx={{
        '&.ant-avatar': {
          background: 'transparent',
          color: getColorFromTheme('dark.100'),
          border: `1px dashed ${getColorFromTheme('dark.100')}`,
        },
      }}
    />
  )
}

export default AvatarEmpty
