import { type Sx, useTabKeyBlocker } from '@faceup/ui'
import { Box } from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import { type ReactNode, useRef } from 'react'
import { UpsellModal } from './UpsellModal'
import { UpsellProvider } from './contexts'
import type { UpsellData } from './upsellData'

type UpsellProps = {
  upsell: UpsellData
  children: ReactNode
  sx?: Sx
}

export const Upsell = (props: UpsellProps) => {
  const { upsell, children, sx } = props
  const [opened, { open, close }] = useDisclosure(false)
  const ref = useRef<HTMLDivElement>(null)
  useTabKeyBlocker(upsell.showUpsell ? [ref] : [])

  return (
    <>
      <UpsellProvider upsell={upsell}>
        <Box ref={ref} onClick={upsell.showUpsell ? open : undefined}>
          <Box
            sx={{
              ...sx,
              pointerEvents: upsell.showUpsell ? 'none' : undefined,
            }}
          >
            {children}
          </Box>
        </Box>
      </UpsellProvider>
      <UpsellModal isOpened={opened} onClose={close} upsell={upsell} />
    </>
  )
}
