import './styles/a.css'
import './styles/badge.css'
import './styles/button.css'
import './styles/form.css'
import './styles/global.css'
import './styles/menu.css'
import './styles/select.css'
import './styles/table.css'
import './styles/tabs.css'
import 'antd/dist/reset.css'
import type { DirectionType } from 'antd/es/config-provider'
import type { AliasToken } from 'antd/es/theme/internal'
import { shade, tint } from 'polished'
import { type PropsWithChildren, createContext, useContext } from 'react'
import { ConfigProvider, type Locale } from './'

type UiBaseProviderProps = PropsWithChildren<{
  locale: Locale | undefined
  colorPrimary?: string
  colorDark?: string
  direction: DirectionType
}>

type TokensBlue = Pick<
  AliasToken,
  'blue1' | 'blue2' | 'blue3' | 'blue4' | 'blue5' | 'blue6' | 'blue7' | 'blue8' | 'blue9' | 'blue10'
>
type TokensPrimary = Pick<
  AliasToken,
  | 'colorPrimary'
  | 'colorPrimaryHover'
  | 'colorPrimaryActive'
  | 'colorPrimaryBg'
  | 'colorPrimaryBgHover'
  | 'colorPrimaryBorder'
  | 'colorPrimaryBorderHover'
  | 'colorPrimaryText'
  | 'colorPrimaryTextHover'
  | 'colorPrimaryTextActive'
>
type TokensLink = Pick<AliasToken, 'colorLink' | 'colorLinkHover' | 'colorLinkActive'>
type TokensText = Pick<
  AliasToken,
  | 'colorText'
  | 'colorTextSecondary'
  | 'colorTextTertiary'
  | 'colorTextQuaternary'
  | 'colorTextLightSolid'
>
type TokensTextExtra = Pick<
  AliasToken,
  | 'colorTextHeading'
  | 'colorTextLabel'
  | 'colorTextDescription'
  | 'colorTextDisabled'
  | 'colorTextPlaceholder'
>
type TokensBorderRadius = Pick<
  AliasToken,
  'borderRadiusXS' | 'borderRadiusSM' | 'borderRadius' | 'borderRadiusLG'
>
type Tokens = TokensPrimary &
  TokensLink &
  TokensText &
  TokensTextExtra &
  TokensBorderRadius &
  Pick<AliasToken, 'fontFamily' | 'colorBorderSecondary' | 'colorBgSpotlight' | 'colorError'>

export const UiBaseProvider = ({
  colorPrimary,
  colorDark,
  locale,
  children,
}: UiBaseProviderProps) => {
  const blue: TokensBlue = {
    blue1: colorPrimary ? tint(0.4, colorPrimary) : '#E6F3FF',
    blue2: colorPrimary ? tint(0.3, colorPrimary) : '#B9DDFF',
    blue3: colorPrimary ? tint(0.2, colorPrimary) : '#71BBFF',
    blue4: colorPrimary ? tint(0.1, colorPrimary) : '#48A7FF',
    blue5: colorPrimary ? colorPrimary : '#0085FF',
    blue6: colorPrimary ? shade(0.1, colorPrimary) : '#007AE1',
    blue7: colorPrimary ? shade(0.2, colorPrimary) : '#0070CD',
    blue8: colorPrimary ? shade(0.3, colorPrimary) : '#0061B1',
    blue9: colorPrimary ? shade(0.4, colorPrimary) : '#005296',
    blue10: colorPrimary ? shade(0.5, colorPrimary) : '#003A68',
  }
  const primary: TokensPrimary = {
    colorPrimary: blue.blue5,
    colorPrimaryHover: blue.blue6,
    colorPrimaryActive: blue.blue7,
    colorPrimaryBg: blue.blue1,
    colorPrimaryBgHover: blue.blue2,
    colorPrimaryBorder: blue.blue3,
    colorPrimaryBorderHover: blue.blue4,
    colorPrimaryText: blue.blue5,
    colorPrimaryTextHover: blue.blue6,
    colorPrimaryTextActive: blue.blue7,
  }
  const link: TokensLink = {
    colorLink: primary.colorPrimary,
    colorLinkHover: primary.colorPrimaryHover,
    colorLinkActive: primary.colorPrimaryActive,
  }
  const text: TokensText = {
    colorText: '#062D46',
    colorTextSecondary: '#5E728A',
    colorTextTertiary: '#8CA0B8',
    colorTextQuaternary: '#B4C4D5',
    colorTextLightSolid: '#FFFFFF',
  }
  const textExtra: TokensTextExtra = {
    colorTextHeading: text.colorText,
    colorTextLabel: text.colorTextSecondary,
    colorTextDescription: text.colorTextTertiary,
    colorTextDisabled: text.colorTextQuaternary,
    colorTextPlaceholder: text.colorTextQuaternary,
  }
  const borderRadius: TokensBorderRadius = {
    borderRadiusXS: 4,
    borderRadiusSM: 6,
    borderRadius: 8,
    borderRadiusLG: 12,
  }
  const token: Tokens = {
    fontFamily: 'Inter, sans-serif',
    colorBorderSecondary: '#ECF0FA',
    ...primary,
    ...link,
    ...text,
    ...textExtra,
    ...borderRadius,

    colorLink: blue.blue5,
    colorLinkHover: blue.blue6,
    colorLinkActive: blue.blue7,
    colorBgSpotlight: '#062D46',
    colorError: '#F54070',
  }

  return (
    <UiBaseInnerProvider token={{ ...token, ...blue }}>
      <ConfigProvider
        componentSize='large'
        locale={locale}
        theme={{
          cssVar: true,
          hashed: false,
          token,
          components: {
            Typography: {
              fontSizeHeading1: 32,
              fontSizeHeading2: 28,
              fontSizeHeading3: 24,
              fontSizeHeading4: 20,
              fontSizeHeading5: 16,
              titleMarginBottom: 0,
              titleMarginTop: 0,
            },
            Badge: {
              textFontWeight: 'semibold',
            },
            Menu: {
              darkItemBg: colorDark ?? '#062D46',
              darkItemSelectedBg: '#5A7A9A40',
              darkPopupBg: colorDark ?? '#062D46',
              darkItemColor: '#6F91B7',
            },
            Tabs: {
              horizontalMargin: '0',
            },
            Form: {
              labelColor: token.colorTextLabel,
              marginLG: 16,
            },
            Input: {
              inputFontSizeSM: 14,
              inputFontSize: 14,
              inputFontSizeLG: 14,
              paddingBlockLG: 10.5,
              borderRadiusSM: borderRadius.borderRadiusSM,
              borderRadius: borderRadius.borderRadius,
              borderRadiusLG: borderRadius.borderRadiusLG,
            },
            Select: {
              singleItemHeightLG: 44,
              fontSizeLG: 14,
            },
            List: {
              itemPaddingLG: '12px 0',
            },
            DatePicker: {
              fontSizeLG: 14,
            },
            Button: {
              fontSizeLG: 14,
              fontWeight: 700,
              colorPrimaryText: primary.colorPrimaryText,
              colorPrimaryHover: primary.colorPrimaryHover,
              colorPrimaryActive: primary.colorPrimaryActive,
            },
          },
        }}
      >
        {children}
      </ConfigProvider>
    </UiBaseInnerProvider>
  )
}

type UiBaseContextProps = {
  token: Tokens & TokensBlue
}

type UiBaseInnerProviderProps = PropsWithChildren<UiBaseContextProps>

const UiBaseContext = createContext<UiBaseContextProps>({
  token: {} as Tokens & TokensBlue,
})

const UiBaseInnerProvider = ({ children, ...props }: UiBaseInnerProviderProps) => (
  <UiBaseContext.Provider value={props}>{children}</UiBaseContext.Provider>
)

export const useUiBaseConfig = () => useContext(UiBaseContext)
