import { Stack } from '@mantine/core'
import type { ReactNode } from 'react'

type FormItemsWrapperProps = {
  children: ReactNode
}

export const FormItemsWrapper = ({ children }: FormItemsWrapperProps) => (
  <Stack spacing='0.25rem'>{children}</Stack>
)
