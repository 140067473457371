import type { PropsOf } from '@emotion/react'
import styled from '@emotion/styled'
import type { PropsWithChildren, ReactNode } from 'react'
import {
  Mention as RmMention,
  MentionsInput as RmMentionsInput,
  type MentionsInputProps as RmMentionsInputProps,
  type SuggestionDataItem,
} from 'react-mentions'

type MProps = PropsOf<typeof RmMention>

// Copied from react-mentions
export type DataFunc<D = undefined> = (
  query: string,
  callback: (data: CustomItem<D>[]) => void
) => Promise<void> | void | Promise<CustomItem<D>[]> | CustomItem<D>[]

type CustomItem<D = undefined> = SuggestionDataItem & { customData: D }

export type MentionProps<D> = Omit<MProps, 'data' | 'renderSuggestion' | 'onAdd'> & {
  data: CustomItem<D>[] | DataFunc<D>
  onAdd?: (
    id: string | number,
    display: string,
    startPos: number,
    endPos: number
    // biome-ignore lint/suspicious/noConfusingVoidType:
  ) => void | undefined
  renderSuggestion?:
    | ((
        suggestion: CustomItem<D>,
        search: string,
        highlightedDisplay: ReactNode,
        index: number,
        focused: boolean
      ) => ReactNode)
    | undefined
}

const Mention = <D,>({ renderSuggestion, onAdd, ...props }: MentionProps<D>) => (
  <RmMention
    {...props}
    onAdd={onAdd}
    renderSuggestion={
      renderSuggestion &&
      ((suggestion, search, highlightedDisplay, index, focused) =>
        renderSuggestion(
          suggestion as unknown as CustomItem<D>,
          search,
          highlightedDisplay,
          index,
          focused
        ))
    }
  />
)

const MentionItem = styled.div`
  padding: 0px 14px;
  height: 32px;
  display: flex;
  align-items: center;
`

const StyledMentionItemNotInteractive = styled.div`
  cursor: default;
  background: #ffffff;
`

type MentionItemNotInteractiveProps = PropsWithChildren

const MentionItemNotInteractive = ({ children }: MentionItemNotInteractiveProps) => (
  <StyledMentionItemNotInteractive onClick={e => e.stopPropagation()}>
    {children}
  </StyledMentionItemNotInteractive>
)

type MentionsInputProps = RmMentionsInputProps & {
  isBordered?: boolean
}

export const MentionsInput = ({
  isBordered = false,
  ...props
}: PropsWithChildren<MentionsInputProps>) => (
  <RmMentionsInput style={defaultStyles(isBordered)} {...props} />
)
MentionsInput.Mention = Mention
MentionsInput.MentionItem = MentionItem
MentionsInput.MentionItemNotInteractive = MentionItemNotInteractive

const defaultStyles = (isBorder: boolean) => ({
  control: {
    backgroundColor: '#fff',
    borderRadius: 5,
    fontSize: 14,
  },

  highlighter: {
    overflow: 'hidden',
  },

  '&multiLine': {
    control: {
      border: isBorder ? '1px solid silver' : 'none',
      minHeight: 40,
    },

    highlighter: {
      padding: 9,
    },

    input: {
      padding: 9,
      minHeight: 63,
      outline: 0,
      border: 0,
    },
  },

  suggestions: {
    maxHeight: '20vh',
    boxShadow: '0px 0px 20px 0px rgba(22, 25, 84, 0.14)',
    borderRadius: 12,
    overflow: 'auto',

    list: {
      backgroundColor: 'white',
      overflow: 'hidden',
      padding: '4px',
    },

    item: {
      // padding: '0px 14px',
      transition: '.4s',
      borderRadius: 8,

      '&focused': {
        background: '#9DB0DF26',
      },
      // because styles are inlined, we cannot use this (what should we use?)
      /* '&last-of-type': {
        color: 'red',
      },*/
    },
  },
})
