import { Flex, Group } from '@mantine/core'
import type { ReactNode } from 'react'

type ButtonGroupProps = {
  children: ReactNode
}

export const ButtonGroup = (props: ButtonGroupProps) => {
  const { children } = props
  return (
    <Flex justify='end'>
      <Group spacing='xs'>{children}</Group>
    </Flex>
  )
}
