import { gql, useMutation, useQuery } from '@apollo/client'
import { UntitledIcon } from '@faceup/icons'
import { ulKey01 } from '@faceup/icons/ulKey01'
import { ulLock01 } from '@faceup/icons/ulLock01'
import {
  CreateManagerModal,
  CreateManagerModalFragments,
  EditManagerModal,
  EditManagerModalFragments,
  MotherProvider,
} from '@faceup/institution'
import { useSearchParams } from '@faceup/router'
import { Button, Card, TableEnhanced, Tooltip, UserAvatar } from '@faceup/ui'
import {
  Divider,
  Pagination,
  Space,
  Tag,
  Typography,
  notification,
  useMessage,
  useModal,
} from '@faceup/ui-base'
import { AccountType } from '@faceup/utils'
import { Badge, Group, Stack } from '@mantine/core'
import moment from 'moment-timezone'
import { Fragment, useEffect, useState } from 'react'
import {
  type CompanyManagersQuery,
  type CompanyManagersQueryVariables,
  type DeleteManagerMutation,
  type DeleteManagerMutationVariables,
  type EditManagerModal_managerToEdit,
  Institution,
  InvitationStatus,
  type RemovePartnerUserMutation,
  type RemovePartnerUserMutationVariables,
  type ResendManagerInvitationMutation,
  type ResendManagerInvitationMutationVariables,
  type TransferOwnershipModal_manager,
} from '../__generated__/globalTypes'
import { DATE_FORMAT, DATE_TIME_FORMAT } from '../constants'
import GrantAccessActionModal, {
  fragments as GrantAccessModalFragments,
} from './GrantAccessActionModal'
import MoreOptions from './MoreOptions'
import PermissionText, { fragments as PermissionTextFragments } from './PermissionText'
import { TransferOwnershipFragments, TransferOwnershipModal } from './TransferOwnershipModal'

type CompanyManagersQuery_allCompanyByIdManagers_items_item = NonNullable<
  NonNullable<
    NonNullable<NonNullable<CompanyManagersQuery['allCompanyByIdManagers']>['items']>[number]
  >
>

const { Text } = Typography

const query = {
  CompanyManagersQuery: gql`
    query CompanyManagersQuery(
      $institutionId: CompanyGlobalId!
      $motherId: CompanyGlobalId!
      $page: Int!
      $rowsPerPage: Int!
    ) {
      institution(institutionId: $institutionId) {
        id
        name
        config {
          id
          reportCategories {
            id
          }
        }
        descendents {
          id
          name
        }

        ...CreateManagerModal_institution
        ...EditManagerModal_institution
      }
      allCompanyByIdManagers(companyId: $motherId, page: $page, rowsPerPage: $rowsPerPage) {
        totalCount
        items {
          id
          name
          email
          phone
          profileImageUrl
          companyIds(motherId: $motherId)
          editable(motherId: $motherId)
          accountType(motherId: $motherId)
          invitation
          sso
          isPartner
          lastLogin
          mother(motherId: $motherId) {
            id
            config {
              id
              reportCategories {
                id
              }
            }
            descendents {
              id
            }
          }
          companies(motherId: $motherId) {
            id
            name
          }
          keys {
            id
            twoFactorAuthentication
            permissions(motherId: $motherId) {
              id
              enabled
              ...PermissionText_permission
            }
          }
          ...TransferOwnershipModal_manager
          ...EditManagerModal_managerToEdit
          ...GrantAccessActionModal_user
        }
      }
    }

    ${TransferOwnershipFragments.TransferOwnershipModal_manager}
    ${EditManagerModalFragments.EditManagerModal_managerToEdit}
    ${PermissionTextFragments.PermissionText_permission}
    ${GrantAccessModalFragments.GrantAccessActionModal_user}
    ${EditManagerModalFragments.EditManagerModal_institution}
    ${CreateManagerModalFragments.CreateManagerModal_institution}
  `,
}

const mutations = {
  DeleteManager: gql`
    mutation DeleteManagerMutation($input: DeleteManagerInput!) {
      deleteManager(input: $input) {
        success
        deletedManagerId
      }
    }
  `,
  RemovePartnerUser: gql`
    mutation RemovePartnerUserMutation($input: RemovePartnerUserInput!) {
      removePartnerUser(input: $input) {
        success
        deletedManagerId
      }
    }
  `,
  ResendManagerInvitation: gql`
    mutation ResendManagerInvitationMutation($input: ResendManagerInvitationInput!) {
      resendManagerInvitation(input: $input) {
        manager {
          id
          invitation
        }
      }
    }
  `,
}

type Props = {
  institutionId: string
}

const ManagersTable = ({ institutionId }: Props) => {
  const pageSize = 10

  const [params] = useSearchParams()

  const [page, setPage] = useState(1)
  const [grantAccessVisible, setGrantAccessVisible] = useState(false)
  const [managerToEdit, setManagerToEdit] = useState<EditManagerModal_managerToEdit | null>(null)
  const [managerToGrantOwnerShip, setManagerToGrantOwnerShip] =
    useState<TransferOwnershipModal_manager | null>(null)
  const [createManagerVisible, setCreateManagerVisible] = useState<boolean>(false)
  const message = useMessage()
  const modal = useModal()

  const { data, loading, refetch } = useQuery<CompanyManagersQuery, CompanyManagersQueryVariables>(
    query.CompanyManagersQuery,
    {
      onError: error => {
        console.error(error)
        notification.error({
          message: 'GQL Error',
          description: error.message,
        })
      },
      fetchPolicy: 'cache-and-network',
      variables: {
        institutionId: institutionId ?? '',
        motherId: institutionId,
        page: page - 1,
        rowsPerPage: pageSize,
      },
    }
  )

  const [resendInvitation] = useMutation<
    ResendManagerInvitationMutation,
    ResendManagerInvitationMutationVariables
  >(mutations.ResendManagerInvitation, {
    onError: error => {
      console.error(error)
      notification.error({
        message: 'GQL Error',
        description: error.message,
      })
    },
    onCompleted: () => {
      message.success('Resent')
    },
  })

  const [deleteManager] = useMutation<DeleteManagerMutation, DeleteManagerMutationVariables>(
    mutations.DeleteManager,
    {
      onError: error => {
        console.error(error)
        notification.error({
          message: 'GQL Error',
          description: error.message,
        })
      },
      onCompleted: data => {
        if (!data.deleteManager?.success) {
          message.error('Unable to delete member')
        }
      },
    }
  )

  const [removePartnerUser] = useMutation<
    RemovePartnerUserMutation,
    RemovePartnerUserMutationVariables
  >(mutations.RemovePartnerUser, {
    onError: error => {
      console.error(error)
      notification.error({
        message: 'GQL Error',
        description: error.message,
      })
    },
    onCompleted: data => {
      if (!data.removePartnerUser?.success) {
        message.error('Unable to remove partner user')
      }
    },
  })

  const makeTableRow = (item: CompanyManagersQuery_allCompanyByIdManagers_items_item | null) => ({
    __typename: 'Manager' as const,
    id: item?.id ?? '',
    name: item?.name ?? '',
    companyIds: item?.companyIds ?? [],
    phone: item?.phone ?? '',
    companies: item?.companies ?? [],
    email: item?.email ?? '',
    editable: item?.editable ?? false,
    profileImageUrl: item?.profileImageUrl ?? null,
    permissions: item?.keys?.permissions ?? [],
    invitation: item?.invitation,
    sso: Boolean(item?.sso),
    lastLogin: item?.lastLogin,
    mother: item?.mother ?? null,
    accountType: item?.accountType ?? null,
    security: {
      sso: Boolean(item?.sso),
      twoFactorAuthentication: Boolean(item?.keys?.twoFactorAuthentication),
    },
  })

  const institution = data?.institution
  const managers = data?.allCompanyByIdManagers?.items ?? []
  const allCompaniesCount = ((institution?.descendents ?? []).length ?? 0) + 1
  const companies = [institution, ...(institution?.descendents ?? [])]
  const totalCount = data?.allCompanyByIdManagers?.totalCount || 0
  const allCategoriesCount = (institution?.config?.reportCategories?.length ?? 0) + 1

  useEffect(() => {
    const action = params.get('action')
    if (action === 'grantAccess') {
      setGrantAccessVisible(true)
    }
  }, [params])

  return (
    <MotherProvider motherId={institutionId}>
      <Card
        title='Managers'
        hasNoPadding
        extra={<Button onClick={() => setCreateManagerVisible(true)}>Add member</Button>}
      >
        <TableEnhanced<ReturnType<typeof makeTableRow>>
          style={{ marginTop: 20 }}
          loading={loading}
          rowKey='id'
          dataSource={managers?.map(manager => makeTableRow(manager ?? null)) ?? []}
          locale={{ emptyText: ' ' }}
          scroll={{ x: 'max-content' }}
          columns={[
            {
              title: 'Name',
              dataIndex: 'name',
              key: 'name',
              width: allCompaniesCount > 1 ? '25%' : '35%',
              render: (name, manager) => (
                <Space align='center'>
                  <UserAvatar user={manager} style={{ marginRight: 10 }} />
                  <Space direction='vertical' size={0}>
                    <Space>
                      <Text style={{ fontSize: 14 }}>{name || manager.email}</Text>
                      {manager.accountType === AccountType.Owner && (
                        <Badge
                          sx={theme => ({
                            color: theme.primaryColor,
                            backgroundColor: theme.colors.primary[0],
                          })}
                        >
                          Owner
                        </Badge>
                      )}
                    </Space>
                    <Text type='secondary' style={{ fontSize: 13 }}>
                      {manager.email} {manager.phone && <>({manager.phone})</>}
                    </Text>
                  </Space>
                </Space>
              ),
            },
            ...(allCompaniesCount > 1
              ? [
                  {
                    title: 'Institution',
                    dataIndex: 'companies',
                    key: 'companies',
                    filters: companies?.map(company => ({
                      text: company?.name ?? '',
                      value: company?.id ?? '',
                    })),
                    // @ts-expect-error companies and manager cannot inherit types
                    onFilter: (value, manager) => manager.companyIds.includes(value),
                    width: '20%',
                    // @ts-expect-error companies and manager cannot inherit types
                    render: (companies, manager) => {
                      if (
                        manager.companyIds.length === allCompaniesCount &&
                        allCompaniesCount > 1
                      ) {
                        return <Tag color='default'>All</Tag>
                      }

                      return (
                        <Space align='center'>
                          {companies.slice(0, 1).map((company: { id: string; name: string }) => (
                            <Tag color='default' key={company?.id ?? ''}>
                              {company?.name}
                            </Tag>
                          ))}
                          {companies.length > 1 && (
                            <Tag color='default'>+{companies.length - 1}</Tag>
                          )}
                        </Space>
                      )
                    },
                  },
                ]
              : []),
            {
              title: 'Permissions',
              dataIndex: 'permissions',
              key: 'permissions',
              width: allCompaniesCount ? '32%' : '42%',
              render: (_, manager) => (
                <Text>
                  {manager.permissions
                    .filter(permission => permission?.enabled)
                    .sort((a, b) => (a?.type?.charCodeAt(0) ?? 0) - (b?.type?.charCodeAt(0) ?? 0))
                    .map(permission => (
                      <Fragment key={permission?.id ?? ''}>
                        <PermissionText
                          permission={permission}
                          key={permission?.id ?? ''}
                          allCategoriesCount={allCategoriesCount}
                        />
                        <Divider type='vertical' />
                      </Fragment>
                    ))}
                </Text>
              ),
            },
            {
              title: 'Security',
              dataIndex: 'security',
              width: '8%',
              key: 'security',
              render: security => (
                <Stack>
                  {security.sso && (
                    <Group>
                      <UntitledIcon icon={ulKey01} />
                      SSO
                    </Group>
                  )}
                  {security.twoFactorAuthentication && (
                    <Group>
                      <UntitledIcon icon={ulLock01} />
                      2FA
                    </Group>
                  )}
                  {!security.sso && !security.twoFactorAuthentication && (
                    <Text type='secondary'>-</Text>
                  )}
                </Stack>
              ),
            },
            {
              title: 'Status',
              dataIndex: 'invitation',
              key: 'invitation',
              width: '8%',
              render: (invitation, { sso }) => (
                <>
                  {invitation === InvitationStatus.Active && <Tag color='#217F50'>Active</Tag>}
                  {invitation === InvitationStatus.Expired && <Tag color='#B60024'>Expired</Tag>}
                  {invitation === InvitationStatus.Sent && <Tag color='#E66B00'>Sent</Tag>}
                  {sso && <Tag color='gold'>SSO</Tag>}
                </>
              ),
            },
            {
              title: 'Last login',
              dataIndex: 'lastLogin',
              key: 'lastLogin',
              width: '10%',
              render: lastLogin => (
                <Tooltip title={`${moment(lastLogin).format(DATE_TIME_FORMAT)}`}>
                  <span>{lastLogin ? moment(lastLogin).format(DATE_FORMAT) : ''}</span>
                </Tooltip>
              ),
            },
            {
              title: '',
              dataIndex: 'id',
              key: 'id',
              width: '5%',
              render: id => {
                const manager = managers?.find(m => m?.id === id) ?? null
                if (!manager?.editable) {
                  return null
                }
                return (
                  <MoreOptions
                    menu={{
                      items: [
                        ...(manager.invitation === InvitationStatus.Active
                          ? [
                              {
                                key: 'edit',
                                label: 'Edit',
                                onClick: () => setManagerToEdit(manager),
                              },
                              ...(manager.accountType !== AccountType.Owner
                                ? [
                                    {
                                      key: 'transfer-ownership',
                                      label: 'Transfer ownership',
                                      onClick: () => setManagerToGrantOwnerShip(manager),
                                    },
                                  ]
                                : []),
                            ]
                          : [
                              {
                                key: 'resend',
                                label: 'Resend',
                                onClick: () =>
                                  resendInvitation({
                                    variables: {
                                      input: {
                                        managerId: manager.id,
                                        motherId: institutionId,
                                      },
                                    },
                                  }),
                              },
                            ]),
                        {
                          key: 'delete',
                          label: 'Delete',
                          onClick: () => {
                            if (totalCount === 1) {
                              modal.error({
                                title: 'Delete last member',
                                okText: 'Ok',
                              })
                              return
                            }

                            if (manager.isPartner) {
                              modal.confirm({
                                title: 'Remove partner user from this institution',
                                okText: 'Ok',
                                onOk: async () => {
                                  await removePartnerUser({
                                    variables: {
                                      input: {
                                        motherId: institutionId,
                                        managerId: manager.id,
                                      },
                                    },
                                  })
                                  refetch()
                                },
                                cancelText: 'Cancel',
                              })
                            } else {
                              modal.confirm({
                                title: 'Delete user',
                                okText: 'Ok',
                                onOk: async () => {
                                  await deleteManager({
                                    variables: {
                                      input: {
                                        motherId: institutionId,
                                        managerId: manager.id,
                                      },
                                    },
                                  })
                                  refetch()
                                },
                                cancelText: 'Cancel',
                              })
                            }
                          },
                        },
                      ],
                    }}
                  />
                )
              },
            },
          ]}
          pagination={false}
        />

        {managerToEdit && institution && (
          <EditManagerModal
            institution={institution}
            manager={null}
            managerToEdit={managerToEdit}
            opened={Boolean(managerToEdit)}
            onClose={() => setManagerToEdit(null)}
            onCompleted={refetch}
          />
        )}

        {createManagerVisible && institution && (
          <CreateManagerModal
            opened
            manager={null}
            institution={institution}
            onCompleted={() => refetch()}
            onClose={() => setCreateManagerVisible(false)}
          />
        )}

        <GrantAccessActionModal
          visible={grantAccessVisible}
          close={() => setGrantAccessVisible(false)}
          users={managers ?? []}
          institution={Institution.Company}
          institutionId={institutionId}
        />

        <TransferOwnershipModal
          manager={managerToGrantOwnerShip}
          setManager={setManagerToGrantOwnerShip}
          institutionId={institutionId}
        />

        <Pagination
          style={styles.pagination}
          pageSize={pageSize}
          current={page}
          total={totalCount || 0}
          onChange={newPage => setPage(newPage)}
          showSizeChanger={false}
        />
      </Card>
    </MotherProvider>
  )
}

const styles = {
  pagination: { textAlign: 'right', marginTop: 20 },
} as const

export default ManagersTable
