import type { UntitledIconData } from '../../UntitledIconData'

const ulChevronDown: UntitledIconData = {
  prefix: 'ul',
  name: 'chevron-down',
  paths: [
    {
      shape: 'M6 9L12 15L18 9',
    },
  ],
}

export default ulChevronDown
