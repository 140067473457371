import { Button } from '@faceup/ui-base'
import { type PropsWithChildren, type ReactNode, forwardRef } from 'react'

type ButtonSmallCirclePrimaryProps = PropsWithChildren<{
  onClick: () => void
  disabled?: boolean
  icon?: ReactNode
}>

export const ButtonSmallCirclePrimary = forwardRef<
  HTMLButtonElement,
  ButtonSmallCirclePrimaryProps
>((props, ref) => <Button ref={ref} shape='circle' type='primary' size='small' {...props} />)
