import { SimpleGrid } from '@mantine/core'
import { useInstitution } from '../../../providers'
import type { UpsellData } from '../upsellData'

type UpgradeButtonsProps = {
  upsell: UpsellData
}

export const UpgradeButtons = (props: UpgradeButtonsProps) => {
  const { upsell: upsellData } = props
  const { upsell } = useInstitution()

  return (
    <SimpleGrid cols={2} spacing='md'>
      {'buttons' in upsell && upsell.buttons(upsellData)}
    </SimpleGrid>
  )
}
