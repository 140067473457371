import { HotlinePhoneNumberType } from './enums'
import { Country } from './localization/enums'

// https://api.support.vonage.com/hc/en-us/articles/115011761808
// https://api.support.vonage.com/hc/en-us/articles/115011767768
// https://dashboard.nexmo.com/download_pricing

export const HOTLINE_COUNTRY_NUMBERS_CONFIGURATION: Record<
  Country,
  Record<HotlinePhoneNumberType, boolean> | undefined
> = {
  [Country.Ao]: {
    [HotlinePhoneNumberType.Landline]: true,
    [HotlinePhoneNumberType.TollFree]: false,
  },
  [Country.Ai]: {
    [HotlinePhoneNumberType.Landline]: true,
    [HotlinePhoneNumberType.TollFree]: false,
  },
  [Country.Ar]: {
    [HotlinePhoneNumberType.Landline]: true,
    [HotlinePhoneNumberType.TollFree]: true,
  },
  [Country.Au]: {
    [HotlinePhoneNumberType.Landline]: true,
    [HotlinePhoneNumberType.TollFree]: true,
  },
  [Country.At]: {
    [HotlinePhoneNumberType.Landline]: true,
    [HotlinePhoneNumberType.TollFree]: true,
  },
  [Country.Bs]: {
    [HotlinePhoneNumberType.Landline]: true,
    [HotlinePhoneNumberType.TollFree]: true,
  },
  [Country.Bh]: {
    [HotlinePhoneNumberType.Landline]: true,
    [HotlinePhoneNumberType.TollFree]: true,
  },
  [Country.Bb]: {
    [HotlinePhoneNumberType.Landline]: true,
    [HotlinePhoneNumberType.TollFree]: true,
  },
  [Country.Be]: {
    [HotlinePhoneNumberType.Landline]: true,
    [HotlinePhoneNumberType.TollFree]: true,
  },
  [Country.Bo]: {
    [HotlinePhoneNumberType.Landline]: true,
    [HotlinePhoneNumberType.TollFree]: true,
  },
  [Country.Ba]: {
    [HotlinePhoneNumberType.Landline]: true,
    [HotlinePhoneNumberType.TollFree]: true,
  },
  [Country.Bw]: {
    [HotlinePhoneNumberType.Landline]: false,
    [HotlinePhoneNumberType.TollFree]: true,
  },
  [Country.Br]: {
    [HotlinePhoneNumberType.Landline]: true,
    [HotlinePhoneNumberType.TollFree]: true,
  },
  [Country.Bg]: {
    [HotlinePhoneNumberType.Landline]: true,
    [HotlinePhoneNumberType.TollFree]: true,
  },
  [Country.Kh]: {
    [HotlinePhoneNumberType.Landline]: true,
    [HotlinePhoneNumberType.TollFree]: true,
  },
  [Country.Ky]: {
    [HotlinePhoneNumberType.Landline]: true,
    [HotlinePhoneNumberType.TollFree]: false,
  },
  [Country.Ca]: {
    [HotlinePhoneNumberType.Landline]: true,
    [HotlinePhoneNumberType.TollFree]: false,
  },
  [Country.Cl]: {
    [HotlinePhoneNumberType.Landline]: true,
    [HotlinePhoneNumberType.TollFree]: true,
  },
  [Country.Cn]: {
    [HotlinePhoneNumberType.Landline]: true,
    [HotlinePhoneNumberType.TollFree]: true,
  },
  [Country.Co]: {
    [HotlinePhoneNumberType.Landline]: true,
    [HotlinePhoneNumberType.TollFree]: true,
  },
  [Country.Cr]: {
    [HotlinePhoneNumberType.Landline]: true,
    [HotlinePhoneNumberType.TollFree]: true,
  },
  [Country.Hr]: {
    [HotlinePhoneNumberType.Landline]: true,
    [HotlinePhoneNumberType.TollFree]: true,
  },
  [Country.Cy]: {
    [HotlinePhoneNumberType.Landline]: true,
    [HotlinePhoneNumberType.TollFree]: true,
  },
  [Country.Cz]: {
    [HotlinePhoneNumberType.Landline]: true,
    [HotlinePhoneNumberType.TollFree]: true,
  },
  [Country.Dk]: {
    [HotlinePhoneNumberType.Landline]: true,
    [HotlinePhoneNumberType.TollFree]: true,
  },
  [Country.Dm]: {
    [HotlinePhoneNumberType.Landline]: true,
    [HotlinePhoneNumberType.TollFree]: false,
  },
  [Country.Do]: {
    [HotlinePhoneNumberType.Landline]: true,
    [HotlinePhoneNumberType.TollFree]: true,
  },
  [Country.Ec]: {
    [HotlinePhoneNumberType.Landline]: true,
    [HotlinePhoneNumberType.TollFree]: true,
  },
  [Country.Eg]: {
    [HotlinePhoneNumberType.Landline]: false,
    [HotlinePhoneNumberType.TollFree]: true,
  },
  [Country.Sv]: {
    [HotlinePhoneNumberType.Landline]: true,
    [HotlinePhoneNumberType.TollFree]: true,
  },
  [Country.Ee]: {
    [HotlinePhoneNumberType.Landline]: true,
    [HotlinePhoneNumberType.TollFree]: true,
  },
  [Country.Fi]: {
    [HotlinePhoneNumberType.Landline]: true,
    [HotlinePhoneNumberType.TollFree]: true,
  },
  [Country.Fr]: {
    [HotlinePhoneNumberType.Landline]: true,
    [HotlinePhoneNumberType.TollFree]: true,
  },
  [Country.Ge]: {
    [HotlinePhoneNumberType.Landline]: true,
    [HotlinePhoneNumberType.TollFree]: true,
  },
  [Country.De]: {
    [HotlinePhoneNumberType.Landline]: true,
    [HotlinePhoneNumberType.TollFree]: true,
  },
  [Country.Gh]: {
    [HotlinePhoneNumberType.Landline]: true,
    [HotlinePhoneNumberType.TollFree]: false,
  },
  [Country.Gr]: {
    [HotlinePhoneNumberType.Landline]: true,
    [HotlinePhoneNumberType.TollFree]: true,
  },
  [Country.Gd]: {
    [HotlinePhoneNumberType.Landline]: true,
    [HotlinePhoneNumberType.TollFree]: false,
  },
  [Country.Gt]: {
    [HotlinePhoneNumberType.Landline]: true,
    [HotlinePhoneNumberType.TollFree]: true,
  },
  [Country.Hk]: {
    [HotlinePhoneNumberType.Landline]: true,
    [HotlinePhoneNumberType.TollFree]: true,
  },
  [Country.Hu]: {
    [HotlinePhoneNumberType.Landline]: true,
    [HotlinePhoneNumberType.TollFree]: true,
  },
  [Country.Is]: {
    [HotlinePhoneNumberType.Landline]: false,
    [HotlinePhoneNumberType.TollFree]: true,
  },
  [Country.In]: {
    [HotlinePhoneNumberType.Landline]: true,
    [HotlinePhoneNumberType.TollFree]: true,
  },
  [Country.Id]: {
    [HotlinePhoneNumberType.Landline]: true,
    [HotlinePhoneNumberType.TollFree]: true,
  },
  [Country.Ie]: {
    [HotlinePhoneNumberType.Landline]: true,
    [HotlinePhoneNumberType.TollFree]: true,
  },
  [Country.Il]: {
    [HotlinePhoneNumberType.Landline]: true,
    [HotlinePhoneNumberType.TollFree]: true,
  },
  [Country.It]: {
    [HotlinePhoneNumberType.Landline]: true,
    [HotlinePhoneNumberType.TollFree]: true,
  },
  [Country.Ci]: {
    [HotlinePhoneNumberType.Landline]: true,
    [HotlinePhoneNumberType.TollFree]: false,
  },
  [Country.Jm]: {
    [HotlinePhoneNumberType.Landline]: true,
    [HotlinePhoneNumberType.TollFree]: true,
  },
  [Country.Jp]: {
    [HotlinePhoneNumberType.Landline]: true,
    [HotlinePhoneNumberType.TollFree]: true,
  },
  [Country.Jo]: {
    [HotlinePhoneNumberType.Landline]: true,
    [HotlinePhoneNumberType.TollFree]: true,
  },
  [Country.Kz]: {
    [HotlinePhoneNumberType.Landline]: true,
    [HotlinePhoneNumberType.TollFree]: false,
  },
  [Country.Ke]: {
    [HotlinePhoneNumberType.Landline]: true,
    [HotlinePhoneNumberType.TollFree]: true,
  },
  [Country.Kg]: {
    [HotlinePhoneNumberType.Landline]: true,
    [HotlinePhoneNumberType.TollFree]: false,
  },
  [Country.La]: {
    [HotlinePhoneNumberType.Landline]: true,
    [HotlinePhoneNumberType.TollFree]: false,
  },
  [Country.Lv]: {
    [HotlinePhoneNumberType.Landline]: true,
    [HotlinePhoneNumberType.TollFree]: true,
  },
  [Country.Lt]: {
    [HotlinePhoneNumberType.Landline]: true,
    [HotlinePhoneNumberType.TollFree]: true,
  },
  [Country.Lu]: {
    [HotlinePhoneNumberType.Landline]: true,
    [HotlinePhoneNumberType.TollFree]: true,
  },
  [Country.Mo]: {
    [HotlinePhoneNumberType.Landline]: true,
    [HotlinePhoneNumberType.TollFree]: false,
  },
  [Country.My]: {
    [HotlinePhoneNumberType.Landline]: true,
    [HotlinePhoneNumberType.TollFree]: true,
  },
  [Country.Mt]: {
    [HotlinePhoneNumberType.Landline]: true,
    [HotlinePhoneNumberType.TollFree]: true,
  },
  [Country.Mu]: {
    [HotlinePhoneNumberType.Landline]: true,
    [HotlinePhoneNumberType.TollFree]: false,
  },
  [Country.Mx]: {
    [HotlinePhoneNumberType.Landline]: true,
    [HotlinePhoneNumberType.TollFree]: true,
  },
  [Country.Md]: {
    [HotlinePhoneNumberType.Landline]: true,
    [HotlinePhoneNumberType.TollFree]: true,
  },
  [Country.Mc]: {
    [HotlinePhoneNumberType.Landline]: false,
    [HotlinePhoneNumberType.TollFree]: true,
  },
  [Country.Me]: {
    [HotlinePhoneNumberType.Landline]: true,
    [HotlinePhoneNumberType.TollFree]: false,
  },
  [Country.Ma]: {
    [HotlinePhoneNumberType.Landline]: true,
    [HotlinePhoneNumberType.TollFree]: true,
  },
  [Country.Mm]: {
    [HotlinePhoneNumberType.Landline]: true,
    [HotlinePhoneNumberType.TollFree]: false,
  },
  [Country.Nl]: {
    [HotlinePhoneNumberType.Landline]: true,
    [HotlinePhoneNumberType.TollFree]: true,
  },
  [Country.Nz]: {
    [HotlinePhoneNumberType.Landline]: true,
    [HotlinePhoneNumberType.TollFree]: true,
  },
  [Country.Ni]: {
    [HotlinePhoneNumberType.Landline]: true,
    [HotlinePhoneNumberType.TollFree]: false,
  },
  [Country.Ng]: {
    [HotlinePhoneNumberType.Landline]: true,
    [HotlinePhoneNumberType.TollFree]: false,
  },
  [Country.No]: {
    [HotlinePhoneNumberType.Landline]: true,
    [HotlinePhoneNumberType.TollFree]: true,
  },
  [Country.Om]: {
    [HotlinePhoneNumberType.Landline]: false,
    [HotlinePhoneNumberType.TollFree]: true,
  },
  [Country.Pk]: {
    [HotlinePhoneNumberType.Landline]: false,
    [HotlinePhoneNumberType.TollFree]: true,
  },
  [Country.Pa]: {
    [HotlinePhoneNumberType.Landline]: true,
    [HotlinePhoneNumberType.TollFree]: true,
  },
  [Country.Py]: {
    [HotlinePhoneNumberType.Landline]: true,
    [HotlinePhoneNumberType.TollFree]: true,
  },
  [Country.Pe]: {
    [HotlinePhoneNumberType.Landline]: true,
    [HotlinePhoneNumberType.TollFree]: true,
  },
  [Country.Ph]: {
    [HotlinePhoneNumberType.Landline]: true,
    [HotlinePhoneNumberType.TollFree]: true,
  },
  [Country.Pl]: {
    [HotlinePhoneNumberType.Landline]: true,
    [HotlinePhoneNumberType.TollFree]: true,
  },
  [Country.Pt]: {
    [HotlinePhoneNumberType.Landline]: true,
    [HotlinePhoneNumberType.TollFree]: true,
  },
  [Country.Pr]: {
    [HotlinePhoneNumberType.Landline]: false,
    [HotlinePhoneNumberType.TollFree]: true,
  },
  [Country.Qa]: {
    [HotlinePhoneNumberType.Landline]: false,
    [HotlinePhoneNumberType.TollFree]: true,
  },
  [Country.Ro]: {
    [HotlinePhoneNumberType.Landline]: true,
    [HotlinePhoneNumberType.TollFree]: true,
  },
  [Country.Ru]: {
    [HotlinePhoneNumberType.Landline]: true,
    [HotlinePhoneNumberType.TollFree]: true,
  },
  [Country.Lc]: {
    [HotlinePhoneNumberType.Landline]: true,
    [HotlinePhoneNumberType.TollFree]: false,
  },
  [Country.Sa]: {
    [HotlinePhoneNumberType.Landline]: true,
    [HotlinePhoneNumberType.TollFree]: true,
  },
  [Country.Rs]: {
    [HotlinePhoneNumberType.Landline]: true,
    [HotlinePhoneNumberType.TollFree]: true,
  },
  [Country.Sg]: {
    [HotlinePhoneNumberType.Landline]: true,
    [HotlinePhoneNumberType.TollFree]: true,
  },
  [Country.Sk]: {
    [HotlinePhoneNumberType.Landline]: true,
    [HotlinePhoneNumberType.TollFree]: true,
  },
  [Country.Si]: {
    [HotlinePhoneNumberType.Landline]: true,
    [HotlinePhoneNumberType.TollFree]: true,
  },
  [Country.Za]: {
    [HotlinePhoneNumberType.Landline]: true,
    [HotlinePhoneNumberType.TollFree]: true,
  },
  [Country.Kr]: {
    [HotlinePhoneNumberType.Landline]: true,
    [HotlinePhoneNumberType.TollFree]: true,
  },
  [Country.Es]: {
    [HotlinePhoneNumberType.Landline]: true,
    [HotlinePhoneNumberType.TollFree]: true,
  },
  [Country.Lk]: {
    [HotlinePhoneNumberType.Landline]: true,
    [HotlinePhoneNumberType.TollFree]: true,
  },
  [Country.Se]: {
    [HotlinePhoneNumberType.Landline]: true,
    [HotlinePhoneNumberType.TollFree]: true,
  },
  [Country.Ch]: {
    [HotlinePhoneNumberType.Landline]: true,
    [HotlinePhoneNumberType.TollFree]: true,
  },
  [Country.Tw]: {
    [HotlinePhoneNumberType.Landline]: true,
    [HotlinePhoneNumberType.TollFree]: true,
  },
  [Country.Tz]: {
    [HotlinePhoneNumberType.Landline]: false,
    [HotlinePhoneNumberType.TollFree]: true,
  },
  [Country.Th]: {
    [HotlinePhoneNumberType.Landline]: true,
    [HotlinePhoneNumberType.TollFree]: true,
  },
  [Country.Tt]: {
    [HotlinePhoneNumberType.Landline]: true,
    [HotlinePhoneNumberType.TollFree]: true,
  },
  [Country.Tn]: {
    [HotlinePhoneNumberType.Landline]: true,
    [HotlinePhoneNumberType.TollFree]: false,
  },
  [Country.Tr]: {
    [HotlinePhoneNumberType.Landline]: true,
    [HotlinePhoneNumberType.TollFree]: true,
  },
  [Country.Ug]: {
    [HotlinePhoneNumberType.Landline]: true,
    [HotlinePhoneNumberType.TollFree]: true,
  },
  [Country.Ua]: {
    [HotlinePhoneNumberType.Landline]: true,
    [HotlinePhoneNumberType.TollFree]: true,
  },
  [Country.Ae]: {
    [HotlinePhoneNumberType.Landline]: true,
    [HotlinePhoneNumberType.TollFree]: true,
  },
  [Country.Gb]: {
    [HotlinePhoneNumberType.Landline]: true,
    [HotlinePhoneNumberType.TollFree]: true,
  },
  [Country.Us]: {
    [HotlinePhoneNumberType.Landline]: true,
    [HotlinePhoneNumberType.TollFree]: true,
  },
  [Country.Uy]: {
    [HotlinePhoneNumberType.Landline]: false,
    [HotlinePhoneNumberType.TollFree]: true,
  },
  [Country.Ve]: {
    [HotlinePhoneNumberType.Landline]: true,
    [HotlinePhoneNumberType.TollFree]: false,
  },
  [Country.Vn]: {
    [HotlinePhoneNumberType.Landline]: true,
    [HotlinePhoneNumberType.TollFree]: true,
  },
  [Country.Vg]: {
    [HotlinePhoneNumberType.Landline]: true,
    [HotlinePhoneNumberType.TollFree]: false,
  },
  [Country.Ad]: undefined,
  [Country.Af]: undefined,
  [Country.Ag]: undefined,
  [Country.Al]: undefined,
  [Country.Am]: undefined,
  [Country.Aq]: undefined,
  [Country.As]: undefined,
  [Country.Aw]: undefined,
  [Country.Ax]: undefined,
  [Country.Az]: undefined,
  [Country.Bd]: undefined,
  [Country.Bf]: undefined,
  [Country.Bi]: undefined,
  [Country.Bj]: undefined,
  [Country.Bl]: undefined,
  [Country.Bm]: undefined,
  [Country.Bn]: undefined,
  [Country.Bq]: undefined,
  [Country.Bt]: undefined,
  [Country.Bv]: undefined,
  [Country.By]: undefined,
  [Country.Bz]: undefined,
  [Country.Cc]: undefined,
  [Country.Cd]: undefined,
  [Country.Cf]: undefined,
  [Country.Cg]: undefined,
  [Country.Ck]: undefined,
  [Country.Cm]: undefined,
  [Country.Cu]: undefined,
  [Country.Cv]: undefined,
  [Country.Cw]: undefined,
  [Country.Cx]: undefined,
  [Country.Dj]: undefined,
  [Country.Dz]: undefined,
  [Country.Eh]: undefined,
  [Country.Er]: undefined,
  [Country.Et]: undefined,
  [Country.Fj]: undefined,
  [Country.Fk]: undefined,
  [Country.Fm]: undefined,
  [Country.Fo]: undefined,
  [Country.Ga]: undefined,
  [Country.Gf]: undefined,
  [Country.Gg]: undefined,
  [Country.Gi]: undefined,
  [Country.Gl]: undefined,
  [Country.Gm]: undefined,
  [Country.Gn]: undefined,
  [Country.Gp]: undefined,
  [Country.Gq]: undefined,
  [Country.Gs]: undefined,
  [Country.Gu]: undefined,
  [Country.Gw]: undefined,
  [Country.Gy]: undefined,
  [Country.Hm]: undefined,
  [Country.Hn]: undefined,
  [Country.Ht]: undefined,
  [Country.Im]: undefined,
  [Country.Io]: undefined,
  [Country.Iq]: undefined,
  [Country.Ir]: undefined,
  [Country.Je]: undefined,
  [Country.Ki]: undefined,
  [Country.Km]: undefined,
  [Country.Kn]: undefined,
  [Country.Kp]: undefined,
  [Country.Kw]: undefined,
  [Country.Lb]: undefined,
  [Country.Li]: undefined,
  [Country.Lr]: undefined,
  [Country.Ls]: undefined,
  [Country.Ly]: undefined,
  [Country.Mf]: undefined,
  [Country.Mg]: undefined,
  [Country.Mh]: undefined,
  [Country.Mk]: undefined,
  [Country.Ml]: undefined,
  [Country.Mn]: undefined,
  [Country.Mp]: undefined,
  [Country.Mq]: undefined,
  [Country.Mr]: undefined,
  [Country.Ms]: undefined,
  [Country.Mv]: undefined,
  [Country.Mw]: undefined,
  [Country.Mz]: undefined,
  [Country.Na]: undefined,
  [Country.Nc]: undefined,
  [Country.Ne]: undefined,
  [Country.Nf]: undefined,
  [Country.Np]: undefined,
  [Country.Nr]: undefined,
  [Country.Nu]: undefined,
  [Country.Pf]: undefined,
  [Country.Pg]: undefined,
  [Country.Pm]: undefined,
  [Country.Pn]: undefined,
  [Country.Ps]: undefined,
  [Country.Pw]: undefined,
  [Country.Re]: undefined,
  [Country.Rw]: undefined,
  [Country.Sb]: undefined,
  [Country.Sc]: undefined,
  [Country.Sd]: undefined,
  [Country.Sh]: undefined,
  [Country.Sj]: undefined,
  [Country.Sl]: undefined,
  [Country.Sm]: undefined,
  [Country.Sn]: undefined,
  [Country.So]: undefined,
  [Country.Sr]: undefined,
  [Country.Ss]: undefined,
  [Country.St]: undefined,
  [Country.Sx]: undefined,
  [Country.Sy]: undefined,
  [Country.Sz]: undefined,
  [Country.Tc]: undefined,
  [Country.Td]: undefined,
  [Country.Tf]: undefined,
  [Country.Tg]: undefined,
  [Country.Tj]: undefined,
  [Country.Tk]: undefined,
  [Country.Tl]: undefined,
  [Country.Tm]: undefined,
  [Country.To]: undefined,
  [Country.Tv]: undefined,
  [Country.Um]: undefined,
  [Country.Uz]: undefined,
  [Country.Va]: undefined,
  [Country.Vc]: undefined,
  [Country.Vi]: undefined,
  [Country.Vu]: undefined,
  [Country.Wf]: undefined,
  [Country.Ws]: undefined,
  [Country.Ye]: undefined,
  [Country.Yt]: undefined,
  [Country.Zm]: undefined,
  [Country.Zw]: undefined,
}

export const HOTLINE_COUNTRIES_WITH_CONFIGURATION = Object.entries(
  HOTLINE_COUNTRY_NUMBERS_CONFIGURATION
)
  .filter(([, hotlineNumbers]) => hotlineNumbers)
  .map(([country]) => country as Country)
  .sort()
