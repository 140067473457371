import { CURRENT_ENCRYPTION_VERSION } from './utils/constants'

export * from './chat'
export * from './files'
export * from './keys'
export * from './password'
export * from './report'
export * from './recovery'
export * from './generators'
export * from './reportClosing'
export * from './migrations'

export const getCurrentEncryptionVersion = () => CURRENT_ENCRYPTION_VERSION
