import {
  FormItem,
  MultiSelect as UiMultiSelect,
  type MultiSelectProps as UiMultiSelectProps,
} from '@faceup/ui'
import type { FieldValues, UseControllerProps } from 'react-hook-form'
import { useCustomController } from '../hooks'
import type { FormItemWrapperProps } from '../typings'

export type MultiSelectProps<T extends FieldValues> = UseControllerProps<T> &
  Omit<UiMultiSelectProps<string> & FormItemWrapperProps, 'value' | 'defaultValue'>

export const MultiSelect = <T extends FieldValues>(props: MultiSelectProps<T>) => {
  const {
    formItemProps,
    inputProps: { onChange: fieldOnChange, value, ...inputProps },
  } = useCustomController(props)
  return (
    <FormItem {...formItemProps}>
      <UiMultiSelect
        {...inputProps}
        value={value ?? []}
        onChange={e => {
          fieldOnChange(e)
          props.onChange?.(e)
        }}
      />
    </FormItem>
  )
}
