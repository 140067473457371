import { gql, useMutation } from '@apollo/client'
import { useNavigate } from '@faceup/router'
import { Button } from '@faceup/ui'
import { Popconfirm, Space, notification } from '@faceup/ui-base'
import { useState } from 'react'
import {
  type ApproveSchoolRegistrationMutation,
  type ApproveSchoolRegistrationMutationVariables,
  Institution,
  type RejectSchoolRegistrationMutation,
  type RejectSchoolRegistrationMutationVariables,
} from '../__generated__/globalTypes'

const mutations = {
  ApproveSchoolRegistration: gql`
    mutation ApproveSchoolRegistrationMutation($input: ApproveSchoolRegistrationInput!) {
      approveSchoolRegistration(input: $input) {
        company {
          id
          registered
          isVerified

          billing {
            id
            plan
          }
        }
      }
    }
  `,
  RejectSchoolRegistration: gql`
    mutation RejectSchoolRegistrationMutation($input: RejectSchoolRegistrationInput!) {
      rejectSchoolRegistration(input: $input) {
        company {
          id
          billing {
            id
            plan
          }

          isVerified

          managers(page: 0, rowsPerPage: 1000) {
            items {
              id
              name
              email
              phone
            }
          }
        }
      }
    }
  `,
}

type Props = {
  institutionId: string | null
  type: Institution | null
}

const RegistrationButtons = ({ institutionId, type }: Props) => {
  const [loadingButton, setLoadingButton] = useState<'verify' | 'reject' | null>()
  const navigate = useNavigate()

  const [rejectSchool, { loading: loadingReject }] = useMutation<
    RejectSchoolRegistrationMutation,
    RejectSchoolRegistrationMutationVariables
  >(mutations.RejectSchoolRegistration, {
    onError: error => {
      setLoadingButton(null)
      console.error(error)
      notification.error({
        message: 'GQL ERROR',
        description: error.message,
      })
    },
    onCompleted: () =>
      navigate(routes => (type === Institution.Company ? routes.companies() : routes.schools())),
  })

  const [approveSchool, { loading: loadingVerify }] = useMutation<
    ApproveSchoolRegistrationMutation,
    ApproveSchoolRegistrationMutationVariables
  >(mutations.ApproveSchoolRegistration, {
    onError: error => {
      setLoadingButton(null)
      console.error(error)
      notification.error({
        message: 'GQL ERROR',
        description: error.message,
      })
    },
    onCompleted: () => setLoadingButton(null),
  })

  if (!institutionId) {
    return null
  }

  return (
    <Space>
      <Popconfirm
        title='Rejecting the registration will send emails to institution'
        onConfirm={() => {
          setLoadingButton('reject')
          rejectSchool({
            variables: {
              input: {
                companyId: institutionId,
              },
            },
          })
        }}
      >
        <Button
          disabled={loadingReject && loadingButton === 'verify'}
          loading={loadingButton === 'reject'}
          variant='secondary'
        >
          Reject registration
        </Button>
      </Popconfirm>

      <Popconfirm
        title='Approving the registration will send emails to institution'
        onConfirm={() => {
          setLoadingButton('verify')
          approveSchool({
            variables: {
              input: {
                companyId: institutionId,
              },
            },
          })
        }}
      >
        <Button
          disabled={loadingVerify && loadingButton === 'reject'}
          loading={loadingButton === 'verify'}
        >
          Verify registration
        </Button>
      </Popconfirm>
    </Space>
  )
}

export default RegistrationButtons
