import { storageGet, storageRemove, storageSet } from '@faceup/utils'
import { CURRENT_ENCRYPTION_VERSION } from './utils/constants'
import { getSodium, handleError } from './utils/general'

const PBK_STORAGE_KEY = 'PBK_STORAGE_KEY'
const PRK_STORAGE_KEY = 'PRK_STORAGE_KEY'

const getItem = (key: string) =>
  storageGet('localStorage', key) ?? storageGet('sessionStorage', key)

export const getPersonalKeys = async () => {
  const sodium = await getSodium()

  const privateKey = getItem(PRK_STORAGE_KEY) ?? null
  const publicKey = getItem(PBK_STORAGE_KEY) ?? null

  return {
    privateKey: privateKey ? sodium.from_base64(privateKey) : null,
    publicKey: publicKey ? sodium.from_base64(publicKey) : null,
  }
}

export const getUnmodifiedKeys = () => {
  const privateKey = getItem(PRK_STORAGE_KEY) ?? null
  const publicKey = getItem(PBK_STORAGE_KEY) ?? null

  return {
    privateKey: privateKey ?? null,
    publicKey: publicKey ?? null,
  }
}

type SavePersonalKeysPayload = {
  passwordKey?: string
  publicKey: string
  privateKey: string
  nonce?: string
  rememberMe: boolean
  version: number
}

export const savePersonalKeys = async (payload: SavePersonalKeysPayload) => {
  const sodium = await getSodium()
  const setItem = (key: string, value: string) =>
    storageSet(payload.rememberMe ? 'localStorage' : 'sessionStorage', key, value)

  try {
    const privateKey =
      payload.version === CURRENT_ENCRYPTION_VERSION && payload.passwordKey && payload.nonce
        ? sodium.crypto_secretbox_open_easy(
            sodium.from_base64(payload.privateKey),
            sodium.from_base64(payload.nonce),
            sodium.from_base64(payload.passwordKey),
            'base64'
          )
        : payload.privateKey

    setItem(PBK_STORAGE_KEY, payload.publicKey)
    setItem(PRK_STORAGE_KEY, privateKey)

    return
  } catch (e) {
    return handleError(e)
  }
}

type SaveSSOKeysPayload = {
  publicKey: string
  privateKey: string
  rememberMe: boolean
}

export const saveSSOKeys = async (payload: SaveSSOKeysPayload) => {
  const setItem = (key: string, value: string) =>
    storageSet(payload.rememberMe ? 'localStorage' : 'sessionStorage', key, value)

  setItem(PBK_STORAGE_KEY, payload.publicKey)
  setItem(PRK_STORAGE_KEY, payload.privateKey)
}

export const deletePersonalKeys = () => {
  const removeItem = (key: string) => {
    storageRemove('localStorage', key)
    storageRemove('sessionStorage', key)
  }

  removeItem(PRK_STORAGE_KEY)
  removeItem(PBK_STORAGE_KEY)
}
