import { useThemeColors } from '@faceup/ui'
import { Group, Stack } from '@mantine/core'

type DotsMeshProps = {
  rows: number
  cols: number
  color?: string
}

export const DotsMesh = ({ rows, cols, color }: DotsMeshProps) => {
  const { themeColors } = useThemeColors()

  return (
    <Stack spacing={8}>
      {[...Array(rows).keys()].map(i => (
        <Group key={i} spacing={8}>
          {[...Array(cols).keys()].map(j => (
            <svg
              width='10'
              height='5'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
              key={`${i}-${j}`}
            >
              <path
                d='M0 .382C.02 3.482 2.091 5.012 4.667 5c2.81-.016 4.682-1.827 4.666-4.667C7.896.696 6.381.99 4.667 1 2.95 1.008 1.356.729 0 .382Z'
                fill={color ?? themeColors.primary[20]}
              />
            </svg>
          ))}
        </Group>
      ))}
    </Stack>
  )
}
