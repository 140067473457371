import { LocalizationProvider } from '@faceup/localization'
import { UserProvider } from '@faceup/member'
import { UiProvider as FUUiProvider } from '@faceup/ui'
import { App, UiBaseProvider, useMessage } from '@faceup/ui-base'
import { Country, DEFAULT_LANGUAGE, convertCountryToCountryName } from '@faceup/utils'
import { type PropsWithChildren, type ReactNode, useEffect } from 'react'
import GeoDataProvider from './Contexts/GeoDataProvider'

type AppProvidersProps = { children: ReactNode }

const AppProviders = ({ children }: AppProvidersProps) => (
  <LocalizationProvider language={DEFAULT_LANGUAGE}>
    <GeoDataProvider>
      <App>
        <UiBaseProvider locale={undefined} direction='ltr'>
          <UiProvider>
            <UserProvider application='kredenc' onLogout={() => null} onChangeLanguage={() => null}>
              {children}
            </UserProvider>
          </UiProvider>
        </UiBaseProvider>
      </App>
    </GeoDataProvider>
  </LocalizationProvider>
)

const UiProvider = ({ children }: PropsWithChildren) => {
  const message = useMessage()

  useEffect(() => {
    if (['stage', 'preview'].includes(import.meta.env.VITE_ENVIRONMENT ?? '')) {
      message.warning({
        key: 'environment-info-box',
        content: `${import.meta.env.VITE_ENVIRONMENT} environment`,
        duration: 0,
        className: 'environment-info-box',
      })
    }
  }, [message])

  return (
    <FUUiProvider
      linkExternalConfirm={{
        confirmRedirectMessageTitle: 'Allow redirect',
        confirmRedirectMessageContent: 'Redirect to external site',
        redirectOkButton: 'Follow link',
        redirectCancelButton: 'Cancel',
      }}
      inputCopyable={{
        onCopySuccess: () => message.success('Copied'),
        copy: 'Copy',
      }}
      inputPhone={{
        searchPlaceholder: 'Search',
        localization: Object.values(Country).reduce(
          (acc, country) => ({
            ...acc,
            [country.toLowerCase()]: convertCountryToCountryName(country, 'en'),
          }),
          {}
        ),
        searchNotFound: 'Not found',
      }}
    >
      {children}
    </FUUiProvider>
  )
}

export default AppProviders
