import { Group } from '@mantine/core'
import { type ReactNode, useContext, useEffect } from 'react'
import { Button } from '../Button'
import { UiContext } from '../UiProvider/UiProvider'
import { Modal, type ModalProps, type ModalVariants } from './Modal'

type ModalConfirmProps = {
  cancelText?: ReactNode | string
  /**
   * Only needed if different from `onClose`.
   * If not defined, the onClose prop will be used.
   */
  onCancel?: () => void
  cancelState?: {
    loading?: boolean
    disabled?: boolean
  }
  confirmText?: ReactNode | string
  onConfirm?: () => void
  confirmState?: {
    loading?: boolean
    disabled?: boolean
  }
  // TODO: danger states
  variant?: ModalVariants
  afterClose?: () => void
  hasNoCancelButton?: boolean
  dataCy?: string
} & ModalProps

export const ModalConfirm = ({
  children,
  opened,
  onClose,
  afterClose,
  cancelText,
  confirmText,
  onCancel,
  onConfirm,
  confirmState,
  cancelState,
  variant = 'primary',
  hasNoCancelButton = false,
  dataCy,
  ...props
}: ModalConfirmProps) => {
  const { modalConfirm: modalContext } = useContext(UiContext)

  useEffect(() => {
    if (!opened && afterClose) {
      afterClose()
    }
  }, [opened, afterClose])
  return (
    <Modal
      variant={variant}
      opened={opened}
      {...props}
      onClose={onClose}
      footer={
        <Group position='right' spacing='xs'>
          {(onCancel ?? onClose) && !hasNoCancelButton && (
            <Button
              variant='tertiary'
              onClick={onCancel ?? onClose}
              loading={cancelState?.loading}
              disabled={cancelState?.disabled}
              data-cy={dataCy ? `${dataCy}-cancel-button` : undefined}
            >
              {cancelText ?? modalContext?.cancelText ?? 'Cancel'}
            </Button>
          )}
          <Button
            variant='primary'
            color={variant === 'danger' ? 'error' : 'primary'}
            onClick={onConfirm}
            loading={confirmState?.loading}
            disabled={confirmState?.disabled}
            data-cy={dataCy ? `${dataCy}-confirm-button` : undefined}
          >
            {confirmText ?? modalContext?.confirmText ?? 'OK'}
          </Button>
        </Group>
      }
    >
      {children}
    </Modal>
  )
}
