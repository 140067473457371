import { gql, useMutation } from '@apollo/client'
import { useLanguageName } from '@faceup/localization'
import { Button, Form, Modal, type ModalProps } from '@faceup/ui'
import { Space, Upload, UploadOutlined, notification, useMessage } from '@faceup/ui-base'
import {
  ALLOWED_FILE_MIME_TYPE_EXTENSION_MAP,
  Institution,
  type Language,
  MaterialGenerationType,
  type MaterialType,
  materialTypeDefinition,
} from '@faceup/utils'
import { Checkbox, Select, TextInput } from '@mantine/core'
import { useForm } from '@mantine/form'
import { useEffect } from 'react'
import type {
  CreateInternalMaterialMutation,
  CreateInternalMaterialMutationVariables,
} from '../../../__generated__/globalTypes'

type CreateMaterialInputs = {
  name: string
  file: File | null
  type: MaterialType | null
  generatedDocument: MaterialGenerationType | null
  isForSchoolDefaultForm: boolean
  withActivePassword: boolean
  language: Language | null
  institution: Institution | null
  isFree: boolean
}

type CreateMaterialModalProps = Pick<ModalProps, 'opened' | 'onClose'> & {
  selectedLanguage: Language | null
  selectedInstitution: Institution | null
  selectedType: MaterialType | null
  onSuccess: () => void
}

const mutations = {
  CreateInternalMaterial: gql`
    mutation CreateInternalMaterialMutation($input: CreateInternalMaterialInput!) {
      createInternalMaterial(input: $input) {
        newMaterial {
          id
        }
      }
    }
  `,
}

export const CreateMaterialModal = (props: CreateMaterialModalProps) => {
  const { selectedLanguage, selectedInstitution, selectedType } = props
  const { getLanguageList } = useLanguageName()
  const message = useMessage()

  const form = useForm<CreateMaterialInputs>({
    initialValues: {
      name: '',
      file: null,
      type: selectedType,
      generatedDocument: null,
      isForSchoolDefaultForm: false,
      withActivePassword: false,
      institution: selectedInstitution,
      language: selectedLanguage,
      isFree: false,
    },
  })

  const [createInternalMaterial] = useMutation<
    CreateInternalMaterialMutation,
    CreateInternalMaterialMutationVariables
  >(mutations.CreateInternalMaterial, {
    onError: error => {
      console.error(error)
      notification.error({
        message: `Can't create material`,
        description: error.message,
      })
    },
    onCompleted: data => {
      if (data.createInternalMaterial?.newMaterial.id) {
        message.success(`Material added`)
        props.onSuccess()
      }
    },
  })

  useEffect(() => {
    const { generatedDocument, institution, isForSchoolDefaultForm, withActivePassword } =
      form.values
    if (
      generatedDocument !== MaterialGenerationType.Leaflet ||
      institution !== Institution.School
    ) {
      if (isForSchoolDefaultForm) {
        form.setFieldValue('isForSchoolDefaultForm', false)
      }
      if (withActivePassword) {
        form.setFieldValue('withActivePassword', false)
      }
    }
  }, [form])

  return (
    <Modal {...props} title='Add material'>
      <Form
        form={form}
        submitButtonText='add'
        onSubmit={async values => {
          if (
            values.language &&
            values.institution &&
            values.type &&
            values.generatedDocument &&
            values.file
          ) {
            await createInternalMaterial({
              variables: {
                input: {
                  name: values.name,
                  language: values.language,
                  type: values.type,
                  generatedDocument: values.generatedDocument,
                  isForSchoolDefaultForm: values.isForSchoolDefaultForm,
                  withActivePassword: values.withActivePassword,
                  institution: values.institution,
                  file: values.file,
                  isFree: values.isFree,
                },
              },
            })
            return true
          }
          return false
        }}
      >
        <TextInput {...form.getInputProps('name')} label='Name' />
        <Upload
          accept={Object.keys(ALLOWED_FILE_MIME_TYPE_EXTENSION_MAP).join(',')}
          beforeUpload={(file: File) => {
            form.setFieldValue('file', file)
            return false
          }}
          onRemove={() => {
            form.setFieldValue('file', null)
          }}
          showUploadList
        >
          <Button variant='secondary'>
            <Space>
              <UploadOutlined />
              Upload file
            </Space>
          </Button>
        </Upload>
        <Select
          {...form.getInputProps('type')}
          data={materialTypeDefinition.map(type => ({
            label: type,
            value: type,
          }))}
          label='Type'
        />
        <Select
          {...form.getInputProps('generatedDocument')}
          data={Object.values(MaterialGenerationType).map(value => ({
            label: value,
            value,
          }))}
          label='Generated document'
        />
        <Select
          {...form.getInputProps('language')}
          data={getLanguageList().map(language => ({
            label: language.name,
            value: language.language,
          }))}
          label='Language'
        />
        <Select
          {...form.getInputProps('institution')}
          data={Object.values(Institution)}
          label='Institution'
        />
        <Checkbox
          {...form.getInputProps('isFree', { type: 'checkbox' })}
          label='Is available also to free schools?'
        />
        <Space />
        {form.values.generatedDocument === MaterialGenerationType.Leaflet &&
          form.values.institution === Institution.School && (
            <>
              <Checkbox
                {...form.getInputProps('isForSchoolDefaultForm', { type: 'checkbox' })}
                label='For default school form'
              />
              <Checkbox
                // only default forms can have password switched on / off
                disabled={!form.values.isForSchoolDefaultForm}
                {...form.getInputProps('withActivePassword', { type: 'checkbox' })}
                label='For schools with an active password'
              />
            </>
          )}
      </Form>
    </Modal>
  )
}
