import styled from '@emotion/styled'
import {
  type BaseOptionType,
  CloseOutlined,
  type DefaultOptionType,
  List,
  Select,
  type SelectProps,
} from '@faceup/ui-base'
import { type ReactNode, useEffect, useMemo, useRef, useState } from 'react'
import { useOutsideAlerter } from '../hooks'

type SelectCustomizableProps<ValueType, OptionType extends BaseOptionType | DefaultOptionType> = {
  renderItem: (item: OptionType, handleChange: (item: OptionType) => void) => ReactNode
  whispererHeight?: string
  maxSearchLength?: number
} & SelectProps<ValueType, OptionType>

const SelectCustomizable = <ValueType, OptionType extends BaseOptionType | DefaultOptionType>({
  renderItem,
  options = [],
  onChange,
  whispererHeight = '200px',
  filterOption,
  notFoundContent,
  maxSearchLength,
  value,
  mode,

  ...props
}: SelectCustomizableProps<ValueType, OptionType>) => {
  const [search, setSearch] = useState('')

  const [clickedOutside, setClickedOutside] = useState(false)
  const selectWrapperRef = useRef(null)
  const parentWrapperRef = useRef(null)
  useOutsideAlerter(
    selectWrapperRef,
    () => {
      setClickedOutside(true)
    },
    parentWrapperRef
  )
  const valueKey = props.fieldNames?.value ?? 'value'
  const labelKey = props.fieldNames?.label ?? 'label'

  const filteredOptions = useMemo(() => {
    if (!search || filterOption === undefined) {
      return options
    }
    return options.filter(option =>
      typeof filterOption === 'boolean' ? filterOption : filterOption(search, option)
    )
  }, [filterOption, options, search])

  useEffect(() => {
    if (maxSearchLength && search.length > maxSearchLength) {
      setSearch(search.substring(0, maxSearchLength))
    }
  }, [search, maxSearchLength])

  // biome-ignore lint/suspicious/noExplicitAny:
  const handleChange = (item: any & { [key: string]: ValueType }) => {
    if (Array.isArray(value) && mode === 'multiple') {
      const isSelected = Boolean(value.find(valueItem => valueItem[valueKey] === item[valueKey]))
      if (isSelected) {
        const items = [...value.filter(valueItem => valueItem[valueKey] !== item[valueKey])]
        onChange?.(items as unknown as ValueType, items)
      } else {
        const items = [...value, item]
        onChange?.(items as unknown as ValueType, items)
      }
    } else {
      // if fieldNames.label is not specified, don't return undefined
      onChange?.(props?.fieldNames?.label && item[props.fieldNames.label], item)
    }
  }

  const addLabelValue = (
    options: (ValueType | OptionType) | OptionType[] | null | undefined
  ): (ValueType | OptionType) | OptionType[] | undefined => {
    if (!props.labelInValue) {
      return options ?? undefined
    }
    if (options === undefined || options === null) {
      return undefined
    }
    if (Array.isArray(options)) {
      return options.map(option => ({
        ...option,
        value: option[valueKey],
        label: option[labelKey],
      }))
    }

    const value = (options as Record<string, unknown>)[valueKey]
    const label = (options as Record<string, unknown>)[labelKey]

    return {
      ...options,
      value,
      label,
    }
  }

  return (
    <div ref={parentWrapperRef}>
      <div ref={selectWrapperRef}>
        <Select
          {...props}
          showSearch
          showArrow={props.loading}
          open={false}
          // if autofocus enabled, page jumps to top
          autoFocus={false}
          mode={mode === 'multiple' ? 'tags' : mode}
          value={addLabelValue(value) as ValueType}
          options={addLabelValue(options) as OptionType[]}
          removeIcon={<IconClose />}
          searchValue={search}
          onClick={event => {
            setClickedOutside(false)
            props?.onClick?.(event)
          }}
          onChange={(value, option) => {
            if (clickedOutside) {
              setClickedOutside(false)
            } else {
              if (Array.isArray(value) && mode === 'multiple') {
                // check if item exists, select doesn't allow typing into input if multiple and not opened
                const isItemInvalid = value.some(item => item.label === undefined)
                if (!isItemInvalid) {
                  onChange?.(value, option)
                }
              } else {
                setSearch('')
                onChange?.(value, option)
              }
            }
          }}
          onSearch={value => {
            setSearch(value)
          }}
        />
      </div>
      <WhispererWrapper height={whispererHeight}>
        <List
          size='small'
          dataSource={filteredOptions}
          renderItem={item => renderItem(item, handleChange)}
          locale={{
            emptyText: notFoundContent,
          }}
        />
      </WhispererWrapper>
    </div>
  )
}

const WhispererWrapper = styled.div<{ height: string }>`
  height: ${({ height }) => height};
  overflow: auto;
  margin-top: 4px;
`

const IconClose = styled(CloseOutlined)`
  color: #ef4a45;
  font-size: 13px;
`

export default SelectCustomizable
