import { isEmail, isUrl } from '@faceup/utils'
import * as yupImport from 'yup'
export type {
  StringSchema,
  ObjectSchema,
  BooleanSchema,
  ArraySchema,
  AnyObject,
  Schema,
  InferType,
} from 'yup'

const dateRange = () =>
  yupImport.object().shape({
    from: yupImport.date().required(),
    to: yupImport.date().required(),
  })

export const yup = { ...yupImport, dateRange }

yup.addMethod(yup.string, 'email', function (message: string) {
  return this.test('email', message, value => (value ? isEmail(value) : false))
})

yup.addMethod(yup.string, 'url', function (message: string) {
  return this.test('url', message, value => (value ? isUrl(value) : false))
})

export * from './wrappers'
export * from './Form'
export * from './FormLocalizationProvider'
export * from './typings'
export * from './functions'
export * from './Inputs'
export * from './hooks'

export {
  FormProvider,
  useFormContext,
  type FieldValues,
  type UseControllerProps,
  type Path,
  type PathValue,
} from 'react-hook-form'
