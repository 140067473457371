import { gql } from '@apollo/client'
import { Card, TableEnhanced, Text } from '@faceup/ui'
import {
  HotlineOrderStatus,
  type ReportSourceHotlineTable_hotline as HotlineReportSourceType,
} from '../__generated__/globalTypes'
import ApproveHotlineModal from './ApproveHotlineModal'

export const ReportSourceHotlineTable_hotline = gql`
  fragment ReportSourceHotlineTable_hotline on ReportSource {
    id
    name
    sourceType
    order {
      status
    }
    isEnabled
    config {
      ... on HotlineReportSourceConfiguration {
        phoneNumber
        phoneNumberType
      }
    }
  }
`

type Props = {
  reportSources: HotlineReportSourceType[]
  loading: boolean
  refetch: () => void
}

const ReportSourcesHotlineTable = ({ reportSources, loading, refetch }: Props) => {
  const makeTableRow = (reportSource: HotlineReportSourceType) => ({
    id: reportSource.id,
    name: reportSource.name,
    status: reportSource.order?.status,
    isEnabled: reportSource.isEnabled,
    phoneNumber:
      reportSource?.config?.__typename === 'HotlineReportSourceConfiguration'
        ? reportSource.config.phoneNumber
        : null,
  })

  return (
    <Card.Segment title='Hotline' hasNoPadding={Boolean(reportSources.length)}>
      {reportSources.length ? (
        <TableEnhanced<ReturnType<typeof makeTableRow>>
          loading={loading}
          rowKey='id'
          dataSource={reportSources.map(reportSource => makeTableRow(reportSource))}
          locale={{ emptyText: ' ' }}
          scroll={{ x: 'max-content' }}
          columns={[
            {
              title: 'ID',
              dataIndex: 'id',
              key: 'id',
              render: id => atob(id).split(':')[1],
            },
            {
              title: 'Name',
              dataIndex: 'name',
              key: 'name',
            },
            {
              title: 'Type',
              dataIndex: 'phoneNumberType',
              key: 'phoneNumberType',
            },
            {
              title: 'Is enabled',
              dataIndex: 'isEnabled',
              key: 'isEnabled',
              render: isEnabled => <>{isEnabled ? 'Yes' : 'No'}</>,
            },
            {
              title: 'Order status',
              dataIndex: 'status',
              key: 'status',
            },
            {
              title: 'Phone number',
              dataIndex: 'phoneNumber',
              key: 'phoneNumber',
              render: number => number ?? '',
            },
            {
              title: 'Actions',
              key: 'approve',
              width: '10%',
              render: (_, row) =>
                row.status === HotlineOrderStatus.Processing && (
                  <ApproveHotlineModal reportSourceId={row.id} refetch={refetch} />
                ),
            },
          ]}
          pagination={false}
        />
      ) : (
        <Text>This institution does not have a hotline yet.</Text>
      )}
    </Card.Segment>
  )
}

export default ReportSourcesHotlineTable
