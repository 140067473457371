import styled from '@emotion/styled'
import { Table, type TableProps } from '@faceup/ui-base'
import type { ReactNode } from 'react'

export type TableEnhancedAdditionalProps = {
  footer?: ReactNode
}

export type TableEnhancedProps<D> = TableEnhancedAdditionalProps &
  Omit<TableProps<D>, 'title' | 'footer'>

// biome-ignore lint/suspicious/noExplicitAny:
export const TableEnhanced = <D extends object = any>({
  footer,
  ...props
}: TableEnhancedProps<D>) => (
  <Wrapper>
    <Table<D> {...props} />
    {footer && <RowUnderTable>{footer}</RowUnderTable>}
  </Wrapper>
)

const RowUnderTable = styled.div`
  padding: 16px;
`

const Wrapper = styled.div`
  & .ant-table-wrapper .ant-pagination {
    margin-inline: 16px;
  }
`
