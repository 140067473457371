import { yupResolver } from '@hookform/resolvers/yup'
import { useEffect } from 'react'
import {
  type FieldValues,
  type UseFormProps,
  type UseFormReturn as UseRHFFormReturn,
  useForm as useRHFForm,
  useWatch as useRHFWatch,
} from 'react-hook-form'
import type { AnyObject, ObjectSchema } from 'yup'

/**
 * What to do with inserted values after submit
 *
 * persistValues: keep inserted values (submit button is disabled in persisted state)
 * resetValues: reset inserted values (how it looks like when you open the form for the first time)
 * nothing: do nothing (submit button is active, values in form are kept)
 *
 * Best practices:
 * - Edit form: persistValues
 * - Create form: resetValues
 * - Form with no submit, filters: nothing
 */
type AfterSubmit = 'persistValues' | 'resetValues' | 'nothing'

type Schema<
  TFieldValues extends FieldValues = FieldValues,
  TContext extends AnyObject = AnyObject,
> = ObjectSchema<TFieldValues, TContext, AnyObject, ''>

export type UseFormReturn<
  TFieldValues extends FieldValues = FieldValues,
  TContext extends AnyObject = AnyObject,
  TTransformedValues extends FieldValues | undefined = undefined,
> = UseRHFFormReturn<TFieldValues, TContext, TTransformedValues> & {
  control: { schema: Schema<TFieldValues, TContext> }
  afterSubmit: AfterSubmit
}

export const useForm = <
  TFieldValues extends FieldValues = FieldValues,
  TContext extends AnyObject = AnyObject,
  TTransformedValues extends FieldValues | undefined = undefined,
>(
  props: Omit<UseFormProps<TFieldValues, TContext>, 'resolver'> & {
    schema: Schema<TFieldValues, TContext>
    afterSubmit: AfterSubmit
    validateOnMount?: boolean
  }
): UseFormReturn<TFieldValues, TContext, TTransformedValues> => {
  const { schema, validateOnMount = false, afterSubmit, ...rest } = props || {}
  const form = {
    ...useRHFForm<TFieldValues, TContext, TTransformedValues>({
      ...rest,
      mode: validateOnMount ? 'onChange' : rest.mode,
      // @ts-expect-error RHF V7 limitation https://github.com/orgs/react-hook-form/discussions/7895
      resolver: yupResolver(schema),
    }),
    afterSubmit,
  }

  const trigger = form.trigger

  // We want to allow to validate on mount
  // https://stackoverflow.com/a/69740259
  useEffect(() => {
    if (validateOnMount) {
      void trigger()
    }
  }, [trigger, validateOnMount])

  return {
    ...form,
    control: {
      ...form.control,
      schema,
    },
  }
}

export const useWatch = useRHFWatch
