import { createContext } from 'react'

export enum ChatPopupAlertType {
  OutsideWorkingHours = 'OutsideWorkingHours',
  NotVictimReport = 'NotVictimReport',
  ReportStatus = 'ReportStatus',
}

type State = {
  alertType: ChatPopupAlertType | null
  closeNotOpenReportAlert: () => void
}

export const PopupsContext = createContext<State>({
  alertType: null,
  closeNotOpenReportAlert: () => undefined,
})
