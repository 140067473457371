import { Country } from './enums'

export const isAfricanCountry = (country: Country) => AFRICAN_COUNTRIES.includes(country)
export const isEuCountry = (country: Country) => EU_COUNTRIES.includes(country as EuCountries)

// European Union countries
export const EU_COUNTRIES = [
  Country.At,
  Country.Be,
  Country.Bg,
  Country.Cy,
  Country.Cz,
  Country.Dk,
  Country.Ee,
  Country.Fi,
  Country.Fr,
  Country.De,
  Country.Gr,
  Country.Hr,
  Country.Hu,
  Country.Ie,
  Country.It,
  Country.Lv,
  Country.Lt,
  Country.Lu,
  Country.Mt,
  Country.Nl,
  Country.Pl,
  Country.Pt,
  Country.Ro,
  Country.Sk,
  Country.Si,
  Country.Es,
  Country.Se,
  Country.Gb,
] as const

// tell TypeScript that the type of this variable is a union of the values of the array
export type EuCountries = (typeof EU_COUNTRIES)[number]

// https://country-code.cl/
export const AFRICAN_COUNTRIES = [
  Country.Ao,
  Country.Bf,
  Country.Bi,
  Country.Bj,
  Country.Bw,
  Country.Cd,
  Country.Cf,
  Country.Cg,
  Country.Ci,
  Country.Cm,
  Country.Cv,
  Country.Dj,
  Country.Dz,
  Country.Eg,
  Country.Eh,
  Country.Er,
  Country.Et,
  Country.Ga,
  Country.Gh,
  Country.Gm,
  Country.Gn,
  Country.Gq,
  Country.Gw,
  Country.Ke,
  Country.Km,
  Country.Lr,
  Country.Ls,
  Country.Ly,
  Country.Ma,
  Country.Mg,
  Country.Ml,
  Country.Mr,
  Country.Mu,
  Country.Mw,
  Country.Mz,
  Country.Na,
  Country.Ne,
  Country.Ng,
  Country.Re,
  Country.Rw,
  Country.Sc,
  Country.Sd,
  Country.Sh,
  Country.Sl,
  Country.Sn,
  Country.So,
  Country.Ss,
  Country.St,
  Country.Sz,
  Country.Td,
  Country.Tg,
  Country.Tn,
  Country.Tz,
  Country.Ug,
  Country.Yt,
  Country.Za,
  Country.Zm,
  Country.Zw,
]
