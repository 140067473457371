import { gql, useMutation } from '@apollo/client'
import { sharedMessages, useIntl } from '@faceup/localization'
import { notification } from '@faceup/ui-base'
import type { ReportExportLog, ReportExportLogVariables } from '../__generated__/globalTypes'

const mutation = gql`
  mutation ReportExportLog($input: CreateExportActivityLogItemMutationInput!) {
    createExportActivityLogItem(input: $input) {
      success
    }
  }
`

export const useReportExportLogger = () => {
  const { formatMessage } = useIntl()

  const [log] = useMutation<ReportExportLog, ReportExportLogVariables>(mutation, {
    onError: error => {
      console.error(error)
      notification.error({
        message: formatMessage(sharedMessages.apiError),
        description: error.message,
      })
    },
  })

  const logExport = (motherId: string, reportIds: string[]) =>
    log({
      variables: {
        input: {
          reportIds,
          motherId,
        },
      },
    })

  return {
    logExport,
  }
}
