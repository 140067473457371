import styled from '@emotion/styled'
import { Button, Card, TableEnhanced } from '@faceup/ui'
import { Form, Input, LoadingOutlined, Typography } from '@faceup/ui-base'
import { isEmail } from '@faceup/utils'
import { Box } from '@mantine/core'
import { useState } from 'react'
import PageTemplateUnlogged from '../PageTemplateUnlogged'
import { fuAdminsMock, mockCredentialTableColumns } from './loginMockUsers'
import useLogin from './useLogin'

const { Title: AntTitle } = Typography

export type FormType = 'login' | '2fa'

const Login = () => {
  const [loading, setLoading] = useState(false)

  const [showTestAccounts, setShowTestAccounts] = useState(false)
  const {
    serverLoginFormError,
    login,
    code,
    setCode,
    email,
    setEmail,
    password,
    setPassword,
    formType,
  } = useLogin(setLoading)

  const hasLoginFormValidationFailed = () => {
    let hasFailed = false

    const isValidPassword = Boolean(password.value)
    if (!isValidPassword) {
      setPassword({ ...password, error: true })
      hasFailed = true
    }

    const isValidEmail = isEmail(email.value.trim())
    if (!isValidEmail) {
      setEmail({ ...email, error: true })
      hasFailed = true
    }

    return hasFailed
  }

  const has2FAFormValidationFailed = () => {
    const isCodeValid = code.value.split(' ').join('').length === 6
    if (!isCodeValid) {
      setCode({ ...code, error: true })

      return true
    }

    return false
  }

  const toggleTestAccounts = () => {
    if (import.meta.env.VITE_ENVIRONMENT === 'development') {
      setShowTestAccounts(!showTestAccounts)
    }
  }

  return (
    <PageTemplateUnlogged
      isFooterShown
      contentUnderCard={
        showTestAccounts && (
          <Box pt='100px' w='800px'>
            <Card title='Users' hasNoPadding>
              <TableEnhanced
                pagination={{ defaultPageSize: 20 }}
                dataSource={fuAdminsMock}
                columns={mockCredentialTableColumns}
                rowKey='email'
              />
            </Card>
          </Box>
        )
      }
    >
      {formType === 'login' ? (
        <>
          <Title data-cy='login-title' level={3}>
            Administration login
          </Title>
          <Form
            layout='vertical'
            noValidate
            style={{ width: '100%' }}
            onFinish={() => {
              if (hasLoginFormValidationFailed() || loading) {
                return
              }

              login()
            }}
          >
            <Form.Item
              label='E-mail'
              data-cy='email-group'
              {...(email.error && {
                validateStatus: 'error',
                help: 'Invalid e-mail',
              })}
            >
              <Input
                size='large'
                type='email'
                autoComplete='email'
                value={email.value}
                onChange={({ target: { value } }) => setEmail({ value, error: false })}
              />
            </Form.Item>

            <Form.Item
              label={
                <LabelWrapper>
                  <div onDoubleClick={() => toggleTestAccounts()}>Password</div>
                </LabelWrapper>
              }
              data-cy='password-group'
              {...((password.error || serverLoginFormError) && {
                validateStatus: 'error',
                help: 'Password or email is incorrect',
              })}
            >
              <Input.Password
                autoComplete='current-password'
                size='large'
                onChange={({ target: { value } }) => setPassword({ value, error: false })}
              />
            </Form.Item>

            <Button type='submit' data-cy='submit-button' isFullWidth>
              {loading && <LoadingOutlined />} Login
            </Button>
          </Form>
        </>
      ) : formType === '2fa' ? (
        <>
          <Title data-cy='login-title' level={3}>
            Two factor code
          </Title>
          <Form
            layout='vertical'
            noValidate
            onFinish={() => {
              if (hasLoginFormValidationFailed() || has2FAFormValidationFailed() || loading) {
                return
              }

              login()
            }}
          >
            <Form.Item
              label='2FA code'
              {...(code.error && {
                validateStatus: 'error',
                help: 'Invalid 2FA code',
              })}
            >
              <Input
                size='large'
                type='text'
                inputMode='numeric'
                autoComplete='off'
                value={code.value}
                onChange={({ target: { value } }) => {
                  setCode({ value, error: false })
                }}
              />
            </Form.Item>
            <Button type='submit' isFullWidth>
              Login
            </Button>
          </Form>
        </>
      ) : null}
    </PageTemplateUnlogged>
  )
}

const Title = styled(AntTitle)`
  text-align: center;
  margin-bottom: 20px !important;
`

const LabelWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex: 1;
`

export const SSOPageSwitch = styled.div`
  text-align: center;
  margin-top: 16px;
  font-weight: 600;
`

export const RegisterBox = styled.div`
  margin-top: 2rem;
  color: #688699;
`

export default Login
