import { gql, useMutation } from '@apollo/client'
import { ModalConfirm } from '@faceup/ui'
import { notification, useMessage } from '@faceup/ui-base'
import type {
  TransferOwnershipInput,
  TransferOwnershipModal_manager,
  TransferOwnershipMutation,
} from '../__generated__/globalTypes'

export const TransferOwnershipFragments = {
  TransferOwnershipModal_manager: gql`
    fragment TransferOwnershipModal_manager on Manager {
      id
      name
    }
  `,
}

const mutations = {
  TransferOwnership: gql`
    mutation TransferOwnershipMutation($managerId: UUID!, $institutionId: CompanyGlobalId!) {
      transferOwnership(input: { managerId: $managerId, institutionId: $institutionId }) {
        oldOwner {
          id
          accountType(motherId: $institutionId)
        }

        newOwner {
          id
          accountType(motherId: $institutionId)
        }
      }
    }
  `,
}
export const TransferOwnershipModal = ({
  manager,
  setManager,
  institutionId,
}: {
  manager: TransferOwnershipModal_manager | null
  setManager: (manager: TransferOwnershipModal_manager | null) => void
  institutionId: string
}) => {
  const message = useMessage()
  const [transfer, { loading }] = useMutation<TransferOwnershipMutation, TransferOwnershipInput>(
    mutations.TransferOwnership,
    {
      onError: error => {
        console.error(error)
        notification.error({
          message: 'GQL ERROR',
          description: error.message,
        })
      },
      onCompleted: () => {
        message.success('Ownership transferred successfully')
        setManager(null)
      },
    }
  )

  return manager ? (
    <ModalConfirm
      title='Transfer ownership'
      opened={!!manager}
      onClose={() => setManager(null)}
      onConfirm={async () => {
        transfer({
          variables: {
            managerId: manager.id,
            institutionId,
          },
        })
      }}
      confirmState={{ loading }}
    >
      Are you sure you want to transfer ownership to <strong>{manager.name}</strong>?
    </ModalConfirm>
  ) : null
}
