import { type ReactNode, createContext, useContext } from 'react'
import type { UpsellData } from '../components'

type InstitutionContextProps = {
  upsell:
    | {
        isAllowed: false
      }
    | {
        isAllowed: true
        buttons: (upsell: UpsellData) => ReactNode
      }
}

type InstitutionProviderProps = {
  children: ReactNode
} & InstitutionContextProps

const InstitutionContext = createContext<InstitutionContextProps>({
  upsell: {
    isAllowed: false,
  },
})

export const InstitutionProvider = ({ children, ...props }: InstitutionProviderProps) => (
  <InstitutionContext.Provider value={props}>{children}</InstitutionContext.Provider>
)

export const useInstitution = () => useContext(InstitutionContext)
