import type { SizeType } from 'antd/es/config-provider/SizeContext'

type Constants = {
  input: {
    height: Record<Exclude<SizeType, undefined>, number>
    heightPx: Record<Exclude<SizeType, undefined>, string>
    sizeClass: Record<Exclude<SizeType, undefined>, string>
  }
}

export const constants: Constants = {
  input: {
    height: {
      small: 24,
      middle: 32,
      large: 44,
    },
    heightPx: {
      small: '24px',
      middle: '32px',
      large: '44px',
    },
    sizeClass: {
      small: 'ant-input-sm',
      middle: '',
      large: 'ant-input-lg',
    },
  },
}
