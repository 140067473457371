import { type ReactNode, createContext, useCallback, useContext, useEffect, useState } from 'react'

type MotherContextProps = {
  setMotherId: (motherId: string) => void
  unsetMotherId: () => void
  motherId: string | null
}

type MotherProviderProps = {
  children: ReactNode
} & Partial<Pick<MotherContextProps, 'motherId'>>

/**
 * Keeps track of currently selected motherId. This is needed because we now support access
 * to multiple institutions for user (currently only for partners), so we need to send motherId
 * to some queries/mutations.
 * Ideally we'd store the mother ID somewhere else (probably in URL) but that's too complicated for now.
 * Partners have to go through institutions every time they reload page or visit link from outside the UI (e.g. email).
 */
const MotherContext = createContext<MotherContextProps>({
  setMotherId: () => null,
  unsetMotherId: () => null,
  motherId: null,
})

export const MotherProvider = ({ children, motherId: defaultMotherId }: MotherProviderProps) => {
  const [motherId, setMotherId] = useState<string | null>(defaultMotherId ?? null)
  // biome-ignore lint/correctness/useExhaustiveDependencies(setMotherId):
  const unsetMotherId = useCallback(() => {
    setMotherId(null)
  }, [setMotherId])

  useEffect(() => {
    if (defaultMotherId) {
      setMotherId(defaultMotherId)
    }
  }, [defaultMotherId])

  return (
    <MotherContext.Provider
      value={{
        unsetMotherId,
        setMotherId,
        motherId,
      }}
    >
      {children}
    </MotherContext.Provider>
  )
}

type UseMotherId = () => {
  getMotherIdWithNull: () => string | null
  getMotherId: () => string
  setMotherId: (motherId: string) => void
  unsetMotherId: () => void
}

export const useMotherId: UseMotherId = () => {
  const mother = useContext(MotherContext)

  return {
    getMotherIdWithNull: useCallback(() => mother.motherId, [mother.motherId]),
    getMotherId: useCallback(() => {
      if (!mother.motherId) {
        throw new Error('Mother ID is null')
      }
      return mother.motherId
    }, [mother.motherId]),
    setMotherId: (motherId: string) => {
      mother.setMotherId(motherId)
    },
    unsetMotherId: () => {
      mother.unsetMotherId()
    },
  }
}
