import { gql, useQuery } from '@apollo/client'
import { useLocalization } from '@faceup/localization'
import { Form } from '@faceup/ui'
import { FormItemType, type Language, getTranslation } from '@faceup/utils'
import { Skeleton, Stack } from '@mantine/core'
import { type ReactNode, useEffect, useState } from 'react'
import type {
  CustomFormItemsQuery,
  CustomFormItemsQueryVariables,
} from '../../__generated__/globalTypes'
import type { Answers } from '../../hooks'
import { dummyNoteContent } from '../../utils'
import type { PreviewCustomValues, PreviewCustomValuesFieldBase } from '../FollowUpPreview'
import { FormElement } from './components'

const query = gql`
  query CustomFormItemsQuery(
    $categoryId: CompanyReportCategoryGlobalId!
    $includeDeletedOptions: Boolean
    $reportSourceId: ID!
  ) {
    formItems(categoryId: $categoryId, reportSourceId: $reportSourceId) {
      id
      formItemId
      type
      hasHint
      isDeleted
      isRequired
      labelTranslations {
        language
        translation
      }
      hintTranslations {
        language
        translation
      }
      options(includeDeleted: $includeDeletedOptions) {
        id
        isDeleted
        labelTranslations {
          translation
          language
        }
      }
    }
  }
`

const excludedItemTypes = [
  FormItemType.Category,
  FormItemType.OrganizationalUnit,
  FormItemType.SenderName,
  FormItemType.Classroom,
]

export const getFormItemLabel: (
  label: string,
  overrideValue?: PreviewCustomValuesFieldBase
) => string = (label, overrideValue) => {
  if (overrideValue?.label !== undefined) {
    return overrideValue.label
  }

  return label
}

export const getFormItemHint: (
  hint: string | undefined,
  overrideValue?: PreviewCustomValuesFieldBase
) => string | undefined = (hint, overrideValue) => {
  if (overrideValue?.hasNote !== undefined) {
    if (overrideValue.hasNote) {
      return dummyNoteContent
    }
    return undefined
  }
  return hint
}

type CustomFormItemsProps = {
  categoryId: string
  reportSourceId: string
  values: Answers
  onChange: (key: string, values: string[]) => void
  defaultLanguage?: Language
  moreInformationRender: ReactNode
  shouldIncludeDeletedOptions?: boolean
  overrideFormData?: PreviewCustomValues
}

export const CustomFormItems = (props: CustomFormItemsProps) => {
  const {
    categoryId,
    reportSourceId,
    values,
    onChange,
    defaultLanguage,
    moreInformationRender,
    shouldIncludeDeletedOptions = false,
    overrideFormData,
  } = props
  const [lastRefetchAt, setLastRefetchAt] = useState<number>()
  const { language } = useLocalization()

  const { data, loading, refetch } = useQuery<CustomFormItemsQuery, CustomFormItemsQueryVariables>(
    query,
    {
      fetchPolicy: 'network-only',
      variables: {
        categoryId,
        reportSourceId,
        includeDeletedOptions: shouldIncludeDeletedOptions,
      },
      skip: !categoryId,
    }
  )

  useEffect(() => {
    if (overrideFormData?.fieldsReloadTimestamp !== lastRefetchAt) {
      setLastRefetchAt(overrideFormData?.fieldsReloadTimestamp)
      void refetch()
    }
  }, [lastRefetchAt, overrideFormData?.fieldsReloadTimestamp, refetch])

  if (loading) {
    return (
      <Stack sx={{ paddingBottom: 8 }}>
        <Skeleton h={22} />
        <Skeleton h={30} />
      </Stack>
    )
  }

  return (
    <>
      {data?.formItems
        .filter(({ isDeleted, type }) => !isDeleted && !excludedItemTypes.includes(type))
        .map(item => {
          const overrideItem = overrideFormData?.fields?.find(
            ({ formItemId }) => formItemId === item.formItemId
          )
          if (item.type === FormItemType.MoreInformation) {
            return moreInformationRender
          }

          const value = values[item.formItemId]
          return (
            <Form.Item
              key={item.formItemId}
              label={getFormItemLabel(
                getTranslation(item.labelTranslations, language, defaultLanguage),
                overrideItem
              )}
              withAsterisk={overrideItem?.isRequired ?? item.isRequired}
              {...(value?.error && {
                errorMessage: value.error,
              })}
              hint={getFormItemHint(
                item.hasHint && item.hintTranslations
                  ? getTranslation(item.hintTranslations, language, defaultLanguage)
                  : undefined,
                overrideItem
              )}
            >
              <FormElement
                item={item}
                type={item.type}
                values={value?.values ?? []}
                onChange={values => onChange(item.formItemId, values)}
                language={language}
                defaultLanguage={defaultLanguage}
              />
            </Form.Item>
          )
        })}
      {overrideFormData?.newField && (
        <Form.Item
          key='new-form-item'
          label={overrideFormData?.newField?.label}
          withAsterisk={overrideFormData?.newField?.isRequired}
          hint={overrideFormData?.newField?.hasNote ? dummyNoteContent : undefined}
        >
          <FormElement
            onChange={() => null}
            language={language}
            defaultLanguage={defaultLanguage}
            values={['']}
            type={overrideFormData.newField.type}
            item={{
              formItemId: '',
              type: FormItemType.SimpleText,
              isRequired: overrideFormData.newField.isRequired,
              hasHint: overrideFormData.newField.hasNote,
              labelTranslations: [],
              hintTranslations: [],
              options: [],
              isDeleted: false,
              __typename: 'FormItem',
              id: '',
            }}
          />
        </Form.Item>
      )}
    </>
  )
}
