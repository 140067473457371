import { Avatar as AntAvatar, type AvatarProps as AntAvatarProps } from 'antd'
import { Group } from 'antd/es/avatar'
import { forwardRef } from 'react'

export type AvatarProps = AntAvatarProps

const InternalAvatar = forwardRef<HTMLSpanElement, AvatarProps>(
  ({ size = 'default', ...props }, ref) => <AntAvatar ref={ref} {...props} size={size} />
)

type CompoundedComponent = typeof InternalAvatar & {
  Group: typeof Group
}

export const Avatar = InternalAvatar as CompoundedComponent

Avatar.Group = Group
