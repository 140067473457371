import styled from '@emotion/styled'
import { UntitledIcon } from '@faceup/icons'
import { List } from '@faceup/ui-base'
import { Box, Flex } from '@mantine/core'
import type { ReactNode } from 'react'
import { ucDots2Grid } from '../customIcons'

type SimpleDragListItemProps = {
  isDraggable: boolean
  /**
   * Used when nested drag lists need to be distinguished.
   */
  identifier?: string
  children: ReactNode
}

export const SimpleDragListItem = (props: SimpleDragListItemProps) => {
  const { isDraggable, children, identifier = '' } = props
  return (
    <Item className={identifier ? `${identifier}-item` : undefined}>
      <Flex justify='space-between' sx={{ width: '100%' }} gap='xs'>
        <Box
          className={isDraggable ? `draggable ${identifier}` : ''}
          sx={{
            cursor: isDraggable ? 'move' : undefined,
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Box
            sx={{
              pointerEvents: 'none',
              opacity: isDraggable ? 1 : 0,
            }}
          >
            <UntitledIcon icon={ucDots2Grid} />
          </Box>
        </Box>
        {children}
      </Flex>
    </Item>
  )
}

const Item = styled(List.Item)`
  padding: 0;
  margin-block: 12px;
  list-style-type: none;
`
