import { gql, useMutation, useQuery } from '@apollo/client'
import { Modal, Select, notification, useMessage } from '@faceup/ui-base'
import { useEffect, useState } from 'react'
import { query as institutionDetail } from '../Pages/InstitutionDetail'
import type {
  LinkCompanyToPartnerModalPartnersQuery,
  linkCompanyToPartnerMutation,
  linkCompanyToPartnerMutationVariables,
} from '../__generated__/globalTypes'

type LinkCompanyToPartnerModalPartnersQuery_partners_items_item = NonNullable<
  NonNullable<NonNullable<LinkCompanyToPartnerModalPartnersQuery['partners']>['items']>[number]
>

type Props = {
  motherId: string
  partnerId?: string
  visible: boolean
  setIsLoading: (loading: boolean) => void
  hide: () => void
}

const query = {
  LinkCompanyToPartnerModalPartnersQuery: gql`
    query LinkCompanyToPartnerModalPartnersQuery {
      partners(page: 0, rowsPerPage: 500, verificationStatus: [Approved]) {
        totalCount
        items {
          id
          name
        }
      }
    }
  `,
}

const mutation = {
  LinkCompanyToPartner: gql`
    mutation linkCompanyToPartnerMutation($input: LinkCompanyToPartnerInput!) {
      linkCompanyToPartner(input: $input) {
        success
      }
    }
  `,
}

const LinkCompanyToPartnerModal = ({ setIsLoading, visible, hide, motherId, partnerId }: Props) => {
  const [selectedPartnerId, setSelectedPartnerId] = useState<string>()
  const message = useMessage()

  const { data, loading } = useQuery<LinkCompanyToPartnerModalPartnersQuery>(
    query.LinkCompanyToPartnerModalPartnersQuery,
    {
      skip: !visible,
    }
  )

  const [linkCompanyToPartner, { loading: linkingToPartner }] = useMutation<
    linkCompanyToPartnerMutation,
    linkCompanyToPartnerMutationVariables
  >(mutation.LinkCompanyToPartner, {
    refetchQueries: [institutionDetail.InstitutionQuery],
    onError: error => {
      console.error(error)
      notification.error({
        message: 'GQL Error',
        description: error.message,
      })
    },
    onCompleted: () => {
      message.success('Company linked')
    },
  })

  useEffect(() => {
    setIsLoading(linkingToPartner)
  }, [linkingToPartner, setIsLoading])

  const partners = (data?.partners?.items ??
    []) as LinkCompanyToPartnerModalPartnersQuery_partners_items_item[]

  const onOk = async () => {
    if (selectedPartnerId) {
      await linkCompanyToPartner({
        variables: {
          input: {
            partnerId: selectedPartnerId,
            motherId,
          },
        },
      })
    }
    hide()
  }

  return (
    <Modal
      visible={visible}
      onCancel={hide}
      onOk={onOk}
      confirmLoading={loading}
      title='Link company to partner'
    >
      <Select
        loading={loading}
        placeholder='Select partner'
        defaultValue={partnerId}
        value={selectedPartnerId}
        onChange={partnerId => setSelectedPartnerId(partnerId)}
      >
        {partners.map(({ id, name }) => (
          <Select.Option key={id} value={id}>
            {name}
          </Select.Option>
        ))}
      </Select>
    </Modal>
  )
}

export default LinkCompanyToPartnerModal
