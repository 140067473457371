import { gql } from '@apollo/client'
import {
  type BooleanSchema,
  Checkbox,
  type InferType,
  ModalForm,
  type ModalFormProps,
  MultiSelect,
  Radio,
  Switch,
  TextInput,
  useForm,
  useWatch,
  yup,
} from '@faceup/form'
import { useAccessRights } from '@faceup/member'
import { FeatureFlagContext, type FeatureFlagContextProps, ModalSegment, Text } from '@faceup/ui'
import { Collapse, Stack, Tooltip } from '@mantine/core'
import { Fragment, type JSX, type ReactNode, useContext, useMemo } from 'react'
import { permissionTypeMessages, sharedMessages } from '../../../Shared/translations'
import { type DefineMessagesType, FormattedMessage, defineMessages } from '../../../TypedIntl'
import {
  type AbstractManagerModal_institution,
  type AbstractManagerModal_manager,
  PermissionType,
} from '../../../__generated__/globalTypes'
import { Upsell, UpsellIcon, useUpsell } from '../../Upsell'
import {
  type Permission,
  type Permissions,
  type ReportAccessVariant,
  findPermissionByPermissionType,
  getReportAccessCategories,
  getReportAccessVariant,
} from './abstractManagerModalHelpers'

const messages = defineMessages({
  allReportCategories: 'Administration.permissions.allReportCategories',
  specificReportCategories: 'Administration.permissions.specificReportCategories',
  specificReportCategoriesOrganizationUnits:
    'Administration.permissions.specificReportCategoriesOrganizationUnits',
  onlyAssignedReports: 'Administration.permissions.onlyAssignedReports',
  accessSettings: 'Administration.settings.abstractManagerModal.accessSettings',
  categories: 'Shared.report.category',
  organizationalUnits: 'Shared.report.organization',
  insufficientPermissions: 'Administration.permissions.tooltip',
})

const permissionGroupMessages: Record<
  AccessKey,
  DefineMessagesType<'title'> | DefineMessagesType<'title' | 'hint'>
> = {
  reports: defineMessages({ title: 'Administration.settings.permissions.reports.title' }),
  analytics: defineMessages({
    title: 'Administration.settings.permissions.analytics.title',
    hint: 'Administration.settings.permissions.analytics.hint',
  }),
  settings: defineMessages({ title: 'Administration.settings.permissions.settings.title' }),
  surveys: defineMessages({
    title: 'Administration.settings.permissions.surveys.title',
    hint: 'Administration.settings.permissions.surveys.hint',
  }),
}

const permissionMessages = permissionTypeMessages

export const AbstractManagerModalFragments = {
  AbstractManagerModal_manager: gql`
    fragment AbstractManagerModal_manager on Manager {
      id
      isAccountOwner(motherId: $motherId)

      companyIds(motherId: $motherId)
      keys {
        permissions(motherId: $motherId) {
          type
          enabled
          additionalData {
            categoryIds
          }
        }
      }
    }
  `,
  AbstractManagerModal_institution: gql`
    fragment AbstractManagerModal_institution on Company {
      id
      config {
        id
        reportCategories {
          id
          name
        }
      }
      organizationalStructure {
        id
        name
      }
    }
  `,
}

const accessKeys = ['reports', 'analytics', 'settings', 'surveys'] as const
export type AccessKey = (typeof accessKeys)[number]

type Access = {
  isVisible?: (config: { featureFlags: FeatureFlagContextProps }) => boolean
  type?: PermissionType
  subItems?: { type: PermissionType; disabled?: boolean }[]
}

type Accesses = Record<AccessKey, Access>

const schema = yup.object().shape({
  email: yup.string().email().min(3).trim().required(),
  institutionIds: yup.array().of(yup.string().required()),
  categoryIds: yup.array().of(yup.string().required()),
  reportAccessVariant: yup.string().oneOf(['all', 'specific', 'assigned']),
  permissions: yup.object(
    Object.values(PermissionType).reduce(
      (acc, key) => ({
        ...acc,
        [key]: yup.boolean(),
      }),
      {} as Record<PermissionType, BooleanSchema>
    )
  ),
  openedSection: yup.object(
    accessKeys.reduce(
      (acc, accessKey) => ({
        ...acc,
        [accessKey]: yup.boolean(),
      }),
      {} as Record<string, BooleanSchema>
    )
  ),
})

// I don't know why it mismatches type if used InferType<typeof managerSchema> on FormProps
// It puts ?: into optional fields and RHF doesn't like it
type ValidationSchema = InferType<typeof schema>

export type FormValues = {
  email: string
  institutionIds: string[]
  permissions: Permissions
}

export const accesses: Accesses = {
  reports: {
    type: PermissionType.ReportAccess,
    subItems: [
      {
        type: PermissionType.ReportAccess,
        disabled: true,
      },
      { type: PermissionType.EditCases },
      { type: PermissionType.ExportCases },
      { type: PermissionType.DeleteCases },
      { type: PermissionType.WriteInternalComments },
      { type: PermissionType.WriteMessages },
    ],
  },
  analytics: {
    type: PermissionType.Analytics,
  },
  settings: {
    subItems: [
      { type: PermissionType.SettingsAccess },
      { type: PermissionType.ManageUsers },
      { type: PermissionType.ManageCategories },
      { type: PermissionType.ManageOrganizationalUnits },
      { type: PermissionType.ManageReportingChannels },
      { type: PermissionType.BillingAccess },
    ],
  },
  surveys: {
    type: PermissionType.Surveys,
    isVisible: ({ featureFlags }) => featureFlags.surveys,
  },
}

const getPermissions: (
  permissions: Partial<Record<PermissionType, boolean>>,
  accessKeys: Partial<Record<AccessKey, boolean>>
) => Record<PermissionType, boolean> = (permissions, accessKeys) =>
  (Object.entries(accesses) as [AccessKey, Access][]).reduce(
    (acc, [accessKey, access]) => {
      let updatedAcc = { ...acc }
      if (access.type) {
        updatedAcc = {
          ...updatedAcc,
          [access.type]: permissions[access.type] ?? false,
        }
      }
      return {
        ...updatedAcc,
        ...access.subItems?.reduce((acc, subItem) => {
          const isParentAccessAllowed = access.type
            ? permissions[access.type]
            : accessKeys[accessKey]
          return {
            ...acc,
            [subItem.type]: permissions[subItem.type] && isParentAccessAllowed,
          }
        }, {}),
      }
    },
    {} as Record<PermissionType, boolean>
  )

type AbstractManagerModalProps = {
  institution: AbstractManagerModal_institution
  manager: AbstractManagerModal_manager | null
  defaultValues: FormValues
  onSubmit: (values: FormValues) => Promise<boolean>
  overrideDisabledMessageForType?: (
    permissionType: PermissionType,
    isPermissionInputDisabled: JSX.Element | null
  ) => JSX.Element | null
  overrideDisabledMessageForSection?: (
    section: AccessKey,
    isPermissionInputDisabled: JSX.Element | null
  ) => JSX.Element | null
  modalVariant: 'add' | 'edit'
} & Required<Pick<ModalFormProps<ValidationSchema>, 'opened' | 'onClose' | 'title'>>

export const AbstractManagerModal = (props: AbstractManagerModalProps) => {
  const {
    manager,
    institution,
    defaultValues,
    opened,
    onClose,
    onSubmit,
    title,
    modalVariant,
    overrideDisabledMessageForType = (_, disabledMessage) => disabledMessage,
    overrideDisabledMessageForSection = (_, disabledMessage) => disabledMessage,
  } = props
  const upsell = useUpsell()
  const accessRights = useAccessRights()
  const featureFlags = useContext(FeatureFlagContext)

  const isKredenc = !manager

  const findEditedUserPermission: (permissionType: PermissionType) => Permission | undefined =
    permissionType => findPermissionByPermissionType(defaultValues.permissions, permissionType)

  const getEditedUserReportAccessCategories: () => string[] = () =>
    getReportAccessCategories(defaultValues.permissions, institution.config.reportCategories ?? [])

  const getMineReportAccessCategories: () => string[] = () => {
    if (isKredenc) {
      return institution.config.reportCategories.map(reportCategory => reportCategory.id)
    }
    if (manager.isAccountOwner) {
      return institution.config.reportCategories?.map(category => category.id) ?? []
    }
    return getReportAccessCategories(
      manager.keys?.permissions ?? [],
      institution.config.reportCategories ?? []
    )
  }

  const organizationStructure = useMemo(
    () => institution.organizationalStructure ?? [],
    [institution.organizationalStructure]
  )

  const getEditedUserReportAccessVariant = () =>
    getReportAccessVariant(
      defaultValues.permissions,
      defaultValues.institutionIds,
      organizationStructure
    )

  const getMineReportAccessVariants = (): ReportAccessVariant => {
    if (isKredenc) {
      return 'all'
    }
    return getReportAccessVariant(
      manager.keys?.permissions ?? [],
      manager?.companyIds ?? [],
      organizationStructure
    )
  }

  const isSomePermissionEnabled = (accessKey: AccessKey) =>
    accesses[accessKey].subItems?.some(subItem => {
      const permission = findEditedUserPermission(subItem.type)
      return permission?.enabled ?? false
    }) ?? false

  const mineCategories = getMineReportAccessCategories()

  const editedUserCategories = getEditedUserReportAccessCategories()

  // If edited user is assigned reports only, we want to have preselected all available categories
  const formCategoryIds = editedUserCategories.length > 0 ? editedUserCategories : mineCategories
  const editorsInstitutions =
    (isKredenc
      ? institution.organizationalStructure.map(institution => institution.id)
      : manager.companyIds) ?? []
  const institutionIds =
    editedUserCategories.length > 0 ? defaultValues.institutionIds : editorsInstitutions

  const form = useForm({
    schema,
    afterSubmit: modalVariant === 'add' ? 'resetValues' : 'persistValues',
    defaultValues: {
      email: defaultValues.email,
      categoryIds: formCategoryIds,
      reportAccessVariant:
        modalVariant === 'add' && manager?.isAccountOwner
          ? 'all'
          : getEditedUserReportAccessVariant(),
      institutionIds,
      permissions: defaultValues.permissions.reduce(
        (acc, permission) => ({
          ...acc,
          [permission.type]: permission.enabled,
        }),
        {}
      ),
      openedSection: accessKeys.reduce(
        (acc, accessKey) => ({
          ...acc,
          [accessKey]: isSomePermissionEnabled(accessKey),
        }),
        {}
      ),
    },
  })
  const watchPermissions = useWatch({ control: form.control, name: 'permissions' })
  const watchOpenedSections = useWatch({ control: form.control, name: 'openedSection' })
  const watchReportAccessVariant = useWatch({ control: form.control, name: 'reportAccessVariant' })
  const watchCategoryIds = useWatch({ control: form.control, name: 'categoryIds' })
  const watchInstitutionIds = useWatch({ control: form.control, name: 'institutionIds' })

  const isSubmitButtonDisabled: boolean = useMemo(() => {
    const areAllPermissionsDisabled = Object.values(
      getPermissions(watchPermissions, watchOpenedSections)
    ).every(permission => !permission)
    if (areAllPermissionsDisabled) {
      return true
    }
    const isReportAccessSpecific = watchReportAccessVariant === 'specific'
    if (isReportAccessSpecific) {
      const allCategoriesCount = institution.config.reportCategories.length ?? 0
      const hasSelectedAllCategories = watchCategoryIds?.length === allCategoriesCount
      const hasSelectedAllOrganizationalUnits =
        watchInstitutionIds?.length === organizationStructure.length
      const areCategoriesEmpty = watchCategoryIds?.length === 0
      const areInstitutionsEmpty = watchInstitutionIds?.length === 0
      if (
        (hasSelectedAllCategories && hasSelectedAllOrganizationalUnits) ||
        areCategoriesEmpty ||
        areInstitutionsEmpty
      ) {
        return true
      }
    }
    return false
  }, [
    watchPermissions,
    watchOpenedSections,
    watchReportAccessVariant,
    watchCategoryIds,
    watchInstitutionIds,
    institution.config.reportCategories,
    organizationStructure,
  ])

  const disabledReportAccessAll =
    !manager?.isAccountOwner &&
    getEditedUserReportAccessVariant() !== 'all' &&
    ['specific', 'assigned'].includes(getMineReportAccessVariants()) ? (
      <FormattedMessage {...messages.insufficientPermissions} />
    ) : null
  const disableReportAccessSpecific =
    !manager?.isAccountOwner &&
    getEditedUserReportAccessVariant() === 'assigned' &&
    ['assigned'].includes(getMineReportAccessVariants()) ? (
      <FormattedMessage {...messages.insufficientPermissions} />
    ) : null

  const customContent: Record<PermissionType, ReactNode> = {
    [PermissionType.ReportAccess]: (
      <Stack spacing='24px' ml='32px'>
        <Radio.Group control={form.control} name='reportAccessVariant' withAsterisk={false}>
          <Stack spacing='8px'>
            <TooltipCannotSetAccess disabledReason={disabledReportAccessAll}>
              <Radio.Item
                value='all'
                label={<FormattedMessage {...messages.allReportCategories} />}
                disabled={Boolean(disabledReportAccessAll)}
              />
            </TooltipCannotSetAccess>
            <TooltipCannotSetAccess disabledReason={disableReportAccessSpecific}>
              <Radio.Item
                value='specific'
                label={
                  <FormattedMessage
                    {...(organizationStructure.length > 1
                      ? messages.specificReportCategoriesOrganizationUnits
                      : messages.specificReportCategories)}
                  />
                }
                disabled={Boolean(disableReportAccessSpecific)}
              />
            </TooltipCannotSetAccess>
            <Collapse in={form.watch('reportAccessVariant') === 'specific'}>
              <Stack
                spacing='8px'
                sx={{
                  marginBlock: '16px',
                }}
              >
                <MultiSelect
                  control={form.control}
                  name='categoryIds'
                  label={<FormattedMessage {...messages.categories} />}
                  options={(institution.config.reportCategories ?? []).map(reportCategory => ({
                    value: reportCategory.id,
                    label: reportCategory.name,
                    disabled:
                      !manager?.isAccountOwner &&
                      !mineCategories.includes(reportCategory.id) &&
                      !getEditedUserReportAccessCategories().includes(reportCategory.id),
                  }))}
                />
                {organizationStructure.length > 1 && (
                  <MultiSelect
                    control={form.control}
                    name='institutionIds'
                    label={<FormattedMessage {...messages.organizationalUnits} />}
                    options={organizationStructure.map(institution => ({
                      value: institution.id,
                      label: institution.name,
                      disabled:
                        (!manager?.isAccountOwner &&
                          !editorsInstitutions.includes(institution.id) &&
                          !defaultValues.institutionIds?.includes(institution.id)) ||
                        (!manager?.isAccountOwner &&
                          // if editor is assigned only, they can't edit OU unless it's already added
                          ['assigned'].includes(getMineReportAccessVariants()) &&
                          !defaultValues.institutionIds?.includes(institution.id)) ||
                        (!manager?.isAccountOwner &&
                          // if edited user is assigned only, editor can only add OUs they already have
                          ['assigned'].includes(getEditedUserReportAccessVariant()) &&
                          !editorsInstitutions.includes(institution.id)),
                    }))}
                  />
                )}
              </Stack>
            </Collapse>
            <Radio.Item
              value='assigned'
              label={<FormattedMessage {...messages.onlyAssignedReports} />}
            />
          </Stack>
        </Radio.Group>
      </Stack>
    ),
    [PermissionType.EditCases]: null,
    [PermissionType.ExportCases]: null,
    [PermissionType.DeleteCases]: null,
    [PermissionType.WriteInternalComments]: null,
    [PermissionType.WriteMessages]: null,
    [PermissionType.Analytics]: null,
    [PermissionType.SettingsAccess]: null,
    [PermissionType.BillingAccess]: null,
    [PermissionType.ManageCategories]: null,
    [PermissionType.ManageOrganizationalUnits]: null,
    [PermissionType.ManageReportingChannels]: null,
    [PermissionType.ManageUsers]: null,
    [PermissionType.Surveys]: null,
  }

  const getPermissionInputDisabledMessage = (permissionType: PermissionType) => {
    if (manager?.isAccountOwner) {
      return null
    }

    if (!accessRights.isVisibleForPermission[permissionType]) {
      if (
        defaultValues.permissions.find(permission => permission.type === permissionType)
          ?.enabled === false
      ) {
        return <FormattedMessage {...messages.insufficientPermissions} />
      }
    }

    return null
  }

  return (
    <ModalForm
      title={title}
      submitButtonText={modalVariant === 'add' ? 'add' : 'save'}
      isSubmitButtonDisabled={isSubmitButtonDisabled}
      opened={opened}
      onClose={onClose}
      onSubmit={async values => {
        const getCategoryIds: () => string[] | null = () => {
          if (!values.permissions.ReportAccess) {
            return []
          }
          if (values.reportAccessVariant === 'all') {
            return null
          }
          if (values.reportAccessVariant === 'specific') {
            return values.categoryIds ?? []
          }
          return []
        }
        const categoryIds = getCategoryIds()
        const getInstitutionIds: () => string[] = () => {
          const allOrganizationalUnitsIds =
            institution.organizationalStructure?.map(organizationalUnit => organizationalUnit.id) ??
            []
          if (categoryIds && categoryIds.length > 0) {
            return values.institutionIds ?? allOrganizationalUnitsIds
          }
          return allOrganizationalUnitsIds
        }
        const result = await onSubmit({
          email: values.email,
          institutionIds: getInstitutionIds(),
          permissions: Object.entries(getPermissions(values.permissions, values.openedSection)).map(
            ([type, enabled]) => ({
              type: type as PermissionType,
              enabled,
              additionalData:
                type === PermissionType.ReportAccess
                  ? {
                      categoryIds,
                    }
                  : undefined,
            })
          ),
        })
        form.reset()
        return result
      }}
      form={form}
      width={620}
    >
      <TextInput
        control={form.control}
        name='email'
        disabled={
          !isKredenc && form.formState.defaultValues && form.formState.defaultValues.email !== ''
        }
        label={<FormattedMessage {...sharedMessages.emailLabel} />}
        data-cy='manager-modal-email-input'
      />
      <Stack spacing='32px'>
        <Text variant='title' size='large'>
          <FormattedMessage {...messages.accessSettings} />
        </Text>
        {(Object.entries(accesses) as [AccessKey, Access][])
          .filter(([, access]) => {
            if (access.isVisible === undefined) {
              return true
            }
            return access.isVisible({ featureFlags })
          })
          .map(([key, access]) => {
            const disabledInputMessage = access.type
              ? getPermissionInputDisabledMessage(access.type)
              : null
            const overriddenDisabledMessage = overrideDisabledMessageForSection(
              key,
              disabledInputMessage
            )
            return (
              <Upsell key={key} upsell={upsell.schoolEditUserPermissions}>
                <ModalSegment
                  title={
                    <>
                      <FormattedMessage {...permissionGroupMessages[key].title} />
                      <UpsellIcon />
                    </>
                  }
                  hint={
                    'hint' in permissionGroupMessages[key] && (
                      <FormattedMessage {...permissionGroupMessages[key].hint} />
                    )
                  }
                  extra={
                    <TooltipCannotSetAccess disabledReason={overriddenDisabledMessage}>
                      <Switch
                        control={form.control}
                        name={access.type ? `permissions.${access.type}` : `openedSection.${key}`}
                        disabled={Boolean(overriddenDisabledMessage)}
                      />
                    </TooltipCannotSetAccess>
                  }
                >
                  <Collapse
                    in={
                      form.watch(
                        access.type ? `permissions.${access.type}` : `openedSection.${key}`
                      ) === true
                    }
                  >
                    <Stack spacing='8px'>
                      {access.subItems?.map(permission => {
                        const disabledInputMessage = getPermissionInputDisabledMessage(
                          permission.type
                        )
                        const overriddenDisabledMessage = overrideDisabledMessageForType(
                          permission.type,
                          disabledInputMessage
                        )
                        const permissionTypeMessages = permissionMessages[permission.type]
                        return (
                          <Fragment key={permission.type}>
                            <TooltipCannotSetAccess disabledReason={overriddenDisabledMessage}>
                              <Checkbox
                                control={form.control}
                                name={`permissions.${permission.type}`}
                                disabled={Boolean(overriddenDisabledMessage) || permission.disabled}
                                label={
                                  <FormattedMessage
                                    {...permissionMessages[permission.type].title}
                                  />
                                }
                                hint={
                                  'hint' in permissionTypeMessages && (
                                    <FormattedMessage {...permissionTypeMessages.hint} />
                                  )
                                }
                              />
                            </TooltipCannotSetAccess>
                            {customContent[permission.type]}
                          </Fragment>
                        )
                      })}
                    </Stack>
                  </Collapse>
                </ModalSegment>
              </Upsell>
            )
          })}
      </Stack>
    </ModalForm>
  )
}

type TooltipCannotSetAccessProps = {
  children: ReactNode
  disabledReason: ReactNode | null
}

const TooltipCannotSetAccess = (props: TooltipCannotSetAccessProps) => {
  const { children, disabledReason } = props
  if (!disabledReason) {
    return <div>{children}</div>
  }

  return (
    <Tooltip label={disabledReason} position='top-start'>
      <div>{children}</div>
    </Tooltip>
  )
}
