import { gql } from '@apollo/client'
import styled from '@emotion/styled'
import { Button } from '@faceup/ui'
import { Form, Input, Modal, Select, Space, Upload, UploadOutlined } from '@faceup/ui-base'
import {
  ALLOWED_IMAGE_MIME_TYPE_EXTENSION_MAP,
  Country,
  DEFAULT_LANGUAGE,
  convertCountryToCountryName,
  isEmail,
  isUrl,
} from '@faceup/utils'
import { useState } from 'react'
import {
  type AllPartnersQuery,
  type AvailablePartnersQuery,
  type CompanyServiceEditorFragment,
  type CreateCompanyServiceInput,
  type EditCompanyServiceInput,
  Institution,
} from '../../__generated__/globalTypes'
import ServiceTranslationsTab from './ServiceTranslationsTab'

type AllPartnersQuery_partners_items_item = NonNullable<
  NonNullable<AllPartnersQuery['partners']>['items']
>[number]
type AvailablePartnersQuery_partners_items_item = NonNullable<
  NonNullable<AvailablePartnersQuery['partners']>['items']
>[number]

const { Option } = Select

export const fragments = {
  CompanyServiceEditorFragment: gql`
    fragment CompanyServiceEditorFragment on CompanyService {
      id
      providerName
      providerEmail
      providerUrl
      countries
      logoUrl
      defaultLanguage
      institutionType

      notAvailablePartners {
        id
        name
      }

      allTranslations {
        id
        language
        description
        categories
      }
    }
  `,
}

type Props = {
  loading: boolean
  service?: CompanyServiceEditorFragment | null
  saveChanges: (service: CreateCompanyServiceInput | EditCompanyServiceInput) => void
  visible: boolean
  setVisible: (visible: boolean) => void
  partners:
    | (AllPartnersQuery_partners_items_item | AvailablePartnersQuery_partners_items_item | null)[]
    | null
    | undefined
}

const CompanyServiceEditor = (props: Props) => {
  const { service } = props

  const [providerLogo, setProviderLogo] = useState<{
    value: File | null
    url: string
    error: boolean
  }>({
    value: null,
    url: service?.logoUrl ?? '',
    error: false,
  })
  const [providerNameField, setProviderNameField] = useState({
    value: service?.providerName ?? '',
    error: false,
  })
  const [providerEmailField, setProviderEmailField] = useState({
    value: service?.providerEmail ?? '',
    error: false,
  })
  const [providerUrlField, setProviderUrlField] = useState({
    value: service?.providerUrl ?? '',
    error: false,
  })
  const [countriesField, setCountriesField] = useState({
    value: service?.countries ?? [],
    error: false,
  })
  const [notAvailablePartnerIdsField, setNotAvailablePartnerIdsField] = useState({
    value: service?.notAvailablePartners?.map(partner => partner?.id ?? '') ?? [],
    error: false,
  })
  const [institutionType, setInstitutionType] = useState({
    value: service?.institutionType ?? Institution.Company,
    error: false,
  })

  const [translations, setTranslations] = useState(
    service?.allTranslations
      ?.filter(t => t?.language ?? false)
      .map(translation => ({
        // biome-ignore lint/style/noNonNullAssertion:
        language: translation!.language!,
        description: {
          error: false,
          value: translation?.description ?? '',
        },
        categories: translation?.categories ?? [],
      })) ?? [
      {
        language: DEFAULT_LANGUAGE,
        description: {
          error: false,
          value: '',
        },
        categories: [],
      },
    ]
  )

  const [defaultLanguage, setDefaultLanguage] = useState(
    service?.defaultLanguage ?? DEFAULT_LANGUAGE
  )

  const validateForm = () => {
    let ok = true

    if (!service && !providerLogo.value) {
      setProviderLogo({ ...providerLogo, error: true })
      ok = false
    }

    if (!providerNameField.value) {
      setProviderNameField({ ...providerNameField, error: true })
      ok = false
    }
    if (!isEmail(providerEmailField.value)) {
      setProviderEmailField({ ...providerEmailField, error: true })
      ok = false
    }
    if (!isUrl(providerUrlField.value)) {
      setProviderUrlField({ ...providerUrlField, error: true })
      ok = false
    }
    if (!countriesField.value.length) {
      setCountriesField({ ...countriesField, error: true })
      ok = false
    }

    if (!institutionType.value) {
      setInstitutionType({ ...institutionType, error: true })
      ok = false
    }

    const tempTranslations = translations
    for (const key in Object.keys(tempTranslations)) {
      const translations = tempTranslations[key]

      if (!translations?.description?.value) {
        ok = false
        // @ts-expect-error Migrating to strict mode, fix this
        tempTranslations[key].description = {
          ...translations?.description,
          error: true,
        }
      }
    }

    setTranslations(tempTranslations)
    return ok
  }

  return (
    <Modal
      title={service ? 'Edit' : 'Add'}
      visible={props.visible}
      width={800}
      onOk={() => {
        if (!validateForm()) {
          return
        }
        props.saveChanges({
          providerName: providerNameField.value.trim(),
          providerEmail: providerEmailField.value.trim(),
          providerUrl: providerUrlField.value.trim(),
          countries: countriesField.value,
          institutionType: institutionType.value,
          logo: providerLogo.value,
          defaultLanguage,
          notAvailablePartnerIds: notAvailablePartnerIdsField.value,
          translations: translations.map(translation => ({
            // biome-ignore lint/style/noNonNullAssertion:
            language: translation.language!,
            // biome-ignore lint/style/noNonNullAssertion:
            description: translation.description.value!.trim(),
            categories: translation.categories.filter(c => c),
          })),
        })
      }}
      onCancel={() => props.setVisible(false)}
      okButtonProps={{
        loading: props.loading,
      }}
    >
      <Form layout='vertical'>
        <Form.Item
          label='Name'
          {...(providerNameField.error && {
            validateStatus: 'error',
            help: 'Invalid name',
          })}
        >
          <Input
            autoComplete='organization'
            value={providerNameField.value}
            onChange={({ target: { value } }) => {
              setProviderNameField({ error: false, value })
            }}
          />
        </Form.Item>
        <Form.Item
          label='Logo'
          {...(providerLogo.error && {
            validateStatus: 'error',
            help: 'Invalid logo',
          })}
        >
          <Space>
            {(providerLogo.url ?? service?.logoUrl) && (
              <ServiceLogo src={providerLogo.url ?? service?.logoUrl} />
            )}
            <Upload
              accept={Object.keys(ALLOWED_IMAGE_MIME_TYPE_EXTENSION_MAP).join(',')}
              beforeUpload={(file: File) => {
                setProviderLogo({ value: file, url: URL.createObjectURL(file), error: false })
                return false
              }}
              showUploadList={false}
            >
              <Button variant='secondary'>
                <Space>
                  <UploadOutlined />
                  Upload logo
                </Space>
              </Button>
            </Upload>
          </Space>
        </Form.Item>
        <Form.Item
          label='E-mail'
          {...(providerEmailField.error && {
            validateStatus: 'error',
            help: 'Invalid e-mail',
          })}
        >
          <Input
            autoComplete='email'
            type='email'
            value={providerEmailField.value}
            onChange={({ target: { value } }) => {
              setProviderEmailField({ error: false, value })
            }}
          />
        </Form.Item>
        <Form.Item
          label='Provider URL'
          {...(providerUrlField.error && {
            validateStatus: 'error',
            help: 'Invalid URL',
          })}
        >
          <Input
            autoComplete='off'
            value={providerUrlField.value}
            onChange={({ target: { value } }) => {
              setProviderUrlField({ error: false, value })
            }}
          />
        </Form.Item>
        <Form.Item
          label='Institution'
          {...(institutionType.error && {
            validateStatus: 'error',
            help: 'Invalid institution',
          })}
        >
          <Select<Institution>
            value={institutionType.value}
            onChange={value => {
              setInstitutionType({ error: false, value })
            }}
          >
            {Object.values(Institution).map(institution => (
              <Option key={institution} value={institution}>
                {institution}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label='Country'
          {...(countriesField.error && {
            validateStatus: 'error',
            help: 'Invalid country',
          })}
        >
          <Select<Country[]>
            mode='multiple'
            value={countriesField.value}
            onChange={value => {
              setCountriesField({ error: false, value })
            }}
          >
            {Object.values(Country).map(country => (
              <Option key={country} value={country}>
                {convertCountryToCountryName(country, 'en')}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item label='Not available partners'>
          <Select
            mode='multiple'
            placeholder='Select partners which will not see this service'
            style={{ width: '100%' }}
            value={notAvailablePartnerIdsField.value}
            onChange={value => {
              setNotAvailablePartnerIdsField({ error: false, value })
            }}
          >
            {props?.partners?.map(partner => (
              <Option key={partner?.id} value={partner?.id ?? ''}>
                {partner?.name}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <ServiceTranslationsTab
          translations={translations}
          defaultLanguage={defaultLanguage}
          setTranslations={setTranslations}
          setDefaultLanguage={setDefaultLanguage}
        />
      </Form>
    </Modal>
  )
}

const ServiceLogo = styled.div<{ src: string }>`
  height: 50px;
  width: 100px;
  background: url('${({ src }) => src}') center left no-repeat;
  background-size: contain;
`

export default CompanyServiceEditor
