import { DEFAULT_LANGUAGE, type Language } from '@faceup/utils'
import { type ReactNode, createContext, useContext } from 'react'

type LocalizationContextType = {
  language: Language
}

const LocalizationContext = createContext<LocalizationContextType>({
  language: DEFAULT_LANGUAGE,
})

type LocalizationProviderProps = {
  children: ReactNode
} & LocalizationContextType

export const LocalizationProvider = ({ children, ...props }: LocalizationProviderProps) => (
  <LocalizationContext.Provider value={props}>{children}</LocalizationContext.Provider>
)

export const useLocalization = () => useContext(LocalizationContext)
