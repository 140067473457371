import { type BadgeProps as AntBadgeProps, Badge as AntdBadge } from '@faceup/ui-base'
import { Box } from '@mantine/core'
import { type Color, useThemeColors } from '../hooks'

type BadgeColor = 'primary' | 'error' | 'light'

const backgroundColors: Record<BadgeColor, Color | null> = {
  primary: 'primary.100',
  error: null,
  light: 'dark.20',
}

const textColors: Record<BadgeColor, Color> = {
  primary: 'white',
  error: 'white',
  light: 'dark.100',
}

export type BadgeProps = {
  color?: BadgeColor
  isBold?: boolean
  customColor?: string
} & Pick<AntBadgeProps, 'children' | 'count' | 'offset' | 'size' | 'showZero' | 'overflowCount'>

export const Badge = ({ color = 'primary', isBold = false, customColor, ...props }: BadgeProps) => {
  const { getColorFromTheme } = useThemeColors()
  const getBackgroundColor = () => {
    if (customColor) {
      return customColor
    }
    const backgroundColor = backgroundColors[color]
    if (backgroundColor) {
      return getColorFromTheme(backgroundColor)
    }
    return undefined
  }
  return (
    <Box
      {...props}
      component={AntdBadge}
      sx={{
        '&.ant-badge': {
          color: 'inherit',

          '& > .ant-badge-count': {
            color: getColorFromTheme(textColors[color]),
            background: getBackgroundColor(),
            fontWeight: isBold ? 700 : undefined,
          },
        },
      }}
    />
  )
}
