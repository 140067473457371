import { Button, Group, type ButtonGroupProps as MuiButtonGroupProps } from '@mantine/core'

type ButtonGroupProps = MuiButtonGroupProps & {
  hasSeparateButtons?: boolean
}

// TODO: Make adapter from this
export const ButtonGroup = (props: ButtonGroupProps) => {
  const { hasSeparateButtons, ...passProps } = props
  if (hasSeparateButtons) {
    return (
      <Group spacing='xs' {...passProps}>
        {passProps.children}
      </Group>
    )
  }

  const childrenElements = (
    Array.isArray(passProps.children) ? passProps.children : [passProps.children]
  ).filter(children => children !== undefined)

  return (
    <Button.Group
      {...passProps}
      sx={{
        ...passProps.sx,
        // Hack for tooltips if button is disabled
        ...(passProps.orientation === 'vertical'
          ? {
              '&>span:first-of-type:not(:last-of-type) [data-button]': {
                borderBottomRightRadius: 0,
                borderBottomLeftRadius: 0,
                borderBottomWidth: 'calc(0.0625rem / 2)',
              },
              '&>span:last-of-type:not(:first-of-type) [data-button]': {
                borderTopRightRadius: 0,
                borderTopLeftRadius: 0,
                borderTopWidth: 'calc(0.0625rem / 2)',
              },
              '&>span [data-button]': {
                width: '100%',
              },
            }
          : {
              '&>span:first-of-type:not(:last-of-type) [data-button]': {
                borderTopRightRadius: 0,
                borderBottomRightRadius: 0,
                borderRightWidth: 'calc(0.0625rem / 2)',
              },
              '&>span:last-of-type:not(:first-of-type) [data-button]': {
                borderTopLeftRadius: 0,
                borderBottomLeftRadius: 0,
                borderLeftWidth: 'calc(0.0625rem / 2)',
              },
            }),
      }}
    >
      {childrenElements.map(child => (
        <span key={passProps.key}>{child}</span>
      ))}
    </Button.Group>
  )
}
