import type { PickerTimeProps } from 'antd/es/time-picker'
import type { Moment } from 'moment-timezone'
import { forwardRef } from 'react'
import { DatePicker } from './DatePicker'

export interface TimePickerProps extends Omit<PickerTimeProps<Moment>, 'picker'> {}

// biome-ignore lint/suspicious/noExplicitAny:
export const TimePicker = forwardRef<any, TimePickerProps>((props, ref) => (
  <DatePicker {...props} picker='time' mode={undefined} ref={ref} />
))

TimePicker.displayName = 'TimePicker'
