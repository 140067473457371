import { Plan } from '../enums'

export const canAddMoreMembers = (
  entitlementValue: number,
  currentCount: number | null,
  // arguments below are only because of partners
  plan: Plan | null,
  isPartnerUser: boolean
) => {
  let maxCount = entitlementValue
  if (plan === Plan.Basic && isPartnerUser) {
    maxCount = maxCount + 1
  }
  return currentCount ? currentCount < maxCount : false
}

export const canAddMoreReportingForms = (entitlementValue: number, currentCount: number | null) =>
  currentCount ? currentCount < entitlementValue : false
