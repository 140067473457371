import { gql, useQuery } from '@apollo/client'
import { Select, Switch, TextInput, useForm, yup } from '@faceup/form'
import { Link } from '@faceup/router'
import { Card, ContentLayout, Form, Paper, TableEnhanced } from '@faceup/ui'
import { Pagination, Space, notification } from '@faceup/ui-base'
import { Country, convertCountryToCountryName } from '@faceup/utils'
import { Box, Group } from '@mantine/core'
import { useDebouncedValue } from '@mantine/hooks'
import moment, { type MomentInput } from 'moment-timezone'
import { useState } from 'react'
import DotText from '../Components/DotText'
import {
  type PartnersTableQuery,
  type PartnersTableQueryVariables,
  VerificationStatus,
} from '../__generated__/globalTypes'
import { DATE_FORMAT, ROWS_PER_PAGE } from '../constants'

const query = {
  PartnersTableQuery: gql`
    query PartnersTableQuery(
      $page: Int!
      $rowsPerPage: Int!
      $name: GraphQLString
      $country: Country
      $email: GraphQLString
      $waitingForRegistration: Boolean
    ) {
      partners(
        page: $page
        rowsPerPage: $rowsPerPage
        name: $name
        country: $country
        email: $email
        waitingForRegistration: $waitingForRegistration
      ) {
        totalCount
        items {
          id
          name
          verificationStatus
          createdAt
        }
      }
    }
  `,
}

const columns = [
  {
    title: 'Name',
    dataIndex: 'name',
    width: '35%',
    render: ([institutionId, name]: [string, string]) => (
      <Link to={routes => routes.partner({ id: institutionId })}>{name}</Link>
    ),
  },
  {
    title: 'Status',
    dataIndex: 'status',
    width: '15%',
    render: (status: VerificationStatus) => {
      if (status === VerificationStatus.Pending) {
        return <DotText color='#ff9b42'>{status}</DotText>
      }
      if (status === VerificationStatus.Approved) {
        return <DotText color='#2eb271'>{status}</DotText>
      }

      return <DotText color='#ff0035'>{status}</DotText>
    },
  },
  {
    title: 'Registration date',
    dataIndex: 'createdAt',
    width: '10%',
    render: (createdAt: MomentInput) => createdAt && moment(createdAt).format(DATE_FORMAT),
  },
]

const Partners = () => {
  const [page, setPage] = useState(1)

  const schema = yup.object({
    name: yup.string().nullable(),
    country: yup.string().oneOf(Object.values(Country)).nullable(),
    email: yup.string().email().nullable(),
    waitingForRegistration: yup.boolean(),
  })

  const form = useForm({
    schema,
    afterSubmit: 'nothing',
    defaultValues: {
      name: null,
      country: null,
      email: null,
      waitingForRegistration: false,
    },
  })

  const [debouncedName] = useDebouncedValue(form.watch('name'), 300)
  const [debouncedEmail] = useDebouncedValue(form.watch('email'), 300)
  const [debouncedWaitingForRegistration] = useDebouncedValue(
    form.watch('waitingForRegistration'),
    300
  )

  const { loading, data } = useQuery<PartnersTableQuery, PartnersTableQueryVariables>(
    query.PartnersTableQuery,
    {
      fetchPolicy: 'cache-and-network',
      variables: {
        page: page - 1,
        rowsPerPage: ROWS_PER_PAGE,
        name: debouncedName,
        country: form.watch('country'),
        email: debouncedEmail,
        waitingForRegistration: debouncedWaitingForRegistration,
      },
      onError: error => {
        console.error(error)
        notification.error({
          message: 'GQL ERROR',
          description: error.message,
        })
      },
    }
  )

  const institutions = data?.partners?.items?.map(item => ({
    key: item?.id,
    name: [item?.id, item?.name],
    status: item?.verificationStatus,
    createdAt: item?.createdAt,
  }))

  return (
    <ContentLayout header={<ContentLayout.Header title='Partners' />}>
      <Space direction='vertical' size='large' style={{ width: '100%' }}>
        <Paper outline='dark'>
          <Form>
            <Group spacing='md' style={{ padding: '24px' }}>
              <TextInput label='Name' control={form.control} name='name' />
              <Box
                style={{
                  flexGrow: 1,
                }}
              >
                <Select
                  label='Country'
                  name='country'
                  control={form.control}
                  allowClear
                  options={Object.values(Country).map(code => ({
                    label: convertCountryToCountryName(code),
                    value: code,
                  }))}
                />
              </Box>
              <TextInput label='Email' control={form.control} name='email' />
              <Switch
                label='Waiting for registration'
                control={form.control}
                name='waitingForRegistration'
              />
            </Group>
          </Form>
        </Paper>
        <Card hasNoPadding>
          <TableEnhanced
            loading={loading}
            columns={columns}
            dataSource={institutions}
            pagination={false}
            style={{ minHeight: 300 }}
            scroll={{ x: 'max-content' }}
          />
          <Pagination
            pageSize={ROWS_PER_PAGE}
            current={page}
            total={data?.partners?.totalCount}
            onChange={page => setPage(page)}
            showSizeChanger={false}
          />
        </Card>
      </Space>
    </ContentLayout>
  )
}

export default Partners
