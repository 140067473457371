export const mockCredentialTableColumns = [
  {
    title: 'Email',
    dataIndex: 'email',
  },
  {
    title: 'Name',
    dataIndex: 'name',
  },
  {
    title: 'Country',
    dataIndex: 'country',
  },
]

export const fuAdminsMock = [
  {
    email: 'nntb@scio.cz',
    name: 'SCIO',
    country: 'Cz',
  },
  {
    email: 'czechia@faceup.com',
    name: 'Václav Klaus',
    country: 'Cz',
  },
  {
    email: 'slovakia@faceup.com',
    name: 'Zuzana Čaputová',
    country: 'Sk',
  },
  {
    email: 'australia@faceup.com',
    name: 'Australia',
    country: 'Au',
  },
  {
    email: 'brasil@faceup.com',
    name: 'Brasil',
    country: 'Br',
  },
  {
    email: 'switzerland@faceup.com',
    name: 'Switzerland',
    country: 'Ch',
  },
  {
    email: 'columbia@faceup.com',
    name: 'Columbia',
    country: 'Co',
  },
  {
    email: 'ghana@faceup.com',
    name: 'Ghana',
    country: 'Gh',
  },
  {
    email: 'india@faceup.com',
    name: 'India',
    country: 'In',
  },
  {
    email: 'kenya@faceup.com',
    name: 'Kenya',
    country: 'Ke',
  },
  {
    email: 'mexico@faceup.com',
    name: 'Mexico',
    country: 'Mx',
  },
  {
    email: 'nigeria@faceup.com',
    name: 'Nigeria',
    country: 'Ng',
  },
  {
    email: 'oman@faceup.com',
    name: 'Oman',
    country: 'Om',
  },
  {
    email: 'poland@faceup.com',
    name: 'Poland',
    country: 'Pl',
  },
  {
    email: 'singapore@faceup.com',
    name: 'Singapore',
    country: 'Sg',
  },
  {
    email: 'unitedstates@faceup.com',
    name: 'United States',
    country: 'Us',
  },
  {
    email: 'southafrica@faceup.com',
    name: 'South Africa',
    country: 'Sg',
  },
]
