import {
  Box,
  CloseButton,
  Divider,
  Flex,
  Overlay,
  Popover,
  type PopoverProps,
  type PopoverTargetProps,
  Text,
} from '@mantine/core'
import type { ReactNode } from 'react'
import { RemoveScroll } from 'react-remove-scroll'
import { fontWeightBold } from '../constants'

type PopoverModalDropdownProps = {
  children?: ReactNode
  title?: ReactNode
  hasNoPadding?: boolean
  onClose?: () => void
}

const PopoverModalDropdown = (props: PopoverModalDropdownProps) => {
  const { children, title, hasNoPadding, onClose } = props
  return (
    <Popover.Dropdown>
      <Flex justify='space-between' sx={{ padding: '0.75rem 1rem' }}>
        <Text c='#688699' fw={fontWeightBold} fz={16}>
          {title}
        </Text>
        <CloseButton onClick={onClose} />
      </Flex>
      <Divider />
      <Box
        sx={{
          padding: hasNoPadding ? 0 : '0.75rem 1rem',
        }}
      >
        <RemoveScroll>{children}</RemoveScroll>
      </Box>
    </Popover.Dropdown>
  )
}

export const PopoverModal = (props: PopoverProps) => (
  <>
    <Popover
      {...props}
      styles={{
        ...props.styles,
        dropdown: {
          padding: 0,
        },
      }}
    >
      {props.children}
    </Popover>
    {props.opened && <Overlay fixed />}
  </>
)

const PopoverModalTarget = (props: PopoverTargetProps) => (
  <Popover.Target {...props}>{props.children}</Popover.Target>
)

PopoverModalDropdown.displayName = 'PopoverModal.Dropdown'

PopoverModal.Target = PopoverModalTarget
PopoverModal.Dropdown = PopoverModalDropdown
