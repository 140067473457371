import { type ReactNode, createContext, useContext } from 'react'
import type { UpsellData } from '../upsellData'

type UpsellContextProps = {
  upsell?: UpsellData
}

type UpsellProviderProps = {
  children: ReactNode
} & UpsellContextProps

const UpsellContext = createContext<UpsellContextProps>({})

export const UpsellProvider = (props: UpsellProviderProps) => {
  const { children, upsell } = props
  return (
    <UpsellContext.Provider
      value={{
        upsell,
      }}
    >
      {children}
    </UpsellContext.Provider>
  )
}

export const useUpsellContext = () => useContext(UpsellContext)
