import { encryptBufferWithReportKey, encryptFileWithReportKey } from './files'
import { getPersonalKeys } from './keys'
import { generatePin } from './password'
import { getSodium, handleError } from './utils/general'

type ReportBody<T> = { victimName: string | null; moreInfo: string; attachments?: T[] }

export const encryptPlaintext = async (plaintext: string, key: string) => {
  const sodium = await getSodium()
  try {
    const newNonce = sodium.randombytes_buf(sodium.crypto_secretbox_NONCEBYTES)
    const encryptedBody = sodium.crypto_secretbox_easy(
      plaintext,
      newNonce,
      sodium.from_base64(key),
      'base64'
    )

    return {
      ciphertext: encryptedBody,
      nonce: sodium.to_base64(newNonce),
    }
  } catch (e) {
    return handleError(e)
  }
}

/**
 * Used mainly for creating new language variations and as a subfunction of createReport functions.
 * If you want to create new report, refer to createReportWithVictim or createReportWithoutVictim
 */
export const encryptReport = async <T>(body: ReportBody<T>, key: string) => {
  const payload = await encryptPlaintext(JSON.stringify(body), key)
  if (typeof payload === 'string') {
    return payload
  }

  return { body: payload.ciphertext, bodyNonce: payload.nonce }
}

export const createReportWithVictimWithBufferAttachments = async (
  body: ReportBody<Buffer>,
  recipients: { id: string | null; key: string }[],
  systemPbk?: string
) => {
  const sodium = await getSodium()

  try {
    const response = await generatePin()
    if (typeof response === 'string') {
      throw new Error(response)
    }

    const [victimPayload, victimPin] = response
    if (!victimPayload.publicKey) {
      throw new Error('Public key missing')
    }

    const key = sodium.randombytes_buf(sodium.crypto_secretbox_KEYBYTES)
    const payload = await encryptReport(body, sodium.to_base64(key))
    if (typeof payload === 'string') {
      throw new Error(payload)
    }

    const encryptedAttachments = await Promise.all(
      body.attachments?.map(attachment => encryptBufferWithReportKey(attachment, key)) ?? []
    )

    const erroredAttachment = encryptedAttachments.find(attachment => attachment === null)

    if (erroredAttachment) {
      throw new Error('Attachment encryption failed')
    }

    const recipientKeys = recipients.map(recipient => ({
      id: recipient.id,
      key: sodium.crypto_box_seal(key, sodium.from_base64(recipient.key), 'base64'),
    }))

    if (systemPbk) {
      const systemKey = {
        id: null,
        key: sodium.crypto_box_seal(key, sodium.from_base64(systemPbk), 'base64'),
      }

      recipientKeys.push(systemKey)
    }

    const senderKey = sodium.crypto_box_seal(
      key,
      sodium.from_base64(victimPayload.publicKey),
      'base64'
    )

    return {
      ...victimPayload,
      victimPin,
      body: payload.body,
      bodyNonce: payload.bodyNonce,
      recipientKeys,
      senderKey,
      attachments: encryptedAttachments,
    }
  } catch (e) {
    return handleError(e)
  }
}

export const createReportWithVictim = async (
  body: ReportBody<File>,
  recipients: { id: string | null; key: string }[],
  systemPbk?: string
) => {
  const sodium = await getSodium()

  try {
    const response = await generatePin()
    if (typeof response === 'string') {
      throw new Error(response)
    }

    const [victimPayload, victimPin] = response
    if (!victimPayload.publicKey) {
      throw new Error('Public key missing')
    }

    const key = sodium.randombytes_buf(sodium.crypto_secretbox_KEYBYTES)
    const payload = await encryptReport(body, sodium.to_base64(key))
    if (typeof payload === 'string') {
      throw new Error(payload)
    }

    const encryptedAttachments = await Promise.all(
      body.attachments?.map(attachment => encryptFileWithReportKey(attachment, key)) ?? []
    )

    const erroredAttachment = encryptedAttachments.find(
      attachment => typeof attachment === 'string'
    ) as string

    if (erroredAttachment) {
      throw new Error(erroredAttachment)
    }

    const recipientKeys = recipients.map(recipient => ({
      id: recipient.id,
      key: sodium.crypto_box_seal(key, sodium.from_base64(recipient.key), 'base64'),
    }))

    if (systemPbk) {
      const systemKey = {
        id: null,
        key: sodium.crypto_box_seal(key, sodium.from_base64(systemPbk), 'base64'),
      }

      recipientKeys.push(systemKey)
    }

    const senderKey = sodium.crypto_box_seal(
      key,
      sodium.from_base64(victimPayload.publicKey),
      'base64'
    )

    return {
      ...victimPayload,
      victimPin,
      body: payload.body,
      bodyNonce: payload.bodyNonce,
      recipientKeys,
      senderKey,
      attachments: encryptedAttachments as File[],
    }
  } catch (e) {
    return handleError(e)
  }
}

export const createReportWithoutVictim = async (
  body: ReportBody<File>,
  recipients: { id: string | null; key: string }[],
  createdKey?: string,
  systemPbk?: string
) => {
  const sodium = await getSodium()

  try {
    const payload = createdKey
      ? await readReportKey(createdKey)
      : { reportKey: sodium.randombytes_buf(sodium.crypto_secretbox_KEYBYTES) }

    if (typeof payload === 'string') {
      throw new Error(payload)
    }

    const { reportKey } = payload

    const encryptPayload = await encryptReport(body, sodium.to_base64(reportKey))
    if (typeof encryptPayload === 'string') {
      throw new Error(encryptPayload)
    }

    const encryptedAttachments = await Promise.all(
      body.attachments?.map(attachment => encryptFileWithReportKey(attachment, reportKey)) ?? []
    )

    const erroredAttachment = encryptedAttachments.find(
      attachment => typeof attachment === 'string'
    ) as string

    if (erroredAttachment) {
      throw new Error(erroredAttachment)
    }

    const recipientKeys = recipients.map(recipient => ({
      id: recipient.id,
      key: sodium.crypto_box_seal(reportKey, sodium.from_base64(recipient.key), 'base64'),
    }))

    if (systemPbk) {
      const systemKey = {
        id: null,
        key: sodium.crypto_box_seal(reportKey, sodium.from_base64(systemPbk), 'base64'),
      }

      recipientKeys.push(systemKey)
    }

    return {
      body: encryptPayload.body,
      bodyNonce: encryptPayload.bodyNonce,
      recipientKeys,
      attachments: encryptedAttachments as File[],
    }
  } catch (e) {
    return handleError(e)
  }
}

/**
 * Without context - behaves like a pure functions, not getting keys from storage
 * Therefore can be used at BE
 */
export const repackReportKeyWithoutContext = async (
  reportKeyEncrypted: string,
  ownerPublicKey: string,
  ownerPrivateKey: string,
  recipientPublicKey: string
) => {
  const sodium = await getSodium()
  try {
    const reportKey = sodium.crypto_box_seal_open(
      sodium.from_base64(reportKeyEncrypted),
      sodium.from_base64(ownerPublicKey),
      sodium.from_base64(ownerPrivateKey),
      'uint8array'
    )

    return {
      reportKey: sodium.crypto_box_seal(
        reportKey,
        sodium.from_base64(recipientPublicKey),
        'base64'
      ),
    }
  } catch (e) {
    return handleError(e)
  }
}

export const readReportKeyWithoutContext = async (
  recipientKey: string,
  recipientPublicKey: string,
  recipientPrivateKey: string
) => {
  const sodium = await getSodium()
  try {
    return {
      reportKey: sodium.crypto_box_seal_open(
        sodium.from_base64(recipientKey),
        sodium.from_base64(recipientPublicKey),
        sodium.from_base64(recipientPrivateKey),
        'uint8array'
      ),
    }
  } catch (e) {
    return handleError(e)
  }
}

export type DecryptedReport = {
  victimName: string | null
  moreInfo: string | null
}

const read = async (body: string, nonce: string, recipientKey: string) => {
  const sodium = await getSodium()
  const payload = await readReportKey(recipientKey)

  if (typeof payload === 'string') {
    throw new Error(payload)
  }

  const { reportKey } = payload

  /**
   * Be careful! Sender could encrypt anything, so we want to JSON parse it first
   */
  const decryptedBody = sodium.crypto_secretbox_open_easy(
    sodium.from_base64(body),
    sodium.from_base64(nonce),
    reportKey,
    'text'
  )

  return JSON.parse(decryptedBody)
}

export const readReport = async (
  body: string,
  nonce: string,
  recipientKey: string
): Promise<DecryptedReport | string> => {
  try {
    const parsedBody = await read(body, nonce, recipientKey)

    return {
      victimName: parsedBody?.victimName ? String(parsedBody?.victimName) : null,
      moreInfo: parsedBody?.moreInfo ? String(parsedBody?.moreInfo) : null,
    }
  } catch (e) {
    return handleError(e)
  }
}

export const readEncryptedField = async (
  body: string,
  nonce: string,
  recipientKey: string
): Promise<{ value: string | null } | string> => {
  try {
    const parsedBody = await read(body, nonce, recipientKey)

    return {
      value: parsedBody?.value ? String(parsedBody?.value) : null,
    }
  } catch (e) {
    return handleError(e)
  }
}

export const repackReportKey = async (ownerKey: string, recipientPubKey: string) => {
  const sodium = await getSodium()
  const payload = await readReportKey(ownerKey)

  try {
    if (typeof payload === 'string') {
      throw new Error('No report key')
    }

    const { reportKey } = payload

    return {
      reportKey: sodium.crypto_box_seal(reportKey, sodium.from_base64(recipientPubKey), 'base64'),
    }
  } catch (e) {
    return handleError(e)
  }
}

export const readReportKey = async (recipientKey: string) => {
  const sodium = await getSodium()
  try {
    const { publicKey, privateKey } = await getPersonalKeys()
    if (!publicKey || !privateKey) {
      throw new Error('No public or private key')
    }

    return {
      reportKey: sodium.crypto_box_seal_open(
        sodium.from_base64(recipientKey),
        publicKey,
        privateKey,
        'uint8array'
      ),
    }
  } catch (e) {
    return handleError(e)
  }
}
