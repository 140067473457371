import { gql, useQuery } from '@apollo/client'
import { getPersonalKeys, saveSSOKeys } from '@faceup/crypto'
import { useMotherId } from '@faceup/institution'
import { sharedMessages, useIntl } from '@faceup/localization'
import { notification } from '@faceup/ui-base'
import type { Language } from '@faceup/utils'
import { type ReactNode, createContext, useContext } from 'react'
import type { UserQuery } from '../__generated__/globalTypes'
import { useAuth } from '../hooks/auth'

const query = {
  UserQuery: gql`
    query UserQuery {
      managerViewer {
        id
        legacyId
        isPartner
        language
        sso
        name
        email
        phone
        createdAt
        profileImageUrl
        partner {
          id
          verificationStatus
          institutions {
            id
          }
        }
        keys {
          id
          privateKey
          publicKey
          partnerPermissions {
            id
            type
            enabled
            motherId
          }
        }
        hasAccessToMultipleInstitutions
        motherImplicit {
          id
          name
        }
        partnerCompanyIds
      }
    }
  `,
}

type UserQuery_managerViewer = NonNullable<UserQuery['managerViewer']>

export type ApplicationType = 'akutan' | 'kredenc'

export type UserContextProps = {
  logout: () => void
  viewer?: UserQuery_managerViewer | null
  // It shouldn't be here, but we need it for now (otherwise we should create some App package and move it there)
  application: ApplicationType | null
}

export const UserContext = createContext<UserContextProps>({
  logout: () => undefined,
  viewer: null,
  application: null,
})

type UserProviderProps = {
  children: ReactNode
  onLogout: () => void
  onChangeLanguage: (language: Language) => void
} & Pick<UserContextProps, 'application'>

/**
 * When app is loaded we first need to know little info about user, mainly if s/he has access to
 * multiple institutions (indirectly via ManagerType.motherImplicit). If motherImplicit exists we can
 * set motherId in MotherContext right away and continue with other queries, if not user needs to
 * choose via Institutions.tsx.
 */
export const UserProvider = ({
  children,
  onLogout,
  onChangeLanguage,
  application,
}: UserProviderProps) => {
  const { formatMessage } = useIntl()
  const { setMotherId, getMotherIdWithNull } = useMotherId()
  const auth = useAuth()

  const { client, data } = useQuery<UserQuery>(query.UserQuery, {
    // @todo: not sure about this
    // fetchPolicy: 'cache-first',
    onError: error => {
      console.error(error)
      notification.error({
        message: formatMessage(sharedMessages.apiError),
        description: error.message,
      })
    },
    onCompleted: async data => {
      // User has invalid JWT
      const viewer = data?.managerViewer
      if (!viewer?.id) {
        logout()
        return
      }
      if (viewer.language) {
        onChangeLanguage(viewer.language)
      }
      const { privateKey } = await getPersonalKeys()
      if (!privateKey && viewer.sso && viewer.keys?.privateKey) {
        await saveSSOKeys({
          privateKey: viewer.keys.privateKey ?? '',
          publicKey: viewer.keys?.publicKey ?? '',
          rememberMe: false,
        })
      }

      /*
       * Non-partner users take mother ID from deprecated motherImplicit resolver: they currently have
       * access only to one institution, so we can assume they actually have exactly one mother
       * accessible to them.
       * Partner users have to choose the mother from the UI (Institutions.tsx).
       *
       * We need to refactor the UI to support multiple institutions to everyone.
       */
      if (!getMotherIdWithNull() && data.managerViewer?.motherImplicit?.id) {
        setMotherId(data.managerViewer?.motherImplicit?.id)
      }
    },
    skip: !auth.isAuthenticated() || application === 'kredenc',
  })

  const logout = async () => {
    client.stop()
    await client.clearStore()
    onLogout()
    auth.logout()
    // no need to clean MotherContext because it will be cleaned once we reload the page
    window.location.href = '/'
  }

  return (
    <UserContext.Provider
      value={{
        application,
        logout,
        viewer: data?.managerViewer,
      }}
    >
      {children}
    </UserContext.Provider>
  )
}

export const useUser = () => useContext(UserContext)
