import { gql } from '@apollo/client'
import styled from '@emotion/styled'
import { ulBuilding08 } from '@faceup/icons/ulBuilding08'
import { ulFolder } from '@faceup/icons/ulFolder'
import { ulGraduationHat02 } from '@faceup/icons/ulGraduationHat02'
import { ulHomeLine } from '@faceup/icons/ulHomeLine'
import { ulInbox01 } from '@faceup/icons/ulInbox01'
import { ulPuzzlePiece } from '@faceup/icons/ulPuzzlePiece'
import { ulTable } from '@faceup/icons/ulTable'
import { ulUsers01 } from '@faceup/icons/ulUsers01'
import { useMatchPath } from '@faceup/router'
import {
  AdminMenu,
  type AdminMenuItem,
  PageContentSkeleton,
  AdminLayout as UiAdminLayout,
} from '@faceup/ui'
import { type FuAdminLayout_admin, FuAdminRole } from '../__generated__/globalTypes'
import { routes } from '../hooks/useRoutes'
import HeaderProfile, { fragments as HeaderProfileFragments } from './HeaderProfile'
import RegionSelect from './RegionSelect'

const logo = '/logo/U-logo.svg'

const useActiveRoute = () => {
  const matchPath = useMatchPath()

  if (matchPath(routes.dashboard)) {
    return 'dashboard'
  }

  if (matchPath(routes.schools)) {
    return 'schools'
  }

  if (matchPath(routes.companies)) {
    return 'companies'
  }

  if (matchPath(routes.partners) || matchPath(routes.partner)) {
    return 'partners'
  }

  if (matchPath(routes.services) || matchPath(routes.service)) {
    return 'services'
  }

  if (matchPath(routes.reports) || matchPath(routes.report)) {
    return 'reports'
  }

  if (matchPath(routes.materials)) {
    return 'materials'
  }

  if (matchPath(routes.globalTables)) {
    return 'global-tables'
  }

  return matchPath(routes.dashboard) ? 'dashboard' : ''
}

export const fragments = {
  FuAdminLayout_admin: gql`
    fragment FuAdminLayout_admin on FuAdmin {
      id
      name
      role
      companyCountries
      schoolCountries
      ...HeaderProfileQuery
    }
    ${HeaderProfileFragments.HeaderProfileQuery}
  `,
}

type Props = {
  children?: React.ReactNode
  logout: () => void
  viewer: FuAdminLayout_admin | null
}

const AdminLayout = ({ viewer, logout, children }: Props) => {
  const activeKey = useActiveRoute()

  // mock loading instead of spinner
  if (!viewer?.id) {
    return (
      <UiAdminLayout
        menu={
          <AdminMenu
            logo={logo}
            homepageLink={routes => routes.dashboard()}
            items={[]}
            activeKey={activeKey}
            logoBackground='#cccccc'
          />
        }
      >
        <PageContentSkeleton />
      </UiAdminLayout>
    )
  }

  const menu: AdminMenuItem[] = [
    {
      key: 'dashboard',
      label: 'Dashboard',
      icon: ulHomeLine,
      link: routes => routes.dashboard(),
    },
    {
      key: 'schools',
      label: 'Schools',
      icon: ulGraduationHat02,
      link: routes => routes.schools(),
      visible: Boolean(viewer?.schoolCountries?.length),
    },
    {
      key: 'companies',
      label: 'Companies',
      icon: ulBuilding08,
      link: routes => routes.companies(),
      visible: Boolean(viewer?.companyCountries?.length),
    },
    {
      key: 'partners',
      label: 'Partners',
      icon: ulUsers01,
      link: routes => routes.partners(),
      visible:
        Boolean(viewer?.companyCountries?.length) && viewer?.role !== FuAdminRole.Psychologist,
    },
    {
      key: 'reports',
      label: 'Reports',
      icon: ulInbox01,
      link: routes => routes.reports(),
      visible: viewer?.role === FuAdminRole.Psychologist,
    },
    {
      key: 'services',
      label: 'Services',
      icon: ulPuzzlePiece,
      link: routes => routes.services(),
      visible: viewer?.role !== FuAdminRole.Psychologist,
    },
    {
      key: 'materials',
      label: 'Materials',
      icon: ulFolder,
      link: routes => routes.materials(),
      visible: viewer?.role !== FuAdminRole.Psychologist,
    },
    {
      key: 'global-tables',
      label: 'Global tables',
      icon: ulTable,
      link: routes => routes.globalTables(),
      visible: viewer?.role === FuAdminRole.Admin,
    },
  ]

  return (
    <UiAdminLayout
      menu={
        <AdminMenu
          logo={logo}
          homepageLink={routes => routes.dashboard()}
          items={menu}
          activeKey={activeKey}
          logoBackground='#fd9f13'
        />
      }
    >
      <Header>
        <RegionSelect />
        <HeaderProfile logout={() => logout()} viewer={viewer} />
      </Header>
      {children}
    </UiAdminLayout>
  )
}

const Header = styled.div`
  background: #fff;
  padding: var(--ant-padding-sm);
  display: flex;
  justify-content: flex-end;
  position: sticky;
  top: 0;
  z-index: 1;
  border-bottom: 1px solid var(--ant-color-border-secondary);
  gap: var(--ant-padding-sm);
`

export default AdminLayout
