import { UntitledIcon } from '@faceup/icons'
import { ulDotsVertical } from '@faceup/icons/ulDotsVertical'
import { Dropdown, type DropdownProps } from '@faceup/ui-base'
import { UnstyledButton } from '@mantine/core'

type Props = DropdownProps

const MoreOptions = (props: Props) => (
  <Dropdown placement='bottomRight' trigger={['click']} {...props}>
    <UnstyledButton color='prussianBlue' data-onboarding='more-options-button'>
      <UntitledIcon icon={ulDotsVertical} />
    </UnstyledButton>
  </Dropdown>
)

export default MoreOptions
