import { type ReactNode, createContext, useContext } from 'react'
import type { Routes } from './interfaces'

type RoutesContextType = {
  routes: Routes
}

const RoutesContext = createContext<RoutesContextType>({
  routes: {} as Routes,
})

type RoutesProviderProps = {
  children: ReactNode
} & RoutesContextType

export const RoutesProvider = ({ children, ...props }: RoutesProviderProps) => (
  <RoutesContext.Provider value={props}>{children}</RoutesContext.Provider>
)

export const useRoutes = () => useContext(RoutesContext).routes
