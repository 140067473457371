import type Sodium from 'libsodium-wrappers-sumo'

export const getSodium = async (): Promise<typeof Sodium> => {
  const { default: sodium } = await import('libsodium-wrappers-sumo')
  await sodium.ready

  return sodium
}

export const handleError = (e: unknown) => {
  if (e instanceof Error) {
    return e.message
  }

  return 'Unspecified encryption error'
}
