import { sharedMessages, useIntl } from '@faceup/localization'
import { notification } from '@faceup/ui-base'
import { captureException } from '@sentry/react'
import { useCallback } from 'react'

// It's not best place, @faceup/crypto should be used instead
export const useCryptoErrorHandler = () => {
  const { formatMessage } = useIntl()

  return useCallback(
    (payload: string) => {
      console.error(payload)
      captureException(payload)
      notification.error({
        message: formatMessage(sharedMessages.encryptionError),
        description: payload,
      })
    },
    [formatMessage]
  )
}
