import styled from '@emotion/styled'
import { Button } from '@faceup/ui'
import { Divider, List, Modal, Popconfirm, Select, Typography } from '@faceup/ui-base'
import { useState } from 'react'
import { Language } from '../../__generated__/globalTypes'

const { Text } = Typography

type Props = {
  visible: boolean
  setVisible: (visible: boolean) => void
  languages: Language[]
  defaultLanguage: Language
  addLanguage: (language: Language) => void
  removeLanguage: (language: Language) => void
  setDefaultLanguage: (Language: Language) => void
}

const EditServiceLanguageModal = (props: Props) => {
  const [newLanguageMode, setNewLanguageMode] = useState(false)
  const [newLanguage, setNewLanguage] = useState<Language | null>(null)

  return (
    <Modal
      title='Edit language'
      visible={props.visible}
      footer={null}
      onCancel={() => props.setVisible(false)}
    >
      <List
        dataSource={props.languages}
        renderItem={language => (
          <List.Item>
            <ListItemContent>
              {language ?? ''}
              <div>
                {language === props.defaultLanguage ? (
                  <Text strong style={{ marginRight: 15 }}>
                    Default language
                  </Text>
                ) : (
                  <>
                    <Popconfirm
                      title='Confirm removing'
                      okText='Remove'
                      cancelText='Cancel'
                      onConfirm={() => props.removeLanguage(language)}
                    >
                      <Button size='small' variant='text' color='error'>
                        Remove
                      </Button>
                    </Popconfirm>
                    <Divider type='vertical' />
                    <Button
                      size='small'
                      variant='text'
                      onClick={() => props.setDefaultLanguage(language)}
                    >
                      Set as default language
                    </Button>
                  </>
                )}
              </div>
            </ListItemContent>
          </List.Item>
        )}
        footer={
          newLanguageMode ? (
            <ListItemContent>
              <Select<Language>
                style={{ width: 250 }}
                onSelect={(value: Language) => setNewLanguage(value)}
                placeholder='Add language...'
              >
                {Object.values(Language)
                  .filter(language => !props.languages.includes(language))
                  .map(language => (
                    <Select.Option key={language} value={language}>
                      {language}
                    </Select.Option>
                  ))}
              </Select>
              <div>
                <Button
                  size='small'
                  variant='text'
                  color='error'
                  onClick={() => setNewLanguageMode(false)}
                >
                  Cancel
                </Button>
                <Divider type='vertical' />
                <Button
                  size='small'
                  variant='text'
                  onClick={() => {
                    if (newLanguage) {
                      props.addLanguage(newLanguage)
                    }
                    setNewLanguageMode(false)
                    setNewLanguage(null)
                  }}
                >
                  Add
                </Button>
              </div>
            </ListItemContent>
          ) : (
            <Button variant='secondary' isFullWidth onClick={() => setNewLanguageMode(true)}>
              Add language
            </Button>
          )
        }
      />
    </Modal>
  )
}

const ListItemContent = styled.div`
  display: flex;
  width: 100%;
  flex-flow: nowrap row;
  justify-content: space-between;
  align-items: center;
`

export default EditServiceLanguageModal
