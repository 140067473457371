import styled from '@emotion/styled'
import { Avatar, type AvatarProps } from '@faceup/ui-base'

type AvatarOutlinedProps = AvatarProps

const AvatarOutlined = (props: AvatarOutlinedProps) => <StyledAvatar {...props} />

const StyledAvatar = styled(Avatar)`
  border: 1px solid #e1e5e8;
  background-color: unset;
`

export default AvatarOutlined
