import { getPersonalKeys } from './keys'
import { CURRENT_ENCRYPTION_VERSION, MEMLIMIT, OPSLIMIT } from './utils/constants'
import { getSodium, handleError } from './utils/general'

type PrehashPayload = {
  password: string
  salt: string
  version?: 1 | typeof CURRENT_ENCRYPTION_VERSION
}

export const prehashPassword = async (payload: PrehashPayload) => {
  const version = payload?.version ?? CURRENT_ENCRYPTION_VERSION
  const sodium = await getSodium()

  if (version === 1) {
    return {
      passwordKey: payload.password,
      passwordKeyPrehash: payload.password,
    }
  }

  const passwordKey = sodium.crypto_pwhash(
    sodium.crypto_secretbox_KEYBYTES,
    payload.password,
    sodium.from_base64(payload.salt),
    OPSLIMIT,
    MEMLIMIT,
    sodium.crypto_pwhash_ALG_ARGON2ID13
  )

  const passwordKeyPrehash = sodium.crypto_pwhash(
    sodium.crypto_secretbox_KEYBYTES,
    passwordKey,
    sodium.from_base64(payload.salt),
    OPSLIMIT,
    MEMLIMIT,
    sodium.crypto_pwhash_ALG_ARGON2ID13,
    'base64'
  )

  return {
    passwordKey: sodium.to_base64(passwordKey),
    passwordKeyPrehash,
  }
}

export const changePassword = async (
  password: string,
  keys: { publicKey: string; privateKey: string } | 'generate' | 'infer'
) => {
  const sodium = await getSodium()

  const salt = sodium.randombytes_buf(sodium.crypto_pwhash_SALTBYTES)
  const nonce = sodium.randombytes_buf(sodium.crypto_secretbox_NONCEBYTES)

  const resolveKeys = () => {
    if (keys === 'generate') {
      return sodium.crypto_box_keypair()
    }

    if (keys === 'infer') {
      return getPersonalKeys()
    }

    return {
      publicKey: sodium.from_base64(keys.publicKey),
      privateKey: sodium.from_base64(keys.privateKey),
    }
  }

  try {
    const { publicKey, privateKey } = await resolveKeys()
    const { passwordKey, passwordKeyPrehash } = await prehashPassword({
      password,
      salt: sodium.to_base64(salt),
      version: CURRENT_ENCRYPTION_VERSION,
    })

    if (!publicKey || !privateKey) {
      throw new Error('No public or private key')
    }

    const privateKeyEncrypted = sodium.crypto_secretbox_easy(
      privateKey,
      nonce,
      sodium.from_base64(passwordKey),
      'base64'
    )

    return {
      password: passwordKeyPrehash,
      publicKey: sodium.to_base64(publicKey),
      privateKeyEncrypted,
      salt: sodium.to_base64(salt),
      nonce: sodium.to_base64(nonce),
      passwordKey,
    }
  } catch (e) {
    return handleError(e)
  }
}

export const generatePin = async () => {
  const sodium = await getSodium()
  const pin = (
    sodium.randombytes_uniform(99999).toString() + sodium.randombytes_uniform(99999).toString()
  ).padStart(10, '0')
  const victimPayload = await changePassword(pin, 'generate')

  if (typeof victimPayload === 'string') {
    return victimPayload
  }

  return [victimPayload, pin] as const
}

export const serializePin = (pin: string) => pin.match(/.{1,4}/g)?.join(' ') ?? ''

export const deserializePin = (pin: string) => pin.split(' ').join('')

export const parsePinToParts = (pin: string) => {
  const trimmedPin = pin.trim()
  const version: 1 | 2 = trimmedPin.length === 14 ? 1 : CURRENT_ENCRYPTION_VERSION
  const [identity, password] =
    version === 1
      ? [trimmedPin.substring(0, 6), trimmedPin.substring(6, 14)]
      : [trimmedPin.substring(0, 6), trimmedPin.substring(6, 16)]

  return { identity, password, version }
}
