import { FormItem, useAwait } from '@faceup/ui'
import {
  Loader,
  TextInput as MantineTextInput,
  type TextInputProps as MantineTextInputProps,
} from '@mantine/core'
import type { FieldValues, UseControllerProps } from 'react-hook-form'
import { useCustomController } from '../hooks'
import type { FormItemWrapperProps } from '../typings'

export type TextInputProps<T extends FieldValues> = UseControllerProps<T> &
  Omit<MantineTextInputProps & FormItemWrapperProps, 'value' | 'defaultValue' | 'placeholder'> & {
    placeholder?: string | Promise<string>
    loading?: boolean
  }
export const TextInput = <T extends FieldValues>(props: TextInputProps<T>) => {
  const {
    formItemProps,
    inputProps: { onChange: fieldOnChange, value, placeholder, loading, ...inputProps },
  } = useCustomController(props)
  const awaitedPlaceholder = useAwait(placeholder)

  return (
    <FormItem {...formItemProps}>
      <MantineTextInput
        {...inputProps}
        placeholder={awaitedPlaceholder}
        onChange={e => {
          fieldOnChange(e)
          props.onChange?.(e)
        }}
        value={value ?? ''}
        icon={loading ? <Loader size='sm' /> : inputProps.icon}
      />
    </FormItem>
  )
}
