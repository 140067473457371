import { UntitledIcon } from '@faceup/icons'
import { ulUpload03 } from '@faceup/icons/ulUpload03'
import { useThemeColors } from '@faceup/ui'
import { useUpsellContext } from './contexts'

type UpsellIconProps = {
  isColorFromParent?: boolean
  size?: number
}

export const UpsellIcon = (props: UpsellIconProps) => {
  const { isColorFromParent, size } = props
  const { upsell } = useUpsellContext()
  const { themeColors } = useThemeColors()

  if (upsell?.showUpsell === false) {
    return null
  }

  return (
    <UntitledIcon
      icon={ulUpload03}
      size={size}
      color={isColorFromParent ? undefined : themeColors.primary[100]}
    />
  )
}
