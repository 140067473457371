import { Button, type ButtonProps } from '@faceup/ui'
import { createPolymorphicComponent } from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import { type ReactNode, forwardRef } from 'react'
import { UpsellIcon } from './UpsellIcon'
import { UpsellModal } from './UpsellModal'
import type { UpsellData } from './upsellData'

const getRightIcon = ({
  rightIcon,
  showUpsell,
}: {
  rightIcon?: ReactNode
  showUpsell: boolean
}) => {
  if (rightIcon && showUpsell) {
    return (
      <>
        {rightIcon} <UpsellIcon isColorFromParent />
      </>
    )
  }

  if (rightIcon) {
    return rightIcon
  }

  if (showUpsell) {
    return <UpsellIcon isColorFromParent />
  }

  return undefined
}

type UpsellButtonProps = {
  upsell: UpsellData
  onClick?: () => void
} & ButtonProps

export const UpsellButton = createPolymorphicComponent<'button', UpsellButtonProps>(
  forwardRef<HTMLButtonElement, UpsellButtonProps>(({ upsell, ...props }, ref) => {
    const [opened, { open, close }] = useDisclosure(false)

    const { showUpsell } = upsell

    const rightIcon = getRightIcon({ rightIcon: props.iconAfter, showUpsell })

    return (
      <>
        <Button
          ref={ref}
          {...props}
          onClick={event => {
            if (showUpsell) {
              event.preventDefault()
              open()
            } else {
              props?.onClick?.()
            }
          }}
          iconAfter={rightIcon}
        >
          {props.children}
        </Button>
        <UpsellModal isOpened={opened} onClose={close} upsell={upsell} />
      </>
    )
  })
)
