import { gql, useMutation } from '@apollo/client'
import { useIdle } from '@mantine/hooks'
import { useEffect } from 'react'
import type {
  CompanyChat_report,
  MarkAsReadMutation,
  MarkAsReadMutationVariables,
} from '../__generated__/globalTypes'

const markAsReadMutation = gql`
  mutation MarkAsReadMutation($input: markAsReadCompanyFollowUpCommentsInput!) {
    markAsReadCompanyFollowUpComments(input: $input) {
      success
    }
  }
`

type MessageType = NonNullable<
  NonNullable<CompanyChat_report['followUpActivities']>['items']
>[number]

export const useMarkMessagesAsRead = ({
  unreadMessage,
  onCompleted,
}: {
  unreadMessage: MessageType | undefined
  onCompleted?: () => void
}) => {
  const idle = useIdle(2000)

  const [markAsRead] = useMutation<MarkAsReadMutation, MarkAsReadMutationVariables>(
    markAsReadMutation,
    {
      onError: error => console.error(error),
      onCompleted,
      refetchQueries: ['AdminLayoutPartnerQuery'],
    }
  )

  useEffect(() => {
    // mark message as read when the user is active on the page
    if (!idle && unreadMessage?.report) {
      markAsRead({
        variables: {
          input: {
            // will mark all the report's messages as read
            reportId: unreadMessage?.report?.id,
            motherId:
              unreadMessage?.report?.company?.mother?.id ??
              unreadMessage?.report?.company?.id ??
              '',
          },
        },
      })
    }
  }, [markAsRead, unreadMessage, idle])
}
