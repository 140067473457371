import { gql, useMutation } from '@apollo/client'
import { useNavigate } from '@faceup/router'
import { notification } from '@faceup/ui-base'
import type {
  AvailablePartnersQuery,
  CreateCompanyService,
  CreateCompanyServiceInput,
  CreateCompanyServiceVariables,
} from '../../__generated__/globalTypes'
import CompanyServiceEditor from './CompanyServiceEditor'

type AvailablePartnersQuery_partners_items_item = NonNullable<
  NonNullable<AvailablePartnersQuery['partners']>['items']
>[number]

const mutation = {
  CreateCompanyService: gql`
    mutation CreateCompanyService($input: CreateCompanyServiceInput!) {
      createCompanyService(input: $input) {
        service {
          id
        }
      }
    }
  `,
}

type Props = {
  visible: boolean
  setVisible: (visible: boolean) => void
  partners: (AvailablePartnersQuery_partners_items_item | null)[] | null | undefined
}

const CreateCompanyServiceModal = ({ visible, setVisible, partners }: Props) => {
  const navigate = useNavigate()

  const [createCompanyService, { loading }] = useMutation<
    CreateCompanyService,
    CreateCompanyServiceVariables
  >(mutation.CreateCompanyService, {
    onError: error => {
      console.error(error)
      notification.error({
        message: 'GQL Error',
        description: error.message,
      })
    },
    onCompleted: data => {
      setVisible(!visible)
      navigate(routes => routes.service({ id: data.createCompanyService?.service?.id ?? '' }))
    },
  })

  return (
    <CompanyServiceEditor
      loading={loading}
      visible={visible}
      setVisible={setVisible}
      partners={partners}
      saveChanges={newService => {
        createCompanyService({
          variables: {
            input: newService as CreateCompanyServiceInput,
          },
        })
      }}
    />
  )
}

export default CreateCompanyServiceModal
