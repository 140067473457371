import styled from '@emotion/styled'
import { Button } from '@faceup/ui'
import { Divider, Input, List } from '@faceup/ui-base'
import { useState } from 'react'

type Props = {
  categories: string[]
  setCategories: (categories: string[]) => void
}

const ServiceCategoriesEditorTable = ({ categories, setCategories }: Props) => {
  const [editedCategory, setEditedCategory] = useState<number | null>(null) // newCategory => -1
  const [categoryName, setCategoryName] = useState('')

  return (
    <List
      bordered
      dataSource={categories}
      renderItem={(category, index) => (
        <List.Item>
          {editedCategory === index ? (
            <ListItemContent>
              <Input
                autoComplete='off'
                size='small'
                value={categoryName}
                onChange={e => setCategoryName(e.target.value)}
                style={{ maxWidth: 200 }}
              />
              <div>
                <Button
                  size='small'
                  variant='text'
                  color='error'
                  onClick={() => {
                    setEditedCategory(null)
                  }}
                >
                  Cancel
                </Button>
                <Divider type='vertical' />
                <Button
                  size='small'
                  variant='text'
                  onClick={() => {
                    const tempCategories = [...categories]
                    tempCategories[index] = categoryName
                    setCategories(tempCategories)
                    setCategoryName('')
                    setEditedCategory(null)
                  }}
                >
                  Save
                </Button>
              </div>
            </ListItemContent>
          ) : (
            <ListItemContent style={{ paddingInline: 10 }}>
              <span>{category}</span>
              <div>
                <Button
                  size='small'
                  variant='text'
                  color='error'
                  onClick={() => setCategories(categories.filter((_, i) => i !== index))}
                >
                  Remove
                </Button>
                <Divider type='vertical' />
                <Button
                  size='small'
                  variant='text'
                  onClick={() => {
                    setCategoryName(category)
                    setEditedCategory(index)
                  }}
                >
                  Edit category
                </Button>
              </div>
            </ListItemContent>
          )}
        </List.Item>
      )}
      footer={
        editedCategory === -1 ? (
          <ListItemContent style={{ paddingInline: 10 }}>
            <Input
              autoComplete='off'
              style={{ maxWidth: 200 }}
              onChange={e => setCategoryName(e.target.value)}
              value={categoryName}
              placeholder='Add category...'
              autoFocus
            />
            <div>
              <Button
                variant='text'
                color='error'
                onClick={() => {
                  setEditedCategory(null)
                  setCategoryName('')
                }}
              >
                Cancel
              </Button>
              <Divider type='vertical' />
              <Button
                variant='text'
                disabled={categoryName.trim() === ''}
                onClick={() => {
                  setCategories([...categories, categoryName])
                  setEditedCategory(null)
                  setCategoryName('')
                }}
              >
                Add
              </Button>
            </div>
          </ListItemContent>
        ) : (
          <Button
            isFullWidth
            onClick={() => {
              setCategoryName('')
              setEditedCategory(-1)
            }}
          >
            Add category
          </Button>
        )
      }
    />
  )
}

const ListItemContent = styled.div`
  display: flex;
  width: 100%;
  flex-flow: nowrap row;
  justify-content: space-between;
  align-items: center;
`

export default ServiceCategoriesEditorTable
