import { UntitledIcon } from '@faceup/icons'
import { ulInfoCircle } from '@faceup/icons/ulInfoCircle'
import { Tooltip, type TooltipProps } from '../Tooltip'

export type MoreInfoProps = Pick<TooltipProps, 'title' | 'placement'>

export const MoreInfo = (props: MoreInfoProps) => (
  <Tooltip {...props}>
    <UntitledIcon icon={ulInfoCircle} />
  </Tooltip>
)
