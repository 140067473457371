import { shade, tint } from 'polished'
import { useContext } from 'react'
import { UiContext } from '../UiProvider/UiProvider'

export const colorsWith6Variants = [
  'primary',
  'success',
  'error',
  'warning',
  'complementary',
] as const
export const colorsWith12Variants = ['dark'] as const
const colorsSingleVariant = ['white', 'text', 'subtleText'] as const

type ColorWith6Variants = (typeof colorsWith6Variants)[number]
type ColorWith12Variants = (typeof colorsWith12Variants)[number]
type ColorSingleVariant = (typeof colorsSingleVariant)[number]

export const colorVariants = [...colorsWith6Variants, ...colorsWith12Variants] as const
export type ColorVariant = (typeof colorVariants)[number]

type UsedColor = ColorWith6Variants & ColorWith12Variants & ColorSingleVariant

const variant6 = [110, 100, 60, 40, 20, 10] as const
type Variant6 = (typeof variant6)[number]
const variant12 = [110, 100, 90, 80, 70, 60, 50, 40, 30, 20, 10, 4] as const
type Variant12 = (typeof variant12)[number]

type ColorType = Record<ColorWith6Variants, Record<Variant6, string>> &
  Record<ColorWith12Variants, Record<Variant12, string>> &
  Record<ColorSingleVariant, string>

type Data =
  | { color: ColorWith6Variants; variant: Variant6 }
  | { color: ColorWith12Variants; variant: Variant12 }
  | { color: ColorSingleVariant; variant: never }
type FnGetColor = (data: Data) => string

export type Color =
  | 'primary.110'
  | 'primary.100'
  | 'primary.60'
  | 'primary.40'
  | 'primary.20'
  | 'primary.10'
  | 'success.110'
  | 'success.100'
  | 'success.60'
  | 'success.40'
  | 'success.20'
  | 'success.10'
  | 'error.110'
  | 'error.100'
  | 'error.60'
  | 'error.40'
  | 'error.20'
  | 'error.10'
  | 'warning.110'
  | 'warning.100'
  | 'warning.60'
  | 'warning.40'
  | 'warning.20'
  | 'warning.10'
  | 'complementary.110'
  | 'complementary.100'
  | 'complementary.60'
  | 'complementary.40'
  | 'complementary.20'
  | 'complementary.10'
  | 'dark.110'
  | 'dark.100'
  | 'dark.90'
  | 'dark.80'
  | 'dark.70'
  | 'dark.60'
  | 'dark.50'
  | 'dark.40'
  | 'dark.30'
  | 'dark.20'
  | 'dark.10'
  | 'dark.4'
  | 'white'
  | 'text'
  | 'subtleText'

type FnGetColorFromTheme = (color: Color) => string

type FnUseThemeColors = () => UseThemeColors
export type UseThemeColors = {
  themeColors: ColorType
  getColorFromTheme: FnGetColorFromTheme
  overriddenPrimaryColor: string | null
  overriddenDarkColor: string | null
}
export const useThemeColors: FnUseThemeColors = () => {
  const { theme } = useContext(UiContext)
  const colors: ColorType = {
    primary: theme?.colors?.primary
      ? {
          110: shade(0.1, theme.colors.primary),
          100: theme.colors.primary,
          60: tint(0.4, theme.colors.primary),
          40: tint(0.6, theme.colors.primary),
          20: tint(0.8, theme.colors.primary),
          10: tint(0.9, theme.colors.primary),
        }
      : {
          110: '#007AE1',
          100: '#0085FF',
          60: '#48A7FF',
          40: '#71BBFF',
          20: '#B9DDFF',
          10: '#E6F3FF',
        },
    success: {
      110: '#2CA86A',
      100: '#32C27B',
      60: '#32C27B99',
      40: '#32C27B66',
      20: '#32C27B33',
      10: '#32C27B1A',
    },
    error: {
      110: '#E53C69',
      100: '#F54070',
      60: '#F5407099',
      40: '#F5407066',
      20: '#F5407033',
      10: '#F540701A',
    },
    warning: {
      110: '#F08035',
      100: '#FA914B',
      60: '#FA914B99',
      40: '#FA914B66',
      20: '#FA914B33',
      10: '#FA914B1A',
    },
    complementary: {
      110: '#8E4CD1',
      100: '#9C53E5',
      60: '#9C53E599',
      40: '#9C53E566',
      20: '#9C53E533',
      10: '#9C53E51A',
    },
    dark: theme?.colors?.dark
      ? {
          110: shade(0.1, theme.colors.dark),
          100: theme.colors.dark,
          90: tint(0.1, theme.colors.dark),
          80: tint(0.2, theme.colors.dark),
          70: tint(0.3, theme.colors.dark),
          60: tint(0.4, theme.colors.dark),
          50: tint(0.5, theme.colors.dark),
          40: tint(0.6, theme.colors.dark),
          30: tint(0.7, theme.colors.dark),
          20: tint(0.8, theme.colors.dark),
          10: tint(0.9, theme.colors.dark),
          4: tint(0.96, theme.colors.dark),
        }
      : {
          110: '#062D46',
          100: '#062D46',
          90: '#1F4259',
          80: '#38576B',
          70: '#516C7E',
          60: '#6A8190',
          50: '#8296A2',
          40: '#9BABB5',
          30: '#B4C0C7',
          20: '#CED6DB',
          10: '#E6EBF0',
          4: '#F5F7FA',
        },
    white: '#ffffff',
    text: '#49607a',
    subtleText: '#8ca0b8',
  }

  const getColor: FnGetColor = ({ color, variant }) => {
    if (colorsWith6Variants.includes(color as ColorWith6Variants)) {
      return colors[color as ColorWith6Variants][variant as Variant6]
    }
    if (colorsWith12Variants.includes(color as ColorWith12Variants)) {
      return colors[color as ColorWith12Variants][variant as Variant12]
    }
    return colors[color as ColorSingleVariant]
  }

  const getColorFromTheme: FnGetColorFromTheme = color => {
    const [colorName, variant] = color.split('.')
    const data: Data = {
      color: colorName as UsedColor,
      ...(variant ? { variant: Number.parseInt(variant) as Variant6 | Variant12 } : {}),
    } as Data
    return getColor(data)
  }

  return {
    themeColors: colors,
    getColorFromTheme,
    overriddenPrimaryColor: theme?.colors?.primary ?? null,
    overriddenDarkColor: theme?.colors?.dark ?? null,
  }
}
