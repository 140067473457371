import { Select } from '@faceup/ui-base'
import { SupportedRegion, convertRegionToRegionName } from '@faceup/utils'
import useRegion from '../utils/useRegion'

const { Option } = Select

const RegionSelect = () => {
  const { region, setRegion } = useRegion()

  return (
    <Select
      value={region}
      onSelect={newRegion => setRegion(newRegion)}
      style={{ maxWidth: '200px' }}
    >
      {Object.values(SupportedRegion).map(region => (
        <Option value={region} key={region}>
          {convertRegionToRegionName(region)}
        </Option>
      ))}
    </Select>
  )
}

export default RegionSelect
