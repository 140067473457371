import type { FormItemType, LogoSize } from '@faceup/utils'
import {
  type Dispatch,
  type ReactNode,
  type SetStateAction,
  createContext,
  useCallback,
  useContext,
  useState,
} from 'react'

export type PreviewCustomValuesFieldBase = {
  label: string
  isRequired: boolean
  hasNote: boolean
  type: FormItemType
}

export type PreviewCustomValuesField = PreviewCustomValuesFieldBase & { formItemId: string }
export type PreviewCustomValuesNewField = PreviewCustomValuesFieldBase

export type PreviewCustomValues = {
  title?: string
  description?: string
  anonymityEnabled?: boolean
  emailNotificationEnabled?: boolean
  logoBase64?: string | null
  logoSize?: LogoSize
  reportColor?: string
  categoryId?: string
  fields: PreviewCustomValuesField[]
  newField?: PreviewCustomValuesNewField
  // Update timestamp if you want to force reload the fields
  // For example, when you add a new field or when you change the order of the fields
  fieldsReloadTimestamp?: number
  source: 'customization'
}

type FnSetField = (values: PreviewCustomValuesField) => void
type FnResetField = (formItemId: string) => void

type FollowUpPreviewContextType = {
  previewValues: Readonly<PreviewCustomValues>
  setPreviewValues: Dispatch<SetStateAction<Readonly<PreviewCustomValues>>>
  setField: FnSetField
  resetField: FnResetField
}
const FollowUpPreviewContext = createContext<FollowUpPreviewContextType>({
  setPreviewValues: () => null,
  previewValues: { fields: [], source: 'customization' },
  setField: () => null,
  resetField: () => null,
})

type FollowUpPreviewProviderProps = {
  children: ReactNode
}

export const FollowUpPreviewProvider = ({ children }: FollowUpPreviewProviderProps) => {
  const [previewValues, setPreviewValues] = useState<PreviewCustomValues>({
    fields: [],
    source: 'customization',
  })

  const setField: (values: PreviewCustomValuesField) => void = useCallback(field => {
    setPreviewValues(prevState => {
      const formItems = prevState.fields.filter(item => item.formItemId !== field.formItemId)
      return {
        ...prevState,
        fields: [...formItems, field],
      }
    })
  }, [])

  const resetField: (formItemId: string) => void = useCallback(formItemId => {
    setPreviewValues(prevState => {
      const formItems = prevState.fields.filter(item => item.formItemId !== formItemId)
      return {
        ...prevState,
        fields: formItems,
      }
    })
  }, [])

  return (
    <FollowUpPreviewContext.Provider
      value={{
        previewValues,
        setPreviewValues,
        setField,
        resetField,
      }}
    >
      {children}
    </FollowUpPreviewContext.Provider>
  )
}

export const useFollowUpPreview = () => useContext(FollowUpPreviewContext)
