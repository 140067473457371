import { FeatureFlagProvider, MantineProvider } from '@faceup/ui'
import { DEFAULT_LANGUAGE, languageEnumToBasename } from '@faceup/utils'
import * as Sentry from '@sentry/react'
import { useEffect, useState } from 'react'
import { IntlProvider } from 'react-intl'
import AppProviders from './AppProviders'
import CustomApolloProvider from './CustomApolloProvider'
import AppRouter from './Pages/AppRouter'
import translations, { type BaseTranslations } from './locales'

const App = () => {
  const [messages, setMessages] = useState<BaseTranslations>()

  useEffect(() => {
    const loadMessages = async () => {
      const newMessages = await translations.messages[DEFAULT_LANGUAGE]()
      setMessages(newMessages)
    }
    void loadMessages()
  }, [])

  return (
    <MantineProvider direction='ltr'>
      <IntlProvider
        locale={languageEnumToBasename(DEFAULT_LANGUAGE)}
        messages={messages?.default}
        onError={() => {
          // Ignore errors
        }}
      >
        <CustomApolloProvider>
          <FeatureFlagProvider>
            <AppProviders>
              <AppRouter />
            </AppProviders>
          </FeatureFlagProvider>
        </CustomApolloProvider>
      </IntlProvider>
    </MantineProvider>
  )
}

export default Sentry.withProfiler(App)
