import { gql } from '@apollo/client'
import { createReportWithoutVictim } from '@faceup/crypto'
import type { Attachment } from '@faceup/ui'
import type {
  usePrepareReportPayloadFragments_company,
  usePrepareReportPayloadFragments_system,
} from '../__generated__/globalTypes'

export const usePrepareReportPayloadFragments = {
  usePrepareReportPayloadFragments_company: gql`
    fragment usePrepareReportPayloadFragments_company on Company {
      id
      recipients {
        id
        keys {
          id
          publicKey
        }
      }
    }
  `,
  usePrepareReportPayloadFragments_system: gql`
    fragment usePrepareReportPayloadFragments_system on System {
      id
      publicKey
    }
  `,
}

export const usePrepareReportPayload =
  () =>
  async (
    company: usePrepareReportPayloadFragments_company,
    system: usePrepareReportPayloadFragments_system,
    isE2EE: boolean,
    values: {
      victimName: string | null
      moreInfo: string
    },
    attachments: Attachment[]
  ) => {
    const systemPublicKey = system.publicKey

    const recipientsKeys =
      company.recipients?.map(recipient => ({
        id: recipient?.id ?? '',
        key: recipient?.keys?.publicKey ?? '',
      })) ?? []

    return await createReportWithoutVictim(
      {
        victimName: values.victimName,
        moreInfo: values.moreInfo.trim(),
        attachments: attachments.map(({ file }) => file),
      },
      recipientsKeys,
      undefined,
      isE2EE ? undefined : systemPublicKey
    )
  }
