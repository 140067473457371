import styled from '@emotion/styled'
import { memo } from 'react'

type Props = {
  color: string
  children: React.ReactNode
}

const DotWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  line-height: 1rem;

  span {
    color: #aaa;
    margin-inline-start: 5px;
  }
`

const Dot = styled.span<{ background: string }>`
  border-radius: 100%;
  padding: 4px;
  margin-inline-end: 0.5em;
  background-color: ${props => props.background};
`

const DotText = memo(({ color, children }: Props) => (
  <DotWrapper>
    <Dot background={color} />
    {children}
  </DotWrapper>
))

export default DotText
