import { Country } from './enums'

// https://en.wikipedia.org/wiki/List_of_country_calling_codes
// made with GitHub Copilot help :)
export const PHONE_PREFIXES: Record<Country, string> = {
  [Country.Ad]: '+376',
  [Country.Ae]: '+971',
  [Country.Af]: '+93',
  [Country.Ag]: '+1-268',
  [Country.Ai]: '+1-264',
  [Country.Al]: '+355',
  [Country.Am]: '+374',
  [Country.Ao]: '+244',
  [Country.Aq]: '+672',
  [Country.Ar]: '+54',
  [Country.As]: '+1-684',
  [Country.At]: '+43',
  [Country.Au]: '+61',
  [Country.Aw]: '+297',
  [Country.Ax]: '+358-18',
  [Country.Az]: '+994',
  [Country.Ba]: '+387',
  [Country.Bb]: '+1-246',
  [Country.Bd]: '+880',
  [Country.Be]: '+32',
  [Country.Bf]: '+226',
  [Country.Bg]: '+359',
  [Country.Bh]: '+973',
  [Country.Bi]: '+257',
  [Country.Bj]: '+229',
  [Country.Bl]: '+590',
  [Country.Bm]: '+1-441',
  [Country.Bn]: '+673',
  [Country.Bo]: '+591',
  [Country.Br]: '+55',
  [Country.Bs]: '+1-242',
  [Country.Bt]: '+975',
  [Country.Bw]: '+267',
  [Country.By]: '+375',
  [Country.Bz]: '+501',
  [Country.Ca]: '+1',
  [Country.Cc]: '+61',
  [Country.Cd]: '+243',
  [Country.Cf]: '+236',
  [Country.Cg]: '+242',
  [Country.Ch]: '+41',
  [Country.Ci]: '+225',
  [Country.Ck]: '+682',
  [Country.Cl]: '+56',
  [Country.Cm]: '+237',
  [Country.Cn]: '+86',
  [Country.Co]: '+57',
  [Country.Cr]: '+506',
  [Country.Cu]: '+53',
  [Country.Cv]: '+238',
  [Country.Cw]: '+599',
  [Country.Cx]: '+61',
  [Country.Cy]: '+357',
  [Country.Cz]: '+420',
  [Country.De]: '+49',
  [Country.Dj]: '+253',
  [Country.Dk]: '+45',
  [Country.Dm]: '+1-767',
  [Country.Do]: '+1-809',
  [Country.Dz]: '+213',
  [Country.Ec]: '+593',
  [Country.Ee]: '+372',
  [Country.Eg]: '+20',
  [Country.Eh]: '+212',
  [Country.Er]: '+291',
  [Country.Es]: '+34',
  [Country.Et]: '+251',
  [Country.Fi]: '+358',
  [Country.Fj]: '+679',
  [Country.Fk]: '+500',
  [Country.Fm]: '+691',
  [Country.Fo]: '+298',
  [Country.Fr]: '+33',
  [Country.Ga]: '+241',
  [Country.Gb]: '+44',
  [Country.Gd]: '+1-473',
  [Country.Ge]: '+995',
  [Country.Gf]: '+594',
  [Country.Gg]: '+44',
  [Country.Gh]: '+233',
  [Country.Gi]: '+350',
  [Country.Gl]: '+299',
  [Country.Gm]: '+220',
  [Country.Gn]: '+224',
  [Country.Gp]: '+590',
  [Country.Gq]: '+240',
  [Country.Gr]: '+30',
  [Country.Gs]: '+500',
  [Country.Gt]: '+502',
  [Country.Gu]: '+1-671',
  [Country.Gw]: '+245',
  [Country.Gy]: '+592',
  [Country.Hk]: '+852',
  [Country.Hm]: '+672',
  [Country.Hn]: '+504',
  [Country.Hr]: '+385',
  [Country.Ht]: '+509',
  [Country.Hu]: '+36',
  [Country.Id]: '+62',
  [Country.Ie]: '+353',
  [Country.Il]: '+972',
  [Country.Im]: '+44',
  [Country.In]: '+91',
  [Country.Io]: '+246',
  [Country.Iq]: '+964',
  [Country.Ir]: '+98',
  [Country.Is]: '+354',
  [Country.It]: '+39',
  [Country.Je]: '+44',
  [Country.Jm]: '+1-876',
  [Country.Jo]: '+962',
  [Country.Jp]: '+81',
  [Country.Ke]: '+254',
  [Country.Kg]: '+996',
  [Country.Kh]: '+855',
  [Country.Ki]: '+686',
  [Country.Km]: '+269',
  [Country.Kn]: '+1-869',
  [Country.Kp]: '+850',
  [Country.Kr]: '+82',
  [Country.Kw]: '+965',
  [Country.Ky]: '+1-345',
  [Country.Kz]: '+7',
  [Country.La]: '+856',
  [Country.Lb]: '+961',
  [Country.Lc]: '+1-758',
  [Country.Li]: '+423',
  [Country.Lk]: '+94',
  [Country.Lr]: '+231',
  [Country.Ls]: '+266',
  [Country.Lt]: '+370',
  [Country.Lu]: '+352',
  [Country.Lv]: '+371',
  [Country.Ly]: '+218',
  [Country.Ma]: '+212',
  [Country.Mc]: '+377',
  [Country.Md]: '+373',
  [Country.Me]: '+382',
  [Country.Mf]: '+590',
  [Country.Mg]: '+261',
  [Country.Mh]: '+692',
  [Country.Mk]: '+389',
  [Country.Ml]: '+223',
  [Country.Mm]: '+95',
  [Country.Mn]: '+976',
  [Country.Mo]: '+853',
  [Country.Mp]: '+1-670',
  [Country.Mq]: '+596',
  [Country.Mr]: '+222',
  [Country.Ms]: '+1-664',
  [Country.Mt]: '+356',
  [Country.Mu]: '+230',
  [Country.Mv]: '+960',
  [Country.Mw]: '+265',
  [Country.Mx]: '+52',
  [Country.My]: '+60',
  [Country.Mz]: '+258',
  [Country.Na]: '+264',
  [Country.Nc]: '+687',
  [Country.Ne]: '+227',
  [Country.Nf]: '+672',
  [Country.Ng]: '+234',
  [Country.Ni]: '+505',
  [Country.Nl]: '+31',
  [Country.No]: '+47',
  [Country.Np]: '+977',
  [Country.Nr]: '+674',
  [Country.Nu]: '+683',
  [Country.Nz]: '+64',
  [Country.Om]: '+968',
  [Country.Pa]: '+507',
  [Country.Pe]: '+51',
  [Country.Pf]: '+689',
  [Country.Pg]: '+675',
  [Country.Ph]: '+63',
  [Country.Pk]: '+92',
  [Country.Pl]: '+48',
  [Country.Pm]: '+508',
  [Country.Pn]: '+870',
  [Country.Pr]: '+1-787',
  [Country.Ps]: '+970',
  [Country.Pt]: '+351',
  [Country.Pw]: '+680',
  [Country.Py]: '+595',
  [Country.Qa]: '+974',
  [Country.Re]: '+262',
  [Country.Ro]: '+40',
  [Country.Rs]: '+381',
  [Country.Ru]: '+7',
  [Country.Rw]: '+250',
  [Country.Sa]: '+966',
  [Country.Sb]: '+677',
  [Country.Sc]: '+248',
  [Country.Sd]: '+249',
  [Country.Se]: '+46',
  [Country.Sg]: '+65',
  [Country.Sh]: '+290',
  [Country.Si]: '+386',
  [Country.Sj]: '+47',
  [Country.Sk]: '+421',
  [Country.Sl]: '+232',
  [Country.Sm]: '+378',
  [Country.Sn]: '+221',
  [Country.So]: '+252',
  [Country.Sr]: '+597',
  [Country.St]: '+239',
  [Country.Sv]: '+503',
  [Country.Sy]: '+963',
  [Country.Sz]: '+268',
  [Country.Tc]: '+1-649',
  [Country.Td]: '+235',
  [Country.Tf]: '+262',
  [Country.Tg]: '+228',
  [Country.Th]: '+66',
  [Country.Tj]: '+992',
  [Country.Tk]: '+690',
  [Country.Tl]: '+670',
  [Country.Tm]: '+993',
  [Country.Tn]: '+216',
  [Country.To]: '+676',
  [Country.Tr]: '+90',
  [Country.Tt]: '+1-868',
  [Country.Tv]: '+688',
  [Country.Tw]: '+886',
  [Country.Tz]: '+255',
  [Country.Ua]: '+380',
  [Country.Ug]: '+256',
  [Country.Um]: '+1',
  [Country.Us]: '+1',
  [Country.Uy]: '+598',
  [Country.Uz]: '+998',
  [Country.Va]: '+379',
  [Country.Vc]: '+1-784',
  [Country.Ve]: '+58',
  [Country.Vg]: '+1-284',
  [Country.Vi]: '+1-340',
  [Country.Vn]: '+84',
  [Country.Vu]: '+678',
  [Country.Wf]: '+681',
  [Country.Ws]: '+685',
  [Country.Ye]: '+967',
  [Country.Yt]: '+262',
  [Country.Za]: '+27',
  [Country.Zm]: '+260',
  [Country.Zw]: '+263',
  [Country.Bq]: '+599',
  [Country.Bv]: '+47',
  [Country.Ss]: '+211',
  [Country.Sx]: '+1-721',
}
