import { gql, useQuery } from '@apollo/client'
import type { Country } from '@faceup/utils'
import { type ReactNode, createContext } from 'react'
import type { GeoData } from '../__generated__/globalTypes'

const query = {
  GeoData: gql`
    query GeoData {
      userLocationByGeoIp {
        callingCode
        country
      }
    }
  `,
}

type GeoDataContextProps = {
  loading: boolean
  callingCode: string | null
  country: Country | null
}

type GeoDataProviderProps = { children: ReactNode }

export const GeoDataContext = createContext<GeoDataContextProps>({
  loading: true,
  callingCode: null,
  country: null,
})

const GeoDataProvider = ({ children }: GeoDataProviderProps) => {
  const { data, loading } = useQuery<GeoData | null>(query.GeoData)

  const userLocationByGeoIp = data?.userLocationByGeoIp
  const callingCode = userLocationByGeoIp?.callingCode ?? null
  const country = userLocationByGeoIp?.country ?? null

  return (
    <GeoDataContext.Provider
      value={{
        callingCode,
        country,
        loading,
      }}
    >
      {children}
    </GeoDataContext.Provider>
  )
}

export default GeoDataProvider
