import { UntitledIcon, type UntitledIconData } from '@faceup/icons'
import { ulArrowLeft } from '@faceup/icons/ulArrowLeft'
import { Link, type RouteCallback } from '@faceup/router'
import { Col, Row, Typography } from '@faceup/ui-base'
import { Box, Group, Stack, UnstyledButton, useMantineTheme } from '@mantine/core'
import type { ReactNode } from 'react'
import { BaseComponent } from '../CoreComponents'
import { HintIcon } from './HintIcon'

type ContentHeaderProps = {
  title: ReactNode
  titleHint?: string
  description?: ReactNode
  centerContent?: ReactNode
  secondaryContent?: ReactNode
  arrowGoBackTo?: RouteCallback
  action?: {
    icon: UntitledIconData
    onAction: () => void
  }
}

export const ContentHeader = ({
  title,
  titleHint,
  description,
  centerContent,
  secondaryContent,
  arrowGoBackTo,
  action,
}: ContentHeaderProps) => {
  const theme = useMantineTheme()

  return (
    <Stack spacing='20px'>
      <Group position='apart' noWrap sx={{ alignItems: 'flex-start' }}>
        <Group noWrap spacing='sm' style={{ minWidth: 0 }}>
          {arrowGoBackTo && (
            <UnstyledButton
              to={arrowGoBackTo}
              component={Link}
              sx={{
                fontSize: 24,
              }}
            >
              <UntitledIcon icon={ulArrowLeft} />
            </UnstyledButton>
          )}
          <Typography.Title ellipsis level={3}>
            {title}
          </Typography.Title>
          {action && (
            <UnstyledButton onClick={action.onAction}>
              <UntitledIcon
                icon={action.icon}
                size={18}
                color={theme.colors['prussianBlue']?.[5]}
              />
            </UnstyledButton>
          )}
          {titleHint && <HintIcon title={titleHint} variant='heading' />}
        </Group>
        {centerContent && <Box>{centerContent}</Box>}
        {secondaryContent && (
          <Box
            sx={{
              flexGrow: centerContent ? undefined : 1,
            }}
          >
            {secondaryContent}
          </Box>
        )}
      </Group>
      {description && (
        <Row>
          <Col span={24}>
            <BaseComponent>{description}</BaseComponent>
          </Col>
        </Row>
      )}
    </Stack>
  )
}
