import { Link } from '@faceup/router'
import { Button } from '@faceup/ui'
import { Result } from '@faceup/ui-base'

const RouteNotFound = () => (
  <Result
    status='404'
    title='404'
    subTitle='Page not found'
    extra={
      <Link to={routes => routes.home()}>
        <Button>Go back to dashboard</Button>
      </Link>
    }
  />
)

export default RouteNotFound
