import { type ReactNode, createContext, useContext } from 'react'
import type { LocaleObject } from 'yup'
import type { SubmitButtonText } from './Form/useFormLogic'
import { yup } from './index'

type FormLocalizationProviderContextProps = {
  submitButton: Record<SubmitButtonText, string>
  successMessage: Record<SubmitButtonText, string>
  modalForm: {
    cancelButton: string
  }
  unsavedChanges: (count: number) => string
  leaveUnsavedChanges: string
  yup: LocaleObject
}

type FormLocalizationProviderProps = {
  children: ReactNode
} & FormLocalizationProviderContextProps

export const FormLocalizationContext = createContext<FormLocalizationProviderContextProps>({
  submitButton: {
    save: 'Save',
    send: 'Send',
    add: 'Add',
  },
  successMessage: {
    save: 'Successfully saved',
    send: 'Successfully sent',
    add: 'Successfully added',
  },
  modalForm: {
    cancelButton: 'Cancel',
  },
  unsavedChanges: (count: number) => `You have ${count} unsaved changes`,
  leaveUnsavedChanges: 'You have unsaved changes. Are you sure you want to leave?',
  yup: {},
})

export const FormLocalizationProvider = ({ children, ...props }: FormLocalizationProviderProps) => {
  props.yup && yup.setLocale(props.yup)
  return (
    <FormLocalizationContext.Provider value={props}>{children}</FormLocalizationContext.Provider>
  )
}

export const useFormLocalization = () => useContext(FormLocalizationContext)
