import {
  Language,
  convertLanguageToBCP47Language,
  convertLanguageToNativeLanguage,
  translateLanguageName,
} from '@faceup/utils'
import { useLocalization } from '../contexts'

type LanguageObject = {
  language: Language
  name: string
}

export const useLanguageName = (areLanguagesNative = false) => {
  const localization = useLocalization()
  const getLanguageName = (language: Language): string => {
    const getName = () => {
      if (areLanguagesNative) {
        return convertLanguageToNativeLanguage(language)
      }
      return (
        translateLanguageName(language, convertLanguageToBCP47Language(localization.language)) ?? ''
      )
    }

    const languageName = getName()
    return languageName.charAt(0).toUpperCase() + languageName.slice(1)
  }

  const getLanguageList = (specificLanguages?: Language[]): LanguageObject[] => {
    const filteredLanguages = Object.values(Language).filter(language =>
      specificLanguages === undefined ? true : specificLanguages.includes(language)
    )
    const sortedLanguages = filteredLanguages.sort((a, b) =>
      getLanguageName(a).localeCompare(getLanguageName(b))
    )
    return sortedLanguages.map(language => ({ language, name: getLanguageName(language) }))
  }

  return {
    getLanguageName,
    getLanguageList,
  }
}
