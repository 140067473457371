import type { UntitledIconData } from '@faceup/icons'

export const ucClipboard: UntitledIconData = {
  prefix: 'custom',
  name: 'ucClipboard',
  viewBox: '0 0 20 20',
  paths: [
    {
      shape:
        'M13.3335 3.33334C14.1085 3.33334 14.496 3.33334 14.8139 3.41852C15.6766 3.64969 16.3505 4.32356 16.5816 5.18629C16.6668 5.50421 16.6668 5.8917 16.6668 6.66667V14.3333C16.6668 15.7335 16.6668 16.4335 16.3943 16.9683C16.1547 17.4387 15.7722 17.8212 15.3018 18.0609C14.767 18.3333 14.067 18.3333 12.6668 18.3333H7.3335C5.93336 18.3333 5.2333 18.3333 4.69852 18.0609C4.22811 17.8212 3.84566 17.4387 3.60598 16.9683C3.3335 16.4335 3.3335 15.7335 3.3335 14.3333V6.66667C3.3335 5.8917 3.3335 5.50421 3.41868 5.18629C3.64985 4.32356 4.32372 3.64969 5.18645 3.41852C5.50436 3.33334 5.89185 3.33334 6.66683 3.33334M11.6668 12.5H6.66683M13.3335 9.16667H6.66683M8.00016 5.00001H12.0002C12.4669 5.00001 12.7002 5.00001 12.8785 4.90918C13.0353 4.82928 13.1628 4.7018 13.2427 4.545C13.3335 4.36674 13.3335 4.13338 13.3335 3.66667V3.00001C13.3335 2.53329 13.3335 2.29994 13.2427 2.12168C13.1628 1.96488 13.0353 1.83739 12.8785 1.7575C12.7002 1.66667 12.4669 1.66667 12.0002 1.66667H8.00016C7.53345 1.66667 7.3001 1.66667 7.12184 1.7575C6.96504 1.83739 6.83755 1.96488 6.75766 2.12168C6.66683 2.29994 6.66683 2.53329 6.66683 3.00001V3.66667C6.66683 4.13338 6.66683 4.36674 6.75766 4.545C6.83755 4.7018 6.96504 4.82928 7.12184 4.90918C7.3001 5.00001 7.53345 5.00001 8.00016 5.00001Z',
      stroke: 'currentColor',
      strokeWidth: '1.25',
      strokeLinecap: 'round',
      strokeLinejoin: 'round',
    },
  ],
}
