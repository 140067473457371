import { FormItem } from '@faceup/ui'
import { Select as AntSelect, type SelectProps as AntSelectProps } from '@faceup/ui-base'
import type { FieldValues, UseControllerProps } from 'react-hook-form'
import { useCustomController } from '../hooks'
import type { FormItemWrapperProps } from '../typings'

export type SelectProps<T extends FieldValues> = UseControllerProps<T> &
  Omit<AntSelectProps & FormItemWrapperProps, 'value' | 'defaultValue'>

export const Select = <T extends FieldValues>(props: SelectProps<T>) => {
  const {
    formItemProps,
    inputProps: { onChange: fieldOnChange, value, ...inputProps },
  } = useCustomController(props)

  return (
    <FormItem {...formItemProps}>
      <AntSelect
        {...inputProps}
        styles={{
          dropdown: {
            zIndex: '1100!important',
          },
        }}
        value={value ?? null}
        onChange={(value, option) => {
          fieldOnChange(value ?? null)
          props.onChange?.(value, option)
        }}
      />
    </FormItem>
  )
}
