import { gql } from '@apollo/client'
import { useLocalization } from '@faceup/localization'
import { FormItemType, type Language, getTranslation } from '@faceup/utils'
import moment from 'moment-timezone'
import type { useFormItemsAnswers_companyReport } from '../__generated__/globalTypes'
import type { FormItemAnswer } from '../components'

export const useFormItemsAnswersFragments = {
  useFormItemsAnswers_companyReport: gql`
    fragment useFormItemsAnswers_companyReport on CompanyReport {
      id
      answers {
        id
        values
        translatedValues: values(sourceLanguage: $sourceLanguage, targetLanguage: $targetLanguage)
        formItem {
          id
          formItemId
          type
          labelTranslations {
            language
            translation
          }
          options(includeDeleted: true) {
            id
            labelTranslations(sourceLanguage: $sourceLanguage, targetLanguage: $targetLanguage) {
              language
              translation
            }
          }
        }
      }
    }
  `,
}

export const useFormItemsAnswers = () => {
  const { language } = useLocalization()
  return (
    answers: NonNullable<useFormItemsAnswers_companyReport['answers']>,
    defaultLanguage?: Language
  ): FormItemAnswer[] =>
    [...answers].map(answer => {
      const getFormItemAnswerWithCustomValue = ({
        answer,
        value,
      }: {
        answer: NonNullable<useFormItemsAnswers_companyReport['answers']>[number]
        value: string
      }) => ({
        id: answer.id,
        formItemId: answer.formItem.formItemId,
        type: answer.formItem.type,
        label: getTranslation(answer.formItem.labelTranslations, language, defaultLanguage),
        value,
      })
      const getSelectFormItemAnswerWithCustomValue = () => {
        const optionTranslations =
          answer.formItem.options.find(option => option.id === answer.values[0])
            ?.labelTranslations ?? []

        const optionTranslationValue = getTranslation(optionTranslations, language, defaultLanguage)

        return getFormItemAnswerWithCustomValue({
          answer,
          value: optionTranslationValue,
        })
      }

      const getMultiSelectFormItemAnswerWithCustomValue = () => {
        const translatedMultiValues = answer.translatedValues ?? []
        const translatedOptions = answer.formItem.options.map(option => ({
          id: option.id,
          label: getTranslation(option.labelTranslations, language, defaultLanguage),
        }))
        const selectedOptions = translatedOptions.filter(option =>
          translatedMultiValues.includes(option.id)
        )
        return getFormItemAnswerWithCustomValue({
          answer,
          value: selectedOptions.map(option => option.label).join(', '),
        })
      }
      const getFormItemAnswerWithSimpleValue = ({
        answer,
      }: {
        answer: NonNullable<useFormItemsAnswers_companyReport['answers']>[number]
      }) => getFormItemAnswerWithCustomValue({ answer, value: answer.translatedValues[0] ?? '' })
      const formItems: Record<FormItemType, () => FormItemAnswer> = {
        [FormItemType.Select]: getSelectFormItemAnswerWithCustomValue,
        [FormItemType.MultiSelect]: getMultiSelectFormItemAnswerWithCustomValue,
        [FormItemType.SimpleText]: () => getFormItemAnswerWithSimpleValue({ answer }),
        [FormItemType.MultilineText]: () => getFormItemAnswerWithSimpleValue({ answer }),
        [FormItemType.Date]: () =>
          getFormItemAnswerWithCustomValue({ answer, value: moment(answer.values[0]).format('L') }),
        [FormItemType.MoreInformation]: () => getFormItemAnswerWithSimpleValue({ answer }),
        [FormItemType.Classroom]: () => getFormItemAnswerWithSimpleValue({ answer }),
        [FormItemType.OrganizationalUnit]: () => getFormItemAnswerWithSimpleValue({ answer }),
        [FormItemType.SenderName]: () => getFormItemAnswerWithSimpleValue({ answer }),
        [FormItemType.Email]: () => getFormItemAnswerWithSimpleValue({ answer }),
        [FormItemType.PhoneNumber]: () => getFormItemAnswerWithSimpleValue({ answer }),
        [FormItemType.Category]: () => getFormItemAnswerWithSimpleValue({ answer }),
      }

      return formItems[answer.formItem.type]()
    })
}
