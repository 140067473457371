import { Box, Stack } from '@mantine/core'
import type { UseFormReturnType } from '@mantine/form'
import { type ReactNode, useContext, useState } from 'react'
import { Button } from '../Button'
import { UiContext } from '../UiProvider/UiProvider'
import { ButtonGroup } from './ButtonGroup'
import { FormItem } from './FormItem'

export const submitButtonTextDefiniton = ['save', 'send', 'add'] as const
export type SubmitButtonText = (typeof submitButtonTextDefiniton)[number]

type FormOnSubmitProps = {
  onSubmit?: () => void
  form?: never
  additionalButtons?: never
  submitButtonText?: never
  customSubmitButtonText?: never
}

type FormHookProps<
  Values = Record<string, unknown>,
  TransformValues extends (values: Values) => unknown = (values: Values) => Values,
> = {
  onSubmit: (values: ReturnType<TransformValues>) => Promise<boolean> | boolean
  form: UseFormReturnType<Values, TransformValues>
  additionalButtons?: ReactNode
} & (
  | { submitButtonText?: SubmitButtonText; customSubmitButtonText?: never }
  | { submitButtonText?: never; customSubmitButtonText?: ReactNode }
)

type FormProps<
  Values = Record<string, unknown>,
  TransformValues extends (values: Values) => unknown = (values: Values) => Values,
> = {
  children: ReactNode
} & (FormOnSubmitProps | FormHookProps<Values, TransformValues>)

export const Form = <
  Values = Record<string, unknown>,
  TransformValues extends (values: Values) => unknown = (values: Values) => Values,
>(
  props: FormProps<Values, TransformValues>
) => {
  const {
    children,
    onSubmit,
    form,
    submitButtonText = 'send',
    customSubmitButtonText,
    additionalButtons,
    ...otherProps
  } = props
  const { form: contextForm } = useContext(UiContext)
  const [isLoading, setIsLoading] = useState(false)
  return (
    <Box
      {...otherProps}
      component='form'
      onSubmit={e => {
        e.preventDefault()
        if (form) {
          form.onSubmit(async values => {
            setIsLoading(true)
            const result = await onSubmit(values)
            if (result) {
              form.resetDirty()
              form.resetTouched()
            }
            setIsLoading(false)
          })()
        } else {
          onSubmit?.()
        }
      }}
      sx={{
        width: '100%',
      }}
    >
      <Stack spacing='0.25rem'>
        {children}
        {form && (
          <Form.ButtonGroup>
            {additionalButtons}
            <Button type='submit' loading={isLoading} disabled={!form.isDirty()}>
              {customSubmitButtonText ??
                contextForm?.submitButton[submitButtonText] ??
                submitButtonText}
            </Button>
          </Form.ButtonGroup>
        )}
      </Stack>
    </Box>
  )
}

Form.Item = FormItem
Form.ButtonGroup = ButtonGroup
