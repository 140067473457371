export const OPSLIMIT = 3
export const MEMLIMIT = 20480000
export const FILE_CHUNK_SIZE = 40960
export const FILE_CHUNK_ENCRYPTED_SIZE = 40960 + 17 // sodium.crypto_secretstream_xchacha20poly1305_ABYTES
export const FILE_ENCRYPTION_CONTEXT = 'Attchmnt'
export const FILE_CHUNK_SUBKEY_ID_LEN = 1
export const RECOVERY_FILE_NAME = 'FaceUpRecoveryKey.txt'
export const CURRENT_ENCRYPTION_VERSION = 2 as const
export const RECOVERY_KEY_LENGTH = 16
export const RECOVERY_KEY_CONTEXT = 'Recovery'
