import { Button } from '@faceup/ui'
import { Form, Input, SettingOutlined, Space, Tabs } from '@faceup/ui-base'
import { useState } from 'react'
import type { Language } from '../../__generated__/globalTypes'
import EditServiceLanguageModal from './EditServiceLanguagesModal'
import ServiceCategoriesEditorTable from './ServiceCategoriesEditorTable'

const { TextArea } = Input

type ServiceTranslation = {
  language: Language
  description: { error: boolean; value: string }
  categories: string[]
}

type Props = {
  translations: ServiceTranslation[]
  defaultLanguage: Language
  setTranslations: (translations: ServiceTranslation[]) => void
  setDefaultLanguage: (language: Language) => void
}

const ServiceTranslationsTab = (props: Props) => {
  const [activeTab, setActiveTab] = useState(props.defaultLanguage)
  const [modalVisible, setModalVisible] = useState(false)

  const items = props.translations
    .sort(t => Number(t.language === props.defaultLanguage))
    .map((translation, index) => ({
      key: translation.language,
      label: translation.language,
      children: (
        <>
          <Form.Item
            label='Description'
            {...(translation.description.error && {
              validateStatus: 'error',
              help: 'Could not be empty',
            })}
          >
            <TextArea
              value={translation.description.value}
              rows={5}
              onChange={({ target: { value } }) => {
                // @ts-expect-error Migrating to strict mode, fix this
                props.translations[index].description = { value, error: false }
                props.setTranslations([...props.translations])
              }}
            />
          </Form.Item>

          <Form.Item label='Categories'>
            <ServiceCategoriesEditorTable
              categories={translation.categories}
              setCategories={categories => {
                const tempTranslations = props.translations
                // @ts-expect-error Migrating to strict mode, fix this
                tempTranslations[index].categories = categories
                props.setTranslations([...tempTranslations])
              }}
            />
          </Form.Item>
        </>
      ),
    }))

  return (
    <>
      <Tabs
        activeKey={String(activeTab)}
        onChange={tab => {
          setActiveTab(tab as Language)
        }}
        tabBarExtraContent={
          <Button variant='secondary' onClick={() => setModalVisible(true)}>
            <Space>
              <SettingOutlined />
              Configure languages
            </Space>
          </Button>
        }
        items={items}
      />
      <EditServiceLanguageModal
        visible={modalVisible}
        languages={props.translations.map(translation => translation.language)}
        defaultLanguage={props.defaultLanguage}
        setVisible={setModalVisible}
        setDefaultLanguage={props.setDefaultLanguage}
        removeLanguage={language => {
          props.setTranslations(
            props.translations.filter(translation => translation.language !== language)
          )
          setActiveTab(props.defaultLanguage)
        }}
        addLanguage={language =>
          props.setTranslations([
            ...props.translations,
            {
              language,
              description: {
                error: false,
                value: '',
              },
              categories: [],
            },
          ])
        }
      />
    </>
  )
}

export default ServiceTranslationsTab
