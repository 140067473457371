import { FormItem, Switch as UiSwitch, type SwitchProps as UiSwitchProps } from '@faceup/ui'
import type { FieldValues, UseControllerProps } from 'react-hook-form'
import { useCustomController } from '../hooks'
import type { FormItemWrapperProps } from '../typings'

type SwitchProps<T extends FieldValues> = UseControllerProps<T> &
  Omit<UiSwitchProps & FormItemWrapperProps, 'value' | 'checked' | 'defaultValue'>
export const Switch = <T extends FieldValues>(props: SwitchProps<T>) => {
  const {
    formItemProps,
    inputProps: { value, onChange: fieldOnChange, ...inputProps },
  } = useCustomController(props)

  return (
    <FormItem {...formItemProps}>
      <UiSwitch
        {...inputProps}
        value={value}
        checked={value}
        onChange={e => {
          fieldOnChange(e)
          props.onChange?.(e)
        }}
      />
    </FormItem>
  )
}
