import { Text } from '@faceup/ui'
import { Radio as MantineRadio, type RadioProps as MantineRadioProps } from '@mantine/core'
import type { RadioGroupProps as MantineRadioGroupProps } from '@mantine/core/lib/Radio/RadioGroup/RadioGroup'
import type { FieldValues, UseControllerProps } from 'react-hook-form'
import { useCustomController } from '../hooks'
import type { FormItemWrapperProps } from '../typings'

type RadioProps<T extends FieldValues> = UseControllerProps<T> &
  Omit<MantineRadioProps & FormItemWrapperProps, 'value' | 'checked' | 'defaultValue'>
export const Radio = <T extends FieldValues>(props: RadioProps<T>) => {
  const {
    formItemProps,
    inputProps: { onChange: fieldOnChange, ...inputProps },
  } = useCustomController(props)

  return (
    <MantineRadio
      {...formItemProps}
      {...inputProps}
      label={<Text color='text'>{formItemProps.label}</Text>}
      onChange={e => {
        fieldOnChange(e)
        props.onChange?.(e)
      }}
    />
  )
}

type RadioGroupProps<T extends FieldValues> = UseControllerProps<T> &
  Omit<MantineRadioGroupProps & FormItemWrapperProps, 'value' | 'defaultValue'>
export const RadioGroup = <T extends FieldValues>(props: RadioGroupProps<T>) => {
  const {
    formItemProps,
    inputProps: { onChange: fieldOnChange, ...inputProps },
  } = useCustomController(props)

  return (
    <MantineRadio.Group
      {...formItemProps}
      {...inputProps}
      label={<Text color='text'>{formItemProps.label}</Text>}
      onChange={e => {
        fieldOnChange(e)
        props.onChange?.(e)
      }}
    >
      {inputProps.children}
    </MantineRadio.Group>
  )
}

Radio.Group = RadioGroup
Radio.Item = MantineRadio
