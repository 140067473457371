import { createPolymorphicComponent } from '@mantine/core'
import { type ReactNode, forwardRef } from 'react'
import type { Color } from '../hooks'
import {
  BaseComponent,
  type BaseComponentProps,
  type FontFamily,
  type FontSize,
  type FontWeight,
  type SxSimplest,
  type TextAlign,
} from './BaseComponent'

export const size = ['small', 'medium', 'large'] as const
type Size = (typeof size)[number]

const fontWeightNormal = ['normal'] as const
type FontWeightNormal = (typeof fontWeightNormal)[number]
export const fontWeight = [...fontWeightNormal, 'bold'] as const
type FontWeightVariant = (typeof fontWeight)[number]

const noFontWeightVariants = ['display', 'headline', 'title', 'label'] as const
type NoFontWeightVariant = (typeof noFontWeightVariants)[number]

type NoFontWeightTextProps = {
  variant?: NoFontWeightVariant
  weight?: FontWeightNormal
}

const withFontWeightVariants = ['body'] as const
type WithFontWeightVariant = (typeof withFontWeightVariants)[number]

export const variants = [...noFontWeightVariants, ...withFontWeightVariants] as const
type Variant = (typeof variants)[number]

type WithFontWeightTextProps = {
  variant?: WithFontWeightVariant
  weight?: FontWeightVariant
}

type TextProps = {
  children?: ReactNode
  color?: Color
  size?: Size
  align?: TextAlign
} & (NoFontWeightTextProps | WithFontWeightTextProps) &
  BaseComponentProps

const fontFamilies: Record<Variant, FontFamily> = {
  display: 'Inter, sans-serif',
  headline: 'Inter, sans-serif',
  title: 'Inter, sans-serif',
  label: 'Inter, sans-serif',
  body: 'Inter, sans-serif',
}

const fontSizes: Record<Variant, Record<Size, FontSize>> = {
  display: {
    small: '40px',
    medium: '48px',
    large: '56px',
  },
  headline: {
    small: '24px',
    medium: '28px',
    large: '32px',
  },
  title: {
    small: '14px',
    medium: '16px',
    large: '20px',
  },
  label: {
    small: '12px',
    medium: '14px',
    large: '16px',
  },
  body: {
    small: '12px',
    medium: '14px',
    large: '16px',
  },
}

const fontWeights: Record<NoFontWeightVariant, FontWeight> &
  Record<WithFontWeightVariant, Record<FontWeightVariant, FontWeight>> = {
  display: 600,
  headline: 600,
  title: 600,
  label: 600,
  body: {
    normal: 400,
    bold: 600,
  },
}

type FnGetTextSx = (
  props: Required<Pick<TextProps, 'weight' | 'variant' | 'size'>> & Pick<TextProps, 'align'>
) => SxSimplest
const getTextSx: FnGetTextSx = ({ variant, size, weight, align }) => {
  const hasFontWeightVariants = withFontWeightVariants.includes(variant as WithFontWeightVariant)
  return {
    fontFamily: fontFamilies[variant],
    fontSize: fontSizes[variant][size],
    fontWeight: hasFontWeightVariants
      ? fontWeights[variant as WithFontWeightVariant][weight]
      : (fontWeights[variant] as FontWeight),
    textAlign: align,
  }
}

const _Text = forwardRef<HTMLDivElement, TextProps>((_props, ref) => {
  const {
    children,
    color,
    weight = 'normal',
    variant = 'body',
    size = 'medium',
    align,
    sx,
    ...props
  } = _props

  return (
    <BaseComponent
      ref={ref}
      {...props}
      sx={({ getColorFromTheme, mergeSx }) =>
        mergeSx(sx, {
          ...getTextSx({
            variant,
            size,
            weight,
            align,
          }),
          color: color ? getColorFromTheme(color) : undefined,
        })
      }
    >
      {children}
    </BaseComponent>
  )
})

_Text.displayName = 'Text'

export const Text = createPolymorphicComponent<'div', TextProps>(_Text)
