import styled from '@emotion/styled'
import { DeleteOutlined, FileOutlined, LoadingOutlined, Space } from '@faceup/ui-base'
import { Tooltip } from '../Tooltip'

export type Attachment = {
  file: File
  compareKey: string
  loading: boolean
  processed: boolean
}

type Props = {
  translations: {
    metadataRemoved: string
    metadataUnableToRemove: string
  }
  attachments: Attachment[]
  setAttachments: (attachments: Attachment[]) => void
}

const AttachmentUploadList = (props: Props) => (
  <FilesWrapper size='middle'>
    {props.attachments.map((attachment, index) => (
      <Tooltip
        placement='top'
        key={attachment.file.size}
        open={attachment.loading ? false : undefined}
        title={
          attachment.processed
            ? props.translations.metadataRemoved
            : props.translations.metadataUnableToRemove
        }
      >
        <Space
          size='small'
          // style is inline because of forwarding ref from <Tooltip /> (throwing error in console)
          style={{
            transition: 'border .3s',
            border: `solid 1px ${
              !attachment.processed && !attachment.loading ? '#ff4d4f' : 'silver'
            }`,
            borderRadius: 5,
            padding: 10,
            margin: '5px 0',
          }}
        >
          {attachment.loading ? <LoadingOutlined /> : <FileOutlined />}
          <FileName>{attachment.file.name}</FileName>
          <DeleteOutlined
            onClick={() => {
              if (!props.attachments.find(a => a.loading)) {
                props.setAttachments(props.attachments.filter((_, i) => i !== index))
              }
            }}
            style={{
              transition: 'color .3s',
              ...((attachment.loading || Boolean(props.attachments.find(a => a.loading))) && {
                cursor: 'not-allowed',
                color: '#688699',
              }),
            }}
          />
        </Space>
      </Tooltip>
    ))}
  </FilesWrapper>
)

const FilesWrapper = styled(Space)`
  flex-wrap: wrap;
`

const FileName = styled.div`
  max-width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

export default AttachmentUploadList
