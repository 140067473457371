import { useMotherId } from '@faceup/institution'
import { useContext } from 'react'
import { UserContext } from '../providers'

export const usePartner = () => {
  const { viewer } = useContext(UserContext)
  const { getMotherIdWithNull } = useMotherId()
  const isPartner = viewer?.isPartner === true
  const isPartnerAdministration = isPartner && !getMotherIdWithNull()

  return {
    isPartnerAdministration,
    isPartner,
    partnerId: viewer?.partner?.id ?? null,
  }
}
