import styled from '@emotion/styled'
import type { UntitledIconData } from '@faceup/icons'
import {
  Route,
  type RouteCallback,
  type RouteProps,
  RouterRoutes,
  useGetRoute,
  useLocation,
  useNavigate,
} from '@faceup/router'
import { Tabs, type TabsProps } from '@faceup/ui-base'
import { Box, type BoxProps } from '@mantine/core'
import { Fragment, type ReactNode } from 'react'
import { useThemeColors } from '../hooks'
import { ContentHeader } from './ContentHeader'

const getRelativePathFromAbsolute = (path: string) => {
  const splittedPath = path.split('/')

  return splittedPath[splittedPath.length - 1]
}

type BaseMenuItem = {
  title: ReactNode
  visible?: boolean
  key: string
  attributes?: NonNullable<TabsProps['items']>[0]['attributes']
}

type TabMenuItemPart = {
  // This is default type, so it's optional
  type?: 'tab'
  route: RouteCallback
  link?: never
  element: RouteProps['element']
}

type LinkMenuItemPart = {
  type: 'link'
  link: string
}

type ButtonMenuItemPart = {
  onClick: () => void
  icon?: UntitledIconData
  type: 'button'
}

type TabMenuItem = BaseMenuItem & TabMenuItemPart
type LinkMenuItem = BaseMenuItem & LinkMenuItemPart
type ButtonMenuItem = BaseMenuItem & ButtonMenuItemPart

export type MenuItemProp = TabMenuItem | LinkMenuItem | ButtonMenuItem

type ContentLayoutGlobalProps = {
  header: ReactNode
  contentProps?: Omit<BoxProps, 'children'>
}

type ContentLayoutWithMenuProps = {
  menu?: MenuItemProp[]
  children?: never
} & ContentLayoutGlobalProps

type ContentLayoutWithChildrenProps = {
  menu?: never
  children?: ReactNode
} & ContentLayoutGlobalProps

type ContentLayoutProps = ContentLayoutWithMenuProps | ContentLayoutWithChildrenProps

export const ContentLayout = ({ menu, children, header, contentProps }: ContentLayoutProps) => {
  const { getColorFromTheme } = useThemeColors()
  return (
    <>
      <Box
        sx={{
          padding: `var(--ant-padding-xl) var(--ant-padding-xl) ${
            menu ? 0 : 'var(--ant-padding-xl)'
          }`,
          marginBlockEnd: menu ? 'var(--ant-margin-xl)' : undefined,
          borderBottom: menu ? `1px solid ${getColorFromTheme('dark.20')}` : undefined,
        }}
      >
        {header}
        {menu && <ContentLayoutMenu menu={menu} />}
      </Box>
      <Box
        {...contentProps}
        sx={{ margin: '0 var(--ant-padding-xl) var(--ant-padding-xl)', ...contentProps?.sx }}
      >
        {children}
        {menu && <ContentRouter menu={menu} />}
      </Box>
    </>
  )
}

const ContentLayoutMenu = ({ menu }: { menu: MenuItemProp[] }) => {
  const { pathname } = useLocation()
  const visibleMenuItems = getVisibleMenuItemsOnly(menu)
  const getRoute = useGetRoute()
  const navigate = useNavigate()

  const activeKey = visibleMenuItems.find(item =>
    'route' in item ? getRoute(item.route) === pathname : false
  )?.key

  return (
    <StyledTabs
      activeKey={activeKey}
      size='middle'
      items={visibleMenuItems.map(item => ({
        key: item.key,
        label: item.title,
        attributes: item.attributes,
      }))}
      renderTabBar={(props, DefaultTabBar) => (
        <DefaultTabBar {...props}>{node => node}</DefaultTabBar>
      )}
      onChange={key => {
        const item = visibleMenuItems.find(item => item.key === key)
        if (item?.type === 'link') {
          window.open(item.link, '_blank')
        } else if (item?.type === 'button') {
          item.onClick()
        } else {
          item?.route && navigate(item?.route)
        }
      }}
    />
  )
}

const ContentRouter = ({ menu }: { menu: MenuItemProp[] }) => {
  const visibleMenuItems = getVisibleMenuItemsOnly(menu)
  const getRoute = useGetRoute()

  return (
    <RouterRoutes>
      {visibleMenuItems.map(item => {
        if (item.type !== 'tab' && item.type !== undefined) {
          return null
        }
        const route = getRoute(item.route)
        const path = getRelativePathFromAbsolute(route)
        return (
          <Fragment key={path}>
            <Route path={path} element={item.element} />
          </Fragment>
        )
      })}
    </RouterRoutes>
  )
}

const StyledTabs = styled(Tabs)`
  padding-top: var(--ant-padding-lg);
`

const getVisibleMenuItemsOnly = (menu: MenuItemProp[]) =>
  menu.filter(item => item.visible === undefined || item.visible)

ContentLayout.Header = ContentHeader
