import type { UntitledIconData } from '../../UntitledIconData'

const ulCheck: UntitledIconData = {
  prefix: 'ul',
  name: 'check',
  paths: [
    {
      shape: 'M20 6L9 17L4 12',
    },
  ],
}

export default ulCheck
