import styled from '@emotion/styled'
import { UntitledIcon, type UntitledIconData } from '@faceup/icons'
import { Button } from '@faceup/ui'
import { Space } from '@faceup/ui-base'
import { type CSSProperties, useState } from 'react'
import { AttachmentRedact, useRedaction } from '../Redaction'

type AttachmentType = {
  id: string
  name: string
  url: string
  mimetype: string
}

type ConfigType = {
  translations: {
    download: string
  }
  icons: {
    attachment: UntitledIconData
  }
  download: (payload: AttachmentType) => Promise<boolean>
}

type AttachmentProps = {
  attachment: AttachmentType
  config: ConfigType
}

const Attachment = ({ attachment, config }: AttachmentProps) => {
  const [loading, setLoading] = useState(false)

  const { isRedactionActive } = useRedaction()

  return (
    <AttachmentWrap>
      <LimitedSpace>
        <AttachmentIcon icon={config.icons.attachment} />
        <AttachmentRedact
          type={{
            type: 'attachments',
            id: attachment.id,
          }}
          text={attachment.name}
        >
          <FileName>{attachment.name}</FileName>
        </AttachmentRedact>
      </LimitedSpace>
      {!isRedactionActive && (
        <Button
          variant='text'
          isCompact
          loading={loading}
          onClick={async () => {
            setLoading(true)
            await config.download(attachment)
            setLoading(false)
          }}
        >
          {config.translations.download}
        </Button>
      )}
    </AttachmentWrap>
  )
}

type AttachmentListProps = {
  style?: CSSProperties
  attachments: AttachmentType[]
} & ConfigType

const AttachmentList = (props: AttachmentListProps) => {
  if (!props.attachments.length) {
    return null
  }

  return (
    <AttachmentsWrapper style={props.style}>
      {props.attachments.map(attachment => (
        <Attachment key={attachment.url} attachment={attachment} config={props} />
      ))}
    </AttachmentsWrapper>
  )
}

const AttachmentsWrapper = styled.div`
  border: 1px solid #e5e7eb;
  border-radius: 6px;

  > div {
    border-bottom: 1px solid #e5e7eb;

    :nth-last-of-type(1) {
      border-bottom: none;
    }
  }
`

const AttachmentIcon = styled(UntitledIcon)`
  display: flex;
  font-size: 20px;
  color: #688699;
`

const LimitedSpace = styled(Space)`
  max-width: calc(100% - 150px);

  .ant-space-item {
    max-width: 100%;
  }
`

const FileName = styled.span`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 400;
  font-size: 14px;
  display: block;
`

const AttachmentWrap = styled.div`
  padding: 7px 7px 7px 18px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export default AttachmentList
