import type { JSX } from 'react'
import { Paper } from '../CoreComponents'
import type { Sx } from '../CoreComponents/BaseComponent'
import { Segment, type SegmentProps } from '../CoreComponents/Segment'

type CardComponent = (props: CardProps) => JSX.Element
type CardSegmentComponent = (props: CardSegmentProps) => JSX.Element
type ComplexComponent = CardComponent & { Segment: CardSegmentComponent }

export type CardProps = { hasNoBorder?: boolean; sx?: Sx } & CardSegmentProps

export const Card: ComplexComponent = (props: CardProps) => {
  const { children, hasNoBorder = false, ...otherProps } = props
  const isSomeOtherPropsFilled = Object.values(otherProps).some(value => value !== undefined)

  return (
    <Paper
      outline={hasNoBorder ? undefined : 'dark'}
      sx={({ mergeSx }) =>
        mergeSx(props.sx, {
          boxSizing: 'border-box',
          padding: '32px 0',
          '& > div:last-of-type > .mantine-Divider-root': { display: 'none' },
        })
      }
    >
      {isSomeOtherPropsFilled ? <CardSegment {...otherProps}>{children}</CardSegment> : children}
    </Paper>
  )
}

type CardSegmentProps = { hasNoPadding?: boolean } & Omit<
  SegmentProps,
  'paddingContent' | 'paddingHeader' | 'marginDivider'
>

const CardSegment: CardSegmentComponent = (_props: CardSegmentProps) => {
  const { hasNoPadding = false, ...props } = _props
  const noPadding = (hasNoPadding && !props.loading) || (!props.loading && !props.children)
  return (
    <Segment
      {...props}
      paddingHeader='0 32px'
      paddingContent={noPadding ? '0' : '0 32px'}
      marginDivider='24px 24px'
    />
  )
}

Card.Segment = CardSegment
