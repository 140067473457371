import { gql } from '@apollo/client'
import { type PermissionText_permission, PermissionType } from '../__generated__/globalTypes'

export const fragments = {
  PermissionText_permission: gql`
    fragment PermissionText_permission on Permission {
      id
      type
      additionalData {
        id
        categoryIds
      }
    }
  `,
}

type Props = {
  allCategoriesCount?: number
  permission: PermissionText_permission | null
  isShortened?: boolean
}

const PermissionText = ({ allCategoriesCount, permission, isShortened = false }: Props) => {
  const categories = permission?.additionalData?.categoryIds
  switch (permission?.type) {
    case PermissionType.SettingsAccess:
      return <>Settings access</>
    case PermissionType.ReportAccess:
      if (!categories || categories.length === allCategoriesCount) {
        return isShortened ? <>All reports access</> : <>Reports access: All reports access</>
      } else if (categories.length === 0) {
        return isShortened ? <>Only assigned reports</> : <>Reports access: Only assigned reports</>
      }
      return isShortened ? (
        <>
          {categories.length} {categories.length > 1 ? 'Categories' : 'Category'}
        </>
      ) : (
        <>
          Report access: ({categories.length} {categories.length > 1 ? 'Categories' : 'Category'})
        </>
      )

    default:
      return null
  }
}

export default PermissionText
