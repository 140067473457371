import type { UntitledIconData } from '../../UntitledIconData'

const ulArrowLeft: UntitledIconData = {
  prefix: 'ul',
  name: 'arrow-left',
  paths: [
    {
      shape: 'M19 12H5M5 12L12 19M5 12L12 5',
    },
  ],
}

export default ulArrowLeft
