import { type ReactNode, createContext, useContext, useState } from 'react'
import { redactCharacter } from '../../utils'

const simpleTextTypeDef = ['moreInfo', 'sender', 'classroom', 'closingComment'] as const
type SimpleTextType = (typeof simpleTextTypeDef)[number]

export const usernameTextTypeDef = ['internalCommentUsername', 'chatMemberUsername'] as const
export type UsernameTextType = (typeof usernameTextTypeDef)[number]

export const specialTextTypeDef = [
  ...usernameTextTypeDef,
  'formItems',
  'chatMessages',
  'internalCommentMessages',
  'attachments',
] as const
export type SpecialTextType = (typeof specialTextTypeDef)[number]

export type TextType = SimpleTextType | SpecialTextType

export type SubText = Readonly<Record<string, string | undefined>>

export type RedactionProviderContextProps = Readonly<
  Partial<Record<SimpleTextType, string>> & Partial<Record<SpecialTextType, SubText>>
>

export type RedactedTexts = Readonly<RedactionProviderContextProps & { reportId: string }>

type RedactionProviderContextHelperProps = {
  isRedactionActive: boolean
  getRedactedTexts: () => RedactedTexts
  texts: RedactionProviderContextProps
  redactText: (textType: RedactTextType, text: RedactText, defaultText: string) => void
  startRedaction: () => void
  cancelRedaction: () => void
}

type RedactionProviderProps = {
  children: (props: RedactionProviderContextHelperProps) => ReactNode
  reportId: string
}

export type RedactTextType =
  | { type: SimpleTextType; id?: never }
  | { type: SpecialTextType; id: string }

type RedactText = {
  start: number
  end: number
  variant: 'redacted' | 'notRedacted'
}

const defaultValues: RedactionProviderContextProps = {
  moreInfo: '',
  sender: '',
  formItems: {},
  chatMessages: {},
}

const getText = (texts: RedactionProviderContextProps, type: RedactTextType): string => {
  // 🤯
  if (specialTextTypeDef.includes(type.type as SpecialTextType)) {
    return (texts[type.type] as SubText)?.[type.id ?? ''] as string
  }
  return texts[type.type] as string
}

const RedactionContext = createContext<RedactionProviderContextHelperProps>({
  isRedactionActive: false,
  getRedactedTexts: () => ({ ...defaultValues, reportId: '' }),
  texts: defaultValues,
  redactText: () => null,
  startRedaction: () => null,
  cancelRedaction: () => null,
})

export const RedactionProvider = ({ children, reportId }: RedactionProviderProps) => {
  const [isRedactionActive, setIsRedactionActive] = useState<boolean>(false)
  const [redactedTexts, setRedactedTexts] = useState<RedactionProviderContextProps>({})

  const values: RedactionProviderContextHelperProps = {
    texts: redactedTexts,
    isRedactionActive,
    getRedactedTexts: (): RedactedTexts => ({ ...redactedTexts, reportId }),
    startRedaction: () => {
      setIsRedactionActive(true)
    },
    cancelRedaction: () => {
      setIsRedactionActive(false)
      setRedactedTexts({})
    },
    redactText: (textType: RedactTextType, text: RedactText, defaultText: string) => {
      setRedactedTexts(prevState => {
        let manipulatedTexts: RedactionProviderContextProps = { ...prevState }
        if (specialTextTypeDef.includes(textType.type as SpecialTextType)) {
          if (manipulatedTexts?.[textType.type] === undefined) {
            manipulatedTexts = {
              ...manipulatedTexts,
              [textType.type]: {},
            }
          }
          let subTexts: SubText = (manipulatedTexts?.[textType.type] ?? {}) as SubText
          if (!subTexts[textType.id ?? '']) {
            subTexts = {
              ...subTexts,
              [textType.id ?? '']: defaultText,
            }
          }
          manipulatedTexts = {
            ...manipulatedTexts,
            [textType.type]: subTexts,
          }
        } else {
          if (!manipulatedTexts[textType.type]) {
            manipulatedTexts = {
              ...manipulatedTexts,
              [textType.type]: defaultText,
            }
          }
        }
        const manipulatedText = getText(manipulatedTexts, textType)
        const getNewText = (): SubText | string | undefined => {
          if (usernameTextTypeDef.includes(textType.type as UsernameTextType)) {
            let manipulatedTextGroup: SubText | string | undefined = manipulatedTexts[textType.type]
            if (typeof manipulatedTextGroup === 'object') {
              let userNumber = 1
              Object.keys(manipulatedTextGroup ?? {}).forEach(id => {
                if (
                  typeof manipulatedTextGroup === 'object' &&
                  manipulatedTextGroup[id] !== undefined
                ) {
                  if (text.variant === 'notRedacted' && id === textType.id) {
                    manipulatedTextGroup = {
                      ...manipulatedTextGroup,
                      [id]: undefined,
                    }
                  } else {
                    manipulatedTextGroup = {
                      ...manipulatedTextGroup,
                      [id]: userNumber.toString(),
                    }
                    userNumber += 1
                  }
                }
              })
              return manipulatedTextGroup
            }
          }
          if (text.variant === 'redacted') {
            // We replace selection with █
            return (
              manipulatedText.substring(0, text.start) +
              redactCharacter.repeat(text.end - text.start) +
              manipulatedText.substring(text.end)
            )
          }
          // We replace selection with original text
          return (
            manipulatedText.substring(0, text.start) +
            defaultText.substring(text.start, text.end) +
            manipulatedText.substring(text.end)
          )
        }
        if (
          specialTextTypeDef.includes(textType.type as SpecialTextType) &&
          !usernameTextTypeDef.includes(textType.type as UsernameTextType)
        ) {
          return {
            ...manipulatedTexts,
            [textType.type]: {
              ...(manipulatedTexts[textType.type] as SubText),
              [textType.id ?? '']: getNewText(),
            },
          }
        }
        return {
          ...manipulatedTexts,
          [textType.type]: getNewText(),
        }
      })
    },
  }

  return <RedactionContext.Provider value={values}>{children(values)}</RedactionContext.Provider>
}

export const useRedaction = () => useContext(RedactionContext)
