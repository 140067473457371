import { UntitledIcon } from '@faceup/icons'
import { ulRefreshCw02 } from '@faceup/icons/ulRefreshCw02'
import { Switch as MantineSwitch, type SwitchProps as MantineSwitchProps } from '@mantine/core'
import { transitionDuration } from '../constants'
import { useThemeColors } from '../hooks'

export type SwitchProps = MantineSwitchProps & {
  loading?: boolean
}

export const Switch = ({ loading, ...props }: SwitchProps) => {
  const { getColorFromTheme } = useThemeColors()
  return (
    <MantineSwitch
      {...props}
      thumbIcon={
        loading &&
        !props.disabled && (
          <UntitledIcon
            icon={ulRefreshCw02}
            isSpinning
            size='0.8rem'
            color={getColorFromTheme('white')}
          />
        )
      }
      styles={{
        track: {
          width: '32px',
          height: '20px',
          borderRadius: '32px',
          backgroundColor: getColorFromTheme('dark.10'),
          border: `1px solid ${getColorFromTheme('dark.20')}`,
          minWidth: 'unset',
          outline: `1px solid transparent`,

          transition: `background-color ${transitionDuration}, border-color ${transitionDuration}, outline ${transitionDuration}`,

          'input:checked+&': {
            backgroundColor: getColorFromTheme('primary.10'),
            borderColor: getColorFromTheme('primary.40'),
          },

          'input:disabled + &': {
            backgroundColor: getColorFromTheme('dark.10'),
            borderColor: getColorFromTheme('dark.20'),
          },

          '.ui-statechangewrapper-root:hover &, &:hover': {
            backgroundColor: getColorFromTheme('primary.10'),
            borderColor: getColorFromTheme('primary.40'),
            outlineColor: getColorFromTheme('primary.10'),
          },
        },
        thumb: {
          width: '16px',
          height: '16px',
          borderRadius: '32px',
          backgroundColor: getColorFromTheme('dark.100'),
          border: 0,
          left: '1px',

          'input:checked + * > &': {
            // width of track - width of thumb - border of track
            left: 'calc(100% - 17px)',
            backgroundColor: getColorFromTheme('primary.100'),
          },

          'input:disabled + * > &': {
            backgroundColor: getColorFromTheme('dark.20'),
          },
        },
      }}
    />
  )
}
