import { UntitledIcon } from '@faceup/icons'
import { ulCheck } from '@faceup/icons/ulCheck'
import { ulChevronDown } from '@faceup/icons/ulChevronDown'
import { Avatar, Badge, Box, Flex, Menu, Tooltip } from '@mantine/core'
import { useUncontrolled } from '@mantine/hooks'
import { type ReactNode, type Ref, forwardRef, useContext, useState } from 'react'
import { Text } from '../CoreComponents'
import { HideManyItems } from '../HideManyItems'
import { Tooltip as UiToolTip } from '../Tooltip'
import { UiContext } from '../UiProvider/UiProvider'
import { useThemeColors } from '../hooks'
import { InputSkeleton, type InputSkeletonPublicProps } from './InputSkeleton'

type DefaultValueType = string

const areAllItemsSelected: <Value>(
  options: MultiSelectItemWithoutAvatars<Value>[] | MultiSelectItemWithAvatars<Value>[],
  selectedValues: Value[]
) => boolean = (options, selectedValues) =>
  options.every(option => selectedValues.includes(option.value))

type MultiSelectItemWithoutAvatars<Value> = {
  label: string
  value: Value
  avatar?: never
  disabled?: boolean
  tooltipText?: string
}

type MultiSelectItemWithAvatars<Value> = {
  label: string
  value: Value
  avatar: ReactNode
  disabled?: boolean
  tooltipText?: string
}

type MultiSelectItem<Value> =
  | MultiSelectItemWithoutAvatars<Value>
  | MultiSelectItemWithAvatars<Value>

export type MultiSelectProps<Value> = (
  | {
      withAvatar: true
      options: MultiSelectItemWithAvatars<Value>[]
    }
  | {
      withAvatar?: false
      options: MultiSelectItemWithoutAvatars<Value>[]
    }
) &
  ({
    onChange?: (value: Value[]) => void
    value: Value[]
    defaultValue?: Value[]
    placeholder?: string
    withinPortal?: boolean
  } & InputSkeletonPublicProps)

export const MultiSelect = <Value extends DefaultValueType>(_props: MultiSelectProps<Value>) => {
  const {
    options,
    onChange: _controlledOnChange,
    value: _controlledValue,
    defaultValue,
    placeholder,
    withAvatar = false,
    withinPortal = true,
    ...props
  } = _props
  const [isOpened, setIsOpened] = useState<boolean>(false)
  const [value, onChange] = useUncontrolled({
    value: _controlledValue,
    defaultValue: defaultValue ?? [],
    onChange: _controlledOnChange,
  })

  const areAllOptionsSelected = areAllItemsSelected(options, value)
  const isSomethingSelected = value.length > 0
  const showDefaultPadding = withAvatar || areAllOptionsSelected || !isSomethingSelected

  return (
    <Menu
      withinPortal={withinPortal}
      width='target'
      opened={isOpened}
      onChange={setIsOpened}
      closeOnItemClick={false}
      styles={{
        dropdown: {
          '&>div': {
            maxHeight: '300px',
            overflowY: 'auto',
          },
        },
      }}
    >
      <Menu.Target>
        <InputSkeleton
          {...props}
          suffix={[
            props.suffix,
            <UntitledIcon
              key='ms-dropdown'
              icon={ulChevronDown}
              size={20}
              flip={isOpened ? 'horizontal' : undefined}
            />,
          ]}
          isFocused={isOpened}
          cursor='pointer'
          role='combobox'
          padding={showDefaultPadding ? 'default' : 'small'}
        >
          <MultiSelectValue
            value={value}
            placeholder={placeholder ?? ''}
            options={options}
            withAvatar={withAvatar}
            isOpened={isOpened}
          />
        </InputSkeleton>
      </Menu.Target>
      <Menu.Dropdown>
        {options.map(option => {
          const menuItem = (
            <MenuItem
              key={option.value}
              isSelected={value.includes(option.value)}
              item={option}
              onClick={() => {
                if (value.includes(option.value)) {
                  onChange([...value.filter(v => v !== option.value)])
                } else {
                  onChange([...value, option.value])
                }
              }}
            />
          )
          return option.tooltipText ? (
            <UiToolTip title={option.tooltipText} key={option.value} zIndex={100000}>
              <div>{menuItem}</div>
            </UiToolTip>
          ) : (
            menuItem
          )
        })}
      </Menu.Dropdown>
    </Menu>
  )
}

type MultiSelectBadgeProps = {
  children: ReactNode
}
const MultiSelectBadge = forwardRef((props: MultiSelectBadgeProps, ref: Ref<HTMLDivElement>) => (
  <Badge ref={ref} {...props} sx={{ overflow: 'visible', height: '32px' }}>
    <Text weight='bold'>{props.children}</Text>
  </Badge>
))

type MenuItemProps<Value> = {
  item: MultiSelectItem<Value>
  isSelected: boolean
  onClick: () => void
}

const MenuItem = <Value extends DefaultValueType>({
  item,
  isSelected,
  onClick,
}: MenuItemProps<Value>) => {
  const { getColorFromTheme } = useThemeColors()
  return (
    <Menu.Item
      onClick={e => {
        e.stopPropagation()
        onClick()
      }}
      disabled={item.disabled}
      sx={{
        marginBlock: '4px',
        border: `1px solid ${isSelected ? getColorFromTheme('primary.20') : 'transparent'}`,
        paddingInlineStart: item.avatar ? '4px' : undefined,
        minHeight: '40px',
        '&:first-of-type': {
          marginBlockStart: 0,
        },
        '&:last-of-type': {
          marginBlockEnd: 0,
        },
      }}
    >
      <Flex justify='space-between' align='center'>
        <Flex align='center' gap='8px'>
          {item.avatar}
          <Text
            weight={isSelected ? 'bold' : undefined}
            color={isSelected ? 'primary.100' : undefined}
          >
            {item.label}
          </Text>
        </Flex>
        {isSelected && (
          <Box
            sx={{
              flexShrink: 0,
            }}
          >
            <UntitledIcon icon={ulCheck} size={20} color={getColorFromTheme('primary.100')} />
          </Box>
        )}
      </Flex>
    </Menu.Item>
  )
}

type MultiSelectValueProps<Value> = {
  isOpened: boolean
} & Required<Pick<MultiSelectProps<Value>, 'value' | 'placeholder' | 'options' | 'withAvatar'>>

const MultiSelectValue = <Value extends DefaultValueType>(props: MultiSelectValueProps<Value>) => {
  const { value, placeholder, options, withAvatar, isOpened } = props
  const { multiSelect: multiSelectTranslations } = useContext(UiContext)
  const areAllOptionsSelected = areAllItemsSelected(options, value)

  if (value.length === 0) {
    return <Text color='subtleText'>{placeholder}</Text>
  }

  if (areAllOptionsSelected) {
    return multiSelectTranslations?.allSelected ?? 'All'
  }

  return (
    <HideManyItems
      spacing='4px'
      renderRemaining={({ remainingItemsCount, shownItemsCount }) => {
        const remainingValues = value.slice(shownItemsCount)
        const remainingValueItems = options.filter(option => remainingValues.includes(option.value))
        return (
          <Tooltip
            opened={isOpened ? false : undefined}
            label={remainingValueItems.map(({ label }) => label).join(', ')}
          >
            <BadgeOrAvatar withAvatar={withAvatar}>+{remainingItemsCount}</BadgeOrAvatar>
          </Tooltip>
        )
      }}
    >
      {options
        .filter(option => value.includes(option.value))
        .map(option =>
          withAvatar ? (
            <Tooltip key={option.value} label={option.label}>
              {option.avatar}
            </Tooltip>
          ) : (
            <MultiSelectBadge key={option.value}>{option.label}</MultiSelectBadge>
          )
        )}
    </HideManyItems>
  )
}

type BadgeOrAvatarProps = {
  children: ReactNode
  withAvatar: boolean
}

const BadgeOrAvatar = forwardRef((props: BadgeOrAvatarProps, ref: Ref<HTMLDivElement>) => {
  const { children, withAvatar } = props
  const { getColorFromTheme } = useThemeColors()

  return withAvatar ? (
    <Avatar
      ref={ref}
      size='32px'
      styles={{ placeholder: { color: getColorFromTheme('subtleText') } }}
    >
      {children}
    </Avatar>
  ) : (
    <MultiSelectBadge ref={ref}>{children}</MultiSelectBadge>
  )
})
