import styled from '@emotion/styled'
import { Col, PaperClipOutlined, Row } from '@faceup/ui-base'
import { ALLOWED_FILE_MIME_TYPE_EXTENSION_MAP } from '@faceup/utils'
import { useDropzone } from 'react-dropzone'
import { type Attachment, AttachmentUploadListDeprecated } from '../AttachmentUploadList'
import { useHandleDrop } from '../Dropzone/Dropzone'

type Props = {
  attachments: Attachment[]
  setAttachments: (attachments: Attachment[]) => void
  input?: React.ReactNode
  disabled?: boolean
  translations: {
    metadataRemoved: string
    metadataUnableToRemove: string
    tooManyFiles: string
    tooLargeFile: string
    unsupportedMimetype: string
  }
}

const DragAndDropDeprecated = ({
  attachments,
  setAttachments,
  input,
  disabled = false,
  translations,
}: Props) => {
  const handleDrop = useHandleDrop()
  const { getRootProps, getInputProps, open } = useDropzone({
    onDrop: (acceptedFiles: File[]) =>
      handleDrop(acceptedFiles, translations, attachments, setAttachments),
    noClick: true,
    noKeyboard: true,
    disabled,
  })

  const acceptMimeTypes = Object.keys(ALLOWED_FILE_MIME_TYPE_EXTENSION_MAP).join(',')

  return (
    <Dropzone {...getRootProps({ className: 'dropzone', disabled })}>
      <input {...getInputProps()} accept={acceptMimeTypes} />
      {input}

      <AttachmentRow>
        <Col flex='auto'>
          <AttachmentUploadListDeprecated
            translations={translations}
            attachments={attachments}
            setAttachments={setAttachments}
          />
        </Col>

        <Col>
          <AttachIcon onClick={() => !disabled && open()} />
        </Col>
      </AttachmentRow>
    </Dropzone>
  )
}

const Dropzone = styled.div`
  border: 1px solid silver;
  border-radius: 5px;
  padding-bottom: 9px;
  position: relative;
`

const AttachmentRow = styled(Row)`
  padding: 10px 9px 0px;
`

const AttachIcon = styled(PaperClipOutlined)`
  font-size: 20px;
  margin: auto;
  position: absolute;
  right: 0px;
  bottom: 0px;
  color: #8a8a8a;
`

export default DragAndDropDeprecated
