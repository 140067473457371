import { gql, useMutation } from '@apollo/client'
import { Button, Form, Modal } from '@faceup/ui'
import { notification } from '@faceup/ui-base'
import { isLength, isNumeric } from '@faceup/utils'
import { TextInput } from '@mantine/core'
import { useForm } from '@mantine/form'
import { useState } from 'react'
import type {
  ApproveHotlineOrderMutation,
  ApproveHotlineOrderMutationVariables,
} from '../__generated__/globalTypes'

const mutations = {
  approveHotlineMutation: gql`
    mutation ApproveHotlineOrderMutation($input: ApproveHotlineOrderInput!) {
      approveHotlineOrder(input: $input) {
        success
      }
    }
  `,
}

const ApproveHotlineModal = ({
  reportSourceId,
  refetch,
}: {
  reportSourceId: string
  refetch: () => void
}) => {
  const [modalOpen, setModalOpen] = useState(false)

  const form = useForm({
    initialValues: { phoneNumber: '' },

    validate: {
      phoneNumber: value => {
        if (
          !isNumeric(value, { no_symbols: true }) ||
          !isLength(value.trim(), {
            min: 5,
            max: 50,
          })
        ) {
          return 'Phone number should only contain digits with no spaces or special characters (search for E.164 notation)'
        }
        return null
      },
    },
  })

  const [approveHotline] = useMutation<
    ApproveHotlineOrderMutation,
    ApproveHotlineOrderMutationVariables
  >(mutations.approveHotlineMutation, {
    onError: error => {
      console.error(error)
      notification.error({
        message: 'GQL ERROR',
        description: error.message,
      })
    },
    onCompleted: () => {
      setModalOpen(false)
      refetch()
    },
  })

  return (
    <>
      <Button onClick={() => setModalOpen(true)}>Approve</Button>
      <Modal
        opened={modalOpen}
        onClose={() => setModalOpen(false)}
        title='Approve hotline mutation'
      >
        <Form
          form={form}
          customSubmitButtonText='Approve'
          onSubmit={async values => {
            await approveHotline({
              variables: {
                input: {
                  phoneNumber: Number(values.phoneNumber),
                  reportSourceId,
                },
              },
            })
            return true
          }}
        >
          <Form.Item label='Phone number'>
            <TextInput {...form.getInputProps('phoneNumber')} />
          </Form.Item>
        </Form>
      </Modal>
    </>
  )
}

export default ApproveHotlineModal
