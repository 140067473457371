import { Region, SupportedRegion } from '../enums'
import { BCP47Language, Country, HotlineLanguage, Language } from './enums'

// https://docs.aws.amazon.com/AWSEC2/latest/UserGuide/using-regions-availability-zones.html
export const regionToRegionNameMap: Record<Region | SupportedRegion, string> = {
  [Region.UsEast2]: 'US East (Ohio)',
  [Region.UsEast1]: 'US East (N. Virginia)',
  [Region.UsWest1]: 'US West (N. California)',
  [Region.UsWest2]: 'US West (Oregon)',
  [Region.AfSouth1]: 'Africa (Cape Town)',
  [Region.ApEast1]: 'Asia Pacific (Hong Kong)',
  [Region.ApSouth2]: 'Asia Pacific (Hyderabad)',
  [Region.ApSoutheast3]: 'Asia Pacific (Jakarta)',
  [Region.ApSoutheast4]: 'Asia Pacific (Melbourne)',
  [Region.ApSouth1]: 'Asia Pacific (Mumbai)',
  [Region.ApNortheast3]: 'Asia Pacific (Osaka)',
  [Region.ApNortheast2]: 'Asia Pacific (Seoul)',
  [Region.ApSoutheast1]: 'Asia Pacific (Singapore)',
  [Region.ApSoutheast2]: 'Asia Pacific (Sydney)',
  [Region.ApNortheast1]: 'Asia Pacific (Tokyo)',
  [Region.CaCentral1]: 'Canada (Central)',
  [Region.EuCentral1]: 'Europe (Frankfurt)',
  [Region.EuWest1]: 'Europe (Ireland)',
  [Region.EuWest2]: 'Europe (London)',
  [Region.EuSouth1]: 'Europe (Milan)',
  [Region.EuWest3]: 'Europe (Paris)',
  [Region.EuSouth2]: 'Europe (Spain)',
  [Region.EuNorth1]: 'Europe (Stockholm)',
  [Region.EuCentral2]: 'Europe (Zurich)',
  [Region.MeSouth1]: 'Middle East (Bahrain)',
  [Region.MeCentral1]: 'Middle East (UAE)',
  [Region.SaEast1]: 'South America (São Paulo)',
}

export const hotlineLanguageToLanguageMap: Record<HotlineLanguage, Language> = {
  [HotlineLanguage.Af]: Language.Af,
  [HotlineLanguage.Ar]: Language.Ar,
  [HotlineLanguage.Eu]: Language.Eu,
  [HotlineLanguage.Bn]: Language.Bn,
  [HotlineLanguage.Bg]: Language.Bg,
  [HotlineLanguage.Ca]: Language.Ca,
  [HotlineLanguage.Zh]: Language.Zh,
  [HotlineLanguage.Zh_Tw]: Language.Zh_Tw,
  [HotlineLanguage.Cs]: Language.Cs,
  [HotlineLanguage.Da]: Language.Da,
  [HotlineLanguage.Nl]: Language.Nl,
  [HotlineLanguage.En_Gb]: Language.En_Gb,
  [HotlineLanguage.En_Us]: Language.En_Us,
  [HotlineLanguage.Fi]: Language.Fi,
  [HotlineLanguage.Fr]: Language.Fr,
  [HotlineLanguage.Fr_Ca]: Language.Fr_Ca,
  [HotlineLanguage.De]: Language.De,
  [HotlineLanguage.El]: Language.El,
  [HotlineLanguage.Gu]: Language.Gu,
  [HotlineLanguage.He]: Language.He,
  [HotlineLanguage.Hi]: Language.Hi,
  [HotlineLanguage.Hu]: Language.Hu,
  [HotlineLanguage.Is]: Language.Is,
  [HotlineLanguage.Id]: Language.Id,
  [HotlineLanguage.It]: Language.It,
  [HotlineLanguage.Ja]: Language.Ja,
  [HotlineLanguage.Kn]: Language.Kn,
  [HotlineLanguage.Ko]: Language.Ko,
  [HotlineLanguage.Lv]: Language.Lv,
  [HotlineLanguage.Ms]: Language.Ms,
  [HotlineLanguage.Ml]: Language.Ml,
  [HotlineLanguage.Nb]: Language.Nb,
  [HotlineLanguage.Pl]: Language.Pl,
  [HotlineLanguage.Pt]: Language.Pt,
  [HotlineLanguage.Pt_Pt]: Language.Pt_Pt,
  [HotlineLanguage.Pa]: Language.Pa,
  [HotlineLanguage.Ro]: Language.Ro,
  [HotlineLanguage.Ru]: Language.Ru,
  [HotlineLanguage.Sr]: Language.Sr,
  [HotlineLanguage.Sk]: Language.Sk,
  [HotlineLanguage.Es_Mx]: Language.Es_Mx,
  [HotlineLanguage.Es]: Language.Es,
  [HotlineLanguage.Sv]: Language.Sv,
  [HotlineLanguage.Ta]: Language.Ta,
  [HotlineLanguage.Te]: Language.Te,
  [HotlineLanguage.Th]: Language.Th,
  [HotlineLanguage.Tr]: Language.Tr,
  [HotlineLanguage.Uk]: Language.Uk,
  [HotlineLanguage.Vi]: Language.Vi,
  [HotlineLanguage.Cy]: Language.Cy,
}

export const countryToDefaultLanguageMap: Record<Country, Language> = {
  [Country.Ad]: Language.Ca,
  [Country.Ae]: Language.Ar,
  [Country.Af]: Language.Ps,
  [Country.Ag]: Language.En_Us,
  [Country.Ai]: Language.En_Us,
  [Country.Al]: Language.Sq,
  [Country.Am]: Language.Hy,
  [Country.Ao]: Language.Pt,
  [Country.Aq]: Language.En_Us,
  [Country.Ar]: Language.Es,
  [Country.As]: Language.En_Us,
  [Country.At]: Language.De,
  [Country.Aw]: Language.Nl,
  [Country.Ax]: Language.Sv,
  [Country.Az]: Language.Az,
  [Country.Ba]: Language.Bs,
  [Country.Bd]: Language.Bn,
  [Country.Be]: Language.Nl,
  [Country.Bf]: Language.Fr,
  [Country.Bg]: Language.Bg,
  [Country.Bh]: Language.Ar,
  [Country.Bi]: Language.Fr,
  [Country.Bj]: Language.Fr,
  [Country.Bl]: Language.Fr,
  [Country.Bn]: Language.Ms,
  [Country.Bo]: Language.Es,
  [Country.Bq]: Language.Nl,
  [Country.Br]: Language.Pt,
  [Country.By]: Language.Ru,
  [Country.Ca]: Language.Fr_Ca,
  [Country.Cd]: Language.Fr,
  [Country.Cf]: Language.Fr,
  [Country.Cg]: Language.Fr,
  [Country.Ch]: Language.De,
  [Country.Ci]: Language.Fr,
  [Country.Cl]: Language.Es,
  [Country.Cm]: Language.Fr,
  [Country.Cn]: Language.Zh,
  [Country.Co]: Language.Es,
  [Country.Cr]: Language.Es,
  [Country.Cu]: Language.Es,
  [Country.Cv]: Language.Pt,
  [Country.Cw]: Language.Nl,
  [Country.Cy]: Language.El,
  [Country.Cz]: Language.Cs,
  [Country.De]: Language.De,
  [Country.Dj]: Language.Fr,
  [Country.Dk]: Language.Da,
  [Country.Do]: Language.Es,
  [Country.Dz]: Language.Ar,
  [Country.Ec]: Language.Es,
  [Country.Ee]: Language.Et,
  [Country.Eg]: Language.Ar,
  [Country.Eh]: Language.Ar,
  [Country.Er]: Language.Ar,
  [Country.Es]: Language.Es,
  [Country.Et]: Language.Am,
  [Country.Fi]: Language.Fi,
  [Country.Fo]: Language.Da,
  [Country.Fr]: Language.Fr,
  [Country.Ga]: Language.Fr,
  [Country.Ge]: Language.Ka,
  [Country.Gb]: Language.En_Gb,
  [Country.Gf]: Language.Fr,
  [Country.Gl]: Language.Da,
  [Country.Gn]: Language.Fr,
  [Country.Gp]: Language.Fr,
  [Country.Gq]: Language.Es,
  [Country.Gr]: Language.El,
  [Country.Gt]: Language.Es,
  [Country.Gw]: Language.Pt,
  [Country.Hk]: Language.Zh,
  [Country.Hn]: Language.Es,
  [Country.Hr]: Language.Hr,
  [Country.Ht]: Language.Fr,
  [Country.Hu]: Language.Hu,
  [Country.Id]: Language.Id,
  [Country.Il]: Language.He,
  [Country.In]: Language.Hi,
  [Country.Iq]: Language.Ar,
  [Country.Ir]: Language.Fa,
  [Country.Is]: Language.Is,
  [Country.It]: Language.It,
  [Country.Jo]: Language.Ar,
  [Country.Jp]: Language.Ja,
  [Country.Ke]: Language.Sw,
  [Country.Kg]: Language.Ru,
  [Country.Km]: Language.Ar,
  [Country.Kp]: Language.Ko,
  [Country.Kr]: Language.Ko,
  [Country.Kw]: Language.Ar,
  [Country.Kz]: Language.Kk,
  [Country.Lb]: Language.Ar,
  [Country.Li]: Language.De,
  [Country.Lk]: Language.Si,
  [Country.Lt]: Language.Lt,
  [Country.Lu]: Language.Fr,
  [Country.Lv]: Language.Lv,
  [Country.Ly]: Language.Ar,
  [Country.Ma]: Language.Ar,
  [Country.Mc]: Language.Fr,
  [Country.Md]: Language.Ro,
  [Country.Me]: Language.Sr,
  [Country.Mf]: Language.Fr,
  [Country.Mg]: Language.Fr,
  [Country.Mk]: Language.Mk,
  [Country.Ml]: Language.Fr,
  [Country.Mn]: Language.Mn,
  [Country.Mo]: Language.Zh,
  [Country.Mq]: Language.Fr,
  [Country.Mr]: Language.Ar,
  [Country.Mt]: Language.Mt,
  [Country.Mu]: Language.Fr,
  [Country.Mx]: Language.Es_Mx,
  [Country.My]: Language.Ms,
  [Country.Mz]: Language.Pt,
  [Country.Nc]: Language.Fr,
  [Country.Ne]: Language.Fr,
  [Country.Ni]: Language.Es,
  [Country.Nl]: Language.Nl,
  [Country.No]: Language.Nb,
  [Country.Nr]: Language.En_Us,
  [Country.Nu]: Language.En_Us,
  [Country.Nz]: Language.En_Us,
  [Country.Om]: Language.Ar,
  [Country.Pa]: Language.Es,
  [Country.Pe]: Language.Es,
  [Country.Pf]: Language.Fr,
  [Country.Pl]: Language.Pl,
  [Country.Pm]: Language.Fr,
  [Country.Pr]: Language.Es,
  [Country.Ps]: Language.Ar,
  [Country.Pt]: Language.Pt_Pt,
  [Country.Py]: Language.Es,
  [Country.Qa]: Language.Ar,
  [Country.Re]: Language.Fr,
  [Country.Ro]: Language.Ro,
  [Country.Rs]: Language.Sr,
  [Country.Ru]: Language.Ru,
  [Country.Rw]: Language.Fr,
  [Country.Sa]: Language.Ar,
  [Country.Sc]: Language.Fr,
  [Country.Sd]: Language.Ar,
  [Country.Se]: Language.Sv,
  [Country.Sg]: Language.Zh,
  [Country.Si]: Language.Sl,
  [Country.Sk]: Language.Sk,
  [Country.Sm]: Language.It,
  [Country.Sn]: Language.Fr,
  [Country.So]: Language.So,
  [Country.Sr]: Language.Nl,
  [Country.St]: Language.Pt,
  [Country.Sv]: Language.Es,
  [Country.Sx]: Language.Nl,
  [Country.Sy]: Language.Ar,
  [Country.Td]: Language.Fr,
  [Country.Tf]: Language.Fr,
  [Country.Tg]: Language.Fr,
  [Country.Th]: Language.Th,
  [Country.Tj]: Language.Ru,
  [Country.Tk]: Language.En_Us,
  [Country.Tl]: Language.Pt,
  [Country.Tn]: Language.Ar,
  [Country.Tr]: Language.Tr,
  [Country.Tw]: Language.Zh_Tw,
  [Country.Tz]: Language.Sw,
  [Country.Ua]: Language.Uk,
  [Country.Us]: Language.En_Us,
  [Country.Uy]: Language.Es,
  [Country.Uz]: Language.Uz,
  [Country.Va]: Language.It,
  [Country.Ve]: Language.Es,
  [Country.Vn]: Language.Vi,
  [Country.Vu]: Language.En_Us,
  [Country.Wf]: Language.Fr,
  [Country.Ws]: Language.En_Us,
  [Country.Ye]: Language.Ar,
  [Country.Yt]: Language.Fr,
  [Country.Au]: Language.En_Us,
  [Country.Bb]: Language.En_Us,
  [Country.Bm]: Language.En_Us,
  [Country.Bs]: Language.En_Us,
  [Country.Bt]: Language.En_Us,
  [Country.Bv]: Language.En_Us,
  [Country.Bw]: Language.En_Us,
  [Country.Bz]: Language.En_Us,
  [Country.Cc]: Language.En_Us,
  [Country.Ck]: Language.En_Us,
  [Country.Cx]: Language.En_Us,
  [Country.Dm]: Language.En_Us,
  [Country.Fj]: Language.En_Us,
  [Country.Fk]: Language.En_Us,
  [Country.Fm]: Language.En_Us,
  [Country.Gd]: Language.En_Us,
  [Country.Gg]: Language.En_Us,
  [Country.Gh]: Language.En_Us,
  [Country.Gi]: Language.En_Us,
  [Country.Gm]: Language.En_Us,
  [Country.Gs]: Language.En_Us,
  [Country.Gu]: Language.En_Us,
  [Country.Gy]: Language.En_Us,
  [Country.Hm]: Language.En_Us,
  [Country.Ie]: Language.En_Us,
  [Country.Im]: Language.En_Us,
  [Country.Io]: Language.En_Us,
  [Country.Je]: Language.En_Us,
  [Country.Jm]: Language.En_Us,
  [Country.Kh]: Language.En_Us,
  [Country.Ki]: Language.En_Us,
  [Country.Kn]: Language.En_Us,
  [Country.Ky]: Language.En_Us,
  [Country.La]: Language.En_Us,
  [Country.Lc]: Language.En_Us,
  [Country.Lr]: Language.En_Us,
  [Country.Ls]: Language.En_Us,
  [Country.Mh]: Language.En_Us,
  [Country.Mm]: Language.En_Us,
  [Country.Mp]: Language.En_Us,
  [Country.Ms]: Language.En_Us,
  [Country.Mv]: Language.En_Us,
  [Country.Mw]: Language.En_Us,
  [Country.Na]: Language.En_Us,
  [Country.Nf]: Language.En_Us,
  [Country.Ng]: Language.En_Us,
  [Country.Np]: Language.En_Us,
  [Country.Pg]: Language.En_Us,
  [Country.Ph]: Language.En_Us,
  [Country.Pk]: Language.En_Us,
  [Country.Pn]: Language.En_Us,
  [Country.Pw]: Language.En_Us,
  [Country.Sb]: Language.En_Us,
  [Country.Sh]: Language.En_Us,
  [Country.Sj]: Language.En_Us,
  [Country.Sl]: Language.En_Us,
  [Country.Ss]: Language.En_Us,
  [Country.Sz]: Language.En_Us,
  [Country.Tc]: Language.En_Us,
  [Country.Tm]: Language.En_Us,
  [Country.To]: Language.En_Us,
  [Country.Tt]: Language.En_Us,
  [Country.Tv]: Language.En_Us,
  [Country.Ug]: Language.En_Us,
  [Country.Um]: Language.En_Us,
  [Country.Vc]: Language.En_Us,
  [Country.Vg]: Language.En_Us,
  [Country.Vi]: Language.En_Us,
  [Country.Za]: Language.En_Us,
  [Country.Zm]: Language.En_Us,
  [Country.Zw]: Language.En_Us,
}

// https://docs.aws.amazon.com/translate/latest/dg/what-is-languages.html#what-is-languages-supported
export const languageToAwsTranslateLanguageMap: Record<Language, string | null> = {
  [Language.Af]: 'af',
  [Language.Am]: 'am',
  [Language.Ar]: 'ar',
  [Language.Az]: 'az',
  [Language.Be]: null,
  [Language.Bg]: 'bg',
  [Language.Bn]: 'bn',
  [Language.Bs]: 'bs',
  [Language.Ca]: 'ca',
  [Language.Ceb]: null,
  [Language.Co]: null,
  [Language.Cs]: 'cs',
  [Language.Cy]: 'cy',
  [Language.Da]: 'da',
  [Language.De]: 'de',
  [Language.El]: 'el',
  [Language.En_Gb]: 'en',
  [Language.En_Us]: 'en',
  [Language.Es]: 'es',
  [Language.Es_Mx]: 'es-MX',
  [Language.Et]: 'et',
  [Language.Eu]: null,
  [Language.Fa]: 'fa',
  [Language.Fa_Af]: 'fa',
  [Language.Fi]: 'fi',
  [Language.Fr]: 'fr',
  [Language.Fr_Ca]: 'fr-CA',
  [Language.Ga]: 'ga',
  [Language.Gu]: 'gu',
  [Language.Ha]: 'ha',
  [Language.He]: 'he',
  [Language.Hi]: 'hi',
  [Language.Hr]: 'hr',
  [Language.Ht]: 'ht',
  [Language.Hu]: 'hu',
  [Language.Hy]: 'hy',
  [Language.Id]: 'id',
  [Language.Is]: 'is',
  [Language.It]: 'it',
  [Language.Ja]: 'ja',
  [Language.Ka]: 'ka',
  [Language.Kk]: 'kk',
  [Language.Kn]: 'kn',
  [Language.Ko]: 'ko',
  [Language.Lt]: 'lt',
  [Language.Lv]: 'lv',
  [Language.Mk]: 'mk',
  [Language.Ml]: 'ml',
  [Language.Mn]: 'mn',
  [Language.Mr]: 'mr',
  [Language.Ms]: 'ms',
  [Language.Mt]: 'mt',
  [Language.Nb]: null,
  [Language.Nl]: 'nl',
  [Language.Ny]: null,
  [Language.Pa]: 'pa',
  [Language.Pl]: 'pl',
  [Language.Ps]: 'ps',
  [Language.Pt]: 'pt',
  [Language.Pt_Pt]: 'pt-PT',
  [Language.Ro]: 'ro',
  [Language.Ru]: 'ru',
  [Language.Si]: 'si',
  [Language.Sk]: 'sk',
  [Language.Sl]: 'sl',
  [Language.So]: 'so',
  [Language.Sq]: 'sq',
  [Language.Sr]: 'sr',
  [Language.Sv]: 'sv',
  [Language.Sw]: 'sw',
  [Language.Ta]: 'ta',
  [Language.Te]: 'te',
  [Language.Th]: 'th',
  [Language.Tl]: 'tl',
  [Language.Tr]: 'tr',
  [Language.Uk]: 'uk',
  [Language.Ur]: 'ur',
  [Language.Uz]: 'uz',
  [Language.Vi]: 'vi',
  [Language.Zh]: 'zh',
  [Language.Zh_Tw]: 'zh-TW',
  [Language.Fy_Nl]: null,
  [Language.Gl]: null,
  [Language.Haw]: null,
  [Language.Hmn]: null,
  [Language.Ig]: null,
  [Language.Jv]: null,
  [Language.Km]: null,
  [Language.Rw]: null,
  [Language.Ku]: null,
  [Language.Ky]: null,
  [Language.Lo]: null,
  [Language.Lb]: null,
  [Language.Mg]: null,
  [Language.Mi]: null,
  [Language.My_Mm]: null,
  [Language.Ne]: null,
  [Language.Or]: null,
  [Language.Sm]: null,
  [Language.Gd]: null,
  [Language.St]: null,
  [Language.Sn]: null,
  [Language.Sd]: null,
  [Language.Su]: null,
  [Language.Tg]: null,
  [Language.Tt]: null,
  [Language.Tk]: null,
  [Language.Ug]: null,
  [Language.Xh]: null,
  [Language.Yi]: null,
  [Language.Yo]: null,
  [Language.Zu]: null,
}

export const supportedRegionToRegionMap: Record<SupportedRegion, Region> = {
  [SupportedRegion.EuWest1]: Region.EuWest1,
  [SupportedRegion.UsWest1]: Region.UsWest1,
}

export const languageToNativeLanguageMap: Record<Language, string> = {
  [Language.Af]: 'Afrikaans',
  [Language.Am]: 'አማርኛ',
  [Language.Ar]: 'العربية',
  [Language.Az]: 'Azərbaycan',
  [Language.Bg]: 'Български',
  [Language.Bn]: 'বাংলা',
  [Language.Bs]: 'Bosanski',
  [Language.Ca]: 'Català',
  [Language.Cs]: 'Čeština',
  [Language.Cy]: 'Cymraeg',
  [Language.Da]: 'Dansk',
  [Language.De]: 'Deutsch',
  [Language.El]: 'Ελληνικά',
  [Language.En_Gb]: 'English (UK)',
  [Language.En_Us]: 'English (US)',
  [Language.Es]: 'Español',
  [Language.Es_Mx]: 'Español de México',
  [Language.Et]: 'Eesti',
  [Language.Fa]: 'فارسی',
  [Language.Fa_Af]: 'فارسی عربی',
  [Language.Fi]: 'Suomi',
  [Language.Fr]: 'Français',
  [Language.Fr_Ca]: 'Français canadien',
  [Language.Ga]: 'Gaeilge',
  [Language.Gu]: 'ગુજરાતી',
  [Language.Ha]: 'Hausa',
  [Language.He]: 'עברית',
  [Language.Hi]: 'हिन्दी',
  [Language.Hr]: 'Hrvatski',
  [Language.Ht]: 'Haitian Creole',
  [Language.Hu]: 'Magyar',
  [Language.Hy]: 'Հայերեն',
  [Language.Id]: 'Indonesia',
  [Language.Is]: 'Íslenska',
  [Language.It]: 'Italiano',
  [Language.Ja]: '日本語',
  [Language.Ka]: 'Ქართული',
  [Language.Kk]: 'Қазақ тілі',
  [Language.Kn]: 'ಕನ್ನಡ',
  [Language.Ko]: '한국어',
  [Language.Lt]: 'Lietuvių',
  [Language.Lv]: 'Latviešu',
  [Language.Mk]: 'Македонски',
  [Language.Ml]: 'മലയാളം',
  [Language.Mn]: 'Монгол',
  [Language.Mr]: 'मराठी',
  [Language.Ms]: 'Melayu',
  [Language.Mt]: 'Malti',
  [Language.Nb]: 'Norsk bokmål',
  [Language.Nl]: 'Nederlands',
  [Language.Pa]: 'ਪੰਜਾਬੀ',
  [Language.Pl]: 'Polski',
  [Language.Ps]: 'پښتو',
  [Language.Pt]: 'Português',
  [Language.Pt_Pt]: 'Português do Portugal',
  [Language.Ro]: 'Română',
  [Language.Ru]: 'Русский',
  [Language.Si]: 'සිංහල',
  [Language.Sk]: 'Slovenčina',
  [Language.Sl]: 'Slovenščina',
  [Language.So]: 'Soomaali',
  [Language.Sq]: 'Shqip',
  [Language.Sr]: 'Српски',
  [Language.Sv]: 'Svenska',
  [Language.Sw]: 'Kiswahili',
  [Language.Ta]: 'தமிழ்',
  [Language.Te]: 'తెలుగు',
  [Language.Th]: 'ไทย',
  [Language.Tl]: 'Filipino',
  [Language.Tr]: 'Türkçe',
  [Language.Uk]: 'Українська',
  [Language.Ur]: 'اردو',
  [Language.Uz]: 'O‘zbek',
  [Language.Vi]: 'Tiếng Việt',
  [Language.Zh]: '中文',
  [Language.Zh_Tw]: '繁體中文',
  [Language.Be]: 'беларуская мова',
  [Language.Ceb]: 'Cebuano',
  [Language.Eu]: 'Euskara',
  [Language.Ny]: 'Chichewa (Nyanja)',
  [Language.Co]: 'Corsu',
  [Language.Fy_Nl]: 'Frysk',
  [Language.Gl]: 'Galego',
  [Language.Haw]: 'ʻŌlelo Hawaiʻi',
  [Language.Hmn]: 'lus Hmoob',
  [Language.Ig]: 'Ásụ̀sụ́ Ìgbò',
  [Language.Jv]: 'باسا جاوا',
  [Language.Km]: 'ភាសាខ្មែរ / ខេមរភាសា',
  [Language.Rw]: 'Ikinyarwanda',
  [Language.Ku]: 'Kurdî / کوردی',
  [Language.Ky]: 'Кыргыз тили',
  [Language.Lo]: 'ພາສາລາວ',
  [Language.Lb]: 'Lëtzebuergesch',
  [Language.Mg]: 'Malagasy',
  [Language.Mi]: 'Māori',
  [Language.My_Mm]: 'မြန်မာစာ',
  [Language.Ne]: 'नेपाली',
  [Language.Or]: 'ଓଡ଼ିଆ',
  [Language.Sm]: 'Gagana faʻa Sāmoa',
  [Language.Gd]: 'Gàidhlig',
  [Language.St]: 'Sesotho',
  [Language.Sn]: 'ChiShona',
  [Language.Sd]: 'سنڌي',
  [Language.Su]: 'بَاسَا سُوْندَا',
  [Language.Tg]: 'Тоҷикӣ',
  [Language.Tt]: 'татар теле',
  [Language.Tk]: 'Türkmençe',
  [Language.Ug]: 'ئۇيغۇر تىلى',
  [Language.Xh]: 'isiXhosa',
  [Language.Yi]: 'ייִדיש, יידיש',
  [Language.Yo]: 'Èdè Yorùbá',
  [Language.Zu]: 'IsiZulu',
}

export const bcp47LanguageToLanguageMap: Record<BCP47Language, Language | null> = {
  [BCP47Language.af_ZA]: Language.Af,
  [BCP47Language.ar]: Language.Ar,
  [BCP47Language.eu_ES]: Language.Eu,
  [BCP47Language.bn_IN]: Language.Bn,
  [BCP47Language.bg_BG]: Language.Bg,
  [BCP47Language.ca_ES]: Language.Ca,
  [BCP47Language.cmn_CN]: Language.Zh,
  [BCP47Language.cmn_TW]: Language.Zh_Tw,
  [BCP47Language.cs_CZ]: Language.Cs,
  [BCP47Language.da_DK]: Language.Da,
  [BCP47Language.nl_NL]: Language.Nl,
  [BCP47Language.en_GB]: Language.En_Gb,
  [BCP47Language.en_US]: Language.En_Us,
  [BCP47Language.fi_FI]: Language.Fi,
  [BCP47Language.fr_FR]: Language.Fr,
  [BCP47Language.fr_CA]: Language.Fr_Ca,
  [BCP47Language.de_DE]: Language.De,
  [BCP47Language.el_GR]: Language.El,
  [BCP47Language.gu_IN]: Language.Gu,
  [BCP47Language.he_IL]: Language.He,
  [BCP47Language.hi_IN]: Language.Hi,
  [BCP47Language.hu_HU]: Language.Hu,
  [BCP47Language.is_IS]: Language.Is,
  [BCP47Language.id_ID]: Language.Id,
  [BCP47Language.it_IT]: Language.It,
  [BCP47Language.ja_JP]: Language.Ja,
  [BCP47Language.kn_IN]: Language.Kn,
  [BCP47Language.ko_KR]: Language.Ko,
  [BCP47Language.lv_LV]: Language.Lv,
  [BCP47Language.ms_MY]: Language.Ms,
  [BCP47Language.ml_IN]: Language.Ml,
  [BCP47Language.nb_NO]: Language.Nb,
  [BCP47Language.pl_PL]: Language.Pl,
  [BCP47Language.pt_BR]: Language.Pt,
  [BCP47Language.pt_PT]: Language.Pt_Pt,
  [BCP47Language.pa_IN]: Language.Pa,
  [BCP47Language.ro_RO]: Language.Ro,
  [BCP47Language.ru_RU]: Language.Ru,
  [BCP47Language.sr_RS]: Language.Sr,
  [BCP47Language.sk_SK]: Language.Sk,
  [BCP47Language.es_MX]: Language.Es_Mx,
  [BCP47Language.es_ES]: Language.Es,
  [BCP47Language.sv_SE]: Language.Sv,
  [BCP47Language.ta_IN]: Language.Ta,
  [BCP47Language.te_IN]: Language.Te,
  [BCP47Language.th_TH]: Language.Th,
  [BCP47Language.tr_TR]: Language.Tr,
  [BCP47Language.uk_UA]: Language.Uk,
  [BCP47Language.vi_VN]: Language.Vi,
  [BCP47Language.cy_GB]: Language.Cy,
  [BCP47Language['yue_CN']]: null,
  [BCP47Language['yue_HK']]: null,
  [BCP47Language['nl_BE']]: null,
  [BCP47Language['en_AU']]: null,
  [BCP47Language['en_IN']]: null,
  [BCP47Language['en_ZA']]: null,
  [BCP47Language['en_GB_WLS']]: Language.En_Gb,
  [BCP47Language['fil_PH']]: null,
  [BCP47Language['es_US']]: Language.Es,
}
