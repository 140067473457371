import {
  type CountryCode as CountryCodeImport,
  getCountryCallingCode as getCountryCallingCodeImport,
  isPossiblePhoneNumber,
} from 'libphonenumber-js'
import { default as isBase64Validator } from 'validator/lib/isBase64'
import { default as isEmailValidator } from 'validator/lib/isEmail'
import { default as isHexColorValidator } from 'validator/lib/isHexColor'
import { default as isIPValidator } from 'validator/lib/isIP'
import { default as isLengthValidator } from 'validator/lib/isLength'
import { default as isNumericValidator } from 'validator/lib/isNumeric'
import { default as isStrongPasswordValidator } from 'validator/lib/isStrongPassword'
import { default as isURLValidator } from 'validator/lib/isURL'
import { default as isUUIDValidator } from 'validator/lib/isUUID'
import { USER_PASSWORD_MAX_LENGTH, USER_PASSWORD_MIN_LENGTH } from './validationConfig'

export const isPasswordValid = (password: string) =>
  isStrongPassword(password) && password.length <= USER_PASSWORD_MAX_LENGTH

export const isStrongPassword = (password: string) =>
  isStrongPasswordValidator(password, {
    minLength: USER_PASSWORD_MIN_LENGTH,
    minNumbers: 1,
    minLowercase: 1,
    minUppercase: 1,
    minSymbols: 1,
  })

export const getPasswordStrengthScore = (password: string): number =>
  isStrongPasswordValidator(password, { returnScore: true }) as unknown as number

export const isEmail = (email: string) => isEmailValidator(email, { allow_utf8_local_part: false })

export const isUUIDv7 = (uuid: string) => isUUIDValidator(uuid, 7)

// base64 in the rough sense that it works with `atob`
// not checking url safety because motherIds used in urls don't currently correspond to that standard
export const isBase64 = (base64: string) => isBase64Validator(base64)

// https://libsodium.gitbook.io/doc/helpers#base64-encoding-decoding
// https://base64.guru/standards/base64url
// should correspond to libsodium's base64 url-safe no-padding output format
// this isn't base64 in the sense that it works with `atob`
export const isBase64UrlSafeNoPadding = (base64: string) =>
  isBase64Validator(base64, { urlSafe: true })

export const isUrl = (url: string) =>
  isURLValidator(url, {
    protocols: ['http', 'https'],
    require_tld: true,
    require_protocol: true,
    require_host: true,
    require_port: false,
    require_valid_protocol: true,
    allow_underscores: false,
    allow_trailing_dot: false,
    allow_protocol_relative_urls: false,
    allow_fragments: true,
    allow_query_components: true,
    disallow_auth: false,
    validate_length: true,
  })

export const isNumeric = isNumericValidator
export const isHexColor = isHexColorValidator
export const isLength = isLengthValidator
export const isIP = isIPValidator

export const isCompanyEmail = async (email: string) => {
  const { isCompanyEmail: isCompanyEmailFn } = await import('free-email-domains-list')
  return isCompanyEmailFn(email)
}

// we are not as strict as we could be, we should use function `isValidPhoneNumber`
export const isValidPhoneNumber = (phoneNumber: string) => isPossiblePhoneNumber(phoneNumber)

// unduplicated exports
export type CountryCode = CountryCodeImport
export const getCountryCallingCode = getCountryCallingCodeImport
