import { gql, useMutation } from '@apollo/client'
import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { sharedMessages, useIntl } from '@faceup/localization'
import { Button } from '@faceup/ui'
import { Alert, notification } from '@faceup/ui-base'
import { useContext } from 'react'
import { FormattedMessage } from 'react-intl'
import {
  type ReopenCompanyReportMutation,
  type ReportStatusAlert_report,
  ReportStatusType,
} from '../../__generated__/globalTypes'
import { ChatPopupAlertType, PopupsContext } from './PopupsContext'
import { RelativeMessagesContext } from './RelativeMessagesContext'

export const fragments = {
  ReportStatusAlert_report: gql`
    fragment ReportStatusAlert_report on CompanyReport {
      id
      status
      isDemo
      company {
        id
        workingHours {
          id
          enabled
          outsideWorkingHoursMessage
        }
      }
      victim {
        id
      }
    }
  `,
}

const mutations = {
  ReopenCompanyReportMutation: gql`
    mutation ReopenCompanyReportMutation {
      reopenCompanyReport {
        report {
          id
          status
        }
      }
    }
  `,
}

type Props = {
  userId: string
  report: ReportStatusAlert_report
  isFirstMessage?: boolean
}

const ReportStatusAlert = ({ report, userId, isFirstMessage }: Props) => {
  const relativeMessages = useContext(RelativeMessagesContext)
  const { formatMessage } = useIntl()
  const { alertType, closeNotOpenReportAlert } = useContext(PopupsContext)
  const [reopenChat] = useMutation<ReopenCompanyReportMutation>(
    mutations.ReopenCompanyReportMutation,
    {
      onError: error => {
        console.error(error.message)
        notification.error({
          message: formatMessage(sharedMessages.apiError),
          description: error.message,
        })
      },
      onCompleted: () => {
        closeNotOpenReportAlert()
      },
    }
  )

  const userIsVictim = Boolean(report?.victim?.id) && userId === report?.victim?.id
  const isArchived = report.status === ReportStatusType.Archived
  const isFollowUp = relativeMessages.type === 'Victim'

  if (alertType === ChatPopupAlertType.ReportStatus) {
    return (
      <>
        <AlertMessage
          data-cy='report-status-alert-report-closed'
          message={
            <FormattedMessage
              {...(isArchived
                ? relativeMessages.reportWasArchived
                : relativeMessages.reportWasClosed)}
            />
          }
          type={isArchived ? 'info' : 'success'}
          showIcon
          status={report.status}
          isFollowUp={isFollowUp}
        />
        {userIsVictim && (
          <Button sx={{ margin: 'auto' }} onClick={() => reopenChat()}>
            {isFollowUp && <FormattedMessage {...relativeMessages.reopen} />}
          </Button>
        )}
      </>
    )
  }

  if (alertType === ChatPopupAlertType.OutsideWorkingHours && userIsVictim && isFirstMessage) {
    return (
      <AlertMessage
        data-cy='report-status-alert-outside-working-hours'
        message={report?.company?.workingHours?.outsideWorkingHoursMessage ?? ''}
        type='warning'
        closable
        afterClose={() => closeNotOpenReportAlert()}
        showIcon
        status={report.status}
        isFollowUp
      />
    )
  }

  return null
}

const AlertMessage = styled(Alert)<{ isFollowUp?: boolean; status: ReportStatusType | null }>`
  width: 100%;
  margin: 0 auto 20px;
  padding: 16px;
  border-radius: 6px;

  ${({ isFollowUp }) =>
    isFollowUp &&
    css`
      border: 0;
      font-weight: 500;
      line-height: 20px;
      font-size: 14px;
    `}

  background: ${({ status }) =>
    status === ReportStatusType.Archived
      ? '#0e9af714'
      : status === ReportStatusType.Closed
        ? '#0cce6b14'
        : '#f7990e14'};

  .ant-alert-message {
    color: ${({ status }) =>
      status === ReportStatusType.Archived
        ? '#0e9af7'
        : status === ReportStatusType.Closed
          ? '#0cce6b'
          : '#f7990e'};
  }

  button svg {
    color: ${({ status }) =>
      status === ReportStatusType.Archived
        ? '#0e9af7'
        : status === ReportStatusType.Closed
          ? '#0cce6b'
          : '#f7990e'};
  }
`

export default ReportStatusAlert
