import type { UntitledIconData } from '../../UntitledIconData'

const ulAlertCircle: UntitledIconData = {
  prefix: 'ul',
  name: 'alert-circle',
  paths: [
    {
      shape:
        'M12 8V12M12 16H12.01M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z',
    },
  ],
}

export default ulAlertCircle
