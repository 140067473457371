import { Button } from '@faceup/ui-base'
import { type ComponentProps, forwardRef } from 'react'

type ButtonCircleProps = Pick<
  ComponentProps<typeof Button>,
  'disabled' | 'icon' | 'onClick' | 'type'
>

export const ButtonCircle = forwardRef<HTMLButtonElement, ButtonCircleProps>((props, ref) => (
  <Button ref={ref} shape='circle' {...props} />
))
