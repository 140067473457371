import { Tabs as AntTabs, type TabsProps as AntTabsProps } from 'antd'
import { cloneElement } from 'react'

export type TabsProps = Omit<AntTabsProps, 'items'> & {
  items?: ExtendWithAttributes<AntTabsProps['items']>
}

type Attributes = { 'data-onboarding'?: string; 'data-cy'?: string } & Record<string, string>

type ExtendWithAttributes<Items> = Items extends (infer Tab)[]
  ? (Tab & { attributes?: Attributes })[]
  : never

export const Tabs = (props: TabsProps) => {
  const keyToAttributesMap =
    props.items?.reduce(
      (acc, item) => ({ ...acc, [item.key]: item.attributes ?? {} }),
      {} as Record<string, Attributes>
    ) ?? {}

  const omittedItems =
    props.items?.map(item =>
      Object.fromEntries(Object.entries(item).filter(([key, _]) => key !== 'attributes'))
    ) ?? []

  const omittedProps = { ...props, items: omittedItems } as typeof props

  return (
    <AntTabs
      {...omittedProps}
      renderTabBar={
        omittedProps.renderTabBar ??
        ((props, DefaultTabBar) => (
          <DefaultTabBar {...props}>
            {node => (node.key ? cloneElement(node, keyToAttributesMap[node.key]) : node)}
          </DefaultTabBar>
        ))
      }
    />
  )
}
