import { Region, type SupportedRegion } from '../enums'
import {
  bcp47LanguageToLanguageMap,
  countryToDefaultLanguageMap,
  hotlineLanguageToLanguageMap,
  languageToAwsTranslateLanguageMap,
  languageToNativeLanguageMap,
  regionToRegionNameMap,
  supportedRegionToRegionMap,
} from './convertersMap'
import { isAfricanCountry } from './countryAreas'
import { BCP47Language, Country, Currency, type HotlineLanguage, Language } from './enums'
import { DEFAULT_LANGUAGE, languageEnumToBasename } from './languages'

export const replaceVariablesInString = (str: string, replacers: Record<string, string | number>) =>
  str.replace(/\{([0-9a-zA-Z_]+)\}/g, (match, i, index) => {
    if (str[index - 1] === '{' && str[index + match.length] === '}') {
      return i
    }

    return replacers[i] ?? ''
  })

export const convertCountryToCountryName = (country: Country, locale = 'en') =>
  new Intl.DisplayNames([locale, 'en'], { type: 'region' }).of(country.toUpperCase()) ?? country

export const translateLanguageName = (language: Language, targetLanguage: BCP47Language) => {
  const translator = new Intl.DisplayNames(targetLanguage as unknown as Intl.LocalesArgument, {
    type: 'language',
  })
  return translator.of(languageEnumToBasename(language))
}

const getObjectValueByKey = <T, K extends keyof T>(key: K, obj: T) => obj[key]

const getObjectKeysByValue = <T extends Record<string, string | number | null>>(
  value: T[keyof T],
  obj: T
) => (Object.keys(obj) as (keyof T)[]).filter(key => obj[key] === value)

export const convertBCP47LanguageToLanguage = (bcp47Language: BCP47Language): Language =>
  getObjectValueByKey(bcp47Language, bcp47LanguageToLanguageMap) ?? DEFAULT_LANGUAGE

export const convertLanguageToBCP47Language = (language: Language): BCP47Language =>
  getObjectKeysByValue(language, bcp47LanguageToLanguageMap)[0] ?? BCP47Language.en_US

export const convertRegionToRegionName = (region: Region | SupportedRegion): string =>
  getObjectValueByKey(region, regionToRegionNameMap)

export const convertCountryToDefaultLanguage = (country: Country): Language =>
  getObjectValueByKey(country, countryToDefaultLanguageMap)

export const convertLanguageToAwsTranslateLanguage = (language: Language): string | null =>
  getObjectValueByKey(language, languageToAwsTranslateLanguageMap)

export const convertAwsTranslateLanguageToLanguage = (language: string): Language | null =>
  getObjectKeysByValue(language, languageToAwsTranslateLanguageMap)[0] ?? null

export const convertSupportedRegionToRegion = (region: SupportedRegion): Region =>
  getObjectValueByKey(region, supportedRegionToRegionMap)

export const convertRegionToSupportedRegion = (region: Region): SupportedRegion => {
  const supportedRegions = getObjectKeysByValue(region, supportedRegionToRegionMap)

  if (!supportedRegions[0]) {
    throw Error(`Region ${region} is not supported`)
  }

  return supportedRegions[0]
}

export const convertHotlineLanguageToLanguage = (hotlineLanguage: HotlineLanguage): Language =>
  getObjectValueByKey(hotlineLanguage, hotlineLanguageToLanguageMap)

export const convertLanguageToHotlineLanguage = (language: Language): HotlineLanguage => {
  const hotlineLanguages = getObjectKeysByValue(language, hotlineLanguageToLanguageMap)

  if (!hotlineLanguages[0] || hotlineLanguages.length > 1) {
    throw Error(`Language ${language} is not supported`)
  }

  return hotlineLanguages[0]
}

export const convertLanguageToNativeLanguage = (language: Language): string =>
  getObjectValueByKey(language, languageToNativeLanguageMap)

export const convertLanguageForChargebee = (language: Language) => {
  switch (language) {
    case Language.Cs:
    case Language.Sk:
      return 'cs'
    default:
      return 'en-US'
  }
}

// currency is defined in Chargebee and this array copies that
export const availableCurrencies = [Currency.CZK, Currency.EUR, Currency.USD, Currency.GBP] as const
export type AvailableCurrency = (typeof availableCurrencies)[number]

export const getAvailableCurrencyFromCountry = (country: Country): AvailableCurrency => {
  if (country === Country.Cz) {
    return Currency.CZK
  }

  if (country === Country.Gb) {
    return Currency.GBP
  }

  if (country === Country.Us || country === Country.Ca || isAfricanCountry(country)) {
    return Currency.USD
  }

  return Currency.EUR
}

export const parseRegion = (value: string | null): Region | null =>
  Region[value as keyof typeof Region] ?? null
