import { gql, useMutation } from '@apollo/client'
import { useAccessRights } from '@faceup/member'
import type { ModalProps } from '@faceup/ui'
import { notification } from '@faceup/ui-base'
import { sharedMessages } from '../../Shared/translations'
import { FormattedMessage, defineMessages, useIntl } from '../../TypedIntl'
import {
  type CreateManagerModal_institution,
  type CreateManagerModal_manager,
  type CreateManagerMutation,
  type CreateManagerMutationVariables,
  PermissionType,
} from '../../__generated__/globalTypes'
import { useMotherId } from '../../providers'
import { AbstractManagerModal, AbstractManagerModalFragments } from './AbstractManagerModal'
import { findPermissionByPermissionType } from './AbstractManagerModal/abstractManagerModalHelpers'

const messages = defineMessages({
  title: 'Administration.settings.createNewUser.title',
})

export const CreateManagerModalFragments = {
  CreateManagerModal_manager: gql`
    fragment CreateManagerModal_manager on Manager {
      id
      isAccountOwner(motherId: $motherId)

      companies(motherId: $motherId) {
        id
      }

      keys {
        id
        permissions(motherId: $motherId) {
          type
          enabled
          additionalData {
            categoryIds
          }
        }
      }

      ...AbstractManagerModal_manager
    }

    ${AbstractManagerModalFragments.AbstractManagerModal_manager}
  `,
  CreateManagerModal_institution: gql`
    fragment CreateManagerModal_institution on Company {
      id
      config {
        id
        reportCategories {
          id
        }
      }
      organizationalStructure {
        id
        name
      }

      ...AbstractManagerModal_institution
    }
    ${AbstractManagerModalFragments.AbstractManagerModal_institution}
  `,
}

const mutations = {
  CreateManager: gql`
    mutation CreateManagerMutation($input: CreateManagerInput!, $motherId: CompanyGlobalId!) {
      createManager(input: $input) {
        createdManager {
          id
          name
          phone
          email

          # need up-to-date count for addMember upsell
          mother(motherId: $motherId) {
            id
            countOfMembers
          }
        }
      }
    }
  `,
}

type CreateManagerModalProps = {
  onCompleted: (data?: CreateManagerMutation) => void
  manager: CreateManagerModal_manager | null
  institution: CreateManagerModal_institution
} & Required<Pick<ModalProps, 'opened' | 'onClose'>>

export const CreateManagerModal = ({
  manager,
  institution,
  onCompleted,
  opened,
  onClose,
}: CreateManagerModalProps) => {
  const { formatMessage } = useIntl()
  const { getMotherId } = useMotherId()
  const accessRights = useAccessRights()

  const [createManager] = useMutation<CreateManagerMutation, CreateManagerMutationVariables>(
    mutations.CreateManager,
    {
      onError: error => {
        console.error(error)
        notification.error({
          message: formatMessage(sharedMessages.apiError),
          description: error.message,
        })
      },
      onCompleted,
    }
  )

  const isKredenc = !manager

  return (
    <AbstractManagerModal
      modalVariant='add'
      title={<FormattedMessage {...messages.title} />}
      manager={manager}
      institution={institution}
      opened={opened}
      onClose={onClose}
      defaultValues={{
        email: '',
        institutionIds: isKredenc
          ? institution.organizationalStructure.map(institution => institution.id)
          : manager.companies?.map(company => company.id) ?? [],
        permissions: Object.values(PermissionType).map(type => {
          const enabled = manager?.isAccountOwner || accessRights.isVisibleForPermission[type]

          if (type === PermissionType.ReportAccess) {
            return {
              type,
              enabled,
              additionalData: isKredenc
                ? {
                    categoryIds: null,
                  }
                : findPermissionByPermissionType(manager.keys?.permissions ?? [], type)
                    ?.additionalData,
            }
          }
          return {
            type,
            enabled,
          }
        }),
      }}
      onSubmit={async values => {
        const result = await createManager({
          variables: {
            motherId: getMotherId(),
            input: {
              motherId: getMotherId(),
              companyIds: values.institutionIds ?? [],
              email: values.email,
              permissions: values.permissions,
            },
          },
        })

        if (result.errors) {
          return false
        }

        onClose()
        return true
      }}
    />
  )
}
