import styled from '@emotion/styled'
import { UntitledIcon } from '@faceup/icons'
import { usAttachment02 } from '@faceup/icons/usAttachment02'
import { Space, Typography } from '@faceup/ui-base'
import { Button } from '../Button'
import { ucDropzone } from '../customIcons'
import Dropzone, { type DropzoneProps } from './Dropzone'

const { Text } = Typography

type ModifiedDropzoneProps = Omit<DropzoneProps, 'style' | 'children' | 'disabled'>

type AttachmentDropzoneProps = {
  translations: {
    dropzoneOpen: string
    dropzoneDrop: string
    dropzoneLimits: string
    add: string
  }
} & ModifiedDropzoneProps

const AttachmentDropzone = (props: AttachmentDropzoneProps) => (
  <Dropzone
    translations={props.translations}
    disabled={false}
    attachments={props.attachments}
    setAttachments={props.setAttachments}
  >
    {(open, isMobile) =>
      isMobile ? (
        <Button onClick={open}>
          <Space align='center'>
            <UploadIcon icon={usAttachment02} />
            {props.translations.add}
          </Space>
        </Button>
      ) : (
        <DropzoneContent>
          <DropzoneIcon icon={ucDropzone} />
          <Text style={{ textAlign: 'center' }}>
            <Button sx={{ fontWeight: 400 }} variant='text' onClick={open} isCompact>
              {props.translations.dropzoneOpen}
            </Button>{' '}
            {props.translations.dropzoneDrop}
          </Text>
          <SecondaryText type='secondary'>{props.translations.dropzoneLimits}</SecondaryText>
        </DropzoneContent>
      )
    }
  </Dropzone>
)

const DropzoneContent = styled.div`
  border: 2px dashed rgba(6, 45, 70, 0.16);
  border-radius: 6px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 1.5rem;
`

const DropzoneIcon = styled(UntitledIcon)`
  font-size: 36px;
  margin-bottom: 1rem;
  color: #688699;
`

const UploadIcon = styled(UntitledIcon)`
  font-size: 22px;
  display: flex;
  color: white;
`

const SecondaryText = styled(Text)`
  font-size: 12px;
  color: #688699;
`

export default AttachmentDropzone
