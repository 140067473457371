import type { UntitledIconData } from '../../UntitledIconData'

const ulXClose: UntitledIconData = {
  prefix: 'ul',
  name: 'x-close',
  paths: [
    {
      shape: 'M18 6L6 18M6 6L18 18',
    },
  ],
}

export default ulXClose
