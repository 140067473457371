type WindowWithTemporaryStorage = typeof window & {
  temporaryStorage: {
    data: { [K in string]: string }
    setItem: (key: string, value: string) => void
    getItem: (key: string) => string | null
    removeItem: (key: string) => void
  }
}

type StorageType = 'sessionStorage' | 'localStorage' | 'temporaryStorage'

export const storageSet = (storage: StorageType, storageKey: string, data: string) => {
  const w = window as WindowWithTemporaryStorage
  try {
    w[storage].setItem(storageKey, data)
  } catch {
    getTemporaryStorage().setItem(storageKey, data)
  }
}

export const storageRemove = (storage: StorageType, storageKey: string) => {
  const w = window as WindowWithTemporaryStorage

  try {
    w[storage].removeItem(storageKey)
  } catch {
    getTemporaryStorage().removeItem(storageKey)
  }
}

export const storageGet = (storage: StorageType, storageKey: string) => {
  const w = window as WindowWithTemporaryStorage

  try {
    return w[storage].getItem(storageKey)
  } catch {
    return getTemporaryStorage().getItem(storageKey)
  }
}

const getTemporaryStorage = () => {
  const w = window as WindowWithTemporaryStorage

  w.temporaryStorage = w.temporaryStorage || {
    data: {},
    setItem: (key, value) => (w.temporaryStorage.data[key] = value),
    getItem: key => w.temporaryStorage.data[key] ?? null,
    removeItem: key => delete w.temporaryStorage.data[key],
  }
  return w.temporaryStorage
}
