export enum Country {
  Ad = 'Ad',
  Ae = 'Ae',
  Af = 'Af',
  Ag = 'Ag',
  Ai = 'Ai',
  Al = 'Al',
  Am = 'Am',
  Ao = 'Ao',
  Aq = 'Aq',
  Ar = 'Ar',
  As = 'As',
  At = 'At',
  Au = 'Au',
  Aw = 'Aw',
  Ax = 'Ax',
  Az = 'Az',
  Ba = 'Ba',
  Bb = 'Bb',
  Bd = 'Bd',
  Be = 'Be',
  Bf = 'Bf',
  Bg = 'Bg',
  Bh = 'Bh',
  Bi = 'Bi',
  Bj = 'Bj',
  Bl = 'Bl',
  Bm = 'Bm',
  Bn = 'Bn',
  Bo = 'Bo',
  Bq = 'Bq',
  Br = 'Br',
  Bs = 'Bs',
  Bt = 'Bt',
  Bv = 'Bv',
  Bw = 'Bw',
  By = 'By',
  Bz = 'Bz',
  Ca = 'Ca',
  Cc = 'Cc',
  Cd = 'Cd',
  Cf = 'Cf',
  Cg = 'Cg',
  Ch = 'Ch',
  Ci = 'Ci',
  Ck = 'Ck',
  Cl = 'Cl',
  Cm = 'Cm',
  Cn = 'Cn',
  Co = 'Co',
  Cr = 'Cr',
  Cu = 'Cu',
  Cv = 'Cv',
  Cw = 'Cw',
  Cx = 'Cx',
  Cy = 'Cy',
  Cz = 'Cz',
  De = 'De',
  Dj = 'Dj',
  Dk = 'Dk',
  Dm = 'Dm',
  Do = 'Do',
  Dz = 'Dz',
  Ec = 'Ec',
  Ee = 'Ee',
  Eg = 'Eg',
  Eh = 'Eh',
  Er = 'Er',
  Es = 'Es',
  Et = 'Et',
  Fi = 'Fi',
  Fj = 'Fj',
  Fk = 'Fk',
  Fm = 'Fm',
  Fo = 'Fo',
  Fr = 'Fr',
  Ga = 'Ga',
  Gb = 'Gb',
  Gd = 'Gd',
  Ge = 'Ge',
  Gf = 'Gf',
  Gg = 'Gg',
  Gh = 'Gh',
  Gi = 'Gi',
  Gl = 'Gl',
  Gm = 'Gm',
  Gn = 'Gn',
  Gp = 'Gp',
  Gq = 'Gq',
  Gr = 'Gr',
  Gs = 'Gs',
  Gt = 'Gt',
  Gu = 'Gu',
  Gw = 'Gw',
  Gy = 'Gy',
  Hk = 'Hk',
  Hm = 'Hm',
  Hn = 'Hn',
  Hr = 'Hr',
  Ht = 'Ht',
  Hu = 'Hu',
  Id = 'Id',
  Ie = 'Ie',
  Il = 'Il',
  Im = 'Im',
  In = 'In',
  Io = 'Io',
  Iq = 'Iq',
  Ir = 'Ir',
  Is = 'Is',
  It = 'It',
  Je = 'Je',
  Jm = 'Jm',
  Jo = 'Jo',
  Jp = 'Jp',
  Ke = 'Ke',
  Kg = 'Kg',
  Kh = 'Kh',
  Ki = 'Ki',
  Km = 'Km',
  Kn = 'Kn',
  Kp = 'Kp',
  Kr = 'Kr',
  Kw = 'Kw',
  Ky = 'Ky',
  Kz = 'Kz',
  La = 'La',
  Lb = 'Lb',
  Lc = 'Lc',
  Li = 'Li',
  Lk = 'Lk',
  Lr = 'Lr',
  Ls = 'Ls',
  Lt = 'Lt',
  Lu = 'Lu',
  Lv = 'Lv',
  Ly = 'Ly',
  Ma = 'Ma',
  Mc = 'Mc',
  Md = 'Md',
  Me = 'Me',
  Mf = 'Mf',
  Mg = 'Mg',
  Mh = 'Mh',
  Mk = 'Mk',
  Ml = 'Ml',
  Mm = 'Mm',
  Mn = 'Mn',
  Mo = 'Mo',
  Mp = 'Mp',
  Mq = 'Mq',
  Mr = 'Mr',
  Ms = 'Ms',
  Mt = 'Mt',
  Mu = 'Mu',
  Mv = 'Mv',
  Mw = 'Mw',
  Mx = 'Mx',
  My = 'My',
  Mz = 'Mz',
  Na = 'Na',
  Nc = 'Nc',
  Ne = 'Ne',
  Nf = 'Nf',
  Ng = 'Ng',
  Ni = 'Ni',
  Nl = 'Nl',
  No = 'No',
  Np = 'Np',
  Nr = 'Nr',
  Nu = 'Nu',
  Nz = 'Nz',
  Om = 'Om',
  Pa = 'Pa',
  Pe = 'Pe',
  Pf = 'Pf',
  Pg = 'Pg',
  Ph = 'Ph',
  Pk = 'Pk',
  Pl = 'Pl',
  Pm = 'Pm',
  Pn = 'Pn',
  Pr = 'Pr',
  Ps = 'Ps',
  Pt = 'Pt',
  Pw = 'Pw',
  Py = 'Py',
  Qa = 'Qa',
  Re = 'Re',
  Ro = 'Ro',
  Rs = 'Rs',
  Ru = 'Ru',
  Rw = 'Rw',
  Sa = 'Sa',
  Sb = 'Sb',
  Sc = 'Sc',
  Sd = 'Sd',
  Se = 'Se',
  Sg = 'Sg',
  Sh = 'Sh',
  Si = 'Si',
  Sj = 'Sj',
  Sk = 'Sk',
  Sl = 'Sl',
  Sm = 'Sm',
  Sn = 'Sn',
  So = 'So',
  Sr = 'Sr',
  Ss = 'Ss',
  St = 'St',
  Sv = 'Sv',
  Sx = 'Sx',
  Sy = 'Sy',
  Sz = 'Sz',
  Tc = 'Tc',
  Td = 'Td',
  Tf = 'Tf',
  Tg = 'Tg',
  Th = 'Th',
  Tj = 'Tj',
  Tk = 'Tk',
  Tl = 'Tl',
  Tm = 'Tm',
  Tn = 'Tn',
  To = 'To',
  Tr = 'Tr',
  Tt = 'Tt',
  Tv = 'Tv',
  Tw = 'Tw',
  Tz = 'Tz',
  Ua = 'Ua',
  Ug = 'Ug',
  Um = 'Um',
  Us = 'Us',
  Uy = 'Uy',
  Uz = 'Uz',
  Va = 'Va',
  Vc = 'Vc',
  Ve = 'Ve',
  Vg = 'Vg',
  Vi = 'Vi',
  Vn = 'Vn',
  Vu = 'Vu',
  Wf = 'Wf',
  Ws = 'Ws',
  Ye = 'Ye',
  Yt = 'Yt',
  Za = 'Za',
  Zm = 'Zm',
  Zw = 'Zw',
}

export enum BCP47Language {
  af_ZA = 'af-ZA',
  ar = 'ar',
  eu_ES = 'eu-ES',
  bn_IN = 'bn-IN',
  bg_BG = 'bg-BG',
  ca_ES = 'ca-ES',
  yue_CN = 'yue-CN',
  yue_HK = 'yue-HK',
  cmn_CN = 'cmn-CN',
  cmn_TW = 'cmn-TW',
  cs_CZ = 'cs-CZ',
  da_DK = 'da-DK',
  nl_BE = 'nl-BE',
  nl_NL = 'nl-NL',
  en_AU = 'en-AU',
  en_IN = 'en-IN',
  en_ZA = 'en-ZA',
  en_GB = 'en-GB',
  en_US = 'en-US',
  en_GB_WLS = 'en-GB-WLS',
  fil_PH = 'fil-PH',
  fi_FI = 'fi-FI',
  fr_CA = 'fr-CA',
  fr_FR = 'fr-FR',
  de_DE = 'de-DE',
  el_GR = 'el-GR',
  gu_IN = 'gu-IN',
  he_IL = 'he-IL',
  hi_IN = 'hi-IN',
  hu_HU = 'hu-HU',
  is_IS = 'is-IS',
  id_ID = 'id-ID',
  it_IT = 'it-IT',
  ja_JP = 'ja-JP',
  kn_IN = 'kn-IN',
  ko_KR = 'ko-KR',
  lv_LV = 'lv-LV',
  ms_MY = 'ms-MY',
  ml_IN = 'ml-IN',
  nb_NO = 'nb-NO',
  pl_PL = 'pl-PL',
  pt_BR = 'pt-BR',
  pt_PT = 'pt-PT',
  pa_IN = 'pa-IN',
  ro_RO = 'ro-RO',
  ru_RU = 'ru-RU',
  sr_RS = 'sr-RS',
  sk_SK = 'sk-SK',
  es_MX = 'es-MX',
  es_ES = 'es-ES',
  es_US = 'es-US',
  sv_SE = 'sv-SE',
  ta_IN = 'ta-IN',
  te_IN = 'te-IN',
  th_TH = 'th-TH',
  tr_TR = 'tr-TR',
  uk_UA = 'uk-UA',
  vi_VN = 'vi-VN',
  cy_GB = 'cy-GB',
}

export enum Language {
  Af = 'Af',
  Am = 'Am',
  Ar = 'Ar',
  Az = 'Az',
  Bg = 'Bg',
  Bn = 'Bn',
  Bs = 'Bs',
  Ca = 'Ca',
  Cs = 'Cs',
  Cy = 'Cy',
  Da = 'Da',
  De = 'De',
  El = 'El',
  En_Gb = 'En_Gb',
  En_Us = 'En_Us',
  Es = 'Es',
  Es_Mx = 'Es_Mx',
  Et = 'Et',
  Fa = 'Fa',
  Fa_Af = 'Fa_Af',
  Fi = 'Fi',
  Fr = 'Fr',
  Fr_Ca = 'Fr_Ca',
  Ga = 'Ga',
  Gu = 'Gu',
  Ha = 'Ha',
  He = 'He',
  Hi = 'Hi',
  Hr = 'Hr',
  Ht = 'Ht',
  Hu = 'Hu',
  Hy = 'Hy',
  Id = 'Id',
  Is = 'Is',
  It = 'It',
  Ja = 'Ja',
  Ka = 'Ka',
  Kk = 'Kk',
  Kn = 'Kn',
  Ko = 'Ko',
  Lt = 'Lt',
  Lv = 'Lv',
  Mk = 'Mk',
  Ml = 'Ml',
  Mn = 'Mn',
  Mr = 'Mr',
  Ms = 'Ms',
  Mt = 'Mt',
  Nb = 'Nb',
  Nl = 'Nl',
  Pa = 'Pa',
  Pl = 'Pl',
  Ps = 'Ps',
  Pt = 'Pt',
  Pt_Pt = 'Pt_Pt',
  Ro = 'Ro',
  Ru = 'Ru',
  Si = 'Si',
  Sk = 'Sk',
  Sl = 'Sl',
  So = 'So',
  Sq = 'Sq',
  Sr = 'Sr',
  Sv = 'Sv',
  Sw = 'Sw',
  Ta = 'Ta',
  Te = 'Te',
  Th = 'Th',
  Tl = 'Tl',
  Tr = 'Tr',
  Uk = 'Uk',
  Ur = 'Ur',
  Uz = 'Uz',
  Vi = 'Vi',
  Zh = 'Zh',
  Zh_Tw = 'Zh_Tw',
  Eu = 'Eu',
  Be = 'Be',
  Ceb = 'Ceb',
  Ny = 'Ny',
  Co = 'Co',
  Fy_Nl = 'Fy_Nl',
  Gl = 'Gl',
  Haw = 'Haw',
  Hmn = 'Hmn',
  Ig = 'Ig',
  Jv = 'Jv',
  Km = 'Km',
  Rw = 'Rw',
  Ku = 'Ku',
  Ky = 'Ky',
  Lo = 'Lo',
  Lb = 'Lb',
  Mg = 'Mg',
  Mi = 'Mi',
  My_Mm = 'My_Mm',
  Ne = 'Ne',
  Or = 'Or',
  Sm = 'Sm',
  Gd = 'Gd',
  St = 'St',
  Sn = 'Sn',
  Sd = 'Sd',
  Su = 'Su',
  Tg = 'Tg',
  Tt = 'Tt',
  Tk = 'Tk',
  Ug = 'Ug',
  Xh = 'Xh',
  Yi = 'Yi',
  Yo = 'Yo',
  Zu = 'Zu',
}

export enum HotlineLanguage {
  Af = 'Af',
  Ar = 'Ar',
  Eu = 'Eu',
  Bn = 'Bn',
  Bg = 'Bg',
  Ca = 'Ca',
  Zh = 'Zh',
  Zh_Tw = 'Zh_Tw',
  Cs = 'Cs',
  Da = 'Da',
  Nl = 'Nl',
  En_Gb = 'En_Gb',
  En_Us = 'En_Us',
  Fi = 'Fi',
  Fr = 'Fr',
  Fr_Ca = 'Fr_Ca',
  De = 'De',
  El = 'El',
  Gu = 'Gu',
  He = 'He',
  Hi = 'Hi',
  Hu = 'Hu',
  Is = 'Is',
  Id = 'Id',
  It = 'It',
  Ja = 'Ja',
  Kn = 'Kn',
  Ko = 'Ko',
  Lv = 'Lv',
  Ms = 'Ms',
  Ml = 'Ml',
  Nb = 'Nb',
  Pl = 'Pl',
  Pt = 'Pt',
  Pt_Pt = 'Pt_Pt',
  Pa = 'Pa',
  Ro = 'Ro',
  Ru = 'Ru',
  Sr = 'Sr',
  Sk = 'Sk',
  Es_Mx = 'Es_Mx',
  Es = 'Es',
  Sv = 'Sv',
  Ta = 'Ta',
  Te = 'Te',
  Th = 'Th',
  Tr = 'Tr',
  Uk = 'Uk',
  Vi = 'Vi',
  Cy = 'Cy',
}

export enum Currency {
  DEM = 'DEM',
  ETB = 'ETB',
  LAJ = 'LAJ',
  ILR = 'ILR',
  CHF = 'CHF',
  LSM = 'LSM',
  USS = 'USS',
  UGW = 'UGW',
  VNC = 'VNC',
  XXX = 'XXX',
  TPE = 'TPE',
  ESA = 'ESA',
  MZM = 'MZM',
  MUR = 'MUR',
  GWE = 'GWE',
  KMF = 'KMF',
  MVQ = 'MVQ',
  BRB = 'BRB',
  WST = 'WST',
  UYU = 'UYU',
  RUR = 'RUR',
  BRR = 'BRR',
  ZWC = 'ZWC',
  XFO = 'XFO',
  ZRZ = 'ZRZ',
  BHD = 'BHD',
  BOB = 'BOB',
  XSU = 'XSU',
  ZWL = 'ZWL',
  EEK = 'EEK',
  MMK = 'MMK',
  GNE = 'GNE',
  LUC = 'LUC',
  USD = 'USD',
  DOP = 'DOP',
  ATS = 'ATS',
  ZMK = 'ZMK',
  SCR = 'SCR',
  TWD = 'TWD',
  ECV = 'ECV',
  KRW = 'KRW',
  ZWD = 'ZWD',
  UGX = 'UGX',
  BYN = 'BYN',
  NOK = 'NOK',
  AYM = 'AYM',
  NIO = 'NIO',
  ILS = 'ILS',
  HRK = 'HRK',
  AOK = 'AOK',
  BOP = 'BOP',
  XEU = 'XEU',
  BUK = 'BUK',
  CSJ = 'CSJ',
  TRL = 'TRL',
  XUA = 'XUA',
  UAK = 'UAK',
  CAD = 'CAD',
  NLG = 'NLG',
  VND = 'VND',
  BGN = 'BGN',
  UAH = 'UAH',
  IEP = 'IEP',
  MTL = 'MTL',
  CZK = 'CZK',
  LVL = 'LVL',
  XBD = 'XBD',
  CLF = 'CLF',
  TMT = 'TMT',
  BRN = 'BRN',
  GQE = 'GQE',
  NPR = 'NPR',
  ARS = 'ARS',
  AUD = 'AUD',
  HKD = 'HKD',
  HUF = 'HUF',
  MVR = 'MVR',
  AOA = 'AOA',
  BOV = 'BOV',
  RSD = 'RSD',
  MXV = 'MXV',
  SZL = 'SZL',
  IQD = 'IQD',
  USN = 'USN',
  ERN = 'ERN',
  KGS = 'KGS',
  ILP = 'ILP',
  UYN = 'UYN',
  CSK = 'CSK',
  SVC = 'SVC',
  BRE = 'BRE',
  YUN = 'YUN',
  TJR = 'TJR',
  CNY = 'CNY',
  MDL = 'MDL',
  CRC = 'CRC',
  ROL = 'ROL',
  MRO = 'MRO',
  SRD = 'SRD',
  CUP = 'CUP',
  GEK = 'GEK',
  XAU = 'XAU',
  GHC = 'GHC',
  HNL = 'HNL',
  XBC = 'XBC',
  SUR = 'SUR',
  SKK = 'SKK',
  ZAR = 'ZAR',
  VUV = 'VUV',
  ITL = 'ITL',
  CHC = 'CHC',
  BWP = 'BWP',
  GEL = 'GEL',
  MXN = 'MXN',
  PKR = 'PKR',
  BMD = 'BMD',
  AOR = 'AOR',
  ZWR = 'ZWR',
  AFN = 'AFN',
  GTQ = 'GTQ',
  JOD = 'JOD',
  OMR = 'OMR',
  ALK = 'ALK',
  ESB = 'ESB',
  UGS = 'UGS',
  HTG = 'HTG',
  IDR = 'IDR',
  ZMW = 'ZMW',
  TRY = 'TRY',
  LUL = 'LUL',
  PEI = 'PEI',
  GBP = 'GBP',
  MAD = 'MAD',
  MGF = 'MGF',
  MKD = 'MKD',
  ZRN = 'ZRN',
  MGA = 'MGA',
  AED = 'AED',
  STD = 'STD',
  CHE = 'CHE',
  YUD = 'YUD',
  BND = 'BND',
  YER = 'YER',
  AWG = 'AWG',
  LSL = 'LSL',
  VEB = 'VEB',
  LUF = 'LUF',
  BBD = 'BBD',
  TMM = 'TMM',
  GHP = 'GHP',
  ALL = 'ALL',
  GHS = 'GHS',
  UZS = 'UZS',
  AMD = 'AMD',
  MOP = 'MOP',
  AFA = 'AFA',
  BGK = 'BGK',
  FJD = 'FJD',
  MNT = 'MNT',
  PAB = 'PAB',
  ADP = 'ADP',
  AZM = 'AZM',
  BIF = 'BIF',
  ARA = 'ARA',
  LYD = 'LYD',
  MYR = 'MYR',
  XDR = 'XDR',
  SGD = 'SGD',
  CHW = 'CHW',
  MXP = 'MXP',
  PGK = 'PGK',
  AZN = 'AZN',
  BEC = 'BEC',
  GNS = 'GNS',
  RUB = 'RUB',
  THB = 'THB',
  XBA = 'XBA',
  XBB = 'XBB',
  SLL = 'SLL',
  CUC = 'CUC',
  BEL = 'BEL',
  DZD = 'DZD',
  SYP = 'SYP',
  BZD = 'BZD',
  INR = 'INR',
  NZD = 'NZD',
  JMD = 'JMD',
  SSP = 'SSP',
  SEK = 'SEK',
  LVR = 'LVR',
  FIM = 'FIM',
  TZS = 'TZS',
  MZE = 'MZE',
  PEH = 'PEH',
  ZAL = 'ZAL',
  SBD = 'SBD',
  BGJ = 'BGJ',
  PEN = 'PEN',
  LKR = 'LKR',
  FRF = 'FRF',
  HRD = 'HRD',
  UYP = 'UYP',
  UYI = 'UYI',
  XCD = 'XCD',
  LRD = 'LRD',
  TJS = 'TJS',
  MTP = 'MTP',
  MLF = 'MLF',
  KES = 'KES',
  VEF = 'VEF',
  ROK = 'ROK',
  FKP = 'FKP',
  DJF = 'DJF',
  MWK = 'MWK',
  LAK = 'LAK',
  SOS = 'SOS',
  GRD = 'GRD',
  XRE = 'XRE',
  KWD = 'KWD',
  PHP = 'PHP',
  XPD = 'XPD',
  XAF = 'XAF',
  NGN = 'NGN',
  GWP = 'GWP',
  LTL = 'LTL',
  GYD = 'GYD',
  COP = 'COP',
  XTS = 'XTS',
  BSD = 'BSD',
  SAR = 'SAR',
  ARP = 'ARP',
  CYP = 'CYP',
  CSD = 'CSD',
  SIT = 'SIT',
  QAR = 'QAR',
  XFU = 'XFU',
  RWF = 'RWF',
  KHR = 'KHR',
  TND = 'TND',
  ZWN = 'ZWN',
  CVE = 'CVE',
  JPY = 'JPY',
  SDG = 'SDG',
  ARY = 'ARY',
  XPF = 'XPF',
  BTN = 'BTN',
  IRR = 'IRR',
  GNF = 'GNF',
  PLZ = 'PLZ',
  DDM = 'DDM',
  SDP = 'SDP',
  BDT = 'BDT',
  ISJ = 'ISJ',
  GIP = 'GIP',
  EUR = 'EUR',
  ESP = 'ESP',
  KPW = 'KPW',
  EGP = 'EGP',
  NIC = 'NIC',
  AON = 'AON',
  ISK = 'ISK',
  NAD = 'NAD',
  RON = 'RON',
  BAM = 'BAM',
  CLP = 'CLP',
  ECS = 'ECS',
  PTE = 'PTE',
  RHD = 'RHD',
  CDF = 'CDF',
  SRG = 'SRG',
  DKK = 'DKK',
  LTT = 'LTT',
  SDD = 'SDD',
  MZN = 'MZN',
  TOP = 'TOP',
  KZT = 'KZT',
  BRC = 'BRC',
  BGL = 'BGL',
  ANG = 'ANG',
  XPT = 'XPT',
  KYD = 'KYD',
  GMD = 'GMD',
  BRL = 'BRL',
  XAG = 'XAG',
  COU = 'COU',
  SHP = 'SHP',
  BAD = 'BAD',
  BEF = 'BEF',
  PYG = 'PYG',
  YUM = 'YUM',
  BYR = 'BYR',
  BYB = 'BYB',
  PLN = 'PLN',
  TTD = 'TTD',
  XOF = 'XOF',
  PES = 'PES',
  LBP = 'LBP',
}
