import { type FieldValues, type UseControllerProps, useController } from 'react-hook-form'
import { splitPropsBetweenFormInputAndFormItemWrapper } from '../functions'
import type { FormItemWrapperProps } from '../typings'

type FnUseCustomController = <T extends FieldValues>(
  props: UseControllerProps<T> & FormItemWrapperProps
) => {
  formItemProps: FormItemWrapperProps
  // Should be fixed in the future
  // biome-ignore lint/suspicious/noExplicitAny:
  inputProps: any
}

export const useCustomController: FnUseCustomController = ({
  name,
  control,
  defaultValue,
  rules,
  shouldUnregister,
  ...props
}) => {
  const { formItemWrapperProps, formInputProps } =
    splitPropsBetweenFormInputAndFormItemWrapper(props)

  const { field, fieldState } = useController({
    name,
    control,
    defaultValue,
    rules,
    shouldUnregister,
  })
  const controlWithSchema = control as unknown as {
    schema: {
      fields: Record<typeof name, { spec: { optional: boolean } }>
    }
  }

  const recursivelyGetIsItemOptional = (
    schema: { fields: Record<string, unknown> },
    name: string
  ): boolean => {
    if (name.includes('.')) {
      const [first, ...rest] = name.split('.')
      return recursivelyGetIsItemOptional(
        schema.fields[first as string] as { fields: Record<string, unknown> },
        rest.join('.')
      )
    }
    return (schema.fields[name] as { spec: { optional: boolean } })?.spec?.optional ?? false
  }

  const withAsterisk =
    formItemWrapperProps.withAsterisk !== undefined
      ? formItemWrapperProps.withAsterisk
      : !recursivelyGetIsItemOptional(controlWithSchema.schema, name)
  return {
    formItemProps: {
      ...formItemWrapperProps,
      errorMessage: fieldState.error?.message,
      withAsterisk,
    },
    inputProps: { ...formInputProps, ...field, disabled: formInputProps.disabled },
  }
}
