import { Institution } from './enums'
import { type Language, languageEnumToBasename } from './localization'

export const buildUrl = (url: string, params: Record<string, string | string[]>) => {
  const result = new URL(url)
  Object.entries(params).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      value.forEach(item => result.searchParams.append(`${key}`, item))
    } else {
      result.searchParams.append(key, value)
    }
  })
  return result.toString()
}

export const getRootDomain = (url: string) => {
  try {
    const { hostname } = new URL(url)
    return hostname.split('.').slice(-2).join('.')
  } catch {
    return 'faceup.com'
  }
}

export const getAdministrationDomain = (environment: string) => {
  if (environment === 'development') {
    return 'http://localhost:3040'
  }

  return window.location.origin.replace('.app.', '.admin.')
}

export const getReportingAppDomain = (environment: string) => {
  if (environment === 'development') {
    return 'http://localhost:3050'
  }

  return window.location.origin.replace('.admin.', '.app.')
}

export const generateShortIdUrl = (
  environment: string,
  shortId: string | null,
  institution: Institution | null,
  language: string | null = null,
  forceDirectLink = false,
  isCheck = false
) => {
  const shortIdPath = `${language ? `/${languageEnumToBasename(language)}` : ''}/${
    institution === Institution.School ? 's' : 'c'
  }/${shortId ?? ''}${isCheck ? '/check' : ''}`

  if (forceDirectLink || environment === 'development' || environment === 'preview') {
    return `${getReportingAppDomain(environment ?? '')}${shortIdPath}`
  }

  const { protocol, origin } = window.location

  const environmentDomain = environment === 'stage' ? 'stage.' : ''

  return `${protocol}//${environmentDomain}${getRootDomain(origin)}${shortIdPath}`
}

export const generateSurveyUrl = (
  environment: string,
  shortLink: string | null,
  language?: Language,
  forceDirectLink = false
) => {
  const generateSurveyPath = (shortLink: string | null, language?: Language) =>
    `${language ? `/${languageEnumToBasename(language)}` : ''}/q/${shortLink ?? ''}`

  const surveyPath = generateSurveyPath(shortLink, language)

  if (environment === 'development' || environment === 'preview' || forceDirectLink) {
    return `${getReportingAppDomain(environment)}${surveyPath}`
  }
  const { protocol, origin } = window.location

  const environmentDomain = environment === 'stage' ? 'stage.' : ''

  return `${protocol}//${environmentDomain}${getRootDomain(origin)}${surveyPath}`
}

export const getApiUrlByCurrentDomain = (url: string) => {
  try {
    const parsedUrl = new URL(url)

    const replaceUrl = (domain: string) => {
      if (
        parsedUrl.hostname.includes('.faceup.com') &&
        window.location.hostname.includes(`.${domain}`)
      ) {
        parsedUrl.hostname = parsedUrl.hostname.replace('.faceup.com', `.${domain}`)
      }
    }

    replaceUrl('nntb.cz')
    replaceUrl('nntb.sk')

    return parsedUrl.toString()
  } catch {
    return url
  }
}

const stubBase = 'https://example.com/'
/**
 * When you just want to create the path, not the whole URL.
 * I'm using the stubBase, so I can use the buildUrl which uses URL which needs the domain part.
 */
export const buildPath = (base: string, params: Record<string, string | string[]>) =>
  buildUrl(`${stubBase}${base}`, params).replace(stubBase, '')
