import { gql, useMutation } from '@apollo/client'
import type { ModalProps } from '@faceup/ui'
import { notification } from '@faceup/ui-base'
import { omitNullInArray } from '@faceup/utils'
import type { JSX } from 'react'
import { sharedMessages } from '../../Shared/translations'
import { FormattedMessage, defineMessages, useIntl } from '../../TypedIntl'
import type {
  EditManagerModal_institution,
  EditManagerModal_manager,
  EditManagerModal_managerToEdit,
  EditManagerMutation,
  EditManagerMutationVariables,
  PermissionType,
} from '../../__generated__/globalTypes'
import { useMotherId } from '../../providers'
import {
  AbstractManagerModal,
  AbstractManagerModalFragments,
  type AccessKey,
  accesses,
} from './AbstractManagerModal'

const messages = defineMessages({
  title: 'Administration.settings.editUser.title',
  cantEditAccountOwner: 'Administration.settings.permissions.cantEditAccountOwner',
})

export const EditManagerModalFragments = {
  EditManagerModal_manager: gql`
    fragment EditManagerModal_manager on Manager {
      id
      isAccountOwner(motherId: $motherId)

      ...AbstractManagerModal_manager
    }

    ${AbstractManagerModalFragments.AbstractManagerModal_manager}
  `,
  EditManagerModal_managerToEdit: gql`
    fragment EditManagerModal_managerToEdit on Manager {
      id
      isAccountOwner(motherId: $motherId)
      name
      phone
      companyIds(motherId: $motherId)
      email
      mother(motherId: $motherId) {
        id
        config {
          id
          reportCategories {
            id
          }
        }
      }
      keys {
        permissions(motherId: $motherId) {
          type
          enabled
          additionalData {
            categoryIds
          }
        }
      }
    }
  `,
  EditManagerModal_institution: gql`
    fragment EditManagerModal_institution on Company {
      id
      ...AbstractManagerModal_institution
    }
    ${AbstractManagerModalFragments.AbstractManagerModal_institution}
  `,
}

const mutations = {
  EditManager: gql`
    mutation EditManagerMutation($input: EditManagerInput!, $motherId: CompanyGlobalId!) {
      editManager(input: $input) {
        manager {
          id
          name
          companyIds(motherId: $motherId)
        }
      }
    }
  `,
}

type EditManagerModalProps = {
  // null for kredenc
  manager: EditManagerModal_manager | null
  institution: EditManagerModal_institution
  managerToEdit: EditManagerModal_managerToEdit
  onCompleted: () => void
} & Required<Pick<ModalProps, 'opened' | 'onClose'>>

export const EditManagerModal = ({
  opened,
  manager,
  institution,
  onClose,
  managerToEdit,
  onCompleted,
}: EditManagerModalProps) => {
  const { formatMessage } = useIntl()
  const { getMotherId } = useMotherId()

  const isKredenc = !manager

  const [editManager] = useMutation<EditManagerMutation, EditManagerMutationVariables>(
    mutations.EditManager,
    {
      onError: error => {
        console.error(error)
        notification.error({
          message: formatMessage(sharedMessages.apiError),
          description: error.message,
        })
      },
      onCompleted,
    }
  )

  const companyIds = omitNullInArray(managerToEdit.companyIds)

  const defaultValues = {
    email: managerToEdit.email ?? '',
    institutionIds: companyIds,
    permissions: managerToEdit.keys.permissions,
  }

  return (
    <AbstractManagerModal
      modalVariant='edit'
      title={<FormattedMessage {...messages.title} />}
      manager={manager}
      institution={institution}
      opened={opened}
      onClose={onClose}
      overrideDisabledMessageForType={(
        permissionType: PermissionType,
        originalMessage: JSX.Element | null
      ) => {
        if (
          accesses.settings.subItems?.map(({ type }) => type).includes(permissionType) &&
          managerToEdit.isAccountOwner
        ) {
          return <FormattedMessage {...messages.cantEditAccountOwner} />
        }

        return originalMessage
      }}
      overrideDisabledMessageForSection={(
        section: AccessKey,
        originalMessage: JSX.Element | null
      ) => {
        if (section === 'settings' && managerToEdit.isAccountOwner) {
          return <FormattedMessage {...messages.cantEditAccountOwner} />
        }

        return originalMessage
      }}
      defaultValues={defaultValues}
      onSubmit={async values => {
        const result = await editManager({
          variables: {
            motherId: getMotherId(),
            input: {
              companyIds: values.institutionIds,
              permissions: values.permissions,
              motherId: getMotherId(),
              managerId: managerToEdit.id,
              name: managerToEdit.name ?? '',
              ...(isKredenc && { email: values.email }),
            },
          },
        })

        if (result.errors) {
          return false
        }

        onClose()
        return true
      }}
    />
  )
}
