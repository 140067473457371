import { useEffect, useState } from 'react'

type FnUseAwait = <D = unknown>(data: Promise<D>) => D | undefined

export const useAwait: FnUseAwait = <D>(data: Promise<D>) => {
  const [resolvedData, setResolvedData] = useState<D>()
  useEffect(() => {
    const resolvePromise = async () => {
      const resolvedData = await data
      setResolvedData(resolvedData)
    }
    void resolvePromise()
  }, [data])

  return resolvedData ?? undefined
}
