import { gql } from '@apollo/client'
import { Card, TableEnhanced } from '@faceup/ui'
import type { ReportSourcesTable_reportSource as OtherReportSourceType } from '../__generated__/globalTypes'

export const ReportSourcesTable_reportSource = gql`
  fragment ReportSourcesTable_reportSource on ReportSource {
    id
    sourceType
    shortLink
    hasActivePassword
    reportPassword(motherId: $motherId)
  }
`

type Props = {
  reportSources: OtherReportSourceType[]
  loading: boolean
}

const ReportSourcesTable = ({ reportSources, loading }: Props) => {
  const makeTableRow = (reportSource: OtherReportSourceType) => ({
    id: reportSource.id,
    password: reportSource.reportPassword,
    type: reportSource.sourceType,
    shortLink: reportSource.shortLink,
    hasActivePassword: reportSource.hasActivePassword,
  })

  return (
    <Card.Segment title='Reporting forms' hasNoPadding>
      <TableEnhanced<ReturnType<typeof makeTableRow>>
        style={{ marginTop: 20 }}
        loading={loading}
        rowKey='id'
        dataSource={reportSources.map(reportSource => makeTableRow(reportSource))}
        locale={{ emptyText: ' ' }}
        scroll={{ x: 'max-content' }}
        columns={[
          {
            title: 'Type',
            dataIndex: 'type',
            key: 'type',
          },
          {
            title: 'Has active password',
            dataIndex: 'hasActivePassword',
            key: 'hasActivePassword',
            render: hasActivePassword => <>{hasActivePassword ? 'Yes' : 'No'}</>,
          },
          {
            title: 'Password',
            dataIndex: 'password',
            key: 'password',
          },
          {
            title: 'Short link',
            dataIndex: 'shortLink',
            key: 'shortLink',
            render: shortLink => (
              <>
                <a
                  target='_blank'
                  href={`https://www.faceup.com/c/${shortLink}`}
                  rel='noreferrer noopener'
                >
                  https://faceup.com/c/{shortLink}
                </a>
                <br />
                <a
                  target='_blank'
                  href={`https://www.nntb.cz/c/${shortLink}`}
                  rel='noreferrer noopener'
                >
                  https://nntb.cz/c/{shortLink}
                </a>
              </>
            ),
          },
        ]}
        pagination={false}
      />
    </Card.Segment>
  )
}

export default ReportSourcesTable
