import { gql, useMutation } from '@apollo/client'
import { notification } from '@faceup/ui-base'
import type {
  AllPartnersQuery,
  EditCompanyService,
  EditCompanyServiceFragment,
  EditCompanyServiceInput,
  EditCompanyServiceVariables,
} from '../../__generated__/globalTypes'
import CompanyServiceEditor, {
  fragments as companyServiceEditorFragment,
} from './CompanyServiceEditor'

type AllPartnersQuery_partners_items_item = NonNullable<
  NonNullable<AllPartnersQuery['partners']>['items']
>[number]

export const fragments = {
  EDIT_COMPANY_SERVICE_FRAGMENT: gql`
    fragment EditCompanyServiceFragment on CompanyService {
      id
      ...CompanyServiceEditorFragment
    }
    ${companyServiceEditorFragment.CompanyServiceEditorFragment}
  `,
}

const mutation = {
  EditCompanyService: gql`
    mutation EditCompanyService($input: EditCompanyServiceInput!) {
      editCompanyService(input: $input) {
        service {
          id
          ...EditCompanyServiceFragment
        }
      }
    }
    ${fragments.EDIT_COMPANY_SERVICE_FRAGMENT}
  `,
}

type Props = {
  visible: boolean
  setVisible: (visible: boolean) => void
  service: EditCompanyServiceFragment
  partners: (AllPartnersQuery_partners_items_item | null)[] | null | undefined
}

const EditCompanyServiceModal = ({ service, visible, setVisible, partners }: Props) => {
  const [editCompanyService, { loading }] = useMutation<
    EditCompanyService,
    EditCompanyServiceVariables
  >(mutation.EditCompanyService, {
    onError: error => {
      console.error(error)
      notification.error({
        message: 'GQL Error',
        description: error.message,
      })
    },
    onCompleted: () => setVisible(false),
  })

  return (
    <CompanyServiceEditor
      loading={loading}
      service={service}
      visible={visible}
      partners={partners}
      setVisible={setVisible}
      saveChanges={newService => {
        editCompanyService({
          variables: {
            input: {
              serviceId: service.id,
              ...newService,
            } as EditCompanyServiceInput,
          },
        })
      }}
    />
  )
}

export default EditCompanyServiceModal
