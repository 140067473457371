import { gql } from '@apollo/client'
import type { FormItemType, Language } from '@faceup/utils'
import { Fragment, type ReactNode } from 'react'
import type { FormItemsAnswers_companyReport } from '../__generated__/globalTypes'
import { useFormItemsAnswers, useFormItemsAnswersFragments } from '../hooks'

export type FormItemAnswer = {
  id: string
  label: string
  value: string
  type: FormItemType
  // Required for redaction
  formItemId: string
}

type FormItemsAnswersProps = {
  companyReport: FormItemsAnswers_companyReport
  renderFormItemAnswer: (props: FormItemAnswer) => ReactNode
  defaultLanguage?: Language
}

export const FormItemsAnswersFragments = {
  FormItemsAnswers_companyReport: gql`
    fragment FormItemsAnswers_companyReport on CompanyReport {
      id
      ...useFormItemsAnswers_companyReport
    }
    ${useFormItemsAnswersFragments.useFormItemsAnswers_companyReport}
  `,
}

export const FormItemsAnswers = (props: FormItemsAnswersProps) => {
  const { renderFormItemAnswer, companyReport, defaultLanguage } = props
  const getFormItemsAnswers = useFormItemsAnswers()

  return (
    <>
      {getFormItemsAnswers(companyReport.answers, defaultLanguage).map(answer => (
        <Fragment key={answer.id}>{renderFormItemAnswer(answer)}</Fragment>
      ))}
    </>
  )
}
