import { type Routes, generatePath } from '@faceup/router'

export const routes: Record<keyof RoutesInterface, string> = {
  home: '/',
  dashboard: '/',
  createAdmin: '/create-admin',
  schools: '/schools',
  companies: '/companies',
  institution: '/institution/:id',
  services: '/services',
  service: '/service/:id',
  reports: '/reports',
  report: '/report/:id',
  partners: '/partners',
  partner: '/partner/:id',
  materials: '/materials',
  globalTables: '/global-tables',
}

export const useRoutes = (): Routes => ({
  home: () => generatePath(routes.home),
  dashboard: () => generatePath(routes.dashboard),
  createAdmin: () => generatePath(routes.createAdmin),
  schools: () => generatePath(routes.schools),
  companies: () => generatePath(routes.companies),
  institution: ({ id }) => generatePath(routes.institution, { id }),
  services: () => generatePath(routes.services),
  service: ({ id }) => generatePath(routes.service, { id }),
  reports: () => generatePath(routes.reports),
  report: ({ id }) => generatePath(routes.report, { id }),
  partners: () => generatePath(routes.partners),
  partner: ({ id }) => generatePath(routes.partner, { id }),
  materials: () => generatePath(routes.materials),
  globalTables: () => generatePath(routes.globalTables),
})

interface RoutesInterface {
  home: RouteNoParams
  dashboard: RouteNoParams
  createAdmin: RouteNoParams
  schools: RouteNoParams
  companies: RouteNoParams
  institution: RouteWithParams<{ id: string }>
  services: RouteNoParams
  service: RouteWithParams<{ id: string }>
  reports: RouteNoParams
  report: RouteWithParams<{ id: string }>
  partners: RouteNoParams
  partner: RouteWithParams<{ id: string }>
  materials: RouteNoParams
  globalTables: RouteNoParams
}

type RouteNoParams = () => string
type RouteWithParams<P> = (params: P) => string

declare module '@faceup/router' {
  export interface RoutesOverride extends RoutesInterface {}
}
