import { UntitledIcon } from '@faceup/icons'
import { ulChevronDown } from '@faceup/icons/ulChevronDown'
import { ulGlobe02 } from '@faceup/icons/ulGlobe02'
import type { Language } from '@faceup/utils'
import { Group, Menu, ScrollArea, Text, UnstyledButton } from '@mantine/core'
import { type ComponentProps, type ForwardedRef, forwardRef } from 'react'
import { useLanguageName } from '../hooks'

type LanguageDropdownProps = {
  languages: Language[]
  activeLanguage: Language
  areLanguagesNative?: boolean
  onChangeLanguage: (language: Language) => void
  styles?: { targetText?: Omit<ComponentProps<typeof Text>, 'children'> }
}

export const LanguageDropdown = forwardRef(
  (
    {
      languages,
      activeLanguage,
      onChangeLanguage,
      areLanguagesNative = false,
      styles,
    }: LanguageDropdownProps,
    ref: ForwardedRef<HTMLElement>
  ) => {
    const { getLanguageName, getLanguageList } = useLanguageName(areLanguagesNative)
    return (
      <Menu>
        <Menu.Target ref={ref}>
          <UnstyledButton data-cy='language-dropdown'>
            <Text {...styles?.targetText} component={Group} spacing='xs'>
              <UntitledIcon icon={ulGlobe02} />
              {getLanguageName(activeLanguage)}
              <UntitledIcon icon={ulChevronDown} size={18} />
            </Text>
          </UnstyledButton>
        </Menu.Target>
        <Menu.Dropdown data-cy='language-dropdown-menu'>
          <ScrollArea.Autosize
            mah={400}
            placeholder={null}
            onPointerEnterCapture={undefined}
            onPointerLeaveCapture={undefined}
          >
            {getLanguageList(languages).map(({ language, name }) => (
              <Menu.Item
                key={language}
                p='xs'
                color={activeLanguage === language ? 'primary' : undefined}
                onClick={() => onChangeLanguage(language)}
              >
                {name}
              </Menu.Item>
            ))}
          </ScrollArea.Autosize>
        </Menu.Dropdown>
      </Menu>
    )
  }
)
