import { UntitledIcon } from '@faceup/icons'
import { ulEye } from '@faceup/icons/ulEye'
import { ulEyeOff } from '@faceup/icons/ulEyeOff'
import {
  type ButtonStylesParams,
  type DefaultMantineColor,
  type MantineTheme,
  MantineProvider as Provider,
  type Tuple,
  createEmotionCache,
} from '@mantine/core'
import { shade, tint } from 'polished'
import type { ReactNode } from 'react'
import stylisRTLPlugin from 'stylis-plugin-rtl'
import { transitionDuration } from '../constants'
import { useThemeColors } from '../hooks'

const radius = {
  xs: '3px',
  sm: '6px',
  md: '16px',
  lg: '24px',
  xl: '48px',
}

const primaryShade = 7
const defaultRadius = radius.sm

const colors: Record<'blue' | 'prussianBlue', Tuple<string, 10>> = {
  blue: [
    '#DCF1FE', // 0
    '#DCF1FE', // 1
    '#BAE2FD', // 2
    '#97D4FC', // 3
    '#74C5FB', // 4
    '#52B7FA', // 5
    '#2FA8F9', // 6
    '#0E9AF7', // 7
    '#007EDF', // 8
    '#0069BA', // 9
  ],
  prussianBlue: [
    '#E6EAEC', // 0
    '#E6EAEC', // 1
    '#CDD5DA', // 2
    '#B4C0C8', // 3
    '#9BABB5', // 4
    '#8296A2', // 5
    '#6A8190', // 6
    '#516C7D', // 7
    '#38576B', // 8
    '#062D46', // 9
  ],
}

const black = colors['prussianBlue'][9]
const white = '#ffffff'

const getButtonModeStyle = (theme: MantineTheme, color: ExtendedCustomColors) => {
  switch (color) {
    case 'primary':
      return {
        '&[disabled]': {
          backgroundColor: theme.colors.primary[3],
          color: white,
        },
      }

    case 'secondary':
      return {
        backgroundColor: white,
        color: theme.colors.primary[7],
        borderColor: theme.colors.primary[7],
        '&:not([data-disabled]):hover': {
          backgroundColor: white,
          color: theme.colors.primary[9],
          borderColor: theme.colors.primary[9],
        },
        '&[disabled]': {
          backgroundColor: white,
          color: theme.colors.primary[3],
          borderColor: theme.colors.primary[3],
        },
      }

    case 'tertiary':
      return {
        backgroundColor: theme.colors.prussianBlue[2],
        color: theme.colors.prussianBlue[9],
        '&:hover': {
          backgroundColor: theme.colors.prussianBlue[1],
          color: theme.colors.prussianBlue[9],
        },
        '&[disabled]': {
          backgroundColor: '#f4f5f5',
          color: theme.colors.prussianBlue[9],
        },
      }

    default:
      return {}
  }
}

const rtlCache = createEmotionCache({
  key: 'mantine-rtl',
  stylisPlugins: [stylisRTLPlugin],
})

const COLOR_OFFSET_CONSTRAINT = 0.13

type MantineProviderProps = {
  direction: MantineTheme['dir']
  children: ReactNode
}

const MantineProvider = ({ direction, children }: MantineProviderProps) => {
  const { getColorFromTheme, overriddenPrimaryColor } = useThemeColors()

  const getPrimaryColor = (): Tuple<string, 10> => {
    if (overriddenPrimaryColor === null) {
      return colors.blue
    }
    return [
      ...Array.from(Array(7).keys())
        .reverse()
        .map(key => tint((key + 1) * COLOR_OFFSET_CONSTRAINT, overriddenPrimaryColor)),
      overriddenPrimaryColor,
      ...Array.from(Array(2).keys()).map(key =>
        shade((key + 1) * COLOR_OFFSET_CONSTRAINT, overriddenPrimaryColor)
      ),
    ] as Tuple<string, 10>
  }

  return (
    <Provider
      withCSSVariables
      // https://mantine.dev/guides/rtl/#dynamic-direction-changes
      emotionCache={direction === 'rtl' ? rtlCache : undefined}
      theme={{
        dir: direction,
        colors: { ...colors, primary: getPrimaryColor() },
        primaryShade,
        defaultRadius,
        black,
        primaryColor: 'primary',
        radius,
        fontFamily: `Inter, sans-serif`,
        headings: {
          fontFamily: 'Inter',
          fontWeight: 600,
          sizes: {
            h1: {
              fontSize: '38px',
            },
            h2: {
              fontSize: '30px',
            },
            h3: {
              fontSize: '24px',
            },
            h4: {
              fontSize: '20px',
            },
            h5: {
              fontSize: '16px',
            },
            h6: {
              fontSize: '14px',
            },
          },
        },
        shadows: {
          xs: '0px 0px 0px 1px rgba(230,234,236,1)',
        },
        components: {
          Checkbox: {
            styles: {
              input: {
                cursor: 'pointer',
                width: '1rem',
                height: '1rem',
              },
              label: {
                cursor: 'pointer',
                paddingLeft: '0.5rem',
              },
              inner: {
                display: 'flex',
                alignItems: 'center',
                width: '1rem',
              },
            },
          },
          Text: {
            defaultProps: {
              size: 'sm',
            },
          },
          Tooltip: {
            defaultProps: {
              withArrow: true,
              withinPortal: true,
            },
            styles: {
              tooltip: {
                backgroundColor: getColorFromTheme('dark.100'),
              },
            },
          },
          Avatar: {
            defaultProps: {
              radius: 'xl',
            },
          },
          Menu: {
            defaultProps: {
              openDelay: 100,
              closeDelay: 300,
              shadow: 'md',
            },
            styles: {
              dropdown: {
                zIndex: '10000!important' as unknown as number,
                boxSizing: 'border-box',
              },
              item: {
                transition: `background-color ${transitionDuration}`,
                '&:hover': {
                  backgroundColor: getColorFromTheme('dark.4'),
                },
              },
            },
          },
          CloseButton: {
            defaultProps: {
              variant: 'transparent',
            },
          },
          UnstyledButton: {
            styles: {
              root: {
                color: 'inherit',
                fontSize: '14px',
              },
            },
          },
          Alert: {
            styles: {
              root: {
                paddingBlock: 8,
                paddingInline: 12,
              },
              icon: {
                marginRight: 8,
              },
            },
          },
          ActionIcon: {
            defaultProps: {
              variant: 'transparent',
            },
          },
          Select: {
            defaultProps: {
              withinPortal: true,
            },
          },
          Badge: {
            defaultProps: {
              radius: 'sm',
            },
            styles: {
              root: {
                textTransform: 'initial',
              },
            },
          },
          Button: {
            defaultProps: {
              radius: 'sm',
            },
            sizes: {
              sm: (_, params) => ({
                root: {
                  paddingInline: params.compact ? undefined : '16px',
                  height: params.compact ? undefined : '40px',
                },
              }),
              md: () => ({
                root: {
                  height: '40px',
                  fontSize: '16px',
                },
              }),
              lg: () => ({
                root: {
                  height: '56px',
                  fontSize: '16px',
                },
              }),
            },
            variants: {
              subtle: () => ({
                root: {
                  '&:not([data-disabled]):hover': {
                    backgroundColor: 'transparent',
                  },
                },
              }),
              filled: () => ({
                root: {
                  '&:hover': {
                    color: white,
                  },
                },
              }),
            },
            styles: (theme, params: ButtonStylesParams) => ({
              root: {
                transition: 'all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1)',
                ...getButtonModeStyle(theme, params.color),
              },
              leftIcon: {
                marginInlineEnd: 15,
                fontSize: '20px',
              },
              rightIcon: {
                marginInlineStart: 15,
                fontSize: '20px',
              },
            }),
          },
          Input: {
            sizes: {
              sm: () => ({
                input: {
                  minHeight: '44px',
                },
              }),
              md: () => ({
                input: {
                  minHeight: '44px',
                  height: '44px',
                  fontSize: '14px',
                },
              }),
            },
            styles: {
              icon: {
                color: colors.prussianBlue[9],
                fontSize: '20px',
              },
            },
          },
          InputWrapper: {
            styles: {
              root: {
                paddingBlockEnd: '22px',
              },
              label: {
                color: '#688699',
                fontWeight: 600,
                marginBottom: '0.25rem',
              },
              error: {
                fontSize: '0.875rem',
                lineHeight: 1.55,
                position: 'absolute',
                marginTop: -5,
              },
              required: {
                fontFamily: 'SimSun,sans-serif',
              },
            },
          },
          PasswordInput: {
            defaultProps: {
              visibilityToggleIcon: ({ reveal }: { reveal: boolean }) => (
                <UntitledIcon icon={reveal ? ulEye : ulEyeOff} color={colors.prussianBlue[5]} />
              ),
            },
            sizes: {
              sm: () => ({
                innerInput: {
                  height: 'unset',
                },
              }),
            },
          },
          Radio: {
            sizes: {
              sm: () => ({
                radio: {
                  width: '1rem',
                  height: '1rem',
                  '&:checked': {
                    background: white,
                  },
                },
                inner: {
                  height: '1.25rem',
                  display: 'flex',
                  alignItems: 'center',
                  '&>svg': {
                    color: getColorFromTheme('primary.100'),
                  },
                },
                label: {
                  paddingLeft: '0.5rem',
                },
              }),
            },
          },
          Table: {
            styles: {
              '& tr td': {
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              },
            },
          },
        },
        cursorType: 'pointer',
        globalStyles: () => ({
          '.environment-info-box': {
            position: 'fixed',
            left: '90px',
            top: 'calc(100vh - 110px)',
            textTransform: 'capitalize',
          },
        }),
      }}
    >
      {children}
    </Provider>
  )
}

type ExtendedCustomColors =
  | 'primary'
  | 'secondary'
  | 'tertiary'
  | 'prussianBlue'
  | DefaultMantineColor

declare module '@mantine/core' {
  export interface MantineThemeColorsOverride {
    colors: Record<ExtendedCustomColors, Tuple<string, 10>>
  }
}

export default MantineProvider
