import { Tooltip as AntTooltip } from '@faceup/ui-base'
import type { PropsWithChildren, ReactNode } from 'react'

export type TooltipProps = PropsWithChildren<{
  title: ReactNode
  placement?:
    | 'top'
    | 'left'
    | 'right'
    | 'bottom'
    | 'topLeft'
    | 'topRight'
    | 'bottomLeft'
    | 'bottomRight'
    | 'leftTop'
    | 'leftBottom'
    | 'rightTop'
    | 'rightBottom'
  open?: boolean
  zIndex?: number
}>

export const Tooltip = (props: TooltipProps) => (
  <AntTooltip {...props} overlayStyle={{ pointerEvents: 'none' }} />
)
