import { createContext } from 'react'

export type FeatureFlagContextProps = {
  application: boolean
  loading: boolean
  surveys: boolean
  snippets: boolean
}

export const FeatureFlagContext = createContext<FeatureFlagContextProps>({
  application: false,
  loading: true,
  surveys: false,
  snippets: false,
})
