import styled from '@emotion/styled'
import { Avatar as AntdAvatar, type AvatarProps as AntdAvatarProps } from '@faceup/ui-base'
import { type ReactNode, type Ref, forwardRef } from 'react'
import { Tooltip } from '../Tooltip'
import AvatarEmpty from './AvatarEmpty'
import AvatarOutlined from './AvatarOutlined'

export type AvatarProps = {
  isEmpty?: boolean
  title?: ReactNode
  emptyIcon?: ReactNode
  emptyTitle?: ReactNode
  variant?: 'solid' | 'outlined'
} & AntdAvatarProps

const Avatar = forwardRef(
  (
    { title, emptyIcon, emptyTitle, isEmpty = false, variant = 'solid', ...props }: AvatarProps,
    ref: Ref<HTMLDivElement>
  ) => {
    const tooltipTitle = isEmpty ? emptyTitle : title
    return (
      <Tooltip title={tooltipTitle ?? ''}>
        <Wrapper ref={ref}>
          {isEmpty ? (
            <AvatarEmpty {...props} icon={emptyIcon} />
          ) : variant === 'solid' ? (
            <AntdAvatar {...props} />
          ) : (
            <AvatarOutlined {...props} />
          )}
        </Wrapper>
      </Tooltip>
    )
  }
)

const Wrapper = styled.span`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
`

export default Avatar
