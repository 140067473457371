import { Box, Flex } from '@mantine/core'
import { useElementSize } from '@mantine/hooks'
import { type ReactNode, useEffect, useMemo, useState } from 'react'

const countVisibleElements = (elementsWidth: number[], wrapperWidth: number) => {
  let totalWidth = 0
  let visibleElements = 0
  for (const elementWidth of elementsWidth) {
    if (totalWidth + elementWidth < wrapperWidth) {
      totalWidth += elementWidth
      visibleElements += 1
    } else {
      break
    }
  }
  return visibleElements
}

type RemainingInfo = {
  remainingItemsCount: number
  shownItemsCount: number
}

type HideManyItemsProps = {
  children: ReactNode[] | ReactNode
  renderRemaining: (remainingInfo: RemainingInfo) => ReactNode
  spacing?: string
}

export const HideManyItems = ({ children, renderRemaining, spacing }: HideManyItemsProps) => {
  const { ref: wrapperRef, width: wrapperWidth } = useElementSize<HTMLDivElement>()
  const { ref: itemsRef, height: itemsHeight } = useElementSize<HTMLDivElement>()
  const [shownItems, setShownItems] = useState<number>(0)
  const [itemsWidth, setItemsWidth] = useState<number[]>()
  const [remainingWidth, setRemainingWidth] = useState<number>()

  const realChildrenArray = useMemo(
    () => (Array.isArray(children) ? children : [children]).flat(),
    [children]
  )

  useEffect(() => {
    if (itemsWidth && wrapperWidth) {
      let shownItems = countVisibleElements(itemsWidth, wrapperWidth)
      // We need space for the "More items" button
      if (shownItems < itemsWidth.length) {
        shownItems = countVisibleElements(itemsWidth, wrapperWidth - (remainingWidth ?? 0))
      }
      setShownItems(shownItems)
    }
  }, [itemsWidth, wrapperWidth, remainingWidth])

  // biome-ignore lint/correctness/useExhaustiveDependencies(realChildrenArray):
  useEffect(() => {
    // We need to recalculate shown items when children changes
    setItemsWidth(undefined)
  }, [realChildrenArray])

  const shownChildrenElements = useMemo(
    () => realChildrenArray.slice(0, shownItems),
    [realChildrenArray, shownItems]
  )
  const remainingItemsCount = useMemo(
    () => (shownItems === undefined ? 0 : realChildrenArray.length - shownItems),
    [realChildrenArray, shownItems]
  )

  return (
    <div>
      {/* This part is for computing correct width of elements. It's not visible for users */}
      <Box
        sx={{
          position: 'relative',
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            visibility: 'hidden',
            overflow: 'hidden',
            top: 0,
            left: 0,
          }}
        >
          <Box
            ref={itemsRef}
            sx={{
              display: 'inline-block',
              width: 'max-content',
              '&>div': {
                '&>div': {
                  paddingInline: `calc(${spacing}/2)`,
                },
                '&:first-of-type>div': {
                  paddingInlineStart: 0,
                },
                '&:last-of-type>div': {
                  paddingInlineEnd: 0,
                },
              },
            }}
          >
            {realChildrenArray.map((item, index) => (
              <Item
                // biome-ignore lint/suspicious/noArrayIndexKey:
                key={index}
                onChangeElementWidth={(width: number) => {
                  if (!itemsWidth || itemsWidth[index] !== width) {
                    setItemsWidth(prevState => {
                      const newState = prevState === undefined ? [] : [...prevState]
                      newState[index] = width
                      return newState
                    })
                  }
                }}
              >
                {item}
              </Item>
            ))}
            <Item
              onChangeElementWidth={width => {
                if (width !== remainingWidth) {
                  setRemainingWidth(width)
                }
              }}
            >
              {renderRemaining({ remainingItemsCount, shownItemsCount: shownItems ?? 0 })}
            </Item>
          </Box>
        </Box>
      </Box>
      {/* Here follows render */}
      <Box
        sx={{
          position: 'relative',
          height: `${itemsHeight}px`,
        }}
        ref={wrapperRef}
      >
        <Flex
          wrap='nowrap'
          sx={{
            position: 'absolute',
            overflow: 'hidden',
            flex: 1,
          }}
          gap={spacing}
        >
          {shownChildrenElements}
          {remainingItemsCount > 0 &&
            renderRemaining({ remainingItemsCount, shownItemsCount: shownItems ?? 0 })}
        </Flex>
      </Box>
    </div>
  )
}

type ItemProps = {
  children: ReactNode
  onChangeElementWidth: (width: number) => void
}

const Item = ({ children, onChangeElementWidth }: ItemProps) => {
  const { ref, width } = useElementSize<HTMLDivElement>()
  useEffect(() => {
    onChangeElementWidth(width)
  }, [width, onChangeElementWidth])
  return (
    <Box ref={ref} sx={{ display: 'inline-block' }}>
      <Box sx={{ display: 'flex' }}>{children}</Box>
    </Box>
  )
}
