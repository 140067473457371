import { Box, Divider, Flex, Group, Skeleton } from '@mantine/core'
import type { ReactNode } from 'react'
import { HintIcon } from '../AdminLayout'
import { useThemeColors } from '../hooks'
import { Text } from './Text'

export type SegmentProps = {
  title?: ReactNode
  hint?: ReactNode
  children?: ReactNode
  loading?: boolean
  extra?: ReactNode
  maxHeight?: string
  paddingContent?: string
  paddingHeader?: string
  marginDivider?: string
  footer?: ReactNode
}

export const Segment = (props: SegmentProps) => {
  const {
    children,
    title,
    hint,
    extra,
    loading = false,
    maxHeight,
    paddingContent,
    paddingHeader,
    marginDivider,
    footer,
    ...otherProps
  } = props
  const { getColorFromTheme } = useThemeColors()
  return (
    <div>
      <Flex
        {...otherProps}
        gap='16px'
        direction='column'
        sx={{
          maxHeight,
          // Needed for upsell overlay
          position: 'relative',
        }}
      >
        {Boolean(title || extra) && (
          <Flex justify='space-between' p={paddingHeader}>
            <Text variant='title' color='dark.100' sx={{ flex: '1 1 auto' }}>
              {loading ? (
                <Skeleton width='100%' height={25} />
              ) : (
                <Group>
                  {title}
                  {hint && <HintIcon title={hint} />}
                </Group>
              )}
            </Text>
            {!loading && <Flex>{extra}</Flex>}
          </Flex>
        )}
        <Box
          p={paddingContent}
          sx={{
            position: 'relative',
            overflow: maxHeight ? 'auto' : undefined,
            maxHeight,
          }}
        >
          {loading ? <Skeleton width='100%' sx={{ minHeight: 300 }} /> : children}
        </Box>
        {footer && <Box p='0 32px'>{footer}</Box>}
      </Flex>
      <Divider
        sx={{
          margin: marginDivider,
          borderTopColor: getColorFromTheme('dark.20'),
        }}
      />
    </div>
  )
}
