import styled from '@emotion/styled'
import logo from '../AdminLayout/assets/logo.svg'

const PublicHeader = () => {
  const webUrl = 'https://www.faceup.com'

  return (
    <HeaderWrapper>
      <InnerHeader>
        <a href={webUrl}>
          <Logo src={logo} alt='Logo FaceUp' />
        </a>
      </InnerHeader>
    </HeaderWrapper>
  )
}

export const HeaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  flex-shrink: 0;
`

export const InnerHeader = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 1280px;
  text-transform: uppercase;
  color: #38576b;
  font-weight: 600;
  margin: 32px 32px;
`

export const HeaderGroup = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 1.5rem;
`

export const Logo = styled.img`
  max-width: 170px;
  max-height: 44px;
  width: auto;
`

export default PublicHeader
